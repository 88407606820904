import { MarkerPin02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import { BasicContext } from 'src/context/BasicContext';

import { initShipperAddress, scriptId } from '../../order.interface';
import MapboxMap from '../MapBoxMap';

const SetMiddlePointModal = ({
  handleClose,
  handleSubmit,
  splitLegData,
}: any) => {
  const { mapService, googleKey } = useContext(BasicContext);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  const [formData, setFormData] = useState<any>({
    initShipperAddress,
  });
  const [isFullAddressChanged, setIsFullAddressChanged] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [fullAddress, setFullAddress] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      console.log('value', value);

      if (!formData.fullAddress) {
        setFormData(initShipperAddress);
        setError('Please select proper address');
        setIsFullAddressChanged(false);
      }

      return;
    }

    setIsFullAddressChanged(true);
    setFormData(value);
  };

  useEffect(() => {
    if (error) {
      setError('');
    }

    if (!fullAddress) {
      setFormData(initShipperAddress);
    }
  }, [fullAddress]);

  const handleNextClick = () => {
    if (!isFullAddressChanged || !fullAddress) {
      setError('Please select or enter a valid address.');
    } else {
      setError('');
      handleSubmit();
    }
  };

  return (
    <>
      <CommonModal
        title="Select a Midpoint"
        titleDesc="Select a midpoint to split your leg into 2."
        handleClose={handleClose}
        headerIcon={<MarkerPin02 />}
        size={'max-w-[600px]'}
        primaryBtnText="Next"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleClose}
        primaryBtnOnClick={handleNextClick}
        // primaryBtnDisabled={!isValid}
      >
        <div className="p-5 flex flex-col gap-y-3">
          {status &&
            (mapService === 'trimble_map' ? (
              <AutoCompleteTrimble
                inputName={`fullAddress`}
                required
                placeholder="Enter address"
                dropdownWrapClass="!top-[unset] bottom-[65%]"
                className={`form_control truncate`}
                label="Address"
                labelClassName="mb-1.5 block w-full"
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                isClearable
                onAddressInputChange={setFullAddress}
                value={fullAddress}
              />
            ) : (
              <AutoCompleteGoogle
                inputName={`fullAddress`}
                required
                placeholder="Enter address"
                dropdownWrapClass="!top-[unset] bottom-[65%]"
                className={`form_control truncate `}
                label="Address"
                labelClassName="mb-1.5 block w-full"
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                isClearable
                onAddressInputChange={setFullAddress}
                value={fullAddress}
              />
            ))}

          {error && <ErrorMsg errorText={error} />}

          <MapboxMap
            startPoint={{
              lat: splitLegData?.shipper?.shipperAddress?.latitude,
              lng: splitLegData?.shipper?.shipperAddress?.longitude,
              name: splitLegData?.shipper?.shipperAddress?.city,
            }}
            endPoint={{
              lat: splitLegData?.consignee?.consigneeAddress?.latitude,
              lng: splitLegData?.consignee?.consigneeAddress?.longitude,
              name: splitLegData?.consignee?.consigneeAddress?.city,
            }}
            midpoint={{
              lat: formData?.latitude,
              lng: formData?.longitude,
              name: formData?.city,
            }}
            height={500}
            className="map-container"
            markerRef={markerRef}
          />
        </div>
      </CommonModal>
    </>
  );
};

export default SetMiddlePointModal;
