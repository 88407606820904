/* eslint-disable max-lines-per-function */

import {
  AlertCircle,
  AlertHexagon,
  PackagePlus,
  SearchLg,
  Star06,
} from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { groupBy } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import ConfirmModal from 'src/components/ConfirmModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  CURRENCY,
  FOOT,
  HANDLING_UNIT_NUMBER,
  KGS,
  LBS,
  MEASUREMENT,
} from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  getPricingRate,
  listHandlingUnit,
  listService,
} from 'src/services/CommonService';
import {
  createUpdateDimension,
  createUpdateService,
} from 'src/services/QuoteService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import DimensionItems from './DimensionItems';

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
};

const initServiceValidForm = {
  limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  isEmailValid: true,
  isPhoneValid: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  isEmergencyPhoneValid: true,
  declaredValue: true,
};

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
];

interface IFormData {
  id: number | null;
  handlingUnit: string | number | null;
  handlingUnitNo: number;
  weight: number;
  itemLength: number;
  width: number;
  height: number;
  totalWeight: number;
  freightClass: number;
  isCommodity: boolean;
  isStack: boolean;
  weightMeasurement: string;
  commodityId: string | null;
  commodityName: string | null;
  sub: string | null;
  description: string | null;
}

const initFormData: IFormData = {
  id: null,
  handlingUnit: '',
  handlingUnitNo: 0,
  weight: 0,
  itemLength: 0,
  width: 0,
  height: 0,
  totalWeight: 0,
  freightClass: 0,
  isCommodity: false,
  isStack: true,
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  commodityId: null,
  commodityName: null,
  sub: null,
  description: null,
};

const providerArr = [
  { label: 'Imperial (lbs)', value: MEASUREMENT.WEIGHT1.value },
  { label: 'Metric (kgs)', value: MEASUREMENT.WEIGHT2.value },
];

const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Airport', value: 'airport' },
  { name: 'Cemetery', value: 'cemetery' },
  { name: 'Church', value: 'church' },
  { name: 'Convention Center', value: 'convention-center' },
  { name: 'Funeral Home', value: 'funeral-home' },
  { name: 'Golf Course', value: 'golf-course' },
  { name: 'Grocery Warehouse', value: 'grocery-warehouse' },
  { name: 'Hospital/Medical Facility', value: 'hospital-medical-facility' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Inspection Site', value: 'inspection-site' },
  { name: 'Marina', value: 'marina' },
  { name: 'Military Base', value: 'military-base' },
  { name: 'Mine Site', value: 'mine-site' },
  { name: 'No Dock/Dock obstruction', value: 'no-dock-dock-obstruction' },
  { name: 'Nursing Home', value: 'nursing-home' },
  { name: 'Park Forest/Preserve', value: 'park-forest-preserve' },
  { name: 'Piers and Wharves', value: 'piers-and-wharves' },
  { name: 'Prison', value: 'prison' },
  { name: 'Ranch', value: 'ranch' },
  {
    name: 'Recreational/athletic Facility',
    value: 'recreational-athletic-facility',
  },
  { name: 'Remote Government', value: 'remote-government' },
  { name: 'Reservation', value: 'reservation' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Service Station', value: 'service-station' },
  { name: 'Social Club', value: 'social-club' },
  { name: 'Steel Mill', value: 'steel-mill' },
  { name: 'Unspecified', value: 'unspecified' },
  { name: 'Utility Site', value: 'utility-site' },
  { name: 'Winery/Brewery/Distillery', value: 'winery-brewery-distillery' },
  { name: 'Other', value: 'other' },
];

const initValidForm = {
  handlingUnit: {
    required: true,
    valid: true,
  },
  handlingUnitNo: {
    required: true,
    valid: true,
  },
  weight: {
    required: true,
    valid: true,
  },
  itemLength: {
    required: true,
    valid: true,
  },
  freightClass: {
    required: true,
    valid: true,
  },
  width: {
    required: true,
    valid: true,
  },
  height: {
    required: true,
    valid: true,
  },
  totalWeight: {
    required: true,
    valid: true,
  },
  isCommodity: {
    required: true,
    valid: true,
  },
  isStack: {
    required: true,
    valid: true,
  },
  commodityId: {
    required: true,
    valid: true,
  },
  commodityName: {
    required: true,
    valid: true,
  },
  sub: {
    required: true,
    valid: true,
  },
  description: {
    required: true,
    valid: true,
  },
};

const initAction = {
  tailgate: false,
};

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

interface IProps {
  setIsCreateQuoteLoading: any;
  handleSubmitCreateQuoteRequest: any;
  dimensionsExtractedData: any;
  handleSetTotalWeight: any;
  formData: IFormData[];
  setFormData: React.Dispatch<React.SetStateAction<IFormData[]>>;
}

const DimensionSection = forwardRef(
  (
    {
      setIsCreateQuoteLoading,
      handleSubmitCreateQuoteRequest,
      dimensionsExtractedData,
      handleSetTotalWeight,
      formData,
      setFormData,
    }: IProps,
    ref
  ) => {
    // DIMENSIONS STATES
    const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
    const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
    const [validForm, setValidForm] = useState([initValidForm]);
    const [lengthMeasurement, setLengthMeasurement] = useState(
      MEASUREMENT.LENGTH1.value
    );
    const [weightMeasurement, setWeightMeasurement] = useState(
      dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
        MEASUREMENT.WEIGHT1.value ||
        dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
          MEASUREMENT.WEIGHT2.value
        ? dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement
        : MEASUREMENT.WEIGHT1.value
    );
    const [validateMeasurement, setValidateMeasurement] = useState(
      initValidateMeasurement
    );
    const [action, setAction] = useState(initAction);

    const [isTailgate, setIsTailgate] = useState<any>([]);
    const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
    const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);
    const [isOverWeightAndLengthConfirmed, setIsOverWeightAndLengthConfirmed] =
      useState(false);
    // DIMENSIONS STATES

    const handleServiceActionType = (actionKey: string) => {
      setAction((oldAction) => ({ ...oldAction, [actionKey]: true }));
    };

    useEffect(() => {
      const form = formData.map((formDataField: any) => {
        formDataField.weightMeasurement = weightMeasurement;

        return formDataField;
      });
      setFormData(form);
      const validateMeasurementValue = {
        weight:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.WEIGHT
            : LBS.WEIGHT,
        length:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.LENGTH
            : LBS.LENGTH,
        width:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.WIDTH
            : LBS.WIDTH,
        height:
          weightMeasurement === MEASUREMENT.WEIGHT2.value
            ? KGS.HEIGHT
            : LBS.HEIGHT,
      };
      setValidateMeasurement((oldValidMeasurement) => ({
        ...oldValidMeasurement,
        ...validateMeasurementValue,
      }));
    }, [weightMeasurement]);

    useEffect(() => {
      listHandlingUnit()
        .then((result: any) => {
          if (result.data && result.data.length) {
            const handlingUnitSelectBoxOptions: any = getSelectBoxOptions(
              result.data,
              'id',
              'name'
            );
            setHandlingUnitOptions(handlingUnitSelectBoxOptions);
          }
        })
        .finally(() => setIsHandlingUnitLoading(false))
        .catch(console.error);
    }, []);

    const isFormValid = () => {
      if (!isOverWeightAndLengthConfirmed) {
        if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
          handleServiceActionType('tailgate');

          return false;
        }
      }

      const validFormData: any = [];
      formData.forEach((form: any) => {
        const validation = {
          ...initValidForm,
          handlingUnit: {
            required: Boolean(form?.handlingUnit),
            valid: Boolean(form?.handlingUnit),
          },
          weight: {
            required: Boolean(form.weight),
            valid: Boolean(form.weight),
          },
          handlingUnitNo: {
            required: Boolean(form.handlingUnitNo),
            valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
          },
          itemLength: {
            required: Boolean(form.itemLength),
            valid: form.itemLength <= validateMeasurement.length,
          },
          width: {
            required: Boolean(form.width),
            valid: form.width <= validateMeasurement.width,
          },
          height: {
            required: Boolean(form.height),
            valid: form.height <= validateMeasurement.height,
          },
          freightClass: {
            required: Boolean(form.freightClass),
            valid: form.freightClass > 0,
          },
          commodityId: {
            required: form.isCommodity ? Boolean(form.commodityId) : true,
            valid: form.isCommodity ? Boolean(form.commodityId) : true,
          },
          sub: {
            required: form.isCommodity ? Boolean(form.sub) : true,
            valid: form.isCommodity ? Boolean(form.sub) : true,
          },
        };
        validFormData.push(validation);
      });
      setValidForm(validFormData);
      let valid = validFormData.every((form: any) =>
        requiredFields.every(
          (requiredField) =>
            form[requiredField].required && form[requiredField].valid
        )
      );

      return valid;
    };

    useEffect(() => {
      let totalWeight: number | string = 0;
      let weightMeasurementValue = '';

      formData.forEach((form: any) => {
        totalWeight += form.totalWeight;
        weightMeasurementValue = form.weightMeasurement;

        const maxLengthOver =
          formData?.[0].weightMeasurement === MEASUREMENT.WEIGHT1.value
            ? LBS.ITEM_LENGTH_OVER
            : KGS.ITEM_LENGTH_OVER;

        if (form.itemLength >= maxLengthOver) {
          // 5 feet over
          setIsItemLengthOver(true);
        }
      });

      totalWeight = totalWeight.toFixed();
      handleSetTotalWeight(totalWeight);

      const isTotalWeightOverBool =
        weightMeasurementValue === MEASUREMENT.WEIGHT1.value
          ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
          : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
      setIsTotalWeightOver(isTotalWeightOverBool);

      return;
    }, [formData]);

    // ADDITIONAL SERVICES CODE
    const { currency } = useContext(BasicContext);

    const [validServicesForm, setValidServicesForm] =
      useState(initServiceValidForm);
    const [serviceList, setServiceList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [serviceTypes, setServiceTypes] = useState<any>();
    const [selectedServices, setSelectedServices] = useState<number[]>([]);
    const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
      useState<boolean>(false);
    const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
    const [limitedAccessTypeList, setLimitedAccessTypeList] =
      useState<any>(limitedAccessList);
    const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
    const [limitedAccessType, setLimitedAccessType] = useState<string>(
      limitedAccessTypeList?.[0].value
    );
    const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

    const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
      useState<boolean>(false);
    const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
    const [inbondType, setInbondType] = useState<string>(
      inbondTypeList[0].value
    );
    const [inbondName, setInbondName] = useState<string>('');
    const [inbondAddress, setInbondAddress] = useState<string>('');
    const [inbondContactMethod, setInbondContactMethod] = useState<string>(
      inbondContactMethodList[0].value
    );
    const [inbondContactValue, setInbondContactValue] = useState<string>('');

    const [isDeclaredValueMore, setIsDeclaredValueMore] =
      useState<boolean>(false);
    const [declaredValue, setDeclaredValue] = useState<any>(0);
    const [rates, setRates] = useState<any>({});
    const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
      useState<boolean>(false);
    const [isExcessValuationModalOpen, setIsExcessValuationModalOpen] =
      useState<boolean>(false);
    const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
    const [dangerType, setDangerType] = useState<string>(
      dangerTypeList[0].value
    );
    const [packagingGroup, setPackagingGroup] = useState<string>(
      packagingGroupOptions[0].value
    );
    const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
    const [goodsClass, setGoodsClass] = useState<string>('');
    const [emergencyContactName, setEmergencyContactName] =
      useState<string>('');
    const [emergencyContactNumber, setEmergencyContactNumber] =
      useState<string>('');
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] =
      useState<string>('');
    const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
      useState<boolean>(false);
    const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
    const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);

    const [serviceSearch, setServiceSearch] = useState<any>({
      pickup: '',
      delivery: '',
      other: '',
    });

    useEffect(() => {
      let filterTypes = limitedAccessList.filter((type: any) => {
        if (type.value === limitedAccessType) {
          return true;
        }

        return type.name
          .toLowerCase()
          ?.includes(limitedAccessSearch.toLowerCase());
      });
      setLimitedAccessTypeList(filterTypes);
    }, [limitedAccessSearch]);

    const submitService = async (quoteId: any) => {
      const formDataCurrent: any = {
        quoteId: quoteId,
        serviceId: selectedServices?.join(','),
        limitedAccess: isLimitedMore,
        inbond: isInbondMore,
        dangerousGoods: isDangerousMore,
      };

      if (isLimitedMore) {
        formDataCurrent.limitedAccessType = limitedAccessType;
        formDataCurrent.limitedAccessOther = limitedAccessOther;
      }

      if (isInbondMore) {
        formDataCurrent.inbondType = inbondType;
        formDataCurrent.inbondName = inbondName;
        formDataCurrent.inbondAddress = inbondAddress;
        formDataCurrent.inbondContactMethod = inbondContactMethod;
        formDataCurrent.inbondContactValue = inbondContactValue;
      }

      if (isDangerousMore) {
        formDataCurrent.dangerGoodsType = dangerType;
        formDataCurrent.goodsClass = goodsClass;
        formDataCurrent.description = description;
        formDataCurrent.unitedNationsNumber = unitedNationsNumber;
        formDataCurrent.packagingGroup = packagingGroup;
        formDataCurrent.emergencyContactName = emergencyContactName;
        formDataCurrent.emergencyContactNumber = emergencyContactNumber;
        formDataCurrent.emergencyContactExt = emergencyContactExt;
      }

      if (isDeclaredValueMore) {
        formDataCurrent.declaredValue = declaredValue;
        formDataCurrent.isDeclaredValue = isDeclaredValueMore;
        formDataCurrent.currency = declaredValueUnit;
      }

      try {
        await createUpdateService(formDataCurrent);
      } catch (err) {
        WalToast.error('Additional services have not been updated', '');
      }

      setIsLoading(false);
      setIsCreateQuoteLoading(false);
    };

    const getName = (contactMethod: string) => {
      if (contactMethod === 'fax-number') {
        return 'Fax';
      } else if (contactMethod === 'phone-number') {
        return 'Phone';
      } else {
        return 'Email';
      }
    };

    const isInBondFormValid = () => {
      let valid = true;
      const validFormData = {
        inbondName: true,
        inbondAddress: true,
        inbondContactValue: true,
        unitedNationsNumber: true,
        isEmailValid: false,
        isPhoneValid: true,
      };

      if (isInbondMore) {
        if (inbondName === '' || inbondName === null) {
          validFormData.inbondName = false;
          valid = false;
        }

        if (inbondAddress === '' || inbondAddress === null) {
          validFormData.inbondAddress = false;
          valid = false;
        }

        if (inbondContactValue === '' || inbondContactValue === null) {
          validFormData.inbondContactValue = false;
          valid = false;
        } else {
          const emailRegex =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
          validFormData.inbondContactValue = true;

          if (getName(inbondContactMethod) === 'Email') {
            if (emailRegex.test(inbondContactValue)) {
              validFormData.isEmailValid = true;
            } else {
              validFormData.isEmailValid = false;
              valid = false;
            }
          } else {
            validFormData.isEmailValid = true;
          }

          if (getName(inbondContactMethod) === 'Phone') {
            if (!validServicesForm.isPhoneValid) {
              validFormData.isPhoneValid = false;
              valid = false;
            }

            if (!validServicesForm.inbondContactValue) {
              validFormData.inbondContactValue = false;
              valid = false;
            }
          }
        }
      }

      setValidServicesForm((oldServiceValidForm) => ({
        ...oldServiceValidForm,
        ...validFormData,
      }));

      return valid;
    };

    const getRate = () => {
      getPricingRate()
        .then((response: any) => {
          setRates(response?.data);
        })
        .catch(() => {
          WalToast.error('Something went wrong while fetching rate.');
          console.error('Pricing rates error');
        });
    };

    useEffect(() => {
      if (!allowHandleChecked && !isLoading) {
        setTimeout(() => {
          setAllowHandleChecked(true);
        }, 1500);
      }
    }, [allowHandleChecked, isLoading]);

    useEffect(() => {
      if (isLimitedMore && allowHandleChecked) {
        setIsAccessTypeModalOpen(true);
      }
    }, [isLimitedMore]);

    useEffect(() => {
      if (isInbondMore && allowHandleChecked) {
        setIsInbondMoreModalOpen(true);
      }
    }, [isInbondMore]);

    useEffect(() => {
      if (isDangerousMore && allowHandleChecked) {
        setIsDangerousMoreModalOpen(true);
      }
    }, [isDangerousMore]);

    useEffect(() => {
      if (isDeclaredValueMore && allowHandleChecked) {
        setIsExcessValuationModalOpen(true);
      }
    }, [isDeclaredValueMore]);

    const getListService = () => {
      listService({})
        .then((response: any) => {
          setServiceList(response.data);

          if (response.data.length) {
            let serviceByGroup: any = groupBy(response.data, (val) => val.type);
            setServiceTypes(serviceByGroup);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch(console.log);
    };

    useEffect(() => {
      getRate();
      getListService();
    }, []);

    const handleChange = (e: any) => {
      let { value, checked } = e.target;
      value = parseInt(value);

      if (checked) {
        setSelectedServices((oldSelectedServices: any) => [
          ...oldSelectedServices,
          value,
        ]);

        if (
          e.target.name === 'tailgate_pickup' ||
          e.target.name === 'tailgate_delivery'
        ) {
          setIsTailgate((oldIsTailgate: any) => [...oldIsTailgate, value]);
        }

        if (e.target.name === 'limited_access_delivery') {
          setIsLimitedMore(true);
        }

        if (e.target.name === 'in-bond') {
          setIsInbondMore(true);
        }

        if (e.target.name === 'excess_valuation') {
          setIsDeclaredValueMore(true);
        }

        if (e.target.name === 'dangerous_goods') {
          setIsDangerousMore(true);
        }
      } else {
        let arr = selectedServices.filter((item: any) => item !== value);
        setSelectedServices(arr);
        setIsTailgate((current: any) =>
          current.filter((item: any) => item !== value)
        );

        if (e.target.name === 'limited_access_delivery') {
          setIsLimitedMore(false);
          setLimitedAccessType(limitedAccessTypeList[0].value);
        }

        if (e.target.name === 'in-bond') {
          setIsInbondMore(false);
          setInbondType(inbondTypeList[0].value);
        }

        if (e.target.name === 'dangerous_goods') {
          setIsDangerousMore(false);
        }

        if (e.target.name === 'excess_valuation') {
          setIsDeclaredValueMore(false);
        }
      }
    };

    const parsedPhoneNumber = inbondContactValue
      ? parsePhoneNumberFromString(inbondContactValue)
      : null;

    const defaultValues = {
      phone: parsedPhoneNumber?.nationalNumber || '',
      phone_country_code: parsedPhoneNumber?.country || '',
    };

    const emergencyContactNo = emergencyContactNumber
      ? parsePhoneNumberFromString(emergencyContactNumber)
      : null;
    const defaultEmergencyPhoneValues = {
      phone: emergencyContactNo?.nationalNumber || '',
      phone_country_code: emergencyContactNo?.country || '',
    };

    const handleNumberChange = (
      country: CountryCode,
      phone: string,
      code: string,
      isValid: boolean
    ) => {
      var validFormData = {
        phone: true,
        isPhoneValid: true,
        inbondContactValue: true,
      };
      defaultValues.phone_country_code = country;
      setInbondContactValue(`+${code}${phone}`);

      if (phone === '') {
        validFormData.phone = false;
        validFormData.isPhoneValid = false;
        validFormData.inbondContactValue = false;
      } else {
        validFormData.isPhoneValid = isValid;
      }

      setValidServicesForm((oldServiceValidForm) => ({
        ...oldServiceValidForm,
        ...validFormData,
      }));
    };

    const handleChangeRadio = (e: any) => {
      let { value, name } = e.target;
      setInbondContactValue('');

      if (name === 'contactMethod') {
        isInBondFormValid();

        setInbondContactMethod(value);
      }

      if (name === 'inbondName') {
        setInbondType(value);
      }

      if (name === 'limitedAccessType') {
        setLimitedAccessType(value);
      }

      if (name === 'dangerType') {
        setDangerType(value);
      }
    };

    const isDangerousGoodsFormValid = () => {
      let valid = true;
      const validFormData = {
        unitedNationsNumber: true,
        goodsClass: true,
        description: true,
        emergencyContactName: true,
        emergencyContactNumber: true,
        isEmergencyPhoneValid: true,
      };

      if (isDangerousMore) {
        if (unitedNationsNumber === '' || unitedNationsNumber === null) {
          validFormData.unitedNationsNumber = false;
          valid = false;
        }

        if (goodsClass === '' || goodsClass === null) {
          validFormData.goodsClass = false;
          valid = false;
        }

        if (description === '' || description === null) {
          validFormData.description = false;
          valid = false;
        }

        if (emergencyContactName === '' || emergencyContactName === null) {
          validFormData.emergencyContactName = false;
          valid = false;
        }

        if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
          validFormData.emergencyContactNumber = false;
          valid = false;
        } else {
          if (!validServicesForm.isEmergencyPhoneValid) {
            validFormData.isEmergencyPhoneValid = false;
            valid = false;
          }
        }
      }

      setValidServicesForm((oldServiceValidForm) => ({
        ...oldServiceValidForm,
        ...validFormData,
      }));

      return valid;
    };

    const handleDangerPhoneChange = (
      country: CountryCode,
      phone: string,
      code: string,
      isValid: boolean
    ) => {
      var validFormData = {
        emergencyContactNumber: true,
        isEmergencyPhoneValid: true,
      };
      defaultEmergencyPhoneValues.phone_country_code = country;
      setEmergencyContactNumber(`+${code}${phone}`);
      setEmergencyPhoneNumber(`${phone}`);

      if (phone === '') {
        validFormData.emergencyContactNumber = false;
        validFormData.isEmergencyPhoneValid = false;
      } else {
        validFormData.isEmergencyPhoneValid = isValid;
      }

      setValidServicesForm({ ...validServicesForm, ...validFormData });
    };

    const isAccessTypeFormValid = () => {
      let valid = true;
      const validFormData = {
        limitedAccessOther: true,
      };

      if (limitedAccessType === 'other') {
        if (limitedAccessOther === '' || limitedAccessOther === null) {
          validFormData.limitedAccessOther = false;
          valid = false;
        }
      }

      setValidServicesForm((oldServiceValidForm) => ({
        ...oldServiceValidForm,
        ...validFormData,
      }));

      return valid;
    };

    const getList = (data: any) => (
      <div className="mt-4 flex flex-col gap-y-4">
        {data.map((val: any) => (
          <div className="flex gap-x-2" key={`service_${val.id}`}>
            <CheckBox
              id={`service_${val.id}`}
              onChangeFunc={handleChange}
              name={val.slug}
              value={val.id}
              checked={selectedServices?.includes(val.id)}
              parentClassName="mt-1.5"
            />

            {/* IN BELOW DIV "text-textSecondary" WILL BE REPLACED BY "text-primary" WHEN FIELD IS AI DETECTED */}
            <div className="text-textSecondary">
              <div className="inline">
                {val.description ? (
                  <TooltipCmp
                    message={val.description}
                    parentClassName="max-w-80 md:!p-3 !p-1.5"
                  >
                    <label
                      htmlFor={`service_${val.id}`}
                      className="text-sm leading-4 cursor-pointer font-medium mr-1"
                    >
                      {val.name}
                    </label>
                  </TooltipCmp>
                ) : (
                  <label
                    htmlFor={`service_${val.id}`}
                    className="text-sm leading-4 cursor-pointer font-medium mr-1"
                  >
                    {val.name}
                  </label>
                )}
              </div>
              {dimensionsExtractedData?.selectedServices?.includes(val.id) && (
                <TooltipCmp message={'Detected with AI'}>
                  <Star06 className="w-3.5 h-3.5 inline align-middle text-primary" />
                </TooltipCmp>
              )}
            </div>
          </div>
        ))}
      </div>
    );

    const filterArr: any = [
      {
        value: CURRENCY.CAD,
        name: 'CAD',
        tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
      },
      {
        value: CURRENCY.USD,
        name: 'USD',
        tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
      },
    ];

    const isExcessValuation = () => {
      let valid = true;
      const validFormData = {
        declaredValue: true,
      };

      if (!declaredValue) {
        validFormData.declaredValue = false;
        valid = false;
      }
      setValidServicesForm((oldServiceValidForm) => ({
        ...oldServiceValidForm,
        ...validFormData,
      }));

      return valid;
    };

    // COMMON AND API CALL CODE HERE
    const handleSubmit = async (quoteId: any) => {
      if (formData?.length) {
        const formPayload = formData.map((formDataField: any) => {
          formDataField.quoteId = quoteId;

          return formDataField;
        });

        try {
          const createUpdateDimensionResponse = await createUpdateDimension({
            dimensions: formPayload,
          });

          if (createUpdateDimensionResponse) {
            console.log('Calling Submit Service Function');
            await submitService(quoteId);
          }
        } catch (err) {
          setIsCreateQuoteLoading(false);
          console.log('createUpdateDimension error ', err);
          WalToast.error('Dimensions have not been updated', '');
        }
      } else {
        return true;
      }
    };

    // Expose the childFunction to the parent
    useImperativeHandle(ref, () => ({
      checkIsDimensionFormValid: isFormValid,
      handleDimensionsSubmit: handleSubmit,
    }));

    const handleModalClose = useCallback(
      (status: boolean) => () => {
        setAction(initAction);

        if (status) {
          setIsOverWeightAndLengthConfirmed(true);

          setTimeout(() => {
            handleSubmitCreateQuoteRequest();
          }, 0);
        }
      },
      [action]
    );

    useEffect(() => {
      console.log('dimensionsExtractedData ', dimensionsExtractedData);
      const dimensionsFormDataArr: IFormData[] = [];
      const dimensionsValidFormDataArr: any = [];

      dimensionsExtractedData?.dimensions?.forEach((dimItem: any) => {
        dimensionsFormDataArr.push({
          ...initFormData,
          handlingUnit:
            handlingUnitOptions.length > 0 ? handlingUnitOptions[0].value : '',
          ...dimItem,
        });
        dimensionsValidFormDataArr.push(initValidForm);
      });

      if (dimensionsFormDataArr?.length) {
        setFormData(dimensionsFormDataArr);
        setValidForm(dimensionsValidFormDataArr);
      }
    }, [dimensionsExtractedData, handlingUnitOptions]);

    return (
      <>
        <li>
          <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
            <h6 className="text-grayLight900 text-base font-medium">
              Dimensions
            </h6>

            <SelectBox
              name="measurement_unit"
              id="measurement_unit"
              label="Measurement Units"
              required={true}
              className="form_control"
              labelClassName="form_label mb-1.5 block"
              size="sm"
              placeholder="Filter by provider"
              isSearchable={false}
              inlineSelect={true}
              options={providerArr}
              onChangeFunc={(event: any) => {
                const val = event?.value;

                if (val === MEASUREMENT.WEIGHT1.value) {
                  setLengthMeasurement(MEASUREMENT.LENGTH1.value);
                  setWeightMeasurement(MEASUREMENT.WEIGHT1.value);
                } else if (val === MEASUREMENT.WEIGHT2.value) {
                  setLengthMeasurement(MEASUREMENT.LENGTH2.value);
                  setWeightMeasurement(MEASUREMENT.WEIGHT2.value);
                }
              }}
              value={providerArr.filter(
                (val: any) => weightMeasurement === val.value
              )}
              isShowAiStar={
                weightMeasurement ===
                dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement
              }
            />

            {isHandlingUnitLoading ? (
              <div className="flex justify-center mt-4">
                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
              </div>
            ) : (
              <DimensionItems
                formData={formData}
                validForm={validForm}
                initFormData={initFormData}
                initValidForm={initValidForm}
                handlingUnitOptions={handlingUnitOptions}
                lengthMeasurement={lengthMeasurement}
                weightMeasurement={weightMeasurement}
                setFormData={setFormData}
                isHandlingUnitLoading={isHandlingUnitLoading}
                setValidForm={setValidForm}
                dimensionsExtractedData={dimensionsExtractedData?.dimensions}
              />
            )}
          </div>
        </li>
        <li>
          <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
            {serviceList.length ? (
              <>
                <h6 className="text-grayLight900 text-base font-medium">
                  Additional Services
                </h6>
                <AccordionCmp
                  title={
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-sm">
                        Pickup
                      </h3>
                      <p className="text-xs text-grayLight600 font-normal">
                        Please select the pickup type below as needed
                      </p>
                    </div>
                  }
                  className="!px-3"
                  contentParentClass="!border-0 !mt-4 !pt-0"
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.pickup}
                      inputType="text"
                      isClearable
                      onChangeFunc={(e: any) =>
                        setServiceSearch((oldServiceSearch: any) => ({
                          ...oldServiceSearch,
                          pickup: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {serviceTypes.PICKUP?.length > 0 ? (
                    getList(serviceTypes.PICKUP)
                  ) : (
                    <NotFoundUI
                      title="No services found"
                      desc={`Your search “${serviceSearch.pickup}” did not match any services. Please try again.`}
                      containerClassName=" !h-auto"
                    />
                  )}
                </AccordionCmp>

                <AccordionCmp
                  title={
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-sm">
                        Delivery
                      </h3>
                      <p className="text-xs text-grayLight600 font-normal">
                        Please select the pickup type below as needed
                      </p>
                    </div>
                  }
                  className="!px-3"
                  contentParentClass="!border-0 !mt-4 !pt-0"
                >
                  <div onClick={(e) => e.stopPropagation()} className="mr-3">
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.delivery}
                      inputType="text"
                      isClearable
                      onChangeFunc={(e: any) =>
                        setServiceSearch((oldServiceSearch: any) => ({
                          ...oldServiceSearch,
                          delivery: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {serviceTypes.DELIVERY?.length ? (
                    getList(serviceTypes.DELIVERY)
                  ) : (
                    <NotFoundUI
                      title="No services found"
                      desc={`Your search “${serviceSearch.delivery}” did not match any services. Please try again.`}
                      containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                    />
                  )}
                </AccordionCmp>
                <AccordionCmp
                  title={
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-sm">
                        Other
                      </h3>
                      <p className="text-xs text-grayLight600 font-normal">
                        Please select the pickup type below as needed
                      </p>
                    </div>
                  }
                  className="!px-3"
                  contentParentClass="!border-0 !mt-4 !pt-0"
                >
                  <div onClick={(e) => e.stopPropagation()} className="mr-3">
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.other}
                      isClearable
                      inputType="text"
                      onChangeFunc={(e: any) =>
                        setServiceSearch((oldServiceSearch: any) => ({
                          ...oldServiceSearch,
                          other: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {serviceTypes.OTHER?.length ? (
                    getList(serviceTypes.OTHER)
                  ) : (
                    <NotFoundUI
                      title="No services found"
                      desc={`Your search “${serviceSearch.other}” did not match any services. Please try again.`}
                      containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                    />
                  )}
                </AccordionCmp>
              </>
            ) : (
              <div className="flex justify-center mt-4">
                {isLoading ? (
                  <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </li>
        {isInbondMoreModalOpen && (
          <CommonModal
            title={'In-Bond Details'}
            titleDesc={'Please fill In-Bond Details'}
            handleClose={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="in-bond"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsInbondMore(false);
              setIsInbondMoreModalOpen(false);
            }}
            headerIcon={<PackagePlus />}
            size={'max-w-[688px]'}
            secondaryBtnText="Cancel"
            primaryBtnText="Save Changes"
            primaryBtnOnClick={() => {
              if (isInBondFormValid()) {
                setIsInbondMoreModalOpen(false);
              }
            }}
            secondaryBtnOnClick={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="in-bond"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsInbondMore(false);
              setIsInbondMoreModalOpen(false);
            }}
          >
            <div className="p-5 ">
              <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
                {inbondTypeList.map((val: any, i) => (
                  <div className="flex gap-2" key={`inbond_${i}`}>
                    <Radio
                      id={`inbond_${i}`}
                      onChangeFunc={handleChangeRadio}
                      inputName={'inbondName'}
                      value={val.value}
                      checked={inbondType === val.value}
                    />

                    <label
                      htmlFor={`inbond_${i}`}
                      className="text-textSecondary text-xs cursor-pointer font-medium"
                    >
                      {val.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="w-full mb-4">
                <InputText
                  inputName="inbondName"
                  required={true}
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.inbondName
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Warehouse or Carrier who will cancel US Bond"
                  labelClassName="block mb-1.5"
                  value={inbondName}
                  icon={
                    !validServicesForm.inbondName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.inbondName ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setInbondName(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />
              </div>
              <div className="w-full mb-5">
                <InputText
                  inputName="inbondAddress"
                  placeholder="123 Address"
                  required={true}
                  className={`form_control ${
                    !validServicesForm.inbondAddress
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Address"
                  labelClassName="block mb-1.5"
                  icon={
                    !validServicesForm.inbondName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={inbondAddress}
                  // errorText={!validServicesForm.inbondAddress ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setInbondAddress(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />
              </div>
              <div className="w-full mb-4">
                {getName(inbondContactMethod) !== 'Phone' ? (
                  <InputText
                    inputName="inbondContactValue"
                    // inputType={getName(inbondContactMethod) === "Phone" ? "number" : "text"}
                    placeholder=""
                    required={true}
                    className={`form_control ${
                      !validServicesForm.inbondContactValue
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    icon={
                      !validServicesForm.inbondContactValue ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    label={getName(inbondContactMethod)}
                    labelClassName="block mb-1.5"
                    value={inbondContactValue}
                    errorText={
                      !validServicesForm.inbondContactValue
                        ? 'This field is required'
                        : !validServicesForm.isEmailValid &&
                          getName(inbondContactMethod) === 'Email'
                        ? 'enter valid email'
                        : ''
                    }
                    onChangeFunc={(e) => {
                      setInbondContactValue(e.target.value);
                      setValidServicesForm((oldServicesValidForm) => ({
                        ...oldServicesValidForm,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                ) : (
                  <PhoneInput
                    onNumberChange={handleNumberChange}
                    name="phone"
                    errors={
                      !validServicesForm.inbondContactValue
                        ? 'Phone number is required'
                        : !validServicesForm.isPhoneValid &&
                          getName(inbondContactMethod) === 'Phone'
                        ? 'Invalid Number'
                        : null
                    }
                    value={inbondContactValue || ''}
                  />
                )}
              </div>
              <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
                {inbondContactMethodList.map((val: any, i) => (
                  <div className="flex gap-2" key={`inbond_contact_${i}`}>
                    <Radio
                      id={`inbond_contact_${i}`}
                      onChangeFunc={handleChangeRadio}
                      inputName={'contactMethod'}
                      value={val.value}
                      checked={inbondContactMethod === val.value}
                    />
                    <label
                      htmlFor={`inbond_contact_${i}`}
                      className="text-textSecondary text-xs cursor-pointer font-medium uppercase"
                    >
                      {val.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </CommonModal>
        )}
        {isDangerousMoreModalOpen && (
          <CommonModal
            title={'Dangerous Goods'}
            titleDesc={'Please provide dangerous goods details'}
            handleClose={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="dangerous_goods"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsDangerousFormSubmitted(false);
              setUnitedNationsNumber('');
              setGoodsClass('');
              setDescription('');
              setEmergencyContactName('');
              setEmergencyContactNumber('');
              setEmergencyContactExt('');
              setIsDangerousMore(false);
              setIsDangerousMoreModalOpen(false);
            }}
            headerIcon={<AlertHexagon />}
            size={'max-w-[688px]'}
            secondaryBtnText="Cancel"
            primaryBtnText="Save Changes"
            primaryBtnOnClick={() => {
              setIsDangerousFormSubmitted(true);

              if (isDangerousGoodsFormValid()) {
                setIsDangerousMoreModalOpen(false);
              }
            }}
            secondaryBtnOnClick={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="dangerous_goods"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsDangerousFormSubmitted(false);
              setUnitedNationsNumber('');
              setGoodsClass('');
              setDescription('');
              setEmergencyContactName('');
              setEmergencyContactNumber('');
              setEmergencyContactExt('');
              setIsDangerousMore(false);
              setIsDangerousMoreModalOpen(false);
            }}
          >
            <div className="p-5 flex flex-col md:gap-5 gap-3">
              <p className="text-grayLight600 font-normal text-xs ">
                Please provide the Dangerous Goods details, as these details
                will show up on the BOL. Failure to enter this data may result
                in delayed pickups.
              </p>
              <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3 py-[12.5px] ">
                {dangerTypeList.map((val: any, i) => (
                  <div className="flex gap-2" key={`danger_${i}`}>
                    <Radio
                      id={`danger_${i}`}
                      onChangeFunc={handleChangeRadio}
                      inputName={'dangerType'}
                      value={val.value}
                      checked={dangerType === val.value}
                    />
                    <label
                      htmlFor={`danger_${i}`}
                      className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
                    >
                      {val.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 ">
                <InputText
                  inputName="unitedNationsNumber"
                  placeholder="UN0000"
                  className={`form_control ${
                    !validServicesForm.unitedNationsNumber
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  required={true}
                  label="UN #"
                  labelClassName="block mb-1.5"
                  value={unitedNationsNumber}
                  icon={
                    !validServicesForm.inbondName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.unitedNationsNumber ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setUnitedNationsNumber(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />

                <div className="content">
                  <SelectBox
                    name="packagingGroup"
                    required
                    label="Packaging Group"
                    labelClassName="form_label block mb-1.5"
                    id="packagingGroup"
                    className="form_control"
                    options={packagingGroupOptions}
                    onChangeFunc={(event: any) =>
                      setPackagingGroup(event.value)
                    }
                    value={packagingGroupOptions.find(
                      (val) => val.value === packagingGroup
                    )}
                  />
                </div>

                <InputText
                  inputType="number"
                  inputName="goodsClass"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.goodsClass
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Class"
                  required={true}
                  labelClassName="block mb-1.5"
                  value={goodsClass}
                  icon={
                    !validServicesForm.goodsClass ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.goodsClass ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setGoodsClass(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />
              </div>
              <div className="w-full">
                <InputText
                  inputName="description"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.description
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Technical Name or Description"
                  required={true}
                  labelClassName="block mb-1.5"
                  value={description}
                  icon={
                    !validServicesForm.description ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.description ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setDescription(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />
              </div>
              <div className="w-full ">
                <InputText
                  inputName="emergencyContactName"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.emergencyContactName
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="24-hr Emergency Contact Name"
                  required={true}
                  labelClassName="block mb-1.5"
                  icon={
                    !validServicesForm.emergencyContactName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={emergencyContactName}
                  // errorText={!validServicesForm.emergencyContactName ? 'This field is required' : null}
                  onChangeFunc={(e) => {
                    setEmergencyContactName(e.target.value);
                    setValidServicesForm((oldServicesValidForm) => ({
                      ...oldServicesValidForm,
                      [e.target.name]: true,
                    }));
                  }}
                />
              </div>
              <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
                <div className="md:flex flex-col">
                  <label className="form_label ">Phone number*</label>
                  <PhoneInput
                    onNumberChange={handleDangerPhoneChange}
                    name="phone"
                    errors={
                      isDangerousFormSubmitted &&
                      !validServicesForm.emergencyContactNumber
                        ? 'Phone number is required'
                        : isDangerousFormSubmitted &&
                          !validServicesForm.isEmergencyPhoneValid
                        ? 'Invalid Number'
                        : null
                    }
                    value={emergencyContactNumber || ''}
                  />
                </div>
                <InputText
                  inputName="emergencyContactExt"
                  placeholder=""
                  className="form_control"
                  label="Ext"
                  labelClassName="block mb-1.5"
                  value={emergencyContactExt}
                  onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
                />
              </div>
            </div>
          </CommonModal>
        )}
        {isExcessValuationModalOpen && (
          <CommonModal
            title={'Excess Valuation'}
            titleDesc={'Please provide Declared Value'}
            handleClose={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="excess_valuation"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsDeclaredValueMore(false);
              setIsExcessValuationModalOpen(false);
            }}
            headerIcon={<AlertHexagon />}
            size={'max-w-[688px]'}
            secondaryBtnText="Cancel"
            primaryBtnText="Save Changes"
            primaryBtnOnClick={() => {
              if (validServicesForm && isExcessValuation()) {
                setIsExcessValuationModalOpen(false);
              }
            }}
            secondaryBtnOnClick={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="excess_valuation"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsExcessValuationModalOpen(false);
            }}
          >
            <div className="flex flex-col justify-center py-3">
              <div className="xxl:w-full xl:w-2/5 w-full px-4">
                <p className="text-dark m-0 p-0 font-medium pt-2 pb-2 text-xs">
                  Declared Value
                </p>
                <div className="flex items-center">
                  <div className="mb-4 w-full">
                    <InputText
                      inputType="number"
                      inputName="declaredValue"
                      required={true}
                      placeholder=""
                      className={`form_control ${
                        !validServicesForm.declaredValue
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      icon={
                        !validServicesForm.declaredValue ? (
                          <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                        ) : null
                      }
                      value={declaredValue}
                      onChangeFunc={(e) => {
                        setDeclaredValue(e.target.value);
                        setValidServicesForm((oldServicesValidForm) => ({
                          ...oldServicesValidForm,
                          [e.target.name]: true,
                        }));
                      }}
                      onFocus={() => {
                        if (declaredValue === 0) {
                          setDeclaredValue('');
                        }
                      }}
                      onBlur={() => {
                        if (declaredValue === '') {
                          setDeclaredValue(0);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full py-2 xl:px-4 px-3">
                <p className="text-dark m-0 p-0 font-medium pb-2 text-xs">
                  Currency
                </p>
                <TabButton
                  tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                  parentClassName="w-full currency-tab quoting-currency"
                  childrenClassName="test"
                  activeClassName="text-grayLight900 bg-utilityGray100"
                  className=""
                  isTooltip={true}
                  tabArray={filterArr}
                  handleOnClick={(e: any) => {
                    setDeclaredValueUnit(e.target.dataset.value);
                  }}
                  isActive={declaredValueUnit}
                />
              </div>
            </div>
          </CommonModal>
        )}
        {isAccessTypeModalOpen && (
          <CommonModal
            title={'Limited access delivery'}
            titleDesc={'Please select limited access delivery type'}
            modalClassName="flex flex-col"
            handleClose={() => {
              setLimitedAccessSearch('');
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="limited_access_delivery"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsAccessTypeModalOpen(false);
            }}
            headerIcon={<PackagePlus />}
            size={'max-w-[688px]'}
            secondaryBtnText="Cancel"
            primaryBtnText="Save Changes"
            primaryBtnOnClick={() => {
              if (isAccessTypeFormValid()) {
                setIsAccessTypeModalOpen(false);
              }
            }}
            secondaryBtnOnClick={() => {
              let option = document.querySelectorAll<HTMLInputElement>(
                'input[type="checkbox"][name="limited_access_delivery"]'
              );

              if (option?.length) {
                option[0].click();
              }
              setValidServicesForm(initServiceValidForm);
              setIsAccessTypeModalOpen(false);
            }}
          >
            <div className="p-5 flex-1 flex flex-col overflow-y-auto">
              <InputText
                inputName="searchCarrier"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={limitedAccessSearch}
                isClearable
                onChangeFunc={(e) => setLimitedAccessSearch(e.target.value)}
                inputType="text"
                parentClassName="w-full mb-4"
              />

              <div className="rounded-lg border border-borderPrimary px-3 py-1 flex-1 overflow-y-auto custom-scrollbar-v2">
                {limitedAccessTypeList.map((val: any, i: any) => (
                  <div
                    className="flex sms:p-4 px-2 py-3 border-b border-utilityGray200 last:border-b-0"
                    key={`limited_${i}`}
                  >
                    <div className="flex items-center">
                      <div
                        className="flex items-start gap-2"
                        key={`service_${val.value}`}
                      >
                        <Radio
                          id={`service_${val.value}`}
                          onChangeFunc={handleChangeRadio}
                          inputName={'limitedAccessType'}
                          value={val.value}
                          checked={limitedAccessType === val.value}
                        />
                        <label
                          htmlFor={`service_${val.value}`}
                          className="text-xs text-textSecondary uppercase cursor-pointer font-medium"
                        >
                          {val.name}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
                {limitedAccessType === 'other' && (
                  <div className="mt-5 w-full">
                    <InputText
                      inputName="limitedAccessOther"
                      className={`form_control ${
                        !validServicesForm.limitedAccessOther
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      label=""
                      value={limitedAccessOther}
                      errorText={
                        !validServicesForm.limitedAccessOther
                          ? 'This field is required'
                          : null
                      }
                      onChangeFunc={(e) => {
                        setLimitedAccessOther(e.target.value);
                        setValidServicesForm((oldServicesValidForm) => ({
                          ...oldServicesValidForm,
                          [e.target.name]: true,
                        }));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </CommonModal>
        )}
        {action.tailgate && (
          <ConfirmModal
            title="Tailgate Over Dimensions"
            description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
              weightMeasurement === MEASUREMENT.WEIGHT1.value
                ? `${LBS.MAX_WEIGHT}lbs`
                : `${KGS.MAX_WEIGHT}kgs`
            }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
            button1="Yes, I am sure"
            button2="Modify Selection"
            handleClose={handleModalClose}
          />
        )}
      </>
    );
  }
);

export default DimensionSection;
