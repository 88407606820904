import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listCustomerOnboarding = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(`${API.CREDIT_DASHBOARD.LIST}`, {
    params,
    signal,
  });

  return response;
};

export const listCustomerOnboardingCount = async (
  params: any,
  signal?: any
) => {
  const response = await axiosInterceptor.get(
    `${API.CREDIT_DASHBOARD.LIST_COUNT}`,
    {
      params,
      signal,
    }
  );

  return response;
};
