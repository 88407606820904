import {
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  Check,
  ChevronDown,
  SearchLg,
  SwitchVertical01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import { USER_ROLE } from 'src/constants/common';
import {
  formatAddress,
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  useRolePermission,
} from 'src/utils/CommonFunctions';

const filterArr = [
  {
    value: 'booked',
    name: 'Booked',
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
  },
];

const tabArr = [
  {
    value: 'pickups',
    name: 'Pickups',
    count: 15,
  },
  {
    value: 'delivered',
    name: 'Delivered',
    count: 30,
  },
  {
    value: 'in_transit',
    name: 'In Transit',
    count: 7,
  },
];

interface IProps {
  orders?: any;
  setParams?: any;
  isOrdersLoading?: any;
  params?: any;
  setOrderId?: any;
}

const LeftSidebar = ({
  orders,
  setParams,
  isOrdersLoading,
  params,
  setOrderId,
}: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const [activeTab, setActiveTab] = useState('pickups');
  const [search, setSearch] = useState('');

  const datePickerRef = useRef<HTMLDivElement>(null);
  // const [month, setMonth] = useState<any>(new Date());
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>(
    moment().format('YYYY-MM-DD')
  );
  const [isDisplaySortOption, setIsDisplaySortOption] = useState(false);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  function formatLastUpdate(date: any) {
    const now: any = new Date();
    const lastUpdate: any = new Date(date);
    const differenceInMilliseconds = now - lastUpdate;
    const differenceInHours = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60)
    );
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInHours <= 72) {
      return `${differenceInHours} hours ago`;
    } else if (differenceInDays <= 3) {
      return `${differenceInDays} days ago`;
    } else {
      return `${lastUpdate.toLocaleDateString()}`;
    }
  }

  const getAddressWholeString = (
    first: string,
    second: string,
    third: string
  ) =>
    [first, second, third].filter((e) => e).length
      ? [first, second, third].filter((e) => e).toString()
      : false;

  const getFilterRange = (type: string) => {
    const currentDate = moment();
    const currentPlusOneDay = currentDate.clone().add(1, 'days');
    const currentRange = moment(dateRange, 'YYYY-MM-DD');

    if (type === 'prev') {
      const prevDate = currentRange.clone().subtract(1, 'days');

      if (hasRoleV2(USER_ROLE.USER)) {
        // For USER role, only allow the current or next day
        if (
          prevDate.isSame(currentDate, 'days') ||
          prevDate.isSame(currentPlusOneDay, 'days')
        ) {
          setDateRange(prevDate.format('YYYY-MM-DD'));
          setParams((old: any) => ({
            ...old,
            orderDate: prevDate.format('YYYY-MM-DD'),
          }));
        }
      } else {
        // For other roles, check if the date is not before '2024-08'
        // const minAllowedDate = moment('2024-08', 'YYYY-MM-DD');
        // if (prevDate.isSameOrAfter(minAllowedDate)) {
        setDateRange(prevDate.format('YYYY-MM-DD'));
        setParams((old: any) => ({
          ...old,
          orderDate: prevDate.format('YYYY-MM-DD'),
        }));
        // }
      }
    }

    if (type === 'next') {
      const nextDate = currentRange.clone().add(1, 'days');

      if (hasRoleV2(USER_ROLE.USER)) {
        // For USER role, only allow the current or next day
        if (
          nextDate.isSame(currentDate, 'days') ||
          nextDate.isSame(currentPlusOneDay, 'days')
        ) {
          setDateRange(nextDate.format('YYYY-MM-DD'));
          setParams((old: any) => ({
            ...old,
            orderDate: nextDate.format('YYYY-MM-DD'),
          }));
        }
      } else {
        // For other roles, no restrictions on next date
        setDateRange(nextDate.format('YYYY-MM-DD'));
        setParams((old: any) => ({
          ...old,
          orderDate: nextDate.format('YYYY-MM-DD'),
        }));
      }
    }
  };

  return (
    <>
      <div className="flex gap-x-2.5">
        <InputText
          inputName="searchQuote"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={search}
          inputType="text"
          onChangeFunc={handleFilter}
          parentClassName="flex-1"
          isClearable={true}
        />
        <OutsideClickHandler
          onOutsideClick={() => {}}
          containerClassName="relative"
        >
          <ButtonCmp
            className={`btn-outline-primary lg:!px-[9px] !px-2 hover:!bg-primary hover:text-white ${
              isDisplaySortOption ? 'bg-primary text-white' : ''
            }`}
            icon={<SwitchVertical01 className="w-4 h-4" />}
            onClick={() => {
              setIsDisplaySortOption(!isDisplaySortOption);
            }}
          >
            <></>
          </ButtonCmp>

          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
              isDisplaySortOption ? '' : 'hidden'
            } `}
          >
            <li className="px-2 text-gray400 my-1.5">Sort by</li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex justify-between gap-x-2 hover:text-primary ${
                params.sortField === 'orderCreateTime' ? 'bg-gray50' : ''
              } `}
              onClick={() => {
                setIsDisplaySortOption(false);
                setParams((old: any) => ({
                  ...old,
                  sortField: 'orderCreateTime',
                }));
              }}
            >
              <span>Last Update Time</span>
              {params.sortField === 'orderCreateTime' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => {
                setIsDisplaySortOption(false);
                setParams((old: any) => ({ ...old, sortField: 'pickupDate' }));
              }}
            >
              Pickup Date
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => {
                setIsDisplaySortOption(false);
                setParams((old: any) => ({
                  ...old,
                  sortField: 'customerName',
                }));
              }}
            >
              Customer
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              // onClick={() => {
              //   setIsDisplaySortOption(false);
              //   setParams((old: any) => ({
              //     ...old,
              //     sortField: 'customerName',
              //   }));
              // }}
            >
              Estimated Time
            </li>
          </ul>
        </OutsideClickHandler>
      </div>

      <TabButton
        parentClassName="tab-border-bottom-parent w-full"
        activeClassName="tab-border-bottom-active"
        className="tab-border-bottom flex-1"
        tabBadgeClassName="tab-border-bottom-badge"
        tabArray={tabArr}
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
        isActive={activeTab}
        isTabBorderBottom={true}
      />

      <div className="flex justify-between gap-x-2">
        <TabButton
          tabArray={filterArr}
          handleOnClick={(e: any) => {
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          }}
          isActive={params.filter}
          isTab={true}
        />
        <div className="cursor-pointer">
          <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px] justify-between items-center gap-1.5 flex bg-white  hover:border-grayText transition-all">
            <ArrowLeft
              className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
              onClick={() => getFilterRange('prev')}
            />

            <div
              className="flex flex-1 justify-center items-center"
              onClick={() => {
                setIsOpenDropDown(true);
              }}
            >
              <CalendarDate className="w-4 h-4 mr-2" />
              <div className="text-textSecondary text-xs font-medium leading-none">
                {' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: params.orderDate
                      ? getDateWithSuffixFormat(
                          getFormattedPickupDate(params?.orderDate)
                        )
                      : '-',
                  }}
                ></span>
              </div>
            </div>

            <ArrowRight
              className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
              onClick={() => getFilterRange('next')}
            />
          </div>
          {isOpenMonthPicker && (
            <div
              className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white date-picker-with-arrow"
              ref={datePickerRef}
            >
              <DateSelect
                inputName="pickupDate"
                selected={
                  params?.orderDate ? moment(params.orderDate).toDate() : null
                }
                dateFormat="dd/MM/yyyy"
                onChangeFunc={(e: any) => {
                  // setMonth(moment(e.value, 'MMM YYYY').toDate());
                  setDateRange(moment(e.value).format('YYYY-MM-DD'));
                  setParams((old: any) => ({
                    ...old,
                    ...{
                      orderDate: moment(e.value).format('YYYY-MM-DD'),
                    },
                  }));
                  setIsOpenDropDown(false);
                }}
                value={getDateWithSuffixFormat(
                  getFormattedPickupDate(params?.orderDate)
                )}
                inline={isOpenMonthPicker}
                icon={<ChevronDown className="w-5 h-5" />}
                minDate={moment('2024-08-01', 'YYYY-MM-DD').toDate()}
                parentClassName="w-full one-month-datepicker"
              />
            </div>
          )}
        </div>
      </div>
      <div className=" flex flex-col gap-y-3 flex-1 overflow-y-auto scrollbar-hide mb-4">
        {!isOrdersLoading &&
          orders.map((value: any, index: any) => (
            <div
              key={index}
              className="rounded-lg border border-utilityGray200 bg-white p-3 cursor-pointer shadow-none hover:shadow-lgc"
              onClick={() => setOrderId(value?.fullId)}
            >
              <div className="flex gap-x-2 justify-between items-start">
                <a
                  href={`https://wal.roserocket.com/#/ops/orders/${value?.roserocketOrderId}`}
                  className={`text-primary text-sm font-medium underline flex-none ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  #{value.fullId}
                </a>
                <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                  {value?.orderTags !== '' &&
                    value?.orderTags?.split(',')?.map((tag: any) => (
                      <BadgeCmp
                        key={tag}
                        style="modern"
                        type="success"
                        mainClassName={`${
                          isOrdersLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {tag}
                      </BadgeCmp>
                    ))}
                  <BadgeCmp style="modern" type="primary">
                    Booked
                  </BadgeCmp>
                </div>
              </div>

              <StatusDotProfileCmp
                parentClassName="mt-1.5"
                dotColor="red"
                label={
                  <>
                    {params.filter === 'booked'
                      ? 'Last Updated:'
                      : 'Pickup Appointment'}
                    &nbsp;
                    <span>{formatLastUpdate(value?.orderCreateTime)}</span>
                  </>
                }
              />

              <ul className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                <li
                  key={index}
                  className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
                >
                  <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                    <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                  </div>
                  <div className="flex-1 text-xs text-grayLight600 font-normal">
                    <span className="text-grayLight900 font-medium">
                      {value?.orderFromOrgName}&nbsp;
                    </span>
                    {`${
                      getAddressWholeString(
                        value?.orderToCity,
                        value?.orderToState,
                        value?.orderToPostal
                      )
                        ? formatAddress(
                            getAddressWholeString(
                              value?.orderToCity,
                              value?.orderToState,
                              value?.orderToPostal
                            )
                          )
                        : '-'
                    }`}
                  </div>
                </li>
                <li
                  key={index}
                  className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
                >
                  <div>
                    <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                  </div>
                  <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                    <span className="text-grayLight900 font-medium">
                      {value?.orderToOrgName}&nbsp;
                    </span>
                    {`${
                      getAddressWholeString(
                        value?.orderToCity,
                        value?.orderToState,
                        value?.orderToPostal
                      )
                        ? formatAddress(
                            getAddressWholeString(
                              value?.orderToCity,
                              value?.orderToState,
                              value?.orderToPostal
                            )
                          )
                        : '-'
                    }`}
                  </div>
                </li>
              </ul>
            </div>
          ))}

        {isOrdersLoading && !orders?.length
          ? [...Array(5)].map((_, index) => (
              <div
                key={index}
                className="rounded-lg border border-utilityGray200 bg-white p-3"
              >
                <div className="flex gap-x-2 justify-between items-start">
                  <a
                    className={`text-primary text-sm font-medium underline flex-none ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    #WAL-TCL4-16
                  </a>
                  <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                    <BadgeCmp
                      style="modern"
                      type="success"
                      mainClassName={`${
                        isOrdersLoading ? 'custom-loading' : ''
                      }`}
                    >
                      LTL
                    </BadgeCmp>

                    <BadgeCmp
                      style="modern"
                      type="primary"
                      mainClassName={`${
                        isOrdersLoading ? 'custom-loading' : ''
                      }`}
                    >
                      Booked
                    </BadgeCmp>
                  </div>
                </div>

                <div
                  className={`flex gap-1.5 items-center mt-1.5 ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                >
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <p className="text-xs text-grayLight900">
                    Last Updated: &nbsp;
                    <span>28/11/2024</span>
                  </p>
                </div>

                <ul className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                  <li
                    className={`relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                      <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal">
                      <span className="text-grayLight900 font-medium">
                        Grandview Crystal Screen Canada&nbsp;
                      </span>
                      Pointe Claire,QC,H9R 1A3
                    </div>
                  </li>
                  <li
                    key={index}
                    className={`relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <div>
                      <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                      <span className="text-grayLight900 font-medium">
                        Techni-Contact Canada Ltd&nbsp;
                      </span>
                      Pointe Claire,QC,H9R 1A3
                    </div>
                  </li>
                </ul>
              </div>
            ))
          : ''}
        {!isOrdersLoading && !orders?.length && (
          <NotFoundUI
            title="No Orders found"
            desc="There are no data for orders."
          />
        )}
      </div>
      {/* <div className="p-3 bg-white rounded-lg border border-[#eaecf0] flex-col justify-center items-start gap-3.5 inline-flex">
          <div className="h-11 flex-col gap-1 flex border-borderPrimary ">
            <div className="w-[427px] justify-start items-start gap-2.5 inline-flex">
              <div className="w-[208.50px] text-[#444ce6] text-sm font-medium font-['Inter'] underline leading-tight">
                #WAL-KP13-13
              </div>
              <div className="grow shrink basis-0 h-[22px] justify-end items-start gap-2.5 flex">
                <div className="px-1.5 py-0.5 bg-white rounded-md shadow border border-[#d0d5dd] justify-start items-center gap-1 flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-[#17b169] rounded-full" />
                  </div>
                  <div className="text-center text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
                    LTL
                  </div>
                </div>
                <div className="px-1.5 py-0.5 bg-white rounded shadow border border-[#d0d5dd] justify-start items-center gap-1 flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-[#6071f3] rounded-full" />
                  </div>
                  <div className="text-center text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
                    Booked
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col gap-1.5 flex">
              <div className="rounded-full justify-start items-center gap-1.5 flex">
                <div className="w-3 h-3 relative">
                  <div className="w-3 h-3 left-0 top-0 absolute bg-[#dfeaff] rounded-full" />
                  <div className="w-1.5 h-1.5 left-[3px] top-[3px] absolute bg-[#444ce6] rounded-full" />
                </div>
                <div className="text-center text-[#101828] text-xs font-medium font-['Inter'] leading-[18px]">
                  Pickup Appointment: Today, 07:00 PM
                </div>
              </div>
            </div>
          </div>
          <div className="p-2.5 bg-gray-50 rounded-lg justify-start items-center gap-2.5 flex ">
            <div className="w-3 h-[30px] justify-center mr-2">
              <img
                src={addressline}
                className="table-profile-icn-circle-xl rounded-md"
                alt="address"
                title="address"
                onError={onError}
              />
            </div>
            <div className="flex-col gap-1 flex">
              <div className="gap-1 flex">
                <div className="text-grayLight600 text-xs font-normal">
                  Koray Okumus
                </div>
                <div className="text-grayText text-xs font-normal">
                  Montreal Eaton Centre, West, Montreal, QC, Canada
                </div>
              </div>
              <div className="gap-1 flex">
                <div className="text-grayLight600 text-xs font-normal ">
                  Olivia Rhye
                </div>
                <div className="text-grayText text-xs font-normal">
                  8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

export default LeftSidebar;
