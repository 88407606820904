import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadBoardRowLoader = () => (
  <>
    <td className="px-5 py-4 max-w-52 w-52 min-w-52">
      <div className="flex items-center">
        {/* <img
            src="https://storage.googleapis.com/western-alliance-gcp/474454c2-1e01-418b-b50d-65c6dc2c3248.svg"
            className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
          /> */}
        <div className="table-profile-customer-circle-xl custom-loading">
          TT
        </div>
        <TooltipCmp message={'name'} parentClassName="">
          <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5 ml-3 custom-loading">
            Canadian Logistic Express
          </span>
        </TooltipCmp>
      </div>
    </td>

    <td className="px-5 py-4">
      <span className="custom-loading">1-800-409-2269</span>
    </td>
    <td className="px-5 py-4 min-w-56 w-56 max-w-56">
      <span className="custom-loading">281</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">Quarter</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">Est. 1 days</span>
    </td>
    <td className="px-5 py-4 min-w-44 w-44 max-w-44">
      <span className="custom-loading">Jan. 30th - 2024</span>
    </td>
    <td className="px-5 py-4 max-w-52 w-52 min-w-52">
      <div className="truncate custom-loading">
        LTL, FTL - Dryvan - Straight Truck
      </div>
    </td>
    <td className="px-5 py-4 max-w-16 w-16 min-w-16">
      <div className="w-4 h-4 custom-loading">
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer " />
      </div>
    </td>
  </>
);

export default LoadBoardRowLoader;
