import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { memo } from 'react';

import CommonModal from '../CommonModal';

interface IDeleteModalProps {
  moduleName?: string;
  moduleTitle?: any;
  moduleTitleDesc?: any;
  handleClose(status: boolean): any;
  isDeleteLoading?: boolean;
  dangerBtnText?: string;
}

const DeleteModal = ({
  moduleName,
  moduleTitle,
  moduleTitleDesc,
  handleClose,
  isDeleteLoading = false,
  dangerBtnText = 'Delete',
}: IDeleteModalProps) => (
  <CommonModal
    title={
      moduleTitle ?? (
        <span className="line-clamp-2 word-break">Delete {moduleName}</span>
      )
    }
    size="max-w-[400px]"
    titleDesc={
      moduleTitleDesc
        ? moduleTitleDesc
        : `Are you sure you want to delete this ${moduleName}? This action can't be undone.`
    }
    handleClose={() => (isDeleteLoading ? false : handleClose(false)())}
    modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
    modalHeaderTextClass="!flex-col"
    modalFooterClass="border-t-0 pt-0"
    headerIcon={<Trash01 />}
    secondaryBtnOnClick={handleClose(false)}
    secondaryBtnDisabled={isDeleteLoading}
    dangerBtnText={dangerBtnText}
    secondaryBtnText="Cancel"
    primaryBtnOnClick={handleClose(true)}
    primaryBtnDisabled={isDeleteLoading}
    primaryBtnLoading={isDeleteLoading}
  >
    &nbsp;
  </CommonModal>
);

export default memo(DeleteModal);
