import { ArrowNarrowRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { calculateDistance } from 'src/utils/CommonFunctions';

const SplitLegDetailsRow = ({ data, splitLegData }: any) => (
  <>
    <td className="px-5 py-4">
      <span>#{data?.orderId}</span>
    </td>
    <td className="px-5 py-4">
      <span>
        {calculateDistance(
          data?.shipper?.shipperAddress?.latitude,
          data?.shipper?.shipperAddress?.longitude,
          data?.consignee?.consigneeAddress?.latitude,
          data?.consignee?.consigneeAddress?.longitude
        )}{' '}
        Mi
      </span>
    </td>
    <td className="px-5 py-4">
      <p className="flex mr-3">{data?.shipper?.shipperAddress?.city}</p>
    </td>
    <td className="px-5 py-4">
      <p className="flex mr-3">{data?.consignee?.consigneeAddress?.city}</p>
    </td>
    <td className="px-5 py-4 px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
      {/* NEED TO SHOW BELOW ARROW ONLY WHEN HOVER ON <tr> FOR THAT NEED TO REMOVE "invisible" CLASS */}
      {splitLegData?.id === data?.id && (
        // invisible
        <ArrowNarrowRight className="w-4 h-4 text-primary cursor-pointer " />
      )}
    </td>
  </>
);

export default SplitLegDetailsRow;
