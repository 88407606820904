/* eslint-disable @typescript-eslint/no-unused-vars */
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { groupBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AccordionCmp from 'src/components/AccordionCmp';
import CheckBox from 'src/components/CheckBox';
import NotFoundUI from 'src/components/NotFoundUI';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getPricingRate, listService } from 'src/services/CommonService';
import WalToast from 'src/utils/WalToast';

import { OrderContext } from '../..';

import AccessTypeModal from './AccessTypeModal';
import {
  additionalServiceArr,
  dangerTypeList,
  inbondContactMethodList,
  inbondTypeList,
  initServiceValidForm,
  limitedAccessList,
  packagingGroupOptions,
} from './additionalServices.const';
import DangerousMoreModal from './DangerousMoreModal';
import ExcessValuationModal from './ExcessValuationModal';
import InBondMoreModal from './InBondMoreModal';

const AdditionalServices = () => {
  const { setValue } = useFormContext();
  const { setIsTailgate, currentLegData } = useContext<any>(OrderContext);

  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [activeTab, setActiveTab] = useState('pickup');
  const [isOpenAccordian, setIsOpenAccordian] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);
  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');

  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);
  const [rates, setRates] = useState<any>({});
  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);
  const [isExcessValuationModalOpen, setIsExcessValuationModalOpen] =
    useState<boolean>(false);
  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>('');
  const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
    useState<boolean>(false);
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
  const { currency } = useContext(BasicContext);
  const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);
  // const [declaredValueWithUnit, setDeclaredValueWithUnit] = useState({
  //   declaredValueCAD: 0,
  //   declaredValueUSD: 0,
  // });
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const declaredValueWithUnit = {
    declaredValueCAD: 0,
    declaredValueUSD: 0,
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];
  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  useEffect(() => {
    if (isDeclaredValueMore && allowHandleChecked) {
      setIsExcessValuationModalOpen(true);
    }
  }, [isDeclaredValueMore]);

  useEffect(() => {
    if (declaredValueUnit === CURRENCY.CAD) {
      if (declaredValueWithUnit.declaredValueCAD)
        setDeclaredValue(declaredValueWithUnit.declaredValueCAD);
    } else if (declaredValueUnit === CURRENCY.USD) {
      if (declaredValueWithUnit.declaredValueUSD)
        setDeclaredValue(declaredValueWithUnit.declaredValueUSD);
    }
  }, [declaredValueUnit]);

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getListService();
    getRate();
  }, []);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        // setInbondContactMethod(inbondContactMethodList[0].value);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }
    }

    setIsSubmitForm(true);
  };

  const getList = (data: any) => (
    <div className="flex sm:flex-row flex-col xlm:[&>div]:min-w-[25%] xlm:[&>div:nth-child(4n+1)]:min-w-[25%] xlm:[&>div:nth-child(4n+3)]:min-w-[25%] xlm:[&>div:nth-child(4n)]:min-w-[25%] xl:[&>div]:min-w-[22%] xl:[&>div:nth-child(4n+1)]:min-w-[23%] xl:[&>div:nth-child(4n+3)]:min-w-[25%] xl:[&>div:nth-child(4n)]:min-w-[30%] sm:[&>div]:min-w-[50%] sm:[&>div:nth-child(4n)]:min-w-[50%] flex-wrap grid-cols-3 overflow-hidden before:[&>div]:content-[''] before:[&>div]:h-px before:[&>div]:w-[90vw] before:[&>div]:bg-utilityGray200 before:[&>div]:top-0 before:[&>div]:left-0 before:[&>div]:absolute before:[&>div]:hidden xl:before:[&>div:nth-child(5n)]:block sm:before:[&>div:nth-child(2n)]:block before:[&>div:nth-child(n)]:block">
      {data.map((val: any) => (
        <div
          className="flex md:py-6 md:px-4 py-5 px-2 relative"
          key={`service_${val.id}`}
        >
          <CheckBox
            id={`service_${val.id}`}
            onChangeFunc={handleChange}
            name={val.slug}
            value={val.id}
            checked={selectedServices.includes(val.id)}
            parentClassName="mt-0.5"
          />
          <div className="flex leading-4">
            {val.description ? (
              <TooltipCmp
                message={val.description}
                parentClassName="max-w-80 md:!p-3 !p-1.5"
              >
                <label
                  htmlFor={`service_${val.id}`}
                  className="md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5"
                >
                  {val.name}
                </label>
              </TooltipCmp>
            ) : (
              <label
                htmlFor={`service_${val.id}`}
                className="md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5"
              >
                {val.name}
              </label>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax';
    } else if (contactMethod === 'phone-number') {
      return 'Phone';
    } else {
      return 'Email';
    }
  };

  const parsedPhoneNumber = inbondContactValue
    ? parsePhoneNumberFromString(inbondContactValue)
    : null;
  const defaultValues = {
    phone: parsedPhoneNumber?.nationalNumber || '',
    phone_country_code: parsedPhoneNumber?.country || '',
  };

  const emergencyContactNo = emergencyContactNumber
    ? parsePhoneNumberFromString(emergencyContactNumber)
    : null;
  const defaultEmergencyPhoneValues = {
    phone: emergencyContactNo?.nationalNumber || '',
    phone_country_code: emergencyContactNo?.country || '',
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isAccessTypeFormValid = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      isInBondFormValid();

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const prepareAdditionalServices = () => {
    const formDataCurrent: any = {
      serviceId: selectedServices,
      limitedAccess: isLimitedMore,
      inbond: isInbondMore,
      dangerousGoods: isDangerousMore,
    };

    if (isLimitedMore) {
      formDataCurrent.limitedAccessType = limitedAccessType;
      formDataCurrent.limitedAccessOther = limitedAccessOther;
    }

    if (isInbondMore) {
      formDataCurrent.inbondType = inbondType;
      formDataCurrent.inbondName = inbondName;
      formDataCurrent.inbondAddress = inbondAddress;
      formDataCurrent.inbondContactMethod = inbondContactMethod;
      formDataCurrent.inbondContactValue = inbondContactValue;
    }

    if (isDangerousMore) {
      formDataCurrent.dangerGoodsType = dangerType;
      formDataCurrent.goodsClass = goodsClass;
      formDataCurrent.description = description;
      formDataCurrent.unitedNationsNumber = unitedNationsNumber;
      formDataCurrent.packagingGroup = packagingGroup;
      formDataCurrent.emergencyContactName = emergencyContactName;
      formDataCurrent.emergencyContactNumber = emergencyContactNumber;
      formDataCurrent.emergencyContactExt = emergencyContactExt;
    }

    if (isDeclaredValueMore) {
      formDataCurrent.declaredValue = declaredValue;
      formDataCurrent.isDeclaredValue = isDeclaredValueMore;
      formDataCurrent.currency = declaredValueUnit;
    }

    return formDataCurrent;
  };

  const submitService = () => {
    const additionalServices = prepareAdditionalServices();

    setIsSubmitForm(false);
    setValue('additionalServices', additionalServices);
  };

  // const isFormValid = () => {
  //   const validFormData: any = [];
  //   formData.forEach((form: any) => {
  //     const validation = {
  //       ...initValidForm,
  //       handlingUnit: {
  //         required: Boolean(form.handlingUnit),
  //         valid: Boolean(form.handlingUnit),
  //       },
  //       weight: {
  //         required: Boolean(form.weight),
  //         valid: Boolean(form.weight),
  //       },
  //       handlingUnitNo: {
  //         required: Boolean(form.handlingUnitNo),
  //         valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
  //       },
  //       itemLength: {
  //         required: Boolean(form.itemLength),
  //         valid: form.itemLength <= validateMeasurement.length,
  //       },
  //       width: {
  //         required: Boolean(form.width),
  //         valid: form.width <= validateMeasurement.width,
  //       },
  //       height: {
  //         required: Boolean(form.height),
  //         valid: form.height <= validateMeasurement.height,
  //       },
  //       freightClass: {
  //         required: Boolean(form.freightClass),
  //         valid: form.freightClass > 0,
  //       },
  //       commodityId: {
  //         required: form.isCommodity ? Boolean(form.commodityId) : true,
  //         valid: form.isCommodity ? Boolean(form.commodityId) : true,
  //       },
  //       sub: {
  //         required: form.isCommodity ? Boolean(form.sub) : true,
  //         valid: form.isCommodity ? Boolean(form.sub) : true,
  //       },
  //     };
  //     validFormData.push(validation);
  //   });
  //   setValidForm(validFormData);
  //   let valid = validFormData.every((form: any) =>
  //     requiredFields.every(
  //       (requiredField) =>
  //         form[requiredField].required && form[requiredField].valid
  //     )
  //   );

  //   return valid;
  // };

  // const validation = () => {
  //   let valid = true;
  //   const validFormData = {
  //     limitedAccessOther: true,
  //     inbondName: true,
  //     inbondAddress: true,
  //     inbondContactValue: true,
  //     isEmailValid: true,
  //     isPhoneValid: true,
  //     unitedNationsNumber: true,
  //     goodsClass: true,
  //     description: true,
  //     emergencyContactName: true,
  //     emergencyContactNumber: true,
  //     isEmergencyPhoneValid: true,
  //     declaredValue: true,
  //   };

  //   if (isLimitedMore && limitedAccessType === 'other') {
  //     if (limitedAccessOther === '' || limitedAccessOther === null) {
  //       validFormData.limitedAccessOther = false;
  //       valid = false;
  //     }
  //   }

  //   if (isInbondMore) {
  //     if (inbondName === '' || inbondName === null) {
  //       validFormData.inbondName = false;
  //       valid = false;
  //     }

  //     if (inbondAddress === '' || inbondAddress === null) {
  //       validFormData.inbondAddress = false;
  //       valid = false;
  //     }

  //     if (inbondContactValue === '' || inbondContactValue === null) {
  //       const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
  //       validFormData.inbondContactValue = false;

  //       if (
  //         getName(inbondContactMethod) === 'Email' &&
  //         !emailRegex.test(inbondContactValue)
  //       ) {
  //         validFormData.isEmailValid = false;
  //         valid = false;
  //       }
  //     }
  //   }

  //   if (isDangerousMore) {
  //     if (unitedNationsNumber === '' || unitedNationsNumber === null) {
  //       validFormData.unitedNationsNumber = false;
  //       valid = false;
  //     }

  //     if (goodsClass === '' || goodsClass === null) {
  //       validFormData.goodsClass = false;
  //       valid = false;
  //     }

  //     if (description === '' || description === null) {
  //       validFormData.description = false;
  //       valid = false;
  //     }

  //     if (emergencyContactName === '' || emergencyContactName === null) {
  //       validFormData.emergencyContactName = false;
  //       valid = false;
  //     }

  //     if (emergencyContactNumber === '' || emergencyContactNumber === null) {
  //       validFormData.emergencyContactNumber = false;
  //       valid = false;
  //     }
  //   }

  //   if (isDeclaredValueMore) {
  //     if (!declaredValue) {
  //       validFormData.declaredValue = false;
  //       valid = false;
  //     }
  //   }
  //   setServicesValidForm(validFormData);

  //   return valid;
  // };

  const isDangerousGoodsFormValid = () => {
    let valid = true;
    const validFormData = {
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      } else {
        if (!validServicesForm.isEmergencyPhoneValid) {
          validFormData.isEmergencyPhoneValid = false;
          valid = false;
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isExcessValuation = () => {
    let valid = true;
    const validFormData = {
      declaredValue: true,
    };

    if (!declaredValue) {
      validFormData.declaredValue = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };
    defaultValues.phone_country_code = country;
    setInbondContactValue(`+${code}${phone}`);

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isValid;
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));
  };

  const handleDangerPhoneChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };
    defaultEmergencyPhoneValues.phone_country_code = country;
    setEmergencyContactNumber(`+${code}${phone}`);
    setEmergencyPhoneNumber(`${phone}`);

    if (phone === '') {
      validFormData.emergencyContactNumber = false;
      validFormData.isEmergencyPhoneValid = false;
    } else {
      validFormData.isEmergencyPhoneValid = isValid;
    }

    setServicesValidForm({ ...validServicesForm, ...validFormData });
  };

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        .includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  useEffect(() => {
    if (isSubmitForm) {
      submitService();
    }
  }, [isSubmitForm]);

  const resetState = () => {
    setIsTailgate([]);
    setIsLimitedMore(false);
    setLimitedAccessType('');
    setLimitedAccessOther('');
    setIsInbondMore(false);
    setInbondType('');
    setInbondName('');
    setInbondAddress('');
    setInbondContactMethod('');
    setInbondContactValue('');
    setIsDeclaredValueMore(false);
    setDeclaredValueUnit('');
    setDeclaredValue('');
    setIsDangerousMore(false);
    setDangerType('');
    setPackagingGroup('');
    setUnitedNationsNumber('');
    setGoodsClass('');
    setEmergencyContactName('');
    setEmergencyContactNumber('');
    setEmergencyContactExt('');
    setDescription('');
    setSelectedServices([]);
  };

  useEffect(() => {
    if (currentLegData && currentLegData?.additionalServices) {
      const additionalServices = currentLegData?.additionalServices;

      setSelectedServices(additionalServices.serviceId);

      // setIsLimitedMore(additionalServices.limitedAccess || false);
      setLimitedAccessType(additionalServices.limitedAccessType || '');
      setLimitedAccessOther(additionalServices.limitedAccessOther || '');

      // setIsInbondMore(additionalServices.inbond || false);
      setInbondType(additionalServices.inbondType || '');
      setInbondName(additionalServices.inbondName || '');
      setInbondAddress(additionalServices.inbondAddress || '');
      setInbondContactMethod(additionalServices.inbondContactMethod || '');
      setInbondContactValue(additionalServices.inbondContactValue || '');

      // setIsDangerousMore(additionalServices.dangerousGoods || false);
      setDangerType(additionalServices.dangerGoodsType || '');
      setGoodsClass(additionalServices.goodsClass || '');
      setDescription(additionalServices.description || '');
      setUnitedNationsNumber(additionalServices.unitedNationsNumber || '');
      setPackagingGroup(additionalServices.packagingGroup || '');
      setEmergencyContactName(additionalServices.emergencyContactName || '');
      setEmergencyContactNumber(
        additionalServices.emergencyContactNumber || ''
      );
      setEmergencyContactExt(additionalServices.emergencyContactExt || '');

      // setIsDeclaredValueMore(additionalServices.isDeclaredValue || false);
      setDeclaredValue(additionalServices.declaredValue || '');
      setDeclaredValueUnit(additionalServices.currency || '');

      if (additionalServices?.serviceId) {
        additionalServices?.serviceId?.forEach((service: any) => {
          const matchingService: any = serviceList.find(
            (s: any) =>
              s.id === service &&
              (s.slug === 'tailgate_pickup' || s.slug === 'tailgate_delivery')
          );

          if (matchingService) {
            setIsTailgate((old: any) => [...old, matchingService.slug]);
          }
        });
      }
    } else {
      resetState();
    }
  }, [currentLegData]);

  return (
    <>
      <h6 className="text-textSecondary text-sm font-medium mb-1.5">
        Are there any additional services for this load?
      </h6>
      <AccordionCmp
        title={
          <div className="flex flex-wrap w-full justify-between items-center">
            <div>
              <h3 className="text-grayLight900 font-semibold text-base">
                Select Additional Services
              </h3>
              <p className="text-xs text-grayLight600">
                Please select any additional services that are needed for this
                shipment.
              </p>
            </div>
            <TabButton
              className="TabButton"
              tabArray={additionalServiceArr}
              activeClassName="!bg-utilityGray100"
              isActive={activeTab}
              handleOnClick={(e: any) => {
                e.stopPropagation();
                setActiveTab(e?.target?.dataset?.value);
              }}
            />
          </div>
        }
        className="shadow-sm !p-0 overflow-hidden !cursor-default"
        contentClassName="!p-0"
        contentParentClass="!mt-0 !pt-0 !border-t-0"
        accordionHeaderWrap="px-4 py-3 cursor-pointer"
        isOpen={isOpenAccordian}
        handleClick={(e: any) => {
          if (e?.target && !e?.target?.closest('.TabButton')) {
            setIsOpenAccordian(!isOpenAccordian);
          }
        }}
      >
        {serviceList.length ? (
          <div className="flex flex-wrap">
            {activeTab === 'pickup' &&
              (serviceTypes && serviceTypes.PICKUP?.length > 0 ? (
                getList(serviceTypes.PICKUP)
              ) : (
                <NotFoundUI
                  title="No services found"
                  desc={`No services found.`}
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              ))}

            {activeTab === 'delivery' &&
              (serviceTypes && serviceTypes.DELIVERY?.length > 0 ? (
                getList(serviceTypes.DELIVERY)
              ) : (
                <NotFoundUI
                  title="No services found"
                  desc={`No services found.`}
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              ))}

            {activeTab === 'other' &&
              (serviceTypes && serviceTypes.OTHER?.length > 0 ? (
                getList(serviceTypes.OTHER)
              ) : (
                <NotFoundUI
                  title="No services found"
                  desc={`No services found.`}
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              ))}
          </div>
        ) : (
          <div className="flex justify-center mt-4">
            {isLoading ? (
              <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
            ) : (
              false
            )}
          </div>
        )}
      </AccordionCmp>

      {isInbondMoreModalOpen && (
        <InBondMoreModal
          setServicesValidForm={setServicesValidForm}
          setIsInbondMore={setIsInbondMore}
          setIsInbondMoreModalOpen={setIsInbondMoreModalOpen}
          isInBondFormValid={isInBondFormValid}
          setIsSubmitForm={setIsSubmitForm}
          handleChangeRadio={handleChangeRadio}
          inbondType={inbondType}
          validServicesForm={validServicesForm}
          inbondName={inbondName}
          setInbondName={setInbondName}
          inbondAddress={inbondAddress}
          setInbondAddress={setInbondAddress}
          getName={getName}
          inbondContactMethod={inbondContactMethod}
          inbondContactValue={inbondContactValue}
          setInbondContactValue={setInbondContactValue}
          handleNumberChange={handleNumberChange}
        />
      )}
      {isDangerousMoreModalOpen && (
        <DangerousMoreModal
          setServicesValidForm={setServicesValidForm}
          setIsDangerousFormSubmitted={setIsDangerousFormSubmitted}
          setUnitedNationsNumber={setUnitedNationsNumber}
          setGoodsClass={setGoodsClass}
          setDescription={setDescription}
          setEmergencyContactName={setEmergencyContactName}
          setEmergencyContactNumber={setEmergencyContactNumber}
          setEmergencyContactExt={setEmergencyContactExt}
          setIsDangerousMore={setIsDangerousMore}
          setIsDangerousMoreModalOpen={setIsDangerousMoreModalOpen}
          handleChangeRadio={handleChangeRadio}
          dangerType={dangerType}
          validServicesForm={validServicesForm}
          isDangerousGoodsFormValid={isDangerousGoodsFormValid}
          setIsSubmitForm={setIsSubmitForm}
          unitedNationsNumber={unitedNationsNumber}
          setPackagingGroup={setPackagingGroup}
          packagingGroup={packagingGroup}
          goodsClass={goodsClass}
          description={description}
          emergencyContactName={emergencyContactName}
          handleDangerPhoneChange={handleDangerPhoneChange}
          isDangerousFormSubmitted={isDangerousFormSubmitted}
          emergencyContactNumber={emergencyContactNumber}
          emergencyContactExt={emergencyContactExt}
        />
      )}
      {isExcessValuationModalOpen && (
        <ExcessValuationModal
          setServicesValidForm={setServicesValidForm}
          setIsDeclaredValueMore={setIsDeclaredValueMore}
          isExcessValuation={isExcessValuation}
          setIsSubmitForm={setIsSubmitForm}
          setIsExcessValuationModalOpen={setIsExcessValuationModalOpen}
          validServicesForm={validServicesForm}
          setDeclaredValue={setDeclaredValue}
          declaredValue={declaredValue}
          filterArr={filterArr}
          setDeclaredValueUnit={setDeclaredValueUnit}
          declaredValueUnit={declaredValueUnit}
        />
      )}
      {isAccessTypeModalOpen && (
        <AccessTypeModal
          setLimitedAccessSearch={setLimitedAccessSearch}
          setServicesValidForm={setServicesValidForm}
          setIsAccessTypeModalOpen={setIsAccessTypeModalOpen}
          isAccessTypeFormValid={isAccessTypeFormValid}
          setIsSubmitForm={setIsSubmitForm}
          limitedAccessSearch={limitedAccessSearch}
          limitedAccessTypeList={limitedAccessTypeList}
          handleChangeRadio={handleChangeRadio}
          limitedAccessType={limitedAccessType}
          validServicesForm={validServicesForm}
          limitedAccessOther={limitedAccessOther}
          setLimitedAccessOther={setLimitedAccessOther}
        />
      )}
    </>
  );
};

export default AdditionalServices;
