import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const AnlyticsDataLoading = () => (
  <ul className="flex -m-2 flex-wrap mt-1.5">
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Total Quote Requests
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                45456
              </span>
            </div>

            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs ">
                  <span className="xxl:text-sm text-xs">25 %</span> &nbsp;vs
                  Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Total Quotes
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                56,342
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">15%</span> &nbsp;vs Last
                  month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Average Time to Quote
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                09m 15s
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">23 %</span> &nbsp;vs
                  Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Win/Loss
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                34.66456
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">21 %</span>
                  &nbsp;vs Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Total Orders
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                4545645
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">21 %</span>
                  &nbsp;vs Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Total Margin
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                28,421.00
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">15%</span>
                  &nbsp;vs Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Margin Percentage
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                65.0045
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">18%</span>
                  &nbsp;vs Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
      <div className="rounded-lg border border-utilityGray200 p-3">
        <div className="w-full justify-between flex items-start">
          <h6 className="text-grayLight600 text-xs font-medium mb-1.5 custom-loading">
            Average Order Value
          </h6>
        </div>
        <div className="w-full flex justify-between pt-2 ">
          <div className="w-full flex flex-col justify-end">
            <div>
              <span className="font-semibold text-2xl custom-loading">
                6,33445
              </span>
            </div>
            <div className="w-full flex  justify-between ">
              <div
                className={`w-fit flex justify-center items-center mt-2 custom-loading`}
              >
                <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                  <span className="xxl:text-sm text-xs">25%</span>
                  &nbsp;vs Last month
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
);

export default AnlyticsDataLoading;
