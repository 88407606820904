import { SearchLg, PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';

import { initServiceValidForm } from './additionalServices.const';

const AccessTypeModal = ({
  setLimitedAccessSearch,
  setServicesValidForm,
  setIsAccessTypeModalOpen,
  isAccessTypeFormValid,
  setIsSubmitForm,
  limitedAccessSearch,
  limitedAccessTypeList,
  handleChangeRadio,
  limitedAccessType,
  validServicesForm,
  limitedAccessOther,
  setLimitedAccessOther,
}: any) => (
  <CommonModal
    title={'Limited access delivery'}
    titleDesc={'Please select limited access delivery type'}
    modalClassName="flex flex-col"
    handleClose={() => {
      setLimitedAccessSearch('');
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="limited_access_delivery"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsAccessTypeModalOpen(false);
    }}
    headerIcon={<PackagePlus />}
    size={'max-w-[688px]'}
    secondaryBtnText="Cancel"
    primaryBtnText="Save Changes"
    primaryBtnOnClick={() => {
      if (isAccessTypeFormValid()) {
        setIsAccessTypeModalOpen(false);
        setIsSubmitForm(true);
      }
    }}
    secondaryBtnOnClick={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="limited_access_delivery"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsAccessTypeModalOpen(false);
    }}
  >
    <div className="p-5 flex-1 flex flex-col overflow-y-auto">
      <InputText
        inputName="searchCarrier"
        placeholder="Search"
        className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
        icon={
          <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
        }
        value={limitedAccessSearch}
        isClearable
        onChangeFunc={(e) => setLimitedAccessSearch(e.target.value)}
        inputType="text"
        parentClassName="w-full mb-4"
      />

      <div className="rounded-lg border border-borderPrimary px-3 py-1 flex-1 overflow-y-auto custom-scrollbar-v2">
        {limitedAccessTypeList.map((val: any, i: any) => (
          <div
            className="flex sms:p-4 px-2 py-3 border-b border-utilityGray200 last:border-b-0"
            key={`limited_${i}`}
          >
            <div className="flex items-center">
              <div
                className="flex items-start gap-2"
                key={`service_${val.value}`}
              >
                <Radio
                  id={`service_${val.value}`}
                  onChangeFunc={handleChangeRadio}
                  inputName={'limitedAccessType'}
                  value={val.value}
                  checked={limitedAccessType === val.value}
                />
                <label
                  htmlFor={`service_${val.value}`}
                  className="text-xs text-textSecondary uppercase cursor-pointer font-medium"
                >
                  {val.name}
                </label>
              </div>
            </div>
          </div>
        ))}
        {limitedAccessType === 'other' && (
          <div className="mt-5 w-full">
            <InputText
              inputName="limitedAccessOther"
              className={`form_control ${
                !validServicesForm.limitedAccessOther
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label=""
              value={limitedAccessOther}
              errorText={
                !validServicesForm.limitedAccessOther
                  ? 'This field is required'
                  : null
              }
              onChangeFunc={(e) => {
                setLimitedAccessOther(e.target.value);
                setServicesValidForm((old: any) => ({
                  ...old,
                  [e.target.name]: true,
                }));
              }}
            />
          </div>
        )}
      </div>
    </div>
  </CommonModal>
);

export default AccessTypeModal;
