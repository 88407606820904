// import { PhoneCall01 } from '@untitled-ui/icons-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Loader from 'src/components/Loader';
import {
  resendCustomerMail,
  verifyOtp,
} from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

import LogoDark from '../../assets/img/logo-dark.png';

const VerifyOtp = ({ setIsVerifyOtp }: any) => {
  const { id } = useParams<{ id: any }>();
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [focusOtp, setFocusOtp] = useState(new Array(4).fill(false));
  const [otpValue, setOtpValue] = useState<any>(null);
  const [isValidOtp, setIsValidOtp] = useState<boolean>(false);
  const [wrongOtp, setWrongOtp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let val = otp.join(' ').trim().replace(/ /g, '');
    setOtpValue(val);

    if (val.length === 4) {
      setIsValidOtp(true);
    } else {
      setIsValidOtp(false);
    }
  }, [otp]);

  useEffect(() => {
    // Focus the first input element after the component mounts
    const firstInput = document.getElementById('otp_text_0');

    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  const handleInputChange = (e: any, index: number) => {
    const value = e.target.value;
    const newValue = value.replace(otp[index], '');

    otp[index] = newValue;

    if (newValue.length === 1) {
      if (document.activeElement === e.target) {
        e.target.blur();
      }
      const nextElement: any = document.querySelector(`.otp-${index + 1}`);

      if (nextElement && nextElement.tagName.toLowerCase() === 'input') {
        nextElement.focus();
      } else {
        let continueButton: any = document.querySelector(`button.continue-btn`);

        if (continueButton) {
          continueButton.focus();
        }
      }
    }

    if (index < 3) {
      otp[index + 1] = '';
    }

    setWrongOtp(false);
    setOtp([...otp]);
  };

  // const onResendOtp = () => {
  //   setOtp(new Array(4).fill(''));
  // };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Backspace') {
      const value = e.target.value;
      const newValue = value.slice(0, 1);

      if (newValue.length === 0) {
        const previousElement: any = document.querySelector(
          `.otp-${index - 1}`
        );

        if (
          previousElement &&
          previousElement.tagName.toLowerCase() === 'input'
        ) {
          previousElement.focus();
        }
      }
    }
  };

  const onVerifyOtp = (otpCode: any) => {
    setIsLoading(true);
    verifyOtp(id, otpCode)
      .then((response: any) => {
        if (response.status) {
          console.log('response.status :>> ', response.status);
          localStorage.setItem(
            'verifyData',
            JSON.stringify({ customerId: id, isVerify: '1' })
          );
          setIsVerifyOtp(true);
        }
        setError(false);
      })
      .catch(() => {
        setError(true);
        // WalToast.error(
        //   e?.response?.data?.message ?? 'Invalid OTP. please enter valid OTP.'
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendMailToCustomer = () => {
    console.log('id :>> ', id);
    setIsMailLoading(true);
    setOtp(new Array(4).fill(''));
    resendCustomerMail({ customerId: id })
      // sendCustomerMail(id)
      .then(() => {
        WalToast.success('Mail send to Customer successfully');
      })
      .catch((e: any) =>
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        )
      )
      .finally(() => {
        setIsMailLoading(false);
      });
  };

  return (
    <div className="w-full h-full bg-white flex justify-center items-center">
      {/* <CommonModal
        title={`Enter the 4 digit code`}
        titleDesc="Please enter verification code"
        headerIcon={<PhoneCall01 />}
        size=""
        handleClose={handleClose}
        primaryBtnOnClick={() => onVerifyOtp(otpValue)}
        primaryBtnText="Confirm"
        primaryBtnDisabled={!isValidOtp || isLoading}
        primaryBtnLoading={isLoading}
      > */}

      <div className="w-[464px] bg-white flex flex-col justify-center items-center border rounded-lg">
        {/* <div className="sm:w-[360px] w-full sm:max-w-full max-w-[343px] text-center px-4 sm:px-0">
          <img src={LogoDark} alt="logo" className="sm:w-50 w-30" />
        </div> */}

        <div className="max-w-[213px] text-center flex items-center justify-center pt-10 pb-3">
          <img src={LogoDark} alt="logo-light" className="w-full" />
        </div>
        <div className="p-5 text-center">
          <div className="text-grayLight900 text-lg font-semibold break-words flex items-center justify-center">
            Verify OTP
          </div>
          <div className="text-center">
            <span className="text-grayLight600 text-sm font-normal leading-normal">
              We sent a verification code to your email
            </span>
            {/* <span className="flex justify-center items-center text-grayLight900 text-sm font-medium leading-normal">
              {currentUser.country || '+1'} (XXX) XXX-
              {currentUser?.phone
                ? currentUser.phone.substring(currentUser.phone.length - 4)
                : '5566'}
            </span> */}
            <div className="w-fit mb-8 mt-5 mx-auto">
              <div className="flex justify-center gap-3  ">
                {otp.map((_: any, index: number) => (
                  <InputText
                    key={`otp_text_${index}`}
                    id={`otp_text_${index}`}
                    inputName="otp"
                    inputType="number"
                    placeholder={focusOtp[index] ? '' : '0'}
                    isError={wrongOtp}
                    min={0}
                    max={9}
                    maxLength={1}
                    className={`md:w-[70px] md:h-[70px] sm:h-16 sm:w-16 h-11 w-11 border-2 border-utilityGray200 /*!border-fgErrorPrimary*/  focus:border-utilityGray200 focus:!shadow-[0_0_0_4px_rgba(158,119,237,0.24)] shadow-sm bg-white rounded-[10px]  transition p-0 md:text-[42px] sm:text-2xl text-lg text-textSecondary /*text-fgErrorPrimary*/ font-normal text-center focus:outline-0 overflow-hidden hide-btn otp-${index}`}
                    value={otp[index]}
                    onFocus={() => {
                      focusOtp[index] = true;
                      setFocusOtp([...focusOtp]);
                    }}
                    onBlur={() => {
                      focusOtp[index] = false;
                      setFocusOtp([...focusOtp]);
                    }}
                    onChangeFunc={(e: any) => handleInputChange(e, index)}
                    handleKeyDown={(e: any) => handleKeyDown(e, index)}
                    // errorText={!validForm.address1 ? 'Address 1 name is required' : null}
                  />
                ))}
              </div>
              {wrongOtp && (
                <p className="text-uitilityred500 text-sm font-normal mt-1 text-start">
                  Please enter valid OTP
                </p>
              )}
              {error && (
                <div className="mt-4 text-start">
                  <ErrorMsg errorText="Invalid OTP! please enter valid OTP." />
                </div>
              )}
            </div>
            <div className="text-grayLight600 text-sm font-normal justify-center items-center flex gap-2 cursor-pointer">
              Didn’t receive the code?
              {!isMailLoading ? (
                <a
                  className="text-primary font-semibold"
                  onClick={() => sendMailToCustomer()}
                >
                  &nbsp;Resend
                </a>
              ) : (
                <div className="relative h-3 w-3 mr-3">
                  <Loader spinnerClass={'h-4 w-4'} />
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex p-3 gap-2">
            {/* <ButtonCmp
              className="btn_secondary_black text-textSecondary flex-1"
              onClick={() => {}}
            >
              Cancel
            </ButtonCmp> */}
            <ButtonCmp
              className={`btn_primary flex-1 `}
              onClick={() => onVerifyOtp(otpValue)}
              loading={isLoading}
              disabled={!isValidOtp || isLoading || isMailLoading}
            >
              Verify Otp
            </ButtonCmp>
          </div>
        </div>
      </div>
      {/* </CommonModal> */}
    </div>
  );
};

export default VerifyOtp;
