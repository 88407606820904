import { yupResolver } from '@hookform/resolvers/yup';
import {
  Check,
  Edit05,
  InfoCircle,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AccordionCmp from 'src/components/AccordionCmp';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import StepBar from 'src/components/StepBar/StepBar';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import { roserocketCarrierSearch } from 'src/services/CarrierService';
import { bookOrder } from 'src/services/QuoteService';
import { onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import defaultImage from '../../../../assets/img/default-image.jpg';
import Logo from '../../../../assets/img/logo2.svg';
import truckLoader from '../../../../assets/img/truckloader.gif';
import Address from '../AddressDetails/Address';
import { IMapAddress, scriptId } from '../AddressDetails/Address.interface';

import ConfirmOrderDimensionRow from './ConfirmOrderDimensionRow';

interface IProps {
  handleClose: any;
  carrierRate: any;
  currency: any;
  addressDetails: any;
  setAddressDetails: any;
  dimensions: any;
  connectSocket: any;
}

interface FormData {
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  shipperName: string;
  shipperEmail: string;
  shipperPhoneNumber: string;
  consigneeName: string;
  consigneeEmail: string;
  consigneePhoneNumber: string;
}

// const phoneRegExp = /^(\+?1?[-.\s]?)?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

const validationSchema = yup.object().shape({
  contactName: yup.string().required('Contact name is required'),
  contactEmail: yup
    .string()
    .email('Invalid email')
    .required('Contact email is required'),
  contactPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Contact phone is required'),
  shipperName: yup.string().required('Shipper name is required'),
  shipperEmail: yup
    .string()
    .email('Invalid email')
    .required('Shipper email is required'),
  shipperPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Shipper phone is required'),
  consigneeName: yup.string().required('Consignee name is required'),
  consigneeEmail: yup
    .string()
    .email('Invalid email')
    .required('Consignee email is required'),
  consigneePhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Consignee phone is required'),
});

export const initAction = {
  mode: 'addressUpdate',
  shipper: false,
  consignee: false,
};

const FinalizeOrderModal = ({
  handleClose,
  carrierRate,
  addressDetails,
  setAddressDetails,
  dimensions,
  connectSocket,
}: IProps) => {
  const { googleKey } = useContext(BasicContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [quotedServices, setQuotedServices] = useState<any[]>([]);
  const [nonQuotedServices, setNonQuotedServices] = useState<any[]>([]);
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [shipperAddress, setShipperAddress] = useState<IMapAddress>();
  const [consigneeAddress, setConsigneeAddress] = useState<IMapAddress>();
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [formManualWarnings, setFormManualWarnings] = useState<any>({});
  const [action, setAction] = useState(initAction);
  const [isShipperAddressChanged, setIsShipperAddressChanged] = useState(false);
  const [isConsigneeAddressChanged, setIsConsigneeAddressChanged] =
    useState(false);
  const [roserocketCarrierSEarchParams, setRoserocketCarrierSEarchParams] =
    useState<any>({
      search: '',
      customerId: addressDetails.customerId,
    });
  const [roserocketCarriers, setRoserocketCarriers] = useState<any[]>([]);
  const [selectedRoserocketCarrier, setSelectedRoserocketCarrier] =
    useState<any>({});
  const [isCarrierError, setIsCarrierError] = useState<boolean>(false);

  window.initMap = () => {
    setStatus(true);
  };

  useEffect(() => {
    setIsConsigneeAddressChanged(
      consigneeAddress?.fullAddress !== addressDetails.consigneeFullAddress &&
        consigneeAddress
        ? true
        : false
    );

    setIsShipperAddressChanged(
      shipperAddress?.fullAddress !== addressDetails.shipperFullAddress &&
        shipperAddress
        ? true
        : false
    );
  }, [addressDetails, shipperAddress, consigneeAddress]);

  useEffect(() => {
    setShipperAddress({
      fullAddress: addressDetails.shipperFullAddress,
      address1:
        addressDetails?.shipperAddress1 === addressDetails?.shipperCity
          ? ''
          : addressDetails.shipperAddress1,
      city: addressDetails.shipperCity,
      state: addressDetails.shipperState,
      country: addressDetails.shipperCountry,
      postal: addressDetails.shipperPostal,
      latitude: addressDetails.shipperLatitude,
      longitude: addressDetails.shipperLongitude,
      stateCode: addressDetails.shipperStateCode,
      countryCode: addressDetails.shipperCountryCode,
    });

    setConsigneeAddress({
      fullAddress: addressDetails.consigneeFullAddress,
      address1:
        addressDetails?.consigneeAddress1 === addressDetails?.consigneeCity
          ? ''
          : addressDetails.consigneeAddress1,
      city: addressDetails.consigneeCity,
      state: addressDetails.consigneeState,
      country: addressDetails.consigneeCountry,
      postal: addressDetails.consigneePostal,
      latitude: addressDetails.consigneeLatitude,
      longitude: addressDetails.consigneeLongitude,
      stateCode: addressDetails.consigneeStateCode,
      countryCode: addressDetails.consigneeCountryCode,
    });

    // checking if address1 and city is same then show manual warning
    if (
      addressDetails?.shipperAddress1 === addressDetails?.shipperCity ||
      addressDetails?.shipperAddress1 === '' ||
      addressDetails?.shipperAddress1 === null ||
      !/\d/.test(addressDetails?.shipperAddress1)
    ) {
      setFormManualWarnings((prev: any) => ({
        ...prev,
        shipperFullAddress:
          "Please provide shipper's complete address, including the street name.",
      }));
    }

    if (
      addressDetails?.consigneeAddress1 === addressDetails?.consigneeCity ||
      addressDetails?.consigneeAddress1 === '' ||
      addressDetails?.consigneeAddress1 === null ||
      !/\d/.test(addressDetails?.consigneeAddress1)
    ) {
      setFormManualWarnings((prev: any) => ({
        ...prev,
        consigneeFullAddress:
          "Please provide consignee's complete address, including the street name",
      }));
    }
  }, [addressDetails]);

  useEffect(() => {
    if (!window?.google?.maps?.places) {
      document.getElementById(scriptId)?.remove();
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap&loading=async`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, [googleKey]);

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };

    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate.replace(/(\d{1,2} \w+)/, '$1,');
  };

  const getBusinessDays = (startDate: Date, endDate: Date) => {
    let count = 0;
    const oneDay = 24 * 60 * 60 * 1000;

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setTime(date.getTime() + oneDay)
    ) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return count;
  };

  const headerIcon = (
    <img
      src={Logo}
      alt="logo-light"
      className="object-covert h-full w-full rounded-[10px] border border-utilityGray200 p-1.5"
    />
  );

  const STEPS = [
    {
      name: 'Confirm Order Details',
      step: 1,
    },
    {
      name: 'Additional Information',
      step: 2,
    },
    {
      name: 'Finalize Order Creation',
      step: 3,
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = (data: any) => {
    if (!selectedRoserocketCarrier?.name) {
      setIsCarrierError(true);

      return;
    }
    setIsLoading(true);
    connectSocket();

    data.shipperFullAddress = shipperAddress?.fullAddress;
    data.shipperAddress1 = shipperAddress?.address1;
    data.shipperCity = shipperAddress?.city;
    data.shipperState = shipperAddress?.state;
    data.shipperStateCode = shipperAddress?.stateCode;
    data.shipperCountry = shipperAddress?.country;
    data.shipperCountryCode = shipperAddress?.countryCode;
    data.shipperPostal = shipperAddress?.postal;
    data.shipperLatitude = shipperAddress?.latitude;
    data.shipperLongitude = shipperAddress?.longitude;

    data.consigneeFullAddress = consigneeAddress?.fullAddress;
    data.consigneeAddress1 = consigneeAddress?.address1;
    data.consigneeCity = consigneeAddress?.city;
    data.consigneeState = consigneeAddress?.state;
    data.consigneeStateCode = consigneeAddress?.stateCode;
    data.consigneeCountry = consigneeAddress?.country;
    data.consigneeCountryCode = consigneeAddress?.countryCode;
    data.consigneePostal = consigneeAddress?.postal;
    data.consigneeLatitude = consigneeAddress?.latitude;
    data.consigneeLongitude = consigneeAddress?.longitude;
    data.selectedCarrier = selectedRoserocketCarrier;

    bookOrder(data, carrierRate.id)
      .then((res: any) => {
        if (res?.data) {
          setAddressDetails((prevState: any) => ({
            ...prevState,
            ...res.data,
          }));
        }
        setActiveStep(3);
      })
      .catch((e: any) => {
        WalToast.error(
          e?.response?.data?.message ??
            "Can't book this order, some details missing"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else if (activeStep === 2) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (carrierRate?.services?.length) {
      setQuotedServices(
        carrierRate?.services?.filter((service: any) => service.isQuoted)
      );
      setNonQuotedServices(
        carrierRate?.services?.filter((service: any) => !service.isQuoted)
      );
    }
  }, [carrierRate]);

  useEffect(() => {
    setFormManualWarnings((old: any) => ({
      ...old,
      consigneeFullAddress:
        consigneeAddress?.address1 &&
        /\d/.test(consigneeAddress?.address1) &&
        consigneeAddress?.address1 !== consigneeAddress?.city
          ? undefined
          : "Please provide consignee's complete address, including the street name.",
    }));
  }, [consigneeAddress]);

  useEffect(() => {
    setFormManualWarnings((old: any) => ({
      ...old,
      shipperFullAddress:
        shipperAddress?.address1 &&
        /\d/.test(shipperAddress?.address1) &&
        shipperAddress?.address1 !== shipperAddress?.city
          ? undefined
          : "Please provide shipper's complete address, including the street name.",
    }));
  }, [shipperAddress]);

  const searchCarriersFromRoserocket = () => {
    roserocketCarrierSearch(roserocketCarrierSEarchParams)
      .then((res: any) => {
        setRoserocketCarriers(
          res?.data.map((data: any) => ({
            name: data.name,
            carrierId: data.carrierId,
            serviceId: data.serviceId,
            location: data.location,
          }))
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (roserocketCarrierSEarchParams.search) {
      searchCarriersFromRoserocket();
    } else {
      setRoserocketCarriers([]);
    }
  }, [roserocketCarrierSEarchParams]);

  const handleAddressSelection = (value: any) => {
    // if address1 is blank then setting city as address1 line
    if (value?.address1 === '') {
      value.address1 = value?.city;
    }

    if (action.shipper) {
      setShipperAddress({
        fullAddress: value.fullAddress,
        address1: value?.address1 === value?.city ? '' : value.address1,
        city: value.city,
        state: value.state,
        country: value.country,
        postal: value.postal,
        latitude: value.latitude,
        longitude: value.longitude,
        stateCode: value.stateCode,
        countryCode: value.countryCode,
      });
    } else if (action.consignee) {
      setConsigneeAddress({
        fullAddress: value.fullAddress,
        address1: value?.address1 === value?.city ? '' : value.address1,
        city: value.city,
        state: value.state,
        country: value.country,
        postal: value.postal,
        latitude: value.latitude,
        longitude: value.longitude,
        stateCode: value.stateCode,
        countryCode: value.countryCode,
      });
    }
    setAction(initAction);
  };

  const headCells = useMemo(
    () => [
      {
        id: 'handling_unit',
        name: 'Handling unit',
      },
      {
        id: 'hu',
        name: '№ of HU',
      },
      {
        id: 'freight_class',
        name: 'Freight Class',
      },
      {
        id: 'total_weight',
        name: 'Total weight',
      },
      {
        id: 'dimensions',
        name: 'Dimensions',
      },
    ],
    []
  );

  const today = new Date();
  const deliveryDate = new Date(carrierRate.projectedDeliveryDate);
  const pickupDate = new Date(addressDetails.pickupDate);
  const formattedDeliveryDate = formatDate(deliveryDate);
  const formattedPickupDate = formatDate(pickupDate);
  const businessDays = getBusinessDays(today, deliveryDate);

  return (
    <>
      <CommonModal
        // title={`Finalize Order Creation with ${carrierRate.name}`}
        title={
          activeStep === 1
            ? `Confirm Order with ${carrierRate.name}`
            : activeStep === 2
            ? `Additional Information with ${carrierRate.name}`
            : activeStep === 3
            ? `Finalize Order Creation With ${carrierRate.name}`
            : `Finalize Order Creation with ${carrierRate.name}`
        }
        titleDesc={
          activeStep === 3 ? (
            <p className="max-w-[340px] mx-auto text-wrap">
              Simplify and Secure Your Order Confirmation Process with{' '}
              {carrierRate.name}
            </p>
          ) : (
            <p className="max-w-[340px] mx-auto text-wrap">
              Review and confirm your order details to directly book this order
              with {carrierRate.name}
            </p>
          )
        }
        handleClose={() => handleClose(false, 'carrierOrderModel')()}
        headerIcon={headerIcon}
        headerSecondIcon={
          <img
            src={`${carrierRate?.imageUrl}${carrierRate?.image}`}
            className="object-covert h-full w-full rounded-[10px] border border-utilityGray200 p-1.5"
            alt={defaultImage}
            title={carrierRate?.name}
            onError={onError}
          />
        }
        headerIconClass="before:hidden border-0 md:!h-[48px] !h-12 md:!w-[48px] !w-12 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] sm:before:-left-[69px] before:-left-[53px] before:opacity-50"
        headerIconSecondClass="before:hidden border-0 md:!h-[48px] !h-12 md:!w-[48px] !w-12 !p-0 !inline-flex items-center relative ml-2 after:content-[''] after:h-[26px] after:w-[26px] after:border-utilityGray200 after:border after:rounded-full after:absolute  after:bg-[url('../../assets/img/link-01.svg')] after:bg-no-repeat after:bg-center after:bg-white after:top-1/2 after:-translate-y-1/2 after:-left-[22px] before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] sm:before:-right-[69px] before:-right-[53px] before:opacity-50"
        size={'max-w-[780px] overflow-unset'}
        isOverflow={false}
        primaryBtnText={activeStep != 3 ? 'Continue' : ''}
        secondaryBtnText={
          activeStep != 3 ? (activeStep > 1 ? 'Back' : 'Cancel') : ''
        }
        modalHeaderTextClass="!block text-center flex-1"
        headerInfoClass="mt-[18px]"
        modalHeaderClass="!pt-5"
        primaryBtnOnClick={handleNext}
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={
          formManualWarnings.shipperFullAddress ||
          formManualWarnings.consigneeFullAddress
        }
        // secondaryBtnOnClick={() => handleClose(false, 'carrierOrderModel')()}
        secondaryBtnOnClick={() =>
          activeStep > 1
            ? setActiveStep(activeStep - 1)
            : handleClose(false, 'carrierOrderModel')()
        }
      >
        <div className="w-full p-5">
          <StepBar
            steps={STEPS}
            activeStep={activeStep}
            stepsParentClass="!justify-center mb-6"
            stepClass="max-sm:flex-col max-sm:text-center"
          />

          {activeStep === 1 && (
            <div className="flex flex-col gap-y-4 ">
              {(isShipperAddressChanged || isConsigneeAddressChanged) && (
                <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 font-normal text-xs text-grayLight600 text-wrap">
                  <InfoCircle className="text-textSecondary w-4 h-4 flex-none" />
                  <div>
                    <span className="text-grayLight900 font-semibold">
                      Warning:
                    </span>{' '}
                    Changes in addresses might cause minor pricing differences
                    in final order amount
                  </div>
                </div>
              )}
              <div className="flex flex-wrap -mx-1 gap-y-2">
                <InputText
                  label="Pickup Date"
                  inputName="name"
                  placeholder="Pickup Date"
                  parentClassName="px-1 sm:w-1/2 w-full"
                  className=""
                  value={`${formattedPickupDate}`}
                  disabled={true}
                  labelClassName="block mb-1.5"
                />
                <InputText
                  label="Delivery Date"
                  inputName="name"
                  placeholder="Delivery Date"
                  parentClassName="px-1 sm:w-1/2 w-full"
                  className=""
                  value={`${formattedDeliveryDate} (${businessDays} Business Days)`}
                  disabled={true}
                  labelClassName="block mb-1.5"
                />
              </div>
              {/* <div className="flex flex-wrap -mx-1 gap-y-2">
                <InputText
                  label="Base Cost"
                  inputName="name"
                  placeholder="Base Cost"
                  parentClassName="px-1 sm:w-1/3 w-full"
                  className=""
                  value={
                    currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(carrierRate.totalCharge)}`
                      : `$${getFormattedNumber(carrierRate.totalChargeUSD)}`
                  }
                  disabled={true}
                  labelClassName="block mb-1.5"
                />
                <InputText
                  label="Additional Services"
                  inputName="name"
                  placeholder="Additional Services"
                  parentClassName="px-1 sm:w-1/3 w-full"
                  className=""
                  value={
                    currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(carrierRate.additionalCharges)}`
                      : `$${getFormattedNumber(
                          carrierRate.additionalChargesUSD
                        )}`
                  }
                  disabled={true}
                  labelClassName="block mb-1.5"
                />
                <InputText
                  label="Total Cost"
                  inputName="name"
                  placeholder="Total Cost"
                  parentClassName="px-1 sm:w-1/3 w-full"
                  className=""
                  value={
                    currency === CURRENCY.CAD
                      ? `$${getFormattedNumber(carrierRate.totalCostCAD)}`
                      : `$${getFormattedNumber(carrierRate.totalCostUSD)}`
                  }
                  disabled={true}
                  labelClassName="block mb-1.5"
                />
              </div> */}
              <div>
                <div className="flex gap-x-1 justify-between items-center mb-1.5">
                  <label className="form_label mb-0 flex gap-2">
                    Shipper Address
                  </label>
                  <div className="gap-x-1 text-primary700 text-xs font-semibold flex items-center cursor-pointer">
                    <Edit05
                      className="w-4 h-4"
                      onClick={() => {
                        setAddressData(shipperAddress);
                        setAction((prev) => ({ ...prev, shipper: true }));
                      }}
                    />
                  </div>
                </div>
                <AutoCompleteGoogle
                  inputName="shipperFullAddress"
                  placeholder="Enter address"
                  // className={`form_control truncate ${
                  //   !validForm.consigneeAddress
                  //     ? 'border border-red-500 border-solid'
                  //     : ''
                  // }`}
                  className={`form_control truncate`}
                  label=""
                  labelClassName="mb-1.5 block"
                  onChangeFunc={(address: any) => {
                    setShipperAddress(address);
                  }}
                  isClearable
                  onAddressInputChange={(value: any) => {
                    setShipperAddress((old: any) => ({
                      ...old,
                      fullAddress: value,
                    }));
                  }}
                  errorText={formManualWarnings?.shipperFullAddress ?? null}
                  value={shipperAddress?.fullAddress}
                />
                {/* <InputText
                  inputName="shipperFullAddress"
                  placeholder="Shipper Address"
                  parentClassName=""
                  className=""
                  value={shipperAddress?.fullAddress}
                  disabled={true}
                  labelClassName="block mb-1.5"
                  errorText={formManualWarnings?.shipperFullAddress}
                /> */}
              </div>
              <div>
                <div className="flex gap-x-1 justify-between items-center mb-1.5">
                  <label className="form_label mb-0 flex gap-2">
                    Consignee Address
                  </label>
                  <div className="gap-x-1 text-primary700 text-xs font-semibold flex items-center cursor-pointer">
                    <Edit05
                      className="w-4 h-4"
                      onClick={() => {
                        setAddressData(consigneeAddress);
                        setAction((prev) => ({ ...prev, consignee: true }));
                      }}
                    />
                  </div>
                </div>
                <AutoCompleteGoogle
                  inputName="consigneeFullAddress"
                  placeholder="Enter address"
                  // className={`form_control truncate ${
                  //   !validForm.consigneeAddress
                  //     ? 'border border-red-500 border-solid'
                  //     : ''
                  // }`}
                  className={`form_control truncate`}
                  label=""
                  labelClassName="mb-1.5 block"
                  onChangeFunc={(address: any) => {
                    setConsigneeAddress(address);
                  }}
                  isClearable
                  onAddressInputChange={(value: any) => {
                    setConsigneeAddress((old: any) => ({
                      ...old,
                      fullAddress: value,
                    }));
                  }}
                  errorText={formManualWarnings?.consigneeFullAddress ?? null}
                  value={consigneeAddress?.fullAddress}
                />
                {/* <InputText
                  inputName="consigneeFullAddress"
                  placeholder="Consignee Address"
                  parentClassName=""
                  className=""
                  value={consigneeAddress?.fullAddress}
                  disabled={true}
                  labelClassName="block mb-1.5"
                  errorText={formManualWarnings?.consigneeFullAddress}
                /> */}
              </div>

              {/* <div className="hidden"> */}
              {/* <label className="form_label mb-1.5 block">Dimensions</label>
              <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="w-full ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={headCells}
                      tableDataArr={dimensions}
                      TableRowCmp={ConfirmOrderDimensionRow}
                      params={dimensions}
                      setParams={[{}]}
                    />
                  </div>
                </div>
              </div> */}
              {/* </div> */}
              <AccordionCmp
                title={<h5 className="text-sm">Dimensions</h5>}
                contentClassName="gap-y-4 flex flex-col"
                className="!p-0 !border-0"
                isOpen
              >
                <>
                  <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                    <div className="w-full ">
                      <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                        <TableCmp
                          headCells={headCells}
                          tableDataArr={dimensions}
                          TableRowCmp={ConfirmOrderDimensionRow}
                          params={dimensions}
                          setParams={[{}]}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </AccordionCmp>
              {quotedServices?.length > 0 && (
                <div>
                  <h6 className="text-grayLight900 text-xs font-semibold mb-1.5">
                    Rated Additional Services
                  </h6>
                  <ul className="rounded-lg border border-utilityGray200 p-3 gap-y-2 flex flex-wrap">
                    {quotedServices?.map((service: any, index: any) => (
                      <li
                        key={index}
                        className="flex  text-grayLight600 text-xs font-medium gap-x-1 w-1/3"
                      >
                        <Check className="w-4 h-4 text-primary flex-none" />
                        {service?.serviceId?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {nonQuotedServices?.length > 0 && (
                <div>
                  <h6 className="text-grayLight900 text-xs font-semibold mb-1.5 flex items-center gap-x-1">
                    Non-Rated Additional Services{' '}
                    <TooltipCmp
                      message={
                        'The carrier did not provide a rate for all additional services specified. Please validate the feasibility of the shipment andor any additional costs.'
                      }
                    >
                      <InfoCircle className="text-primary700 w-3 h-3 cursor-pointer" />
                    </TooltipCmp>
                  </h6>
                  <ul className="rounded-lg border border-utilityGray200 p-3 gap-y-2 flex flex-wrap">
                    {nonQuotedServices?.map((service: any, index: any) => (
                      <li
                        className="flex  text-grayLight600 text-xs font-medium gap-x-1 w-1/3"
                        key={index}
                      >
                        <Check className="w-4 h-4 text-primary flex-none" />
                        {service?.serviceId?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {activeStep === 2 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                  Select Roserocket Carrier
                </label>
                <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                  <div className="relative">
                    <InputText
                      label={'Select a carrier'}
                      inputName="carrierSearch"
                      placeholder="Search"
                      labelClassName="block mb-1.5"
                      className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={roserocketCarrierSEarchParams.search}
                      isError={isCarrierError}
                      onChangeFunc={(e: any) => {
                        setRoserocketCarrierSEarchParams((old: any) => ({
                          ...old,
                          search: e?.target?.value,
                        }));
                      }}
                      onFocus={() => {
                        searchCarriersFromRoserocket();
                        setIsCarrierError(false);
                      }}
                      onBlur={() => {
                        setTimeout(() => {}, 200);
                      }}
                    />
                    {roserocketCarriers?.length > 0 && (
                      <div
                        className={` mt-2 w-full max-h-[235px] team-user-select overflow-auto py-3 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 absolute`}
                      >
                        {roserocketCarriers.map(
                          (roserocketCarrier: any, index: any) => (
                            <div
                              key={index}
                              className="w-full rounded-lg cursor-pointer hover:bg-gray50"
                              onClick={() => {
                                setSelectedRoserocketCarrier(roserocketCarrier);
                                setRoserocketCarriers([]);
                              }}
                            >
                              <div className="px-3.5 py-2.5  justify-start  gap-2 flex items-center">
                                <div className="flex-col justify-start items-start flex ">
                                  <div className="text-grayLight900 text-xs font-medium leading-tight">
                                    {roserocketCarrier.name}
                                  </div>
                                  <div className="text-grayLight600 text-xs font-normal leading-tight break-all">
                                    {roserocketCarrier.location}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {!!selectedRoserocketCarrier?.name && (
                    <div className="mt-3 flex-1 overflow-y-auto scrollbar-hide max-h-[60vh]">
                      <div className="w-full py-[9px]  border-t justify-between items-center flex border-utilityGray200 last:border-b">
                        <div className="justify-start items-center gap-3 flex">
                          {/* <div className="w-9 h-9 relative rounded-full flex-none">
                        
                      </div> */}

                          <div className="flex-col justify-start items-start flex">
                            <div className="justify-center items-center gap-3 flex">
                              <div className="text-textSecondary text-xs font-semibold leading-tight">
                                {selectedRoserocketCarrier?.name || ''}
                              </div>
                            </div>
                            <div className="text-grayLight600 text-xs font-normal leading-tight">
                              {selectedRoserocketCarrier?.location || ''}
                            </div>
                          </div>
                        </div>
                        <div className="justify-center items-center gap-1.5 flex">
                          <ButtonCmp
                            className="text-danger700 text-sm font-semibold leading-tight cursor-pointer btn-link no-underline !shadow-none"
                            onClick={() => setSelectedRoserocketCarrier({})}
                            type="button"
                          >
                            Remove
                          </ButtonCmp>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                  Contact Details
                </label>
                <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                  <div className="flex -mx-1 flex-wrap gap-y-2">
                    <Controller
                      name="contactName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <InputText
                          {...field}
                          label="Name"
                          inputName="contactName"
                          placeholder="Enter name"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={errors.contactName?.message ? true : false}
                          // errorText={errors.contactName?.message}
                        />
                      )}
                    />
                    <Controller
                      name="contactEmail"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          value={value}
                          onChangeFunc={async (e: any) => {
                            onChange(e);

                            if (e.target.value) {
                              await trigger('contactEmail');
                              clearErrors('contactEmail');
                            }
                          }}
                          label="Email"
                          inputName="email"
                          placeholder="Enter Email"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={errors.contactEmail?.message ? true : false}
                          // errorText={errors.contactEmail?.message}
                        />
                      )}
                    />
                    <div className="px-1 md:w-1/3 w-full">
                      <label className="form_label block mb-1.5">
                        Phone number
                      </label>
                      <Controller
                        name="contactPhoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            onNumberChange={(country: any, phone: any) => {
                              field.onChange(`${phone}`);
                            }}
                            disabled={false}
                            errors={
                              errors.contactPhoneNumber?.message ? true : false
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                  Shipper Details
                </label>
                <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                  <div className="flex -mx-1 flex-wrap gap-y-2">
                    <Controller
                      name="shipperName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <InputText
                          {...field}
                          label="Name"
                          inputName="name"
                          placeholder="Enter name"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={errors.shipperName?.message ? true : false}
                          // errorText={errors.shipperName?.message}
                        />
                      )}
                    />
                    <Controller
                      name="shipperEmail"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          value={value}
                          onChangeFunc={async (e: any) => {
                            onChange(e);

                            if (e.target.value) {
                              await trigger('shipperEmail');
                              clearErrors('shipperEmail');
                            }
                          }}
                          label="Email"
                          inputName="email"
                          placeholder="Enter Email"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={errors.shipperEmail?.message ? true : false}
                          // errorText={errors.shipperEmail?.message}
                        />
                      )}
                    />
                    <div className="px-1 md:w-1/3 w-full">
                      <label className="form_label block mb-1.5">
                        Phone number
                      </label>
                      <Controller
                        name="shipperPhoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            onNumberChange={(country: any, phone: any) => {
                              field.onChange(`${phone}`);
                            }}
                            disabled={false}
                            errors={
                              errors.shipperPhoneNumber?.message ? true : false
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                  Consignee Details
                </label>
                <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                  <div className="flex -mx-1 flex-wrap gap-y-2">
                    <Controller
                      name="consigneeName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <InputText
                          {...field}
                          label="Name"
                          inputName="consigneeName"
                          placeholder="Enter name"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={errors.consigneeName?.message ? true : false}
                          // errorText={errors.consigneeName?.message}
                        />
                      )}
                    />
                    <Controller
                      name="consigneeEmail"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          value={value}
                          onChangeFunc={async (e: any) => {
                            onChange(e);

                            if (e.target.value) {
                              await trigger('consigneeEmail');
                              clearErrors('consigneeEmail');
                            }
                          }}
                          label="Email"
                          inputName="email"
                          placeholder="Enter Email"
                          parentClassName="px-1 md:w-1/3 w-full"
                          labelClassName="block mb-1.5"
                          isError={
                            errors.consigneeEmail?.message ? true : false
                          }
                          // errorText={errors.consigneeEmail?.message}
                        />
                      )}
                    />
                    <div className="px-1 md:w-1/3 w-full">
                      <label className="form_label block mb-1.5">
                        Phone number
                      </label>
                      <Controller
                        name="consigneePhoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            onNumberChange={(country: any, phone: any) => {
                              field.onChange(`${phone}`);
                            }}
                            disabled={false}
                            errors={
                              errors.consigneePhoneNumber?.message
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div>
              <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                Additional service not included
              </label>
              <ul className="flex flex-wrap rounded-lg border border-utilityGray200 p-3 gap-y-2">
                {carrierRate?.services?.map(
                  (service: any, index: any) =>
                    !service?.isQuoted && (
                      <li
                        className="flex gap-x-1 md:w-1/3 sm:w-1/2 w-full pr-1"
                        key={index}
                      >
                        <Check className="w-4 h-4 text-primary" />
                        <p className="text-grayLight600 text-xs font-medium">
                          {service?.serviceId?.name}
                        </p>
                      </li>
                    )
                )}
              </ul>
            </div> */}
            </form>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <div className="-mt-5 smd:max-w-[500px] max-w-[250px] mx-auto">
                <img
                  src={truckLoader}
                  alt=""
                  width={400}
                  className="rounded-2xl mx-auto"
                />
                <p className=" bg-utilityGray200 h-[1px] smd:-mt-[68px] -mt-[43px]"></p>
              </div>
              <p className="text-textSecondary text-sm font-medium mt-6 mb-2 ">
                Booking order with {carrierRate.name}
              </p>
              <p className="mx-auto text-gray500 text-xs font-normal">
                <span className="text-fgErrorPrimary">*</span> You can close
                this. We&apos;ll notify you once your order was successfully
                booked!
              </p>
              <ButtonCmp
                iconSide="right"
                className="btn_primary min-w-[102px] mt-6 mb-3"
                onClick={() => handleClose(false, 'carrierOrderModel')()}
              >
                Close
              </ButtonCmp>
            </div>
          )}
        </div>
      </CommonModal>

      <Address
        isOpen={action.shipper || action.consignee}
        mode={'addressUpdate'}
        handleClose={() => setAction(initAction)}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />
    </>
  );
};

export default FinalizeOrderModal;
