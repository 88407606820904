import React from 'react';
import {
  convertSecondsToTime,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const Row = ({ data }: any) => {
  console.log('data', data);

  return (
    <>
      <td className={`px-5 py-4  min-w-96 w-96 max-w-96`}>
        <div className="flex items-center truncate">
          <div className="table-profile-wrap notification-mark-wrap">
            {data?.image ? (
              <img
                src={`${data?.imageUrl}${data?.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data.firstName} ${data.lastName}`)}
              </div>
            )}
            {data.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>

          <div className="pl-3 w-[calc(100%_-_58px)]">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.firstName} {data.lastName}
            </h6>
          </div>
        </div>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.totalHits}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.totalRequest}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.totalQuote}</span>
      </td>

      <td className={`px-5 py-4`}>
        <span className="">-</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">
          {data.avgTimeToClassify
            ? convertSecondsToTime(data.avgTimeToClassify)
            : '-'}
        </span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">
          {data.avgTimeToQuote
            ? convertSecondsToTime(data.avgTimeToQuote)
            : '-'}
        </span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.wonLoss}</span>
      </td>
    </>
  );
};

export default Row;
