import React, { useContext, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';
import {
  updateOrCreateCarrierPrice,
  updateSalesPrice,
} from 'src/services/OrderService';

import { OrderContext } from '..';
import CarrierModal from '../../Common/Carrier/CarrierModal';
import SalesModal from '../../Common/Sales/SalesModal';

import CarrierPriceRow from './carrierPriceRow';
import salesPriceRow from './salesPriceRow';

const CarrierAndSalesPrice = () => {
  const {
    currentLegData,
    setCurrentLegData,
    id,
    customerList,
    isCustomerLoading,
    order,
    setOrder,
  } = useContext<any>(OrderContext);

  const [isSaleFormOpen, setIsSaleFormOpen] = useState<boolean>(false);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [salesEditData, setSalesEditData] = useState<any>(null);

  const handleSalesClose = () => {
    setIsSaleFormOpen(false);
  };

  const handleCarrierEdit = (data: any) => {
    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleSalesEdit = (data: any) => {
    setSalesEditData(data);
    setIsSaleFormOpen(true);
  };

  const salesHeadCells = useMemo(
    () => [
      {
        id: 'customer',
        name: 'Customer',
      },
      {
        id: 'baseSalePrice',
        name: 'Base Sale Price',
      },
      {
        id: 'Additionals',
        name: 'Additionals',
      },
      {
        id: 'totalSalePrice',
        name: 'Total Sale Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const carrierHeadCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
      },
      {
        id: 'carrierType',
        name: 'Carrier Type',
      },
      {
        id: 'quoteID',
        name: 'Quote ID',
      },
      {
        id: 'proNumber',
        name: 'PRO Number',
      },
      {
        id: 'totalCost',
        name: 'Total Cost',
      },
      {
        id: 'additionals',
        name: 'Additionals',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    updateOrCreateCarrierPrice({
      ...newCarrierPrice,
      orderLegId: currentLegData?.id,
      masterOrderId: id,
    })
      .then((response) => {
        if (response?.data) {
          let carrierPrice = {};

          if (response?.data && response?.data.length > 0) {
            carrierPrice = response.data.map((data: any) => ({
              ...data,
              additional_charge: data.additional_charge
                ? JSON.parse(data.additional_charge)
                : [],
              carrier: data.carrier ? JSON.parse(data.carrier) : {},
            }));
          }

          setIsCarrierFormOpen(false);

          setCurrentLegData((old: any) => ({
            ...old,
            carrierPrice: carrierPrice,
          }));

          // setCarrierData(carrierPrice);
        }
      })
      .catch(console.error);
  };

  const handleUpdateSalesPrice = (salePrice: any) => {
    updateSalesPrice({
      ...salePrice,
      orderLegId: currentLegData?.id,
    })
      .then((response) => {
        if (response?.data) {
          let salesPrice = {};

          if (response?.data) {
            salesPrice = {
              ...response?.data,
              additional_charge: response?.data.additional_charge,
            };
          }

          setIsSaleFormOpen(false);

          setOrder((old: any) => ({
            ...old,
            salesPrice: [salesPrice],
          }));

          // setCarrierData(carrierPrice);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      {((order?.salesPrice && order?.salesPrice?.length > 0) ||
        (currentLegData?.carrierPrice &&
          currentLegData?.carrierPrice?.length > 0)) && (
        <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
          <h5 className="text-grayLight900 text-xl font-semibold">
            Carrier & Sale Price
          </h5>

          {order?.salesPrice && order?.salesPrice?.length > 0 && (
            <>
              <h6 className="text-textSecondary text-xs font-medium">
                Total Sale Price
              </h6>
              <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={salesHeadCells}
                    tableDataArr={order?.salesPrice}
                    // TableLoaderRowCmp={CarriersListLoading}
                    TableRowCmp={salesPriceRow}
                    tableRowCmpProps={{ handleSalesEdit }}
                    numberOfSkeletonRows={1}
                  />
                </div>
              </div>
            </>
          )}

          {currentLegData?.carrierPrice &&
            currentLegData?.carrierPrice?.length > 0 && (
              <>
                <div className="flex justify-between items-end">
                  <h6 className="text-textSecondary text-xs font-medium text-end">
                    Total Cost
                  </h6>
                </div>
                <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={carrierHeadCells}
                      tableDataArr={currentLegData?.carrierPrice}
                      TableRowCmp={CarrierPriceRow}
                      tableRowCmpProps={{ handleCarrierEdit }}
                      numberOfSkeletonRows={1}
                    />
                  </div>
                </div>
              </>
            )}
        </li>
      )}

      {isSaleFormOpen && (
        <SalesModal
          handleClose={handleSalesClose}
          handleAddSalesPrice={handleUpdateSalesPrice}
          orderSalesPrice={salesEditData}
          masterOrderId={id}
          customerList={customerList}
          isCustomerLoading={isCustomerLoading}
          customerId={salesEditData?.customerId}
        />
      )}

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={() => {
            setIsCarrierFormOpen(false);
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          customerId={order?.cusotmerId}
        />
      )}
    </>
  );
};

export default CarrierAndSalesPrice;
