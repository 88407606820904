import { ArrowLeft, Edit05, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import CropperModal from 'src/components/CropperModal/CropperModal';
import PageSectionLayout from 'src/components/PageSectionLayout';
import TabButton from 'src/components/TabButton';
import { TABLE_IDS } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  CustomerDetailById,
  updateCustomerImage,
} from 'src/services/CustomerService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
  useRolePermission,
  isValidJSON,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import NotFoundUI from '../../components/NotFoundUI';
import { scriptId } from '../QuotingHub/QuotingDashboard/AddressDetails/Address.interface';

import CustomerDetailAllocationHistoryTab from './CustomerDetailAllocationHistoryTab';
import CustomerDetailAnalyticsTab from './CustomerDetailAnalyticsTab';
import CustomerDetailClaimsAndDisputesTab from './CustomerDetailClaimsAndDisputesTab';
import CustomerDetailContactsTab from './CustomerDetailContactTab';
import CustomerDetailCreditTab from './CustomerDetailCreditTab';
import CustomerDetailEditModal from './CustomerDetailEditModal';
import CustomerDetailOverviewTab from './CustomerDetailOverviewTab';
import CustomerDetailRightSideDetailSection from './CustomerDetailRightSideDetailSection';

const CustomerDetail = () => {
  const location = useLocation();
  const { googleKey } = useContext(BasicContext);

  const { hasRoleV2 } = useRolePermission();
  const customerId = window.location.pathname?.split('/').pop();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.CUSTOMER_DETAILS);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : 'overview';
  });
  const [upload, setUpload] = useState(false);
  const [imageError, setImageError] = useState('');
  const [isCustomerDetailsLoading, setIsCustomerDetailsLoading] =
    useState(true);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [customerDetail, setCustomerDetail] = useState<any>(undefined);
  const [displayImage, setDisplayImage] = useState<any>(null);
  const [isShowCustomerEditModal, setIsShowCustomerEditModal] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);

  const tabArray: any = [
    {
      value: 'overview',
      name: 'Overview',
      isAllow: true,
    },
    {
      value: 'analytics',
      name: 'Analytics',
      isAllow: true,
    },
    {
      value: 'claims&disputes',
      name: 'Claims / Disputes',
      isAllow: true,
    },
    {
      value: 'credit',
      name: 'Credit',
      isAllow: true,
    },
    {
      value: 'allocation_history',
      name: 'Allocation History',
      isAllow: hasRoleV2('admin'),
    },
    {
      value: 'contacts',
      name: 'Contacts',
      isAllow: true,
    },
  ];

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const getCustomerById = () => {
    setIsCustomerDetailsLoading(true);
    setCustomerDetail(undefined);
    CustomerDetailById({ customerId: customerId })
      .then((response: any) => {
        response.data.newSegmentArray = response?.data?.new_segment
          ?.split(',')
          ?.map((item: string) => item.trim())
          .filter((e: string) => e !== '');

        if (hasRoleV2('user')) {
          response.data.customerSalesRepHistory = [
            response.data.customerSalesRepHistory?.[0],
          ];
        }
        setCustomerDetail(response.data);
      })
      .finally(() => {
        setIsCustomerDetailsLoading(false);
        setIsRefresh(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (isRefresh) {
      getCustomerById();
    }
  }, [isRefresh]);

  useEffect(() => {
    if (localStorage.getItem(TABLE_IDS.CUSTOMER_DETAILS)) {
      return () => {
        localStorage.removeItem(TABLE_IDS.CUSTOMER_DETAILS);
      };
    }
  }, []);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const chooseFile = (e: any) => {
    setIsImageUploadLoading(true);
    e.preventDefault();
    setImageError('');
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const { result } = checkFileTypeValidation(files, MAX_FILE_SIZE);

    if (result) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        setDisplayImage('');
        setUpload(false);
      };

      reader.onloadend = () => {
        setDisplayImage(reader.result as any);
        setUpload(true);
      };
      reader.readAsDataURL(files[0]);
      e.target.value = null;
    } else {
      setImageError('Image size should be less than 2MB.');
    }
  };

  const imageUpload = (data: any) => {
    if (!customerId) {
      return;
    }
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    const appendFormData: any = new FormData();
    appendFormData.append('image', fileInfo?.convertedFile);
    updateCustomerImage(customerId, appendFormData)
      .then((response) => {
        setCustomerDetail({
          ...customerDetail,
          ...{ image: response?.data.image, imageUrl: response?.data.imageUrl },
        });
        WalToast.success('Customer image updated successfully');
      })
      .finally(() => setIsImageUploadLoading(false))
      .catch(() => WalToast.error('Customer image not updated'));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <>
            {/* <ul className="mb-4 flex flex-wrap items-center gap-x-2 pb-1">
              <li className="p-1 text-borderPrimary">
                <div className="cursor-pointer" onClick={() => navigate('/')}>
                  <Home01 className="w-4 h-4" />
                </div>
              </li>
              <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <div
                  className="px-2 cursor-pointer"
                  onClick={() => navigate('/customers')}
                >
                  Customer
                </div>
              </li>
              <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <div className="px-2 cursor-pointer">Customer Detail</div>
              </li>
            </ul> */}
          </>
        }
        contentClassName="!p-0"
        headerClassName="!p-0"
      >
        <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[102px] before:h-[70px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
          <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
            <div
              className="flex items-center cursor-pointer gap-1.5"
              onClick={() => {
                localStorage.setItem('customer-detail', 'visited');
                navigate('/customers', {
                  state: { from: location.pathname },
                });
              }}
            >
              <ArrowLeft className="h-4 w-4" />
              <span className="text-grayLight600 text-xs font-semibold">
                Back to Customers
              </span>
            </div>
            <div
              className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer transition-all duration-[0.3s] hover:bg-borderPrimary"
              onClick={() => {
                localStorage.setItem('customer-detail', 'visited');

                if (history.length > 3) {
                  navigate(-1);
                } else {
                  navigate('/customers');
                }
              }}
            >
              <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
            </div>
          </div>

          <div className=" xlm:px-8 px-5 relative w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
            <div className="flex flex-col">
              <label
                htmlFor="fileSelect"
                className={`relative [&>div:nth-child(2)]:opacity-0 hover:[&>div:nth-child(2)]:opacity-100 xlm:w-36 xl:w-36 mdm:w-28 sm:w-24 sms:w-24 w-16 xlm:h-36 xl:h-36 mdm:h-28 sm:h-24 sms:h-24 h-16 rounded-xl overflow-hidden justify-center items-center flex border-4 border-white bg-utilityGray100 shadow-lg text-primary xl:text-6xl sm:text-4xl sms:text-2xl text-base font-semibold tracking-[-1.2px] flex-none cursor-pointer ${
                  isCustomerDetailsLoading ? 'custom-loading' : ''
                }`}
              >
                {customerDetail?.image && customerDetail?.imageUrl ? (
                  <img
                    className="w-full h-full relative rounded-xl object-contain"
                    src={`${customerDetail?.imageUrl}${customerDetail?.image}`}
                  />
                ) : (
                  <div>
                    {customerDetail?.name
                      ? getShortName(customerDetail?.name)
                      : ''}
                  </div>
                )}
                {!isImageUploadLoading && (
                  <div className="absolute h-full w-full bg-utilityBlack bg-opacity-60 text-white flex items-center justify-center transition-all duration-[0.3s]">
                    <Edit05 className="mdm:w-6 mdm:h-6 sm:w-5 sm:h-5 w-4 h-4" />
                  </div>
                )}
              </label>

              <input
                type="file"
                name="image"
                id="fileSelect"
                accept="image/*"
                onChange={chooseFile}
                className="hidden"
              />
            </div>
            <div className="flex-1 flex sms:flex-row flex-col justify-between sm:mt-2 gap-2 sms:items-end items-start">
              <div className="">
                <h6
                  className={`mr-3 text-grayLight900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]  ${
                    isCustomerDetailsLoading
                      ? 'custom-loading px-5 mt-1 !inline-block h-7'
                      : ''
                  }`}
                >
                  {customerDetail?.name ?? ''}
                </h6>

                <div
                  className={`flex mt-1 ${
                    isCustomerDetailsLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600 sm:text-sm text-xs sm:mt-0 mt-0.5 font-normal">{`${customerDetail?.address1}, ${customerDetail?.city}, ${customerDetail?.country}`}</span>
                </div>
              </div>
              <ButtonCmp
                type="button"
                className="btn-outline-primary shadow py-2 px-3"
                onClick={() => setIsShowCustomerEditModal(true)}
                disabled={isCustomerDetailsLoading}
              >
                Edit Customer
              </ButtonCmp>
            </div>
          </div>
          <div className=" xlm:px-8 px-5 relative w-full pb-[26px] flex sms:gap-4 gap-2 xs:items-end items-start">
            <span className="text-danger text-xs">{imageError}</span>
          </div>
          <div className="border-t border-utilityGray200 xlm:px-8 px-5 py-[30px] bg-white flex flex-wrap flex-1">
            <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_312px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
              <TabButton
                parentClassName="!bg-gray50 w-full overflow-x-auto scrollbar-hide text-nowrap"
                className="rounded-md text-gray500 !border-0 w-1/4"
                tabParentClassName="!block mb-8"
                activeClassName="!bg-primary400 !text-blue"
                tabArray={tabArray.filter((e: any) => e.isAllow)}
                isActive={activeTab}
                isTab={true}
                handleOnClick={(e: any) => {
                  console.log('selected', e.target.dataset.value);
                  setActiveTab(e.target.dataset.value);
                }}
              />

              {activeTab === 'overview' && (
                <CustomerDetailOverviewTab
                  customerDetail={customerDetail}
                  customerId={customerId}
                />
              )}

              {activeTab === 'analytics' && (
                <>
                  {window.MODE === 'production' ? (
                    <NotFoundUI
                      title="Coming Soon"
                      desc="Analytics features are in progress – soon you'll be able to explore key metrics and trends to guide your strategies."
                      containerClassName="my-0 flex-1"
                      contentClassName="before:bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-[280px]"
                      iconClassName="!hidden"
                      iconSecondaryClassName="!flex"
                    />
                  ) : (
                    <CustomerDetailAnalyticsTab
                      customerId={customerId as string}
                    />
                  )}
                </>
              )}

              {activeTab === 'claims&disputes' && (
                <CustomerDetailClaimsAndDisputesTab
                  customerId={customerId as string}
                  customerName={customerDetail?.name}
                />
              )}

              {activeTab === 'credit' && (
                <CustomerDetailCreditTab customerId={customerId as string} />
              )}

              {activeTab === 'allocation_history' && (
                <CustomerDetailAllocationHistoryTab
                  customerId={customerId as string}
                />
              )}

              {activeTab === 'contacts' && (
                <CustomerDetailContactsTab customerId={customerId as string} />
              )}
            </div>

            <CustomerDetailRightSideDetailSection
              customerDetail={customerDetail}
              isCustomerDetailsLoading={isCustomerDetailsLoading}
            />
          </div>
        </div>
      </PageSectionLayout>

      {upload && (
        <CropperModal
          modalTitle="Edit Customer Profile Image"
          modalDesc="Edit customer profile image as per your requirement"
          imageUpload={imageUpload}
          defaultImage={customerDetail?.imageUrl + customerDetail?.image || ''}
          imageUrl={displayImage}
          setUpload={setUpload}
          setCropData={setDisplayImage}
          isCropType={false}
          defaultCropType="Square"
          primaryBtnLoading={false}
        />
      )}

      {isShowCustomerEditModal && (
        <CustomerDetailEditModal
          customerDetail={customerDetail}
          handleClose={(isReloadData: boolean) => {
            if (isReloadData) {
              setIsRefresh(true);
            }
            setIsShowCustomerEditModal(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerDetail;
