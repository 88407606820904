import { Copy06, LinkExternal02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ClaimResolve from 'src/app/Claims&Disputes/Claims/ClaimResolve';
import DisputeResolve from 'src/app/Claims&Disputes/Disputes/DisputeResolve';
import { getShortName } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';
import defaultimage from '../../../../assets/img/default-image.jpg';
import DraftMailModal from '../../Common/DraftMailModal';
import UpdateLocation from '../../Common/UpdateLocation';
import UploadOrderPodModal from '../../Common/UploadOrderPodModal';
import { appointmentDetails } from '../../order.constant';
import { initAction } from '../../order.interface';
import AppointmentModal from '../../OrderDetails/AppointmentDetails/AppointmentModal';
import AppointmentSuccessModal from '../../OrderDetails/AppointmentDetails/AppointmentSuccessModal';
import ViewOrderPodModal from '../ViewOrderPodModal';

import ActionButtons from './ActionButtons';
import AssignCarrier from './AssignCarrier';
import ProgressList from './ProgressList';
import ReportInterruptionModal from './ReportInterruptionModal';

const OrderStatus = () => {
  const {
    currentLegData,
    isLoading,
    setCurrentLegData,
    setOrder,
    setTabValues,
    activeLeg,
    carrierData,
    setCarrierData,
    id,
    order,
  } = useContext<any>(OrderContext);

  const [action, setAction] = useState<any>(initAction);
  const [isCarrierAssigned, setIsCarrierAssigned] = useState<boolean>(false);
  const [isOrderInReview, setIsOrderInReview] = useState<boolean>(false);
  const [isInvoiced, setIsInvoiced] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  const [claim, setClaim] = useState(null);
  const [dispute, setDispute] = useState(null);
  const [isProgressLoading, setIsProgressLoading] = useState(true);

  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });
  const [documents, setDocuments] = useState<any>([]);

  const [orderNextActionTitle, setOrderNextActionTitle] = useState(
    'Order Created - Assign a Carrier'
  );

  const [orderNextActionDescription, setOrderNextActionDescription] = useState(
    'Please assign to assign a carrier to move this to dispatched.'
  );

  useEffect(() => {
    const checkCarrierAssigned = currentLegData?.carrierPrice?.length > 0;
    setIsCarrierAssigned(checkCarrierAssigned);
    setCarrierData(currentLegData?.carrierPrice);

    if (currentLegData?.orderStatus === 'booked') {
      if (!checkCarrierAssigned) {
        setOrderNextActionTitle('Order Created - Assign a Carrier');
        setOrderNextActionDescription(
          'Please assign a carrier to move this to dispatched.'
        );
      }

      if (checkCarrierAssigned) {
        setOrderNextActionTitle(
          'Order Dispatched. Carrier & Shipper Appointments Required.'
        );
        setOrderNextActionDescription(
          'Ensure both carrier and shipper appointments are set.'
        );
      }

      if (currentLegData?.shipperAppointmentDate) {
        setOrderNextActionTitle(
          `Pickup Appointment Scheduled Today at ${moment(
            currentLegData?.shipperAppointmentTime,
            'HH:mm:ss'
          ).format('hh:mm A')}`
        );
        setOrderNextActionDescription(
          'Pick the exact time between carrier and shipper'
        );
      } else {
        setOrderNextActionTitle(
          'Order Dispatched. Carrier & Shipper Appointments Required.'
        );
        setOrderNextActionDescription(
          'Ensure both carrier and shipper appointments are set.'
        );
      }
    }

    if (currentLegData?.orderStatus === 'dispatched') {
      setOrderNextActionTitle('Shipment In Transit');
      setOrderNextActionDescription(
        'Last updated on 12:45 PM at Quebec QC, Canada.'
      );
    }

    if (currentLegData?.orderStatus === 'in-transit') {
      if (currentLegData?.documents) {
        setDocuments(currentLegData?.documents);

        if (
          currentLegData?.documents &&
          currentLegData?.documents?.filter(
            (doc: any) => doc.documentType === 'POD'
          )?.length > 0
        )
          setOrderNextActionTitle(
            'Shipment Delivered. Please Request And Review POD'
          );
        setOrderNextActionDescription(
          'Waiting for the carrier to provide POD and invoice'
        );
      } else {
        setOrderNextActionTitle(
          'Shipment Delivered. Please Request and Upload POD'
        );
        setOrderNextActionDescription('Confirm load with the carrier');
      }
    }

    if (
      currentLegData?.orderStatus === 'delivered' &&
      (currentLegData?.claimId || currentLegData?.disputeId) &&
      currentLegData?.claim?.status !== 'Resolved' &&
      currentLegData?.dispute?.status !== 'Resolved'
    ) {
      setIsOrderInReview(true);
      setOrderNextActionTitle('Order On Hold. Claim in Progress');
      setOrderNextActionDescription('Pending resolution of the claim');
    } else {
      setIsOrderInReview(false);
    }

    if (currentLegData?.claim) {
      setClaim(currentLegData?.claim);
    }

    if (currentLegData?.dispute) {
      setDispute(currentLegData?.dispute);
    }

    if (
      currentLegData?.orderStatus === 'delivered' &&
      currentLegData?.claim?.status === 'Resolved' &&
      currentLegData?.dispute?.status === 'Resolved'
    ) {
      setIsOrderInReview(false);
      setIsInvoiced(true);

      setOrderNextActionTitle('Customer Invoiced, Awaiting Payment');
      setOrderNextActionDescription('Payment will be processed via QuickBooks');
    }

    if (
      currentLegData?.orderStatus === 'delivered' &&
      !currentLegData?.claimId &&
      !currentLegData?.disputeId
    ) {
      setIsOrderInReview(false);
      setIsInvoiced(true);

      setOrderNextActionTitle('Customer Invoiced, Awaiting Payment');
      setOrderNextActionDescription('Payment will be processed via QuickBooks');
    } else {
      if (isInvoiced) {
        setIsInvoiced(false);
      }
    }

    if (currentLegData?.orderStatus === 'completed') {
      setIsOrderCompleted(true);
      setOrderNextActionTitle('Payment Received, Order Completed');
      setOrderNextActionDescription('All processes finalized');
    } else {
      if (isOrderCompleted) {
        setIsOrderCompleted(false);
      }
    }
  }, [currentLegData]);

  useEffect(() => {
    if (carrierData && carrierData?.length > 0) {
      setIsCarrierAssigned(true);
    }
  }, [carrierData]);

  const carrierDetail = useMemo(() => {
    if (carrierData?.length > 0) {
      return (
        <>
          {carrierData[0]?.carrier?.image ? (
            <img
              src={defaultimage}
              className="w-8 h-8 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
            />
          ) : (
            <div className="w-8 h-8 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
              {getShortName(carrierData[0]?.carrier?.name)}
            </div>
          )}
          <div>
            <h6 className="text-grayLight900 text-xs font-medium">
              {carrierData[0]?.carrier?.name}
            </h6>
            <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
              #6526581
              <Copy06 className="w-3 h-3 text-primary" />
              <LinkExternal02 className="w-3 h-3 text-primary" />
            </p>
          </div>
        </>
      );
    }

    return null;
  }, [carrierData]);

  const handleEditAppointMent = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));
  };

  return (
    <>
      <div className="rounded-xl border border-utilityGray200 p-4 flex flex-col gap-y-4 custom-class">
        <div className="flex flex-wrap">
          <div className=" flex items-center gap-3 flex-1">{carrierDetail}</div>
          <div className="flex gap-x-2">
            {!isOrderCompleted && (
              <ActionButtons
                currentLegData={currentLegData}
                isCarrierAssigned={isCarrierAssigned}
                setAction={setAction}
                handleEditAppointMent={handleEditAppointMent}
                documents={documents}
                isOrderInReview={isOrderInReview}
                dispute={dispute}
                claim={claim}
                isInvoiced={isInvoiced}
                isProgressLoading={isProgressLoading}
              />
            )}
          </div>
        </div>
        <div>
          {/* WHEN STATUS IS BOOKED */}
          <h6
            className={`text-grayLight900 text-xl font-semibold ${
              isLoading || isProgressLoading ? 'custom-loading' : ''
            }`}
          >
            {orderNextActionTitle}
          </h6>
          <p
            className={`text-grayLight900 text-sm font-normal ${
              isLoading || isProgressLoading ? 'custom-loading' : ''
            }`}
          >
            {orderNextActionDescription}
          </p>
        </div>
        <div className="flex items-start flex-wrap gap-2 mt-4">
          <ProgressList
            isLoading={isLoading}
            currentLegData={currentLegData}
            setCurrentLegData={setCurrentLegData}
            setOrder={setOrder}
            isProgressLoading={isProgressLoading}
            setIsProgressLoading={setIsProgressLoading}
            isOrderCompleted={isOrderCompleted}
          />
        </div>
      </div>

      {action.mode === 'update-location' && (
        <UpdateLocation
          action={action}
          setAction={setAction}
          addressData={currentLegData?.consignee?.consigneeAddress}
          orderLegId={currentLegData?.id}
        />
      )}

      {action.mode === 'report-interruption' && (
        <ReportInterruptionModal
          handleClose={() => {
            setAction((old: any) => ({ ...old, mode: null }));
          }}
          handleOnSubmit={(data: any) => {
            setAction((old: any) => ({ ...old, mode: null }));
            setCurrentLegData((old: any) => ({
              ...old,
              claimId: data?.claim?.id,
              claim: data?.claim,
              disputeId: data?.dispute?.id,
              dispute: data?.dispute,
            }));
          }}
          orderLegId={currentLegData?.id}
        />
      )}

      {action.mode === 'upload-pod' && (
        <UploadOrderPodModal
          handleClose={() => setAction(initAction)}
          orderLegId={currentLegData?.id}
          title={'Upload POD'}
          viewPDFTitle={'View POD'}
          fileStorePath={'POD_document'}
          documentType={'POD'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          handleOnSubmit={(documentData: any) => {
            if (documentData) {
              setDocuments((prevDocs: any[]) => [
                ...prevDocs,
                { ...documentData },
              ]);
            }
            setAction(initAction);
          }}
          isShowTitle={true}
        />
      )}

      {action.mode === 'review-pod' && (
        <ViewOrderPodModal
          handleClose={() => setAction(initAction)}
          title={'Review POD'}
          titleDesc={'Verify and Approve Proof of Delivery'}
          isShowButtons={true}
          contentTitle={'Review PODs'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          orderLegId={currentLegData?.id}
          setDocuments={setDocuments}
        />
      )}

      {action.mode === 'view-pod' && (
        <ViewOrderPodModal
          handleClose={() => setAction(initAction)}
          title={'View POD'}
          titleDesc={'Verify and Approve Proof of Delivery'}
          isShowButtons={false}
          contentTitle={'View PODs'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          orderLegId={currentLegData?.id}
        />
      )}

      {action.mode === 'upload-customs-documents' && (
        <UploadOrderPodModal
          handleClose={() => setAction(initAction)}
          orderLegId={currentLegData?.id}
          title={'Upload Customs Document'}
          viewPDFTitle={'View Document'}
          fileStorePath={'customs_document'}
          documentType={'customs_document'}
          documents={
            documents?.length > 0 &&
            documents?.filter(
              (doc: any) => doc.documentType === 'customs_document'
            )
          }
          handleOnSubmit={(documentData: any) => {
            if (documentData) {
              setDocuments((prevDocs: any[]) => [
                ...prevDocs,
                { ...documentData },
              ]);
            }
            setAction(initAction);
          }}
          isShowTitle={true}
        />
      )}

      {action.mode === 'assign-carrier' && (
        <AssignCarrier
          currentLegData={currentLegData}
          action={action}
          setAction={setAction}
          setCarrierData={setCarrierData}
          masterOrderId={id}
          order={order}
        />
      )}

      {action.mode && appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={currentLegData?.id}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let date = '';
            let type = action.mode;

            if (type === 'carrier-pickup-appointment') {
              date = 'carrierPickupAppointmentDatetime';
            } else if (type === 'carrier-delivery-appointment') {
              date = 'carrierDeliveryAppointmentDatetime';
            } else if (type === 'shipper-appointment') {
              date = 'shipperAppointmentDatetime';
            } else if (type === 'delivery-appointment') {
              date = 'deliveryAppointmentDatetime';
            }

            const updatedLegData = {
              ...currentLegData,
              [`${date}`]: formattedData.combinedDatetime,
            };

            if (setCurrentLegData) {
              setCurrentLegData(updatedLegData);
            }

            if (setTabValues) {
              setTabValues((prevTabValues: any) => ({
                ...prevTabValues,
                [activeLeg]: {
                  ...prevTabValues[activeLeg],
                  [`${type}Datetime`]: formattedData.combinedDatetime,
                },
              }));
            }

            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
              type: action.mode,
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={`Set Appointment`}
          DraftDetails={{
            subjectLine: appointmentDetails[action.type].draftTitle,
            toEmailAddress:
              appointmentDetails[action.type].sendMailTo === 'shipper'
                ? currentLegData?.shipper?.email
                : appointmentDetails[action.type].sendMailTo === 'consignee'
                ? currentLegData?.consignee?.email
                : '',
            emailDetails: `I hope this message finds you well. We would like to arrange a carrier appointment for pick-up of your order: #${currentLegData?.legId}. Please let us know when you are available.`,
          }}
        />
      )}

      {action.mode === 'send-pick-up-reminder' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`${
            currentLegData?.customerName
              ? `Send a Pickup Reminder to ${currentLegData?.customerName}`
              : 'Send a Pickup Reminder'
          }`}
          DraftDetails={{
            subjectLine: 'RE: Send Pickup Reminder',
            toEmailAddress: `${currentLegData?.customerEmail}`,
            emailDetails: `This is a friendly reminder regarding the upcoming pickup scheduled with XPO Logistics Freight, Inc. for your order: #${currentLegData?.legId}.`,
          }}
        />
      )}

      {action.mode === 'request-pod' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`${
            carrierData && carrierData?.length > 0
              ? `Send To ${carrierData[0]?.carrier?.name}`
              : 'Send a POD request'
          }`}
          DraftDetails={{
            subjectLine: 'RE: Request POD',
            toEmailAddress: `${carrierData[0]?.carrier?.email}`,
            emailDetails: `I hope this message finds you well.

Kindly upload or attach the Proof of Delivery (POD) for the shipment associated for order: #${currentLegData?.legId}.`,
          }}
        />
      )}

      {action.mode === 'invoice-customer' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`Send Invoice To Customer`}
          DraftDetails={{
            subjectLine: 'RE: Invoice Customer',
            toEmailAddress: `${currentLegData?.customerEmail}`,
            emailDetails: `Please find attached the Proof of Delivery (POD) and the corresponding customer invoice for your order: #${currentLegData?.legId} delivered on November 4th 2024.`,
          }}
        />
      )}

      {action.mode === 'resolve-claim' && (
        <ClaimResolve
          handleClose={() => setAction(initAction)}
          claim={currentLegData?.claim}
          isClaimListPage={false}
          handleOnSubmit={(claimData: any) => {
            setCurrentLegData((curr: any) => ({ ...curr, claim: claimData }));
          }}
        />
      )}

      {action.mode === 'resolve-dispute' && (
        <DisputeResolve
          handleClose={() => setAction(initAction)}
          dispute={currentLegData?.dispute}
          isDisputeListPage={false}
          handleOnSubmit={(disputeData: any) => {
            setCurrentLegData((curr: any) => ({
              ...curr,
              dispute: disputeData,
            }));
          }}
        />
      )}
    </>
  );
};

export default OrderStatus;
