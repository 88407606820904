import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const createCreditIncreaseRequest = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CREDIT_INCREASE_REQUEST.CREATE}`,
    data
  );

  return response;
};

export const declineCreditIncreaseRequest = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CREDIT_INCREASE_REQUEST.DECLINE}`,
    data
  );

  return response;
};

export const approveCreditIncreaseRequest = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CREDIT_INCREASE_REQUEST.APPROVE}`,
    data
  );

  return response;
};

export const customerCreditIncreaseRequestList = async (
  params: any,
  signal: any
) => {
  const response = await axiosInterceptor.get(
    API.CREDIT_INCREASE_REQUEST.LIST,
    {
      params,
      signal,
    }
  );

  return response;
};

export const customerCreditIncreaseRequestListCount = async (
  params: any,
  signal: any
) => {
  const response = await axiosInterceptor.get(
    API.CREDIT_INCREASE_REQUEST.LIST_COUNT,
    { params, signal }
  );

  return response;
};
