import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { getShortName } from 'src/utils/CommonFunctions';

const carrierRow = ({ data, handleCarrierEdit }: any) => {
  let carrier = data?.carrier;

  const additionalChargesTotal = Array.isArray(data?.additional_charge)
    ? data?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  return (
    <>
      <td className="px-5 py-4 w-[15%] max-w-[1px] min-w-[15%]">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            {carrier?.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={`${carrier?.imageUrl}${carrier?.image}`}
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(carrier?.name)}
              </div>
            )}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {carrier?.name}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #WQ085020145
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>Common</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <Link
          to={'#'}
          className={`text-primary font-medium  ${
            data?.code ? 'underline' : ''
          }`}
        >
          {data?.code ? `#${data?.code}` : '-'}
        </Link>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <Link to={'#'} className="text-primary font-medium underline">
          #{data?.proNumber}
        </Link>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${data?.baseCarrierRate}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${additionalChargesTotal}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${data?.totalPrice}</span>
      </td>
      <td className="px-5 py-4 w-[56px] min-w-[56px] max-w-[56px]">
        <Edit01
          className="w-4 h-4 cursor-pointer"
          onClick={() => {
            handleCarrierEdit(data);
          }}
        />
      </td>
    </>
  );
};

export default carrierRow;
