import React, { useState, useEffect } from 'react';

interface DialPadProps {
  onCall: (number: string) => void;
  onHangup: () => void;
  isCallInProgress: boolean;
  setPhoneNumber: any;
  phoneNumber: any;
  countryCode: any;
  setCountryCode: any;
}

const DialPad = ({
  onCall,
  onHangup,
  isCallInProgress,
  setPhoneNumber,
  phoneNumber,
  countryCode,
  setCountryCode,
}: DialPadProps) => {
  const [countryName, setCountryName] = useState<string>('India');

  const countryCodes = [
    { code: '+91', flag: '🇮🇳', name: 'India' },
    { code: '+1', flag: '🇺🇸', name: 'USA' },
    { code: '+1', flag: '🇨🇦️', name: 'Canada' },
  ];

  const handleButtonClick = (value: string) => {
    setPhoneNumber((prev: any) => prev + value);
  };

  const handleClear = () => {
    setPhoneNumber('');
  };

  const handleDelete = () => {
    setPhoneNumber((prev: any) => prev.slice(0, -1));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Allow only digits, '*', and '#' to be typed
    const value = e.target.value.replace(countryCode, '');
    const validChars = /^[0-9*#]*$/;

    if (validChars.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryName(e.target.value);
    const country = countryCodes.find((c) => c.name === e.target.value);
    setCountryCode(country ? country.code : '');
    setPhoneNumber('');
  };

  // Add keyboard event listener for number input
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const validKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '*',
        '#',
        'Backspace',
        'Delete',
      ];

      if (validKeys.includes(e.key)) {
        e.preventDefault();

        if (e.key === 'Backspace' || e.key === 'Delete') {
          handleDelete();
        } else {
          handleButtonClick(e.key);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [phoneNumber]);

  return (
    <div className="p-4 border rounded w-80 mx-auto">
      <h1 className="text-xl font-bold mb-4">Enter a Number</h1>

      {/* Country code dropdown with flags */}
      <div className="flex items-center mb-4 border px-4 py-2 rounded">
        <select
          value={countryName}
          onChange={handleCountryChange}
          className="flex items-center text-lg border-none bg-transparent mr-2"
        >
          {countryCodes.map((item) => (
            <option key={item.name} value={item.name}>
              {item.flag}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={countryCode + phoneNumber}
          onChange={handleInputChange}
          placeholder="Enter number"
          className="w-full outline-none text-lg"
          autoFocus
        />
        <button onClick={handleClear} className="text-red-500 ml-2">
          ✖
        </button>
      </div>

      {/* Dial pad */}
      <div className="grid grid-cols-3 gap-2 mb-4">
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map(
          (key) => (
            <button
              key={key}
              onClick={() => handleButtonClick(key)}
              className="bg-gray-100 hover:bg-gray-200 text-lg font-bold py-2 rounded shadow"
            >
              {key}
            </button>
          )
        )}
      </div>

      {/* Action buttons */}
      <div className="flex justify-center">
        <button
          onClick={handleDelete}
          className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-l"
        >
          Delete
        </button>
        {!isCallInProgress ? (
          <button
            onClick={() => onCall(countryCode + phoneNumber)}
            disabled={!phoneNumber}
            className="bg-emerald-300 hover:bg-emerald-400 px-6 py-2 rounded-r disabled:bg-gray-300 hover:cursor-pointer"
          >
            📞 Call
          </button>
        ) : (
          <button
            onClick={onHangup}
            disabled={!phoneNumber}
            className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-r disabled:bg-gray-300"
          >
            🔴️ End
          </button>
        )}
      </div>
    </div>
  );
};

export default DialPad;
