import {
  ArrowNarrowLeft,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Copy01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import TrackTraceAiCmp from 'src/components/TrackTraceAiCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../assets/img/default-image.jpg';
import blueDot from '../../assets/img/radar/blue-gradient-dot.svg';
import checkBoxGreen from '../../assets/img/radar/checkbox-green.svg';
import clock from '../../assets/img/radar/clock.svg';
import cross from '../../assets/img/radar/cross-red.svg';
import magnifier from '../../assets/img/radar/magnifying-glass.svg';
import truck from '../../assets/img/radar/truck.svg';
import { scriptId } from '../QuotingHub/QuotingDashboard/AddressDetails/Address.interface';

import UpdateLocation from './UpdateLocation';

const tabArr = [
  {
    value: 'dimensions',
    name: 'Dimensions',
  },
  {
    value: 'additional_services',
    name: 'Additional Services',
  },
  {
    value: 'appointments',
    name: 'Appointments',
  },
  {
    value: 'pricing',
    name: 'Pricing',
  },
];

const OrderDetail = ({ setOrderId }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('dimensions');
  const [isDisplayLocation, setIsDisplayLocation] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);

  useEffect(() => {
    getMapService().then((response: any) => {
      setIsLoading(false);

      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  });

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  return (
    <>
      <div>
        <p
          className="flex items-center gap-x-0.5 text-grayLight900 text-xs font-medium mb-2.5 cursor-pointer"
          onClick={() => setOrderId(null)}
        >
          <ArrowNarrowLeft className="w-4 h-4" />
          Back
        </p>

        <div className="flex gap-x-2 justify-between items-start">
          <a
            href={`#`}
            className={`text-primary text-xl font-medium flex-none `}
            target="_blank"
          >
            #WAL-NSBB-34
          </a>
          <BadgeCmp style="modern" type="success">
            Dispatched
          </BadgeCmp>
        </div>
        <div className="flex gap-x-2 justify-between items-start">
          <p className="text-xs text-gray400 font-normal">
            Tracking ID :&nbsp;
            <span className="text-grayLight600 font-medium mr-2">
              hdfgdjffk452A
            </span>
            <Copy01
              className="w-4 h-4 text-senary300 cursor-pointer inline"
              onClick={() => copyDebounce('hdfgdjffk452A')}
            />
          </p>
          <StatusDotProfileCmp
            label="Last Update: 7 hours ago"
            dotColor="green"
          />
        </div>
      </div>
      <div className=" flex flex-col gap-y-5 flex-1 overflow-y-auto scrollbar-hide">
        <ul className="rounded-xl border border-utilityGray200 p-4">
          <li className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden ">
            <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
              <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
            </div>
            <div className="flex-1 text-xs text-grayLight600 font-normal">
              <h6 className="text-gray400 text-xs font-normal">Koray Okumus</h6>
              <p className="text-textSecondary text-xs font-medium">
                Montreal Eaton Centre, West, Montreal, QC, Canada
              </p>
            </div>
          </li>
          <li className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden">
            <div>
              <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
            </div>
            <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
              <div className="flex-1 text-xs text-grayLight600 font-normal">
                <h6 className="text-gray400 text-xs font-normal">
                  Olivia Rhye
                </h6>
                <p className="text-textSecondary text-xs font-medium">
                  8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className="rounded-xl border border-utilityGray200 p-3 ">
          <ul className="-mx-2 -my-3 flex flex-wrap">
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Account Manager
              </h6>
              <div className="flex items-center gap-x-1 mt-1">
                <img
                  src={defaultImage}
                  className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
                {/* IF NO IMAGE FOUND THAN BELOW COMMENTED CODE WILL BE USED */}
                {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                  WA
                </div> */}
                <p className="text-grayLight900 text-xs font-medium">
                  Kate Morrison
                </p>
              </div>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Customer
              </h6>
              <div className="flex items-center gap-x-1 mt-1">
                <img
                  src={defaultImage}
                  className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
                {/* IF NO IMAGE FOUND THAN BELOW COMMENTED CODE WILL BE USED */}
                {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
              WA
            </div> */}
                <p className="text-grayLight900 text-xs font-medium">
                  Koray Okumus
                </p>
              </div>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Order Created
              </h6>

              <p className="text-grayLight900 text-xs font-medium mt-1">
                6th November 2024
              </p>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Total Price
              </h6>

              <p className="text-grayLight900 text-xs font-medium mt-1">$562</p>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">Margin</h6>

              <p className="text-grayLight900 text-xs font-medium mt-1">
                $54.34{' '}
                <span className="text-gray500 font-normal text-[11px]">
                  (20.45%)
                </span>
              </p>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Service Type
              </h6>

              <BadgeCmp type="success" style="modern">
                LTL
              </BadgeCmp>
            </li>
            <li className="w-full px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">Tags</h6>
              <div className="mt-1.5 flex flex-wrap gap-2">
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={truck} className="mr-1" />
                  Missing Carrier
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={blueDot} className="mr-1" />
                  LTL
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={magnifier} className="mr-1" />
                  After Hours Traced
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={cross} className="mr-1" />
                  Do Not Track
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={checkBoxGreen} className="mr-1" />
                  Delivered
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={clock} className="mr-1" />
                  Lift gate Required
                </BadgeCmp>
              </div>
            </li>
          </ul>
        </div>
        <AccordionCmp
          title={
            <div className="w-full">
              <h6 className="text-grayLight900 text-xs font-medium">
                Order Details
              </h6>
              <ul className="rounded-[10px] border border-utilityGray200 bg-gray25 p-4 flex gap-x-2 mt-3">
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    Order Type
                  </h6>
                  <BadgeCmp type="success" style="modern" mainClassName="w-fit">
                    LTL
                  </BadgeCmp>
                </li>
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    PO number
                  </h6>
                  <p className="text-textSecondary text-sm font-medium">
                    58923354
                  </p>
                </li>
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    Reference Number
                  </h6>
                  <p className="text-textSecondary text-sm font-medium">
                    156GJI584HJBHD
                  </p>
                </li>
              </ul>
            </div>
          }
          className="!p-0 overflow-hidden flex-none"
          accordionHeaderWrap="p-4 relative"
          AccordionIconClass="absolute top-4 right-4"
          contentParentClass="bg-gray25 !mt-0"
          contentClassName="!bg-gray25 px-4 pb-4"
        >
          <AccordionCmp
            title={
              <div className="w-full">
                <div className="flex justify-between pr-6">
                  <div className="flex items-center gap-2">
                    <h6 className="text-grayLight900 text-base font-medium">
                      #WAL-NSBB-34-A
                    </h6>
                    <BadgeCmp
                      style="modern"
                      type="success"
                      mainClassName={` ${isLoading ? 'custom-loading' : ''}`}
                    >
                      Completed
                    </BadgeCmp>
                  </div>
                  <ul className="flex gap-x-3">
                    <li className="w-8 h-8 flex justify-center items-center rounded-full border border-borderPrimary bg-white">
                      <ChevronLeft className="w-4 h-4" />
                    </li>
                    <li className="w-8 h-8 flex justify-center items-center rounded-full border border-borderPrimary bg-white">
                      <ChevronRight className="w-4 h-4" />
                    </li>
                  </ul>
                </div>
                <ul className="rounded-[10px] border border-utilityGray200 p-4 flex gap-x-2 mt-3 bg-white">
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Carrier
                    </h6>
                    <div className="flex items-center gap-x-1">
                      <img
                        src={defaultImage}
                        className="w-4 h-4 rounded-sm border border-utilityBlack border-opacity-[0.08] flex-none"
                      />
                      {/* IF NO IMAGE FOUND THAN BELOW COMMENTED CODE WILL BE USED */}
                      {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                        WA
                      </div> */}
                      <p className="text-grayLight900 text-xs font-medium truncate">
                        XPO Logistics Freight, Inc.
                      </p>
                    </div>
                  </li>
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Shipper
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                      Koray Okumus
                    </p>
                  </li>
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Consignee
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                      Olivia Rhye
                    </p>
                  </li>
                </ul>
              </div>
            }
            className="!p-0 overflow-hidden flex-none border-0 !bg-transparent rounded-none"
            accordionHeaderWrap="relative"
            AccordionIconClass="absolute top-2 right-0"
            contentParentClass="bg-gray25 !mt-0 !border-t-0"
            contentClassName="!bg-gray25"
          >
            <div className="rounded-lg border border-utilityGray200 bg-white p-4">
              <TabButton
                parentClassName="tab-border-bottom-parent w-full gap-x-4 mb-5"
                activeClassName="tab-border-bottom-active"
                className="tab-border-bottom !px-0"
                tabBadgeClassName="tab-border-bottom-badge"
                tabArray={tabArr}
                handleOnClick={(e: any) => {
                  setActiveTab(e?.target?.dataset?.value);
                }}
                isActive={activeTab}
                isTabBorderBottom={true}
              />
              {activeTab === 'dimensions' && (
                <>
                  <AccordionCmp
                    title="1 Pallet"
                    accordionHeaderWrap="text-xs"
                    className="border-t-0 border-x-0 rounded-none last:border-b-0 !px-0"
                    contentClassName="rounded-lg !bg-gray25 p-3 "
                    contentParentClass="!pt-0 !border-t-0"
                  >
                    <ul className="flex flex-wrap gap-y-5 -mx-1">
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Handling unit
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          Pallet
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Dimensions (Inches)
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          90” X 50” X 40”
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Class
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          400
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Total weight
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          20 (lbs)
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          REF Number
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          55KDLF351
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Unit Weight
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          50 (lbs)
                        </p>
                      </li>
                    </ul>
                  </AccordionCmp>
                  <AccordionCmp
                    title="2 Pallets"
                    accordionHeaderWrap="text-xs"
                    className="border-t-0 border-x-0 rounded-none last:border-b-0 !px-0"
                    contentClassName="rounded-lg !bg-gray25 p-3 "
                    contentParentClass="!pt-0 !border-t-0"
                  >
                    <ul className="flex flex-wrap gap-y-5 -mx-1">
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Handling unit
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          Pallet
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Dimensions (Inches)
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          90” X 50” X 40”
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Class
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          400
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Total weight
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          20 (lbs)
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          REF Number
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          55KDLF351
                        </p>
                      </li>
                      <li className="flex flex-col gap-y-1 px-1 w-1/3">
                        <h6 className="text-grayLight600 text-xs font-normal">
                          Unit Weight
                        </h6>
                        <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                          50 (lbs)
                        </p>
                      </li>
                    </ul>
                  </AccordionCmp>
                </>
              )}
            </div>
          </AccordionCmp>
        </AccordionCmp>

        <AccordionCmp
          title={
            <div className="flex items-center justify-between gap-x-2 w-full">
              <div>
                <h6 className="text-grayLight900 text-base font-medium mb-0.5">
                  Location: Cornwall, K0C 1P0
                </h6>
                <StatusDotProfileCmp
                  dotColor="green"
                  label="Last Update: 7 hours ago"
                />
              </div>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsDisplayLocation(true);
                }}
              >
                Update Location
              </ButtonCmp>
            </div>
          }
          className="!p-4"
          contentParentClass="!mt-0 !border-t-0"
          contentClassName="flex flex-col gap-y-6"
        >
          <>
            <div>
              <div className="flex justify-between items-center gap-x-2 mb-1.5">
                <h6 className="text-textSecondary text-sm font-medium">
                  60% Complete
                </h6>
                <p className="text-gray500 text-xs font-normal">2 days left</p>
              </div>
              <progress
                className="progress bg-utilityGray200 progress-success flex"
                value="60"
                max="100"
              ></progress>
            </div>
            <ul className="flex justify-between gap-x-2 pb-3 border-b border-utilityGray200">
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">ETA</h6>
                <p className="text-grayLight900 text-xs font-medium">
                  4:13 PM, Nov 20th
                </p>
              </li>
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">
                  Est. Distance Left
                </h6>
                <p className="text-grayLight900 text-xs font-medium">220 MI</p>
              </li>
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">
                  Predictive Location Index
                </h6>
                <BadgeCmp style="modern" type="success" mainClassName="w-fit">
                  On Track
                </BadgeCmp>
                <BadgeCmp style="modern" type="warning" mainClassName="w-fit">
                  At Risk
                </BadgeCmp>
                <BadgeCmp style="modern" type="red" mainClassName="w-fit">
                  Delayed
                </BadgeCmp>
                <BadgeCmp style="modern" type="red" mainClassName="w-fit">
                  Critical Delay
                </BadgeCmp>
              </li>
            </ul>
            <ul>
              <li className="relative flex items-start gap-x-4 pb-6 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
                <div className="w-20 text-right">
                  <h6 className="text-gray500 text-xs font-normal">
                    4 Nov 2023
                  </h6>
                  <p className="text-gray400 leading-4 !text-[10px] font-normal">
                    7:00 AM
                  </p>
                </div>
                <div className="after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-primary after:bg-fgSuccessPrimary">
                  <Plus className="w-3 h-3 text-white" />
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-xs font-normal">
                    Bowden
                  </h6>
                  <p className="text-utilityGray800 leading-4 !text-[11px] font-normal">
                    8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                  </p>
                  <p className="text-gray500 leading-4 !text-[11px] font-normal">
                    #WAL-NSBB-34-C
                  </p>
                </div>
              </li>
              <li className="relative flex items-start gap-x-4 pb-6 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
                <div className="w-20 text-right">
                  <h6 className="text-gray500 text-xs font-normal">
                    4 Nov 2023
                  </h6>
                  <p className="text-gray400 leading-4 !text-[10px] font-normal">
                    6:00 AM
                  </p>
                </div>
                <div className="after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-transparent after:bg-fgSuccessPrimary">
                  <div className="w-2 h-2 bg-fgSuccessPrimary rounded-full"></div>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-xs font-normal">
                    Calgary
                  </h6>
                  <p className="text-utilityGray800 leading-4 !text-[11px] font-normal">
                    8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                  </p>
                  <p className="text-gray500 leading-4 !text-[11px] font-normal">
                    #WAL-NSBB-34-B
                  </p>
                </div>
              </li>
              <li className="relative flex items-start gap-x-4 pb-6 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
                <div className="w-20 text-right">
                  <h6 className="text-gray500 text-xs font-normal">
                    4 Nov 2023
                  </h6>
                  <p className="text-gray400 leading-4 !text-[10px] font-normal">
                    5:00 AM
                  </p>
                </div>
                <div className="after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-transparent after:bg-fgSuccessPrimary">
                  <div className="w-2 h-2 bg-fgSuccessPrimary rounded-full"></div>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-xs font-normal">
                    Toronto
                  </h6>
                  <p className="text-utilityGray800 leading-4 !text-[11px] font-normal">
                    8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                  </p>
                  <p className="text-gray500 leading-4 !text-[11px] font-normal">
                    #WAL-NSBB-34-A
                  </p>
                </div>
              </li>
            </ul>
          </>
        </AccordionCmp>

        {/* BELOW COMPONENT ALSO USED IN ORDER DETAIL */}
        <TrackTraceAiCmp />
      </div>
      <div className="p-4 shadow-tmd flex gap-x-4">
        {/* <ButtonCmp
          className="btn-outline-primary"
          onClick={() => setIsDisplayLocation(true)}
        >
          <Mark className="w-5 h-5" />
          Update Location
        </ButtonCmp> */}
        <ButtonCmp className="btn-outline-primary flex-1" onClick={() => {}}>
          Report Interruption
        </ButtonCmp>
        <ButtonCmp className="btn-outline-primary flex-1" onClick={() => {}}>
          Mark as Delivered
        </ButtonCmp>
      </div>

      {isDisplayLocation ? (
        <UpdateLocation
          status={status}
          handleClose={() => setIsDisplayLocation(false)}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default OrderDetail;
