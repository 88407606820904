import { yupResolver } from '@hookform/resolvers/yup';
import {
  Calendar,
  // Calendar,
  CurrencyDollar,
  Percent02,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { PATH } from 'src/constants/path';
import { listService } from 'src/services/CommonService';
import { getActiveQuote } from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import AdditionalCharge from '../../OrderDetails/AdditionalCharge';

const schema = yup.object().shape({
  quoteId: yup.string().nullable().required('This field is required'),
  carrierId: yup.number().nullable().required('This field is required'),
  budgetCost: yup.number(),
  proNumber: yup.string().required('This field is required'),
  baseCarrierRate: yup
    .number()
    .required('This field is required')
    .min(1, 'The value must be greater than 0')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  carrierBookingDate: yup.date(),
  fuel: yup
    .number()
    .required('This field is required')
    .min(1, 'The value must be greater than 0')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalPrice: yup
    .number()
    .required('This field is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup
        .string()
        .required('charge is required')
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
      price: yup
        .number()
        .required('price is required')
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
    })
  ),
});

// const schema = yup.object().shape({
//   quoteId: yup.string().when('carrierId', {
//     is: (value: any) => value !== 'unassign',
//     then: () => yup.string().required('This field is required'),
//     otherwise: () => yup.string().notRequired(),
//   }),
//   carrierId: yup
//     .mixed()
//     .nullable()
//     .required('This field is required')
//     .test(
//       'is-unassigned',
//       'Please select a carrier',
//       (value: any) =>
//         value === 'unassign' || (value !== null && value !== undefined)
//     ),
//   budgetCost: yup.number().when('carrierId', {
//     is: (value: any) => value === 'unassign',
//     then: () => yup.number().required('This field is required'),
//     otherwise: () => yup.number().notRequired(),
//   }),
//   proNumber: yup.string().when('carrierId', {
//     is: (value: any) => value !== 'unassign',
//     then: () => yup.number().required('This field is required'),
//     otherwise: () => yup.number().notRequired(),
//   }),
//   baseCarrierRate: yup.number().when('carrierId', {
//     is: (value: any) => value !== 'unassign',
//     then: () =>
//       yup
//         .number()
//         .required('This field is required')
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         )
//         .min(1, 'The value must be greater than 0'),
//     otherwise: () =>
//       yup
//         .number()
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         )
//         .notRequired(),
//   }),
//   carrierBookingDate: yup.date(),
//   fuel: yup.number().when('carrierId', {
//     is: (value: any) => value !== 'unassign',
//     then: () =>
//       yup
//         .number()
//         .required('This field is required')
//         .min(1, 'The value must be greater than 0')
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         ),
//     otherwise: () => yup.number().notRequired(),
//   }),
//   totalPrice: yup.number().when('carrierId', {
//     is: (carrierId: any) => carrierId !== 'unassign',
//     then: () =>
//       yup
//         .number()
//         .required('This field is required')
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         ),
//     otherwise: () => yup.number().notRequired(),
//   }),
//   additional_charge: yup.array().of(
//     yup.object().shape({
//       charge: yup
//         .string()
//         .test(
//           'charge-required-if-carrier-not-unassign',
//           'charge is required',
//           function (value: any) {
//             const { carrierId } = this.parent;

//             if (carrierId !== 'unassign' && (!value || value.trim() === '')) {
//               return false;
//             }

//             return true;
//           }
//         )
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         ),

//       price: yup
//         .number()
//         .test(
//           'price-required-if-carrier-not-unassign',
//           'price is required',
//           function (value: any) {
//             const { carrierId } = this.parent;

//             if (
//               carrierId !== 'unassign' &&
//               (value === undefined || value === null)
//             ) {
//               return false;
//             }

//             return true;
//           }
//         )
//         .transform((value, originalValue) =>
//           originalValue === '' ? null : value
//         ),
//     })
//   ),
// });

interface IProps {
  handleClose: () => void;
  handleAddCarrierPrice: any;
  carrierEditData: any;
  customerId: number | null;
}

export interface IFormData {
  carrierId: number | undefined | null | string;
  quoteId: number;
  budgetCost: number;
  baseCarrierRate: number | undefined | string;
  proNumber: number;
  carrierBookingDate: Date;
  fuel: number;
  totalPrice: number | undefined | string;
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    price: null | number;
  }>;
}

const CarrierModal = ({
  handleClose,
  handleAddCarrierPrice,
  carrierEditData,
  customerId,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carriers, setCarriers] = useState<any[]>([]);
  const [carrierList, setCarrierList] = useState<any[]>([]);
  const [isFieldsInitialized, setIsFieldsInitialized] = useState(false);
  const [activeQuoteList, setActiveQuoteList] = useState<any[]>([]);
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [activeQuoteOptionList, setActiveQuoteOptionList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [isUnassigned, setIsUnassigned] = useState(false);
  const [selectedQuoteCode, setSelectedQuoteCode] = useState(false);

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      carrierId: undefined,
      quoteId: undefined,
      budgetCost: undefined,
      baseCarrierRate: undefined,
      proNumber: undefined,
      carrierBookingDate: moment().toDate(),
      fuel: undefined,
      totalPrice: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          price: null,
        },
      ],
    },
  });

  const { handleSubmit, watch, control, setValue } = methods;

  const { replace } = useFieldArray({
    control,
    name: 'additional_charge',
  });

  const watchQuoteId: any = watch('quoteId');
  const watchCarrierId: any = watch('carrierId');
  const watchAdditionalCharges: any = watch('additional_charge');
  const watchBaseCarrierRate: any = watch('baseCarrierRate');
  const watchFuel: any = watch('fuel');

  const handleTotalPrice = () => {
    const additionalPrice = watchAdditionalCharges
      ?.filter(
        (item: any) =>
          item.price !== null &&
          item.price !== undefined &&
          !isNaN(Number(item.price))
      )
      .reduce((acc: any, item: any) => acc + Number(item.price), 0);

    const baseCarrierRate = watchBaseCarrierRate || 0;
    const fuel = watchFuel || 0;

    if (
      additionalPrice !== null &&
      !isNaN(additionalPrice) &&
      baseCarrierRate !== null &&
      !isNaN(baseCarrierRate) &&
      fuel !== null &&
      !isNaN(fuel)
    ) {
      const totalPrice =
        additionalPrice + Number(baseCarrierRate) + Number(fuel);

      setValue('totalPrice', totalPrice.toFixed(2));
    }
  };

  const getActvieQuotesData = () => {
    setIsQuoteLoading(true);
    getActiveQuote({ customerId, quoteId: carrierEditData?.quoteId ?? null })
      .then((response: any) => {
        if (response.data) {
          setActiveQuoteList(response.data);

          const quoteOptions = response.data.map((quote: any) => ({
            value: quote.quote.id,
            label: `#${quote.quote.code}`,
            code: `${quote.quote.code}`,
          }));

          // setCarriers(response?.carrier);

          // const carrierOptions = getSelectBoxOptions(
          //   response?.carrier,
          //   'id',
          //   'name',
          //   true,
          //   'iconUrl',
          //   'icon',
          //   'members',
          //   null,
          //   true
          // );

          // const unassignOption = {
          //   value: 'unassign',
          //   label: 'Unassigned Carrier',
          //   image: null,
          //   isImage: false,
          // };

          // setCarrierList([...carrierOptions]);

          setActiveQuoteOptionList(quoteOptions);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsQuoteLoading(false);
      });
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        if (response.data) {
          const formattedList = response.data.map((item: any) => ({
            label: item.name,
            value: item.slug,
          }));
          setServiceList(formattedList);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  useEffect(() => {
    getActvieQuotesData();
    getListService();
  }, []);

  useEffect(() => {
    handleTotalPrice();
  }, [watchAdditionalCharges, watchCarrierId]);

  const onSubmit = (data: any) => {
    setIsLoading(true);

    console.log('data', data);

    const uniqueKey =
      carrierEditData?.uniqueKey || `${data.carrierId}-${Date.now()}`;

    let carrierData = {};

    if (data?.carrierId) {
      // const filteredQuotes = activeQuoteList.filter(
      //   (quote: any) => quote.quote.id === watchQuoteId
      // );

      // const filteredCarrier = filteredQuotes.flatMap((quote: any) =>
      //   quote.carriersData.filter(
      //     (carrier: any) => carrier?.carrierId?.id === data?.carrierId
      //   )
      // );

      // carrierData =
      //   filteredCarrier?.length > 0
      //     ? filteredCarrier[0]?.carrierId
      //     : carrierData;

      // console.log('carrierData', carrierData);

      carrierData = carriers?.find((carr) => carr?.id === data?.carrierId);
    }

    handleAddCarrierPrice({
      ...data,
      carrier: carrierData,
      code: selectedQuoteCode,
      uniqueKey,
    });
    setIsLoading(false);
  };

  const handleQuoteChange = (selectedQuoteId: any) => {
    setCarrierList([]);
    setValue('carrierId', null);

    const filteredQuotes = activeQuoteList.filter(
      (quote: any) => quote.quote.id === selectedQuoteId
    );

    if (filteredQuotes.length > 0) {
      const carrierIds = filteredQuotes.flatMap((quote) =>
        quote.carriersData.map((carr: any) => carr.carrierId)
      );

      setCarriers(carrierIds);

      const carrierListOptions = filteredQuotes[0]?.carriersData.map(
        (carrier: any) => ({
          label: carrier?.carrierId?.name,
          value: carrier?.carrierId?.id,
          image: carrier?.carrierId?.image
            ? carrier?.carrierId?.imageUrl + carrier?.carrierId?.image
            : null,
          isImage: true,
        })
      );

      setCarrierList(carrierListOptions);
    } else {
      setCarrierList([]);
    }

    setValue('baseCarrierRate', '');
    setValue('totalPrice', '');
  };

  const handleCarrierChange = (selectedCarrierId: any) => {
    const isUnassignedCarrier = selectedCarrierId === 'unassign';
    setIsUnassigned(isUnassignedCarrier);

    if (!isUnassignedCarrier) {
      const filteredQuotes = activeQuoteList.filter(
        (quote: any) => quote.quote.id === watchQuoteId
      );

      const filteredCarrier = filteredQuotes.flatMap((quote: any) =>
        quote.carriersData.filter(
          (carrier: any) => carrier?.carrierId?.id === selectedCarrierId
        )
      );

      if (filteredCarrier && filteredCarrier?.length > 0) {
        const totalCharge = filteredCarrier?.[0]?.totalCharge;
        setValue('baseCarrierRate', totalCharge);
        setValue('totalPrice', totalCharge);
      }
    } else {
      setValue('baseCarrierRate', '');
      setValue('totalPrice', '');
    }
  };

  useEffect(() => {
    if (carrierEditData) {
      replace(carrierEditData.additional_charge);
      setValue('baseCarrierRate', carrierEditData?.baseCarrierRate);
      setValue('totalPrice', carrierEditData?.totalPrice);
      setValue('quoteId', carrierEditData?.quoteId);
      setValue('fuel', carrierEditData?.fuel);
      setValue('proNumber', carrierEditData?.proNumber);

      setIsFieldsInitialized(true);
    } else {
      setIsFieldsInitialized(true);
    }
  }, [carrierEditData]);

  useEffect(() => {
    if (activeQuoteList && carrierEditData?.carrierId) {
      const filteredQuotes = activeQuoteList.filter(
        (quote: any) => quote.quote.id === +carrierEditData?.quoteId
      );

      if (filteredQuotes.length > 0) {
        const carrierIds = filteredQuotes.flatMap((quote) =>
          quote.carriersData.map((carr: any) => carr.carrierId)
        );

        setCarriers(carrierIds);

        const carrierListOptions = filteredQuotes[0]?.carriersData.map(
          (carrier: any) => ({
            label: carrier?.carrierId?.name,
            value: carrier?.carrierId?.id,
            image: carrier?.carrierId?.image
              ? carrier?.carrierId?.imageUrl + carrier?.carrierId?.image
              : null,
            isImage: true,
          })
        );
        setCarrierList(carrierListOptions);
      } else {
        setCarrierList([]);
      }

      if (carrierEditData) {
        setValue('carrierId', carrierEditData?.carrierId);
      }
    }
  }, [activeQuoteList, carrierEditData?.carrierId]);

  return (
    <CommonModal
      title={carrierEditData ? 'Edit a Carrier' : 'Add a Carrier'}
      titleDesc="Attach a carrier to this order, if applicable at this time."
      handleClose={handleClose}
      headerIcon={<Plus />}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px]'}
      primaryBtnText={carrierEditData ? 'Save' : 'Assign'}
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={handleClose}
    >
      <>
        <FormProvider {...methods}>
          <div className="p-5 flex flex-col gap-y-4">
            {!isUnassigned && (
              <Controller
                name="quoteId"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SelectBox
                    label="Quote ID"
                    labelClassName="form_label block mb-1.5"
                    name="quoteId"
                    id="quoteId"
                    size="sm"
                    placeholder="Select Quote ID"
                    noOptionMessage="No Quote ID Found"
                    isSearchable={true}
                    className="form_control"
                    // isClearable={true}
                    options={activeQuoteOptionList}
                    onChangeFunc={(e: any) => {
                      const selectedQuoteId = e?.value;
                      onChange(selectedQuoteId);
                      setSelectedQuoteCode(e?.code);
                      handleQuoteChange(selectedQuoteId);
                    }}
                    value={activeQuoteOptionList?.find(
                      (e: any) => e?.value == value
                    )}
                    isLoading={isQuoteLoading}
                    labelRight={
                      <button
                        type="button"
                        className="text-primary700 text-xs flex font-semibold gap-2.5 z-[5]"
                        onClick={() => {
                          window.open(PATH.ADDRESS_DETAILS, '_blank');
                        }}
                      >
                        Add Quote
                      </button>
                    }
                    errorText={error ? error.message : null}
                  />
                )}
              />
            )}
            <Controller
              name="carrierId"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SelectBox
                  label="Select Carrier"
                  labelClassName="form_label block mb-1.5"
                  name="customerId"
                  id="customerId"
                  size="sm"
                  placeholder="All Carrier"
                  noOptionMessage="No Carrier Found"
                  isSearchable={true}
                  className="form_control"
                  // isClearable={true}
                  options={carrierList}
                  onChangeFunc={(e: any) => {
                    onChange(e?.value);
                    handleCarrierChange(e?.value);
                  }}
                  value={
                    value ? carrierList?.find((e) => e?.value == value) : null
                  }
                  errorText={error ? error.message : null}
                  isLoading={isQuoteLoading}
                  required
                  isClearable
                />
              )}
            />

            {!isUnassigned && (
              <>
                <Controller
                  name="baseCarrierRate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Base Carrier Rate"
                      inputName="baseCarrierRate"
                      placeholder="Enter Base Carrier Rate"
                      parentClassName=""
                      className="pl-8"
                      value={value}
                      onChangeFunc={onChange}
                      onBlur={handleTotalPrice}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      icon={
                        <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                      }
                    />
                  )}
                />

                <Controller
                  name="proNumber"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="PRO Number"
                      inputName="proNumber"
                      placeholder="Enter PRO Number"
                      value={value}
                      labelClassName="block mb-1.5"
                      onChangeFunc={onChange}
                      errorText={error ? error.message : null}
                    />
                  )}
                />

                {isFieldsInitialized && (
                  <AdditionalCharge
                    isSelectBox={true}
                    serviceList={serviceList}
                    onBlur={handleTotalPrice}
                  />
                )}

                <Controller
                  name="fuel"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Fuel"
                      inputName="fuel"
                      placeholder="Enter Fuel Value"
                      parentClassName=""
                      className="pl-8 w-full"
                      value={value}
                      onChangeFunc={onChange}
                      onBlur={handleTotalPrice}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      icon={
                        <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                      }
                    />
                  )}
                />

                <Controller
                  name="totalPrice"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Total Price"
                      inputName="total_sale_price"
                      placeholder="Enter Total Sale Price"
                      parentClassName=""
                      className="pl-8 w-full"
                      value={value}
                      onChangeFunc={onChange}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      icon={
                        <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                      }
                      readOnly
                    />
                  )}
                />
              </>
            )}

            {isUnassigned && (
              <>
                <Controller
                  name="budgetCost"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Budget Cost"
                      inputType="number"
                      inputName="budgetCost"
                      placeholder="Enter Budget Cost"
                      parentClassName=""
                      className="pl-8"
                      value={value}
                      onChangeFunc={onChange}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      icon={
                        <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                      }
                    />
                  )}
                />

                <Controller
                  name="carrierBookingDate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DateSelect
                      inputName="carrierBookingDate"
                      className="form_control"
                      parentClassName="datepicker-w-auto one-month-datepicker w-full"
                      label="Carrier Booking Date"
                      selected={moment().toDate()}
                      onChangeFunc={(e: any) => onChange(e?.value)}
                      minDate={moment().toDate()}
                      labelClassName="block"
                      placeholder="Select Date"
                      dateFormat="dd/MM/yyyy"
                      errorText={error?.message}
                      icon={<Calendar className="h-5 w-5" />}
                      value={getDateWithSuffixFormat(
                        getFormattedPickupDate(value)
                      )}
                    />
                  )}
                />
              </>
            )}
          </div>
        </FormProvider>
      </>
    </CommonModal>
  );
};

export default CarrierModal;
