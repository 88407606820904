import React, { useState } from 'react';
import TabButton from 'src/components/TabButton';

import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';

import AccountSettings from './AccountSettings';
import Appearance from './Appearance';
import CustomerSettings from './CustomerSettings';
import LoadInsuranceSettings from './LoadInsuranceSettings';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('account-settings');

  const tabArray: any = [
    {
      value: 'appearance',
      name: 'Organization Setting',
    },
    {
      value: 'customer-settings',
      name: 'Customer Settings',
    },
    {
      value: 'account-settings',
      name: 'Account Settings',
    },
    {
      value: 'load-insurance-settings',
      name: 'Load Insurance Settings',
    },
  ];

  const tabArrayStaging: any = [
    {
      value: 'appearance',
      name: 'Organization Setting',
    },
    {
      value: 'account-settings',
      name: 'Account Settings',
    },
  ];

  const tabArrayLive: any = [
    {
      value: 'account-settings',
      name: 'Account Settings',
    },
  ];

  return (
    <PageSectionLayout
      header={<Header title="Settings" desc="Manage your account settings." />}
    >
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className="rounded-md text-gray500 !border-0 min-w-[182px] "
        tabParentClassName={`!w-full `}
        activeClassName="!bg-primary100 !text-primary700"
        tabArray={
          window.MODE === 'production'
            ? tabArrayLive
            : window.MODE === 'staging'
            ? tabArrayStaging
            : tabArray
        }
        isActive={activeTab}
        isTab={true}
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
      />
      {activeTab === 'account-settings' && <AccountSettings />}
      {activeTab === 'customer-settings' && <CustomerSettings />}
      {activeTab === 'appearance' && <Appearance />}
      {activeTab === 'load-insurance-settings' && <LoadInsuranceSettings />}
    </PageSectionLayout>
  );
};

export default Settings;
