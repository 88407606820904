import {
  DotsVertical,
  ReverseLeft,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import TimerBadge from 'src/components/TimerBadge';
import {
  removeFromQuote,
  draftQuoteEmail,
} from 'src/services/QuoteEmailService';
import { convertToGBMBKB, getShortName } from 'src/utils/CommonFunctions';

import attachmentDefault from '../../../../assets/img/attachment-default.png';
import csvImage from '../../../../assets/img/csv-icon.png';
import docxImage from '../../../../assets/img/docx-icon.png';
import pdfImage from '../../../../assets/img/pdf-icon.png';
import pngImage from '../../../../assets/img/png-icon.png';

import MessageBodyLoading from './MessageBodyLoading';
import ReplyModal from './ReplyModal';

const MailDetailView = ({
  params,
  emailDetails,
  setIsEmailClicked,
  setParams,
}: any) => {
  const selectedEmail = emailDetails?.emailBody;
  const dotsMenuRef = useRef<HTMLDivElement>(null);

  const emailContent = selectedEmail?.body?.content
    ?.replace(/\\|\r\n/g, '')
    .replace(
      /<table(.*?)>([\s\S]*?)<\/table>/g,
      (match: any, attributes: any, innerContent: any) =>
        // Wrap the <table> in a <div> with the class 'responsive-table'
        `<div class="responsive-table"><table${attributes}>${innerContent}</table></div>`
    );

  const customStyles = `
  <style>
    /* Basic Styles */
    html, body {
      font-family: "Inter", sans-serif !important;
      color: #475467 !important;
      font-weight: 500 !important;
      max-width: 100% !important;
    }
    img{
      max-width: 100%;
    }
    html *{
      font-family: "Inter", sans-serif !important;
      line-height: 1.5 !important;
    }
    html::-webkit-scrollbar,.responsive-table::-webkit-scrollbar{
      width: 4px;
      height: 4px;
    }
    html::-webkit-scrollbar-thumb,.responsive-table::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #eaecf0;
    }
    body {
      font-size: 12px !important;
      margin: 0;
    }
    
    /* Paragraph (p) styling */
    p {
      font-size: 14px !important;
      color: #475467 !important;
      font-weight: 400 !important;
    }
    font {
      font-size: 14px !important;
      color: #475467 !important;
      font-weight: 400 !important;
    }
    /* Heading styles */
    h1 {
      font-size: 30px !important;
      font-weight: 700 !important;
      color: #475467 !important;
      margin: 10px 0;
    }

    h2 {
      font-size: 24px !important;
      font-weight: 600 !important;
       color: #475467 !important;
      margin: 10px 0;
    }

    h3 {
      font-size: 20px !important;
      font-weight: 600 !important;
       color: #475467 !important;
      margin: 10px 0;
    }

    h4 {
      font-size: 18px !important;
      font-weight: 600 !important;
       color: #475467 !important;
      margin: 10px 0;
    }

    h5 {
      font-size: 16px !important;
      font-weight: 600 !important;
       color: #475467 !important;
      margin: 10px 0;
    }

    h6 {
      font-size: 14px !important;
      font-weight: 600 !important;
       color: #475467 !important;
      margin: 10px 0;
    }

    /* List item (li) styling */
    li {
      font-size: 14px !important;
      font-weight: 500 !important;
       color: #475467 !important;
    }

    /* Div and Span styling */
    div, span {
      font-size: 14px !important;
       color: #475467 !important;
      font-weight: 400 !important;
    }
    div{
      margin-top: 5px !important;
      margin-bottom: 5px !important;    
    }
    /* Gmail Signature - Hide it */
    .gmail_signature {
      display: none !important;
    }

    /* Default styling for other text elements */
    .MsoNormal {
       color: #475467 !important;
    }
       a, a *{
      color: #3538cd !important;
    }

    .responsive-table{
      overflow-x: auto;
      padding-bottom: 5px;
    }
  </style>
`;

  const customScript = `
  <script>
    document.addEventListener('DOMContentLoaded', function() {
      // Custom JavaScript manipulation after the content is loaded
      const signature = document.querySelector('.gmail_signature');
      if (signature) {
        signature.style.display = 'none';  // Example: hide Gmail signature
      }

      // Further manipulation (example: change the color of MsoNormal paragraphs)
      const paragraphs = document.querySelectorAll('.MsoNormal');
      paragraphs.forEach(p => {
        p.style.color = 'gray';  // Example: change text color to gray
      });
    });
  </script>
`;

  const srcDocContent = `
  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
      ${customStyles}  <!-- Inject custom styles -->
    </head>
    <body lang="EN-US" style="word-wrap:break-word;">
      ${emailContent}
      ${customScript}  <!-- Inject custom JavaScript -->
    </body>
  </html>
`;

  const [isShowReplyModal, setIsShowReplyModal] = useState<boolean>(false);
  const [draftEmail, setDraftEmail] = useState<any>();
  const [draftEmailLoading, setDraftEmailLoading] = useState<any>();
  const [isClassApplied, setIsClassApplied] = useState<boolean>(false);
  const [isEmailDiscardLoading, setIsEmailDiscardLoading] = useState(false);

  const handleToggleClass = () => {
    setIsClassApplied(!isClassApplied);
  };

  // Check if click is outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dotsMenuRef.current &&
        !dotsMenuRef.current.contains(event.target as Node)
      ) {
        setIsClassApplied(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dotsMenuRef]);

  const handleDiscardEmail = async () => {
    setIsEmailDiscardLoading(true);
    removeFromQuote({ id: selectedEmail?.id }).then(() => {
      setIsEmailDiscardLoading(false);
      setParams((old: any) => ({
        ...old,
        page: 1,
      }));
    });
  };

  const handleReply = () => {
    setIsShowReplyModal(true);

    if (params?.isQuoteEmail) {
      setDraftEmailLoading(true);
      const payload = {
        quote_details: {
          is_quote_related: emailDetails?.isQuoteEmail === 1 ? true : false,
          parcels: emailDetails?.extractDetails
            ? JSON.parse(emailDetails?.extractDetails)
            : '',
        },
        sender: emailDetails?.emailBody?.toRecipients[0]?.emailAddress?.name,
        receiver: emailDetails?.emailBody?.from?.emailAddress?.name,
        message_id: emailDetails?.messageId,
      };
      console.log('Draft Email API payload:', payload);
      draftQuoteEmail(payload).then((response: any) => {
        setDraftEmail(response?.result?.email_content);
        setDraftEmailLoading(false);
      });
    }
  };

  return (
    <>
      {selectedEmail && (
        <>
          <div className="flex flex-wrap xxl:gap-4 lg:gap-3 gap-2 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
            <div className="lg:w-11 w-8 lg:h-11 h-8 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none">
              <span className="text-primary lg:text-xl text-sm font-semibold">
                {selectedEmail?.from?.emailAddress?.name
                  ? getShortName(
                      selectedEmail?.from?.emailAddress?.name?.toUpperCase()
                    )
                  : ''}
              </span>
            </div>
            <div className="flex-1 flex flex-wrap xxl:gap-x-4 gap-x-2 lg:flex-row flex-col ">
              <div className="flex-1">
                {/* <div className="flex flex-wrap gap-x-2 gap-y-1 items-center mb-0.5"> */}
                <h6 className="text-grayLight900 text-base font-medium flex xxl:gap-4 gap-3 items-center">
                  {selectedEmail?.from?.emailAddress?.name}
                </h6>
                {/* <div className="rounded-md border border-utilityGray200 bg-gray50 flex px-1.5 py-0.5">
                    <a
                      href="mailto:katherine.moss@wal.com"
                      className="text-textSecondary text-xs font-medium line-clamp-1 break-all"
                    >
                      {selectedEmail?.from?.emailAddress?.address}
                    </a>
                  </div> */}
                {/* </div> */}

                <a
                  href="mailto:katherine.moss@wal.com"
                  className="text-grayLight600 text-xs leading-[1.5] font-normal block w-fit"
                >
                  {selectedEmail?.from?.emailAddress?.address}
                </a>

                {/* <div className="text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5">
                  <span className="font-bold mr-1">Subject: </span>
                  <span className="font-normal">{selectedEmail?.subject}</span>
                </div> */}
              </div>
              <div className="flex flex-wrap items-end lg:flex-col">
                <div className="text-grayLight600 text-xs font-normal leading-[2]">
                  {moment(emailDetails?.receivedDateTime)
                    .utc()
                    .format('dddd, MMMM D, YYYY, h:mm A')}
                </div>
                <TimerBadge badgeDate={emailDetails?.receivedDateTime} />
              </div>
            </div>
            <ButtonCmp
              className="btn_secondary_black !px-1.5 !py-1.5 self-start sm:hidden"
              onClick={handleToggleClass}
            >
              <DotsVertical className="w-3.5 h-3.5 text-gray400 sm:hidden flex-none" />
            </ButtonCmp>
            <ButtonCmp
              className="btn_secondary_black !px-1.5 !py-1.5 self-start sm:hidden"
              onClick={() => setIsEmailClicked(false)}
            >
              <XClose className="w-3.5 h-3.5" />
            </ButtonCmp>
          </div>
          <div className="flex-1 w-full flex-col justify-start items-start gap-4 flex p-5 overflow-y-auto custom-scrollbar-v2">
            <div className="text-grayLight600 text-sm leading-[1.5] font-normal mt-0.5">
              <span className="font-bold mr-1">Subject: </span>
              <span className="font-normal">{selectedEmail?.subject}</span>
            </div>
            <iframe
              srcDoc={srcDocContent}
              style={{ border: 'none', width: '100%', height: 'auto' }}
              className="min-h-[auto]"
            />
            {emailDetails?.externalAttachment &&
              emailDetails.externalAttachment.length > 0 && (
                <div className="w-full">
                  <div className="text-black font-medium text-lg mb-2">
                    Attachments
                  </div>
                  <ul className=" flex flex-wrap -m-1.5">
                    {JSON.parse(emailDetails?.externalAttachment)?.map(
                      (attachment: any, index: number) => {
                        const fileExtension = attachment?.fileUrl
                          ?.split('.')
                          ?.pop()
                          ?.toLowerCase();
                        let fileIcon = null;
                        let fileName = attachment?.name;
                        const fileUrl = attachment?.fileUrl;
                        const fileSize = attachment?.size;

                        const formattedFileSize = convertToGBMBKB(fileSize);

                        if (fileExtension === 'pdf') {
                          fileIcon = (
                            <img src={pdfImage} alt="PDF" className="h-8" />
                          );
                        } else if (
                          fileExtension === 'jpg' ||
                          fileExtension === 'jpeg'
                        ) {
                          fileIcon = (
                            <img
                              src={pngImage}
                              alt="JpgImage"
                              className="w-10 h-10"
                            />
                          );
                        } else if (fileExtension === 'png') {
                          fileIcon = (
                            <img
                              src={pngImage}
                              alt="PngImage"
                              className="w-10 h-10"
                            />
                          );
                        } else if (fileExtension === 'csv') {
                          fileIcon = (
                            <img
                              src={csvImage}
                              alt="CSV"
                              className="w-10 h-10"
                            />
                          );
                        } else if (
                          fileExtension === 'docx' ||
                          fileExtension === 'doc'
                        ) {
                          fileIcon = (
                            <img
                              src={docxImage}
                              alt="DOCX"
                              className="w-10 h-10"
                            />
                          );
                        } else if (fileExtension === 'zip') {
                          fileIcon = (
                            <img
                              src={csvImage}
                              alt="ZIP"
                              className="w-10 h-10"
                            />
                          );
                        } else if (fileExtension === 'html') {
                          fileIcon = (
                            <img
                              src={csvImage}
                              alt="HTML"
                              className="w-10 h-10"
                            />
                          );
                        } else {
                          fileIcon = (
                            <img
                              src={attachmentDefault}
                              alt="DEFAULT"
                              className="w-10 h-10"
                            />
                          );
                        }

                        return (
                          <li
                            key={index}
                            className={`p-1.5 w-1/2 ${
                              index % 2 === 0 ? 'col-start-1' : 'col-start-2'
                            }`}
                          >
                            <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
                              {fileIcon}
                              <div className="flex-1 w-[calc(100%_-_85px)]">
                                <a
                                  href={fileUrl}
                                  download
                                  className="text-textSecondary text-xs font-medium block truncate"
                                >
                                  {fileName}
                                </a>
                                <div className="text-grayLight600 text-xs !text-[11px] font-normal">
                                  {formattedFileSize}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
          </div>
          <div
            ref={dotsMenuRef}
            className={`flex sm:flex-wrap gap-2 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200 px-5 py-3  max-sm:top-465px] max-sm:right-[55px] max-sm:shadow max-sm:bg-white max-sm:rounded-lg max-sm:border max-sm:border-borderPrimary max-sm:h-64 max-sm:overflow-y-auto max-sm:flex-col max-sm:p-3 justify-end ${
              isClassApplied ? 'max-sm:absolute' : 'max-sm:hidden'
            } `}
          >
            {params?.isQuoteEmail && (
              <ButtonCmp
                className="btn_secondary_black"
                icon={<XClose className="w-4 h-4" />}
                onClick={handleDiscardEmail}
                loading={isEmailDiscardLoading}
                disabled={isEmailDiscardLoading}
              >
                Dismiss
              </ButtonCmp>
            )}
            <ButtonCmp
              className="btn_secondary_black"
              icon={<ReverseLeft className="w-4 h-4" />}
              onClick={handleReply}
              disabled={isEmailDiscardLoading}
            >
              Reply
            </ButtonCmp>
          </div>
        </>
      )}

      {!selectedEmail && <MessageBodyLoading />}

      {isShowReplyModal && (
        <ReplyModal
          emailDetails={selectedEmail}
          handleClose={() => setIsShowReplyModal(false)}
          draftEmail={draftEmail}
          draftEmailLoading={draftEmailLoading}
        />
      )}
    </>
  );
};

export default MailDetailView;
