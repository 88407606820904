import {
  Calendar,
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import ButtonCmp from '../ButtonCmp';
import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';
import SelectBox from '../SelectBox/SelectBox';

import { IDateSelectProps } from './dateSelect.interface';

const CustomDateInput = React.forwardRef(
  ({ value, onClick, dateSelectClassName }: any, ref: any) => (
    <div
      className={`form_control text-grayLight900 font-medium  [&.disabled]:border-borderPrimary [&.disabled]:bg-gray50 [&.disabled]:text-gray500 [&.disabled>svg]:hidden [&.disabled]:opacity-100 ${dateSelectClassName}`}
      onClick={onClick}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  )
);

CustomDateInput.displayName = 'CustomDateInput';

export const TimeRanges = [
  { label: 'Today', value: 'today', key: 'today' },
  { label: 'Tomorrow', value: 'tomorrow', key: 'tomorrow' },
  { label: 'This Week', value: 'this_week', key: 'this_week' },
  { label: 'Next Week', value: 'next_week', key: 'next_week' },
  { label: 'This Month', value: 'this_month', key: 'this_month' },
];

const renderCustomHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const decreaseYear = () => changeYear(date.getFullYear() - 1);
  const increaseYear = () => changeYear(date.getFullYear() + 1);

  return (
    <div className="my-3 mx-[23px] flex justify-between items-center">
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseYear}
        >
          <ChevronLeftDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <ChevronLeft className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
      <span>
        {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
      </span>
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <ChevronRight className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black lg:!py-1 lg:!px-1"
          onClick={increaseYear}
        >
          <ChevronRightDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
    </div>
  );
};

const DateTimePicker = React.forwardRef(
  (
    {
      inputName,
      placeholder,
      className,
      labelClassName,
      parentClassName,
      calendarIconClass,
      errorText,
      tabIndex,
      isShowCustomMonthYearHeader,
      dateSelectClassName,
      value,
      onChangeFunc,
      pickupTime,
      setPickupTime,
      window,
      setWindow,
      availabilityDateSubmit,
      resetAvailabilityDate,
      ...props
    }: IDateSelectProps,
    ref: any
  ) => {
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [isOpenDropDown, setIsOpenDropDown] = useState<any>(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [initialDateTime, setInitialDateTime] = useState<any>({});
    const initialDateTimeRef = useRef<any>(initialDateTime);

    useEffect(() => {
      initialDateTimeRef.current = initialDateTime;
    }, [initialDateTime]);

    useEffect(() => {
      if (isOpenDropDown) {
        const newDateTime = {
          pickupDate: props.selected,
          pickupTime,
          window,
        };
        setInitialDateTime(newDateTime);
        initialDateTimeRef.current = newDateTime;
      }
    }, [isOpenDropDown]);

    const windowHours = [
      {
        value: '15',
        label: '15 Minutes',
      },
      {
        value: '30',
        label: '30 Minutes',
      },
      {
        value: '45',
        label: '45 Minutes',
      },
      {
        value: '60',
        label: '1 Hour',
      },
      {
        value: '120',
        label: '2 Hours',
      },
      {
        value: '180',
        label: '3 Hours',
      },
      {
        value: '240',
        label: '4 Hours',
      },
      {
        value: '300',
        label: '5 Hours',
      },
      {
        value: '360',
        label: '6 Hours',
      },
    ];

    const closeDateTimePicker = () => {
      resetAvailabilityDate(initialDateTimeRef.current);
      setIsOpenDropDown(false);
    };

    useEffect(() => {
      if (!isOpenDropDown) return;

      function handleClickOutside(event: any) {
        if (
          datePickerRef.current &&
          !datePickerRef.current.contains(event.target)
        ) {
          closeDateTimePicker();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpenDropDown]);

    const onChangeDate = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined
    ) => {
      if (date) {
        date = new Date(date.toISOString());
      }

      let customEvent: any = event;
      customEvent = {
        event,
        ...{ value: date, id: props.id, name: inputName },
      };
      onChangeFunc(customEvent);
    };

    const handlePickupTimeChange = (e: any) => {
      const time = e.target.value;

      if (/^[0-9:]*$/.test(time)) {
        // Check for a valid hh:mm pattern or partial valid input
        const timeRegex =
          /^(|[0-1]?$|[0-1][0-9]?$|2?$|2[0-3]?$|[0-1][0-9]:?$|2[0-3]:?$|[0-1][0-9]:[0-5]?$|2[0-3]:[0-5]?$|[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9])$/;

        if (timeRegex.test(time)) {
          setPickupTime(time);
        }
      }
    };

    const handleWindowChange = (e: any) => {
      setWindow(e.value);
    };

    const handleTimeRangeChangesCurrent = (val: any) => {
      const now = new Date();
      let newSelectedDate = new Date(now);

      switch (val) {
        case 'today': {
          newSelectedDate = now;
          break;
        }

        case 'tomorrow': {
          newSelectedDate.setDate(now.getDate() + 1);
          break;
        }

        case 'this_week': {
          const dayOfWeek = newSelectedDate.getDay();
          const daysUntilEndOfWeek = 6 - dayOfWeek;
          newSelectedDate.setDate(
            newSelectedDate.getDate() + daysUntilEndOfWeek
          );
          break;
        }

        case 'next_week': {
          const dayOfWeek = newSelectedDate.getDay();
          const daysUntilEndOfWeek = 6 - dayOfWeek + 7;
          newSelectedDate.setDate(
            newSelectedDate.getDate() + daysUntilEndOfWeek
          );
          break;
        }

        case 'this_month': {
          newSelectedDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          break;
        }

        default: {
          console.warn('Unknown time range:', val);
          break;
        }
      }

      setSelectedDate(newSelectedDate);

      if (newSelectedDate) {
        onChangeDate(newSelectedDate, undefined);
      }
      console.log('Updated Selected Date:', newSelectedDate);
    };

    return (
      <div
        className={`relative one-month-datepicker  datepicker-w-auto date-time-picker cursor-pointer ${parentClassName}`}
        ref={datePickerRef}
      >
        <div
          className={`w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px] justify-between items-center gap-1.5 flex bg-white`}
        >
          <div
            className={`flex flex-1 items-center`}
            onClick={() => setIsOpenDropDown(!isOpenDropDown)}
          >
            <label
              className={`text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none block ${labelClassName}`}
              onClick={() => setIsOpenDropDown(true)}
              dangerouslySetInnerHTML={{ __html: value || '' }}
            ></label>
          </div>
        </div>

        {isOpenDropDown && (
          <div
            className={`shadow-xl absolute border-utilityGray200 border rounded-xl mt-1 z-[2] min-w-[435px] flex w-full bg-white cursor-pointer wal-single-date-picker`}
          >
            <div className="border-r border-utilityGray200 min-w-[157px]">
              <SelectBox
                parentClassName="selectbox-dropdown-without-border"
                name="timeRangeListGroup"
                id="timeRangeListGroup"
                className="form_control shadow"
                size="sm"
                classComp="hide-select-value-container lg:block hidden"
                options={TimeRanges}
                isCloseOnScroll={false}
                onMenuOpen={() => setIsOpenDropDown(true)}
                onChangeFunc={(event: any) =>
                  handleTimeRangeChangesCurrent(event.value)
                }
                value={TimeRanges.find((val) => val.value === '')}
                defaultValue={TimeRanges.find((val) => val.value === '')}
                menuIsOpen={isOpenDropDown}
                inlineSelect={true}
              />
              <div className="border-t border-utilityGray200 mx-2.5 py-4 flex flex-col gap-y-4">
                <div className="flex items-center gap-x-2 flex-wrap">
                  <label className="form_label block mb-1.5 w-full">
                    Pickup Time
                  </label>
                  <InputText
                    inputName="pickupTime"
                    inputType="text"
                    labelClassName="mb-1.5 block"
                    className="form_control"
                    parentClassName="flex-1 checkmark-hide"
                    placeholder={'HH:MM'}
                    value={pickupTime}
                    required={true}
                    onChangeFunc={handlePickupTimeChange}
                  />
                </div>

                <SelectBox
                  name="window"
                  id="window"
                  className="form_control"
                  placeholder=""
                  size="sm"
                  isMultipleSelection={false}
                  options={windowHours}
                  onChangeFunc={handleWindowChange}
                  value={windowHours.find((e) => e.value == window)}
                  menuPlaceOption={'top'}
                  label="Window (+-)"
                  labelClassName="block mb-1.5 form_label"
                />
              </div>
            </div>
            <div className="flex-grow relative w-full">
              <DatePicker
                ref={ref}
                placeholderText={placeholder}
                selected={selectedDate}
                onChange={onChangeDate}
                className={`${className} cursor-pointer `}
                minDate={moment().toDate()}
                tabIndex={tabIndex}
                showFourColumnMonthYearPicker={true}
                renderCustomHeader={
                  isShowCustomMonthYearHeader ? renderCustomHeader : undefined
                }
                id={props.id}
                onKeyDown={(e) => {
                  if (e?.keyCode === 9 || e?.which === 9) {
                    ref?.current.setOpen(false);
                  }
                }}
                fixedHeight={true}
                autoComplete="off"
                name={inputName}
                dropdownMode="select"
                showIcon={true}
                icon={<Calendar className="h-5 w-5 text-textSecondary" />}
                toggleCalendarOnIconClick
                calendarIconClassname={`icon absolute right-2 top-1/2 -translate-y-1/2 text-lg text-textSecondary ${calendarIconClass}`}
                customInput={
                  <CustomDateInput
                    value={props.selected}
                    dateSelectClassName={dateSelectClassName}
                  />
                }
                popperPlacement="top-end"
                {...props}
                inline={isOpenDropDown}
              />
              <div className="border-t border-utilityGray200 px-4 py-3 flex flex-col gap-y-2.5">
                <div className="flex gap-x-2">
                  <ButtonCmp
                    type="button"
                    className="btn_secondary_black flex-1"
                    onClick={() => closeDateTimePicker()}
                  >
                    Cancel
                  </ButtonCmp>
                  <ButtonCmp
                    type="button"
                    className="btn-outline-primary flex-1"
                    onClick={() => {
                      initialDateTime.pickupTime = pickupTime;
                      initialDateTime.window = window;
                      setInitialDateTime(initialDateTime);
                      initialDateTimeRef.current = initialDateTime;
                      setIsOpenDropDown(false);
                      availabilityDateSubmit(initialDateTime);
                    }}
                  >
                    Apply
                  </ButtonCmp>
                </div>
              </div>
            </div>
          </div>
        )}
        {errorText && <ErrorMsg errorText={errorText} />}
      </div>
    );
  }
);

DateTimePicker.defaultProps = {
  inputName: '',
  className: '',
  labelClassName: '',
  label: '',
  errorText: null,
  id: '',
};

DateTimePicker.displayName = 'DateTimePicker';

export default DateTimePicker;
