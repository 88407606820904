import { Calendar, InfoCircle, Plus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IFormData, initShipperAddress } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  DATE_FORMAT,
  QUOTE_CLASSIFICATION,
  TIME_FORMAT,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { CustomersAllList } from 'src/services/CustomerService';
import { getMapService } from 'src/services/IntegrationService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';
import { removeFromQuote } from 'src/services/QuoteEmailService';
import { createQuote, createUpdateSummary } from 'src/services/QuoteService';
import {
  createShipper,
  deleteShippers,
  listShippers,
  updateShipper,
} from 'src/services/ShipperService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import Address from '../../QuotingDashboard/AddressDetails/Address';
import {
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initValidForm,
  scriptId,
} from '../../QuotingDashboard/AddressDetails/Address.interface';

import ConsigneeAddressSection from './ConsigneeAddressSection';
import DimensionSection from './DimensionSection';
import ShipperAddressSection from './ShipperAddressSection';

const serviceTypeArr = [
  {
    label: 'LTL',
    value: QUOTE_CLASSIFICATION.LTL,
  },
  {
    label: 'FTL',
    value: QUOTE_CLASSIFICATION.FTL,
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const currencyTypeOptions = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'CAD',
    value: 'cad',
  },
];

const urgencyTypeArr = [
  { label: 'Live', value: 'live' },
  { label: 'RFQ', value: 'rfq' },
];

const initQuoteFormData = {
  ...initFormData,
  urgency: 'live',
  classification: undefined,
  linearFootage: undefined,
  totalWeight: undefined,
  isInsureLoad: false,
  insuranceCommercialValue: undefined,
  insuranceCurrencyType: 'cad',
};

const initValidQuoteForm = {
  ...initValidForm,
  insuranceCommercialValue: true,
  insuranceCurrencyType: true,
  equipmentTypeId: true,
};

const areStringArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const cleanObject = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        !!key && value !== undefined && value !== null && value !== ''
    )
  );

const getAddressDetails = async (searchTerm: string): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps.places) {
      reject(new Error('Google Maps Places API is not loaded.'));

      return;
    }

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    // Step 1: Get Place Predictions
    autocompleteService.getPlacePredictions(
      { input: searchTerm },
      (predictions: any[], status: string) => {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions ||
          predictions.length === 0
        ) {
          resolve(undefined);

          return;
        }

        const placeId = predictions[0].place_id;

        const placesService = new window.google.maps.places.PlacesService(
          document.createElement('div') // Dummy div required for PlacesService
        );

        // Step 2: Get Place Details
        placesService.getDetails(
          { placeId },
          (place: any, detailsStatus: string) => {
            if (
              detailsStatus !==
                window.google.maps.places.PlacesServiceStatus.OK ||
              !place
            ) {
              reject(new Error('Failed to fetch place details.'));

              return;
            }

            // Step 3: Parse Address Components
            const addressComponents = place.address_components;
            const formattedAddress: Record<string, string | number> = {
              fullAddress: place.formatted_address || '',
              address1: '',
              city: '',
              state: '',
              country: '',
              postal: '',
              latitude: place.geometry?.location?.lat() || 0,
              longitude: place.geometry?.location?.lng() || 0,
              stateCode: '',
              countryCode: '',
            };

            addressComponents.forEach((component: any) => {
              const types = component.types;

              if (types.includes('street_number')) {
                formattedAddress.address1 =
                  `${component.long_name} ${formattedAddress.address1}`.trim();
              } else if (types.includes('route')) {
                formattedAddress.address1 += ` ${component.long_name}`.trim();
              } else if (types.includes('locality')) {
                formattedAddress.city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                formattedAddress.state = component.long_name;
                formattedAddress.stateCode = component.short_name;
              } else if (types.includes('country')) {
                formattedAddress.country = component.long_name;
                formattedAddress.countryCode = component.short_name;
              } else if (types.includes('postal_code')) {
                formattedAddress.postal = component.long_name;
              }
            });

            resolve(formattedAddress);
          }
        );
      }
    );
  });

interface IProps {
  selectedEmail: any;
  setParams: any;
}

// eslint-disable-next-line max-lines-per-function
const CreateQuoteFromMailSection = ({ selectedEmail, setParams }: IProps) => {
  const navigate = useNavigate();
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const dimensionComponentRef = useRef<any>(null);
  const [dimensionsFormData, setDimensionsFormData] = useState<any[]>([]);

  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);

  const [formData, setFormData] = useState<IFormData>(initQuoteFormData);

  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');

  const [validForm, setValidForm] = useState(initValidQuoteForm);

  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);

  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');

  // loaders
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [isCreateQuoteLoading, setIsCreateQuoteLoading] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);

  // AI extraction obj
  const [quoteAndShipperExtractedData, setQuoteAndShipperExtractedData] =
    useState<any>(undefined);
  const [dimensionsExtractedData, setDimensionsExtractedData] =
    useState<any>(undefined);

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const mapAIExtractedDataForQuoteAndAddress = async (
    emailExtractedData: any,
    isRaw = false
  ) => {
    const extractedResponseObj: any = {
      classification: emailExtractedData?.service_type?.toLowerCase() || null,
      customerId: Number(emailExtractedData?.customer_id) || null,
      pickupDate: emailExtractedData?.pickup_date
        ? moment(emailExtractedData?.pickup_date).toDate()
        : emailExtractedData?.availability_date
        ? moment(emailExtractedData?.availability_date).toDate()
        : null,
      totalWeight: emailExtractedData?.total_weight,
      linearFootage: emailExtractedData?.linear_footage,
      consigneeAddress: {},
      shipperAddress: {},
    };

    if (emailExtractedData?.equipment_type?.length) {
      const equipmentTypeFullNames: string[] = [];
      const equipmentTypeId: string[] = [];

      emailExtractedData?.equipment_type?.forEach((eqType: any) => {
        const matchingEqTypeOption = equipmentTypeOptions.find(
          (eqTypeOption: any) => eqTypeOption?.label === eqType
        );

        if (matchingEqTypeOption) {
          equipmentTypeId.push(matchingEqTypeOption?.value);
          equipmentTypeFullNames.push(matchingEqTypeOption?.label);
        }
      });

      extractedResponseObj.equipmentTypeId = equipmentTypeId;
      extractedResponseObj.equipmentTypeFullNames = equipmentTypeFullNames;
    }

    if (emailExtractedData?.from) {
      try {
        const fromAddressResult = await getAddressDetails(
          `${emailExtractedData?.from?.address}, ${emailExtractedData?.from?.city}, ${emailExtractedData?.from?.state} ${emailExtractedData?.from?.zipcode}, ${emailExtractedData?.from?.country}`
        );

        if (fromAddressResult && fromAddressResult?.address1) {
          extractedResponseObj.shipperAddress = fromAddressResult;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    }

    if (emailExtractedData?.to) {
      try {
        const toAddressResult = await getAddressDetails(
          `${emailExtractedData?.to?.address}, ${emailExtractedData?.to?.city}, ${emailExtractedData?.to?.state} ${emailExtractedData?.to?.zipcode}, ${emailExtractedData?.to?.country}`
        );

        if (toAddressResult && toAddressResult?.address1) {
          extractedResponseObj.consigneeAddress = toAddressResult;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    }

    return isRaw ? extractedResponseObj : cleanObject(extractedResponseObj);
  };

  const mapAIExtractedDataForDimensions = (emailExtractedData: any) => {
    const extractedResponseObj: any = {
      dimensions: [],
      selectedServices:
        emailExtractedData?.additional_services?.map((str: string) =>
          parseInt(str, 10)
        ) || null,
    };

    emailExtractedData?.dimension?.forEach((dim: any) => {
      extractedResponseObj.dimensions.push({
        handlingUnit: dim?.parcel ? Number(dim?.parcel) : undefined,
        handlingUnitNo: dim?.no_of_parcels,
        weight: dim?.weight,
        itemLength: dim?.length,
        width: dim?.width,
        height: dim?.height,
        totalWeight: dim?.total_weight,
        weightMeasurement: dim?.weight_measure?.toUpperCase(),
      });
    });

    return extractedResponseObj;
  };

  useEffect(() => {
    console.log('quoteAndShipperExtractedData ', quoteAndShipperExtractedData);

    if (quoteAndShipperExtractedData?.pickupDate) {
      checkPickupDate(quoteAndShipperExtractedData?.pickupDate);
    }

    setShipperAddress(
      quoteAndShipperExtractedData?.shipperAddress?.fullAddress || ''
    );

    setConsigneeAddress(
      quoteAndShipperExtractedData?.consigneeAddress?.fullAddress || ''
    );

    setFormData({ ...initQuoteFormData, ...quoteAndShipperExtractedData });
  }, [quoteAndShipperExtractedData]);

  window.initMap = () => {
    setStatus(true);
  };

  const extractDetailsFromEmailExtractedDetails = async (
    selectedEmailExtractedData: any
  ) => {
    setQuoteAndShipperExtractedData(
      await mapAIExtractedDataForQuoteAndAddress(selectedEmailExtractedData)
    );

    setDimensionsExtractedData(
      mapAIExtractedDataForDimensions(selectedEmailExtractedData)
    );
  };

  useEffect(() => {
    if (selectedEmail?.extractDetails) {
      const selectedEmailExtractedData = JSON.parse(
        selectedEmail?.extractDetails
      );

      console.log('selectedEmailExtractedData ', selectedEmailExtractedData);
      extractDetailsFromEmailExtractedDetails(selectedEmailExtractedData);
    }

    // important : cleanup
    return () => {
      setFormData(initQuoteFormData);
      setValidForm(initValidQuoteForm);
      setDimensionsFormData([]);
      setShipperAddress('');
      setConsigneeAddress('');
      setQuoteAndShipperExtractedData(undefined);
      setDimensionsExtractedData(undefined);
    };
  }, [selectedEmail]);

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });

    if (formData.pickupDate !== '' || formData.pickupDate !== null) {
      checkPickupDate(formData.pickupDate);
    }

    getVehicleTypeOptions();
  }, []);

  useEffect(() => {
    if (!status) {
      document.getElementById(scriptId)?.remove();
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, [googleKey]);

  const getCustomer = () => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          result.data = result.data.filter((data: any) => !data.isDeleted);

          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          // setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, []);

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    if (name === 'isInsureLoad') {
      newData[name] = event?.checked;
    } else {
      newData[name] = event ? event.value : null;
    }

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setIsConsigneeBtnDisable(false);
        setConsigneeAddress(consignee.fullAddress);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
      }
    }
    setFormData((old) => ({ ...old, ...newData }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({ ...validForm, consigneeAddress: true });
      }

      if (name === 'shipperId') {
        setValidForm({ ...validForm, shipperAddress: true });
      }
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (companyName === '') {
      WalToast.error(`Please enter ${type}'s company name`, '');

      return false;
    }

    if (address.fullAddress === '' || typeof address !== 'object') {
      WalToast.error(`Please enter ${type}'s address`, '');

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setShipperAddress(value);
      setValidForm({ ...validForm, shipperAddress: true });
      setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setConsigneeAddress(value);
      setIsConsigneeBtnDisable(false);
      setValidForm({ ...validForm, consigneeAddress: true });
    }
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((old: any) => ({ ...old, [name]: value }));
  };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
      classification: true,
      urgency: true,
      totalWeight: true,
      insuranceCommercialValue: true,
      equipmentTypeId: true,
    };

    if (formData.classification === '' || formData.classification === null) {
      validFormData.classification = false;
      valid = false;
    } else if (
      formData?.classification === QUOTE_CLASSIFICATION.FTL ||
      formData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      if (
        formData.totalWeight === '' ||
        formData.totalWeight === null ||
        formData.totalWeight === undefined
      ) {
        validFormData.totalWeight = false;
        valid = false;
      }

      if (
        !formData.equipmentTypeId?.length ||
        formData.equipmentTypeId === '' ||
        formData.equipmentTypeId === null ||
        formData.equipmentTypeId === undefined
      ) {
        validFormData.equipmentTypeId = false;
        valid = false;
      }
    }

    if (
      formData.isInsureLoad &&
      (Number(formData.insuranceCommercialValue) === 0 ||
        formData.insuranceCommercialValue === '' ||
        formData.insuranceCommercialValue === null)
    ) {
      validFormData.insuranceCommercialValue = false;
      valid = false;
    }

    if (formData.urgency === '' || formData.urgency === null) {
      validFormData.urgency = false;
      valid = false;
    }

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    if (formData.shipperAddress.fullAddress === undefined) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperAddress.fullAddress === '' ||
      formData.shipperAddress.fullAddress === null
    ) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    if (formData.consigneeAddress.fullAddress === undefined) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeAddress.fullAddress === '' ||
      formData.consigneeAddress.fullAddress === null
    ) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      const tomorrow = moment().add(1, 'days');
      setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const handleSummarySubmit = async (quoteId: any) => {
    try {
      let quoteAiExtractedData: any = {};

      if (selectedEmail?.extractDetails) {
        const selectedEmailExtractedData = JSON.parse(
          selectedEmail?.extractDetails
        );
        console.log('selectedEmailExtractedData ', selectedEmailExtractedData);

        quoteAiExtractedData = await mapAIExtractedDataForQuoteAndAddress(
          selectedEmailExtractedData,
          true
        );

        const dimensionsAiData = mapAIExtractedDataForDimensions(
          selectedEmailExtractedData
        );

        quoteAiExtractedData.dimensions = dimensionsAiData.dimensions;
        quoteAiExtractedData.selectedServices =
          dimensionsAiData.selectedServices;
      }

      const summaryResponse = await createUpdateSummary({
        quoteId,
        quoteAiExtractedData: JSON.stringify(quoteAiExtractedData),
      });

      if (summaryResponse?.data?.data?.classification === 'other') {
        let redirectUrl = `${PATH.MY_QUOTE}`;
        navigate(redirectUrl);
      } else {
        let redirectUrl = `${PATH.MY_QUOTE}/${quoteId}`;
        navigate(redirectUrl);
      }
    } catch (e) {
      console.log('error : ', e);
    }
  };

  const handleDiscardEmail = async () => {
    setIsCreateQuoteLoading(true);
    removeFromQuote({ id: selectedEmail?.messageId }).then(() => {
      setIsCreateQuoteLoading(false);
      setParams((old: any) => ({
        ...old,
        page: 1,
      }));
    });
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();

    let dimensionValidationResponse = false;
    let quoteAndAddressDetailValidationResponse = isFormValid();

    if (dimensionComponentRef.current) {
      dimensionValidationResponse =
        dimensionComponentRef.current.checkIsDimensionFormValid();
    }

    if (
      formData.classification === QUOTE_CLASSIFICATION.LTL &&
      !dimensionValidationResponse
    ) {
      return true;
    }

    if (!quoteAndAddressDetailValidationResponse) {
      return true;
    }

    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,
      equipmentTypeId: formData.equipmentTypeId?.toString(),
      equipmentTypeFullNames: formData.equipmentTypeFullNames?.toString(),
      linearFootage: formData.linearFootage ?? undefined,
      totalWeight: formData.totalWeight ?? undefined,
      classification: formData.classification,
      urgency: formData.urgency,
      isFromQuoteRequest: true,
      emailContentId: selectedEmail?.emailBody?.id,
      insuranceCurrencyType: formData?.insuranceCurrencyType,
      insuranceCommercialValue: formData?.insuranceCommercialValue || undefined,
    };

    try {
      setIsCreateQuoteLoading(true);
      const createQuoteResponse = await createQuote(data);

      if (createQuoteResponse) {
        if (data?.classification === 'ltl') {
          await dimensionComponentRef.current.handleDimensionsSubmit(
            createQuoteResponse.data?.id
          );
        }
        await handleSummarySubmit(createQuoteResponse?.data?.id);
      }
    } catch (error: any) {
      setIsCreateQuoteLoading(false);
      const errMsg =
        error?.response?.status === 409
          ? error?.response?.data?.message
          : 'Something went wrong! Please try again';
      WalToast.error(errMsg, '');
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? formData.shipperId
          : formData.consigneeId;

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                }
                setFormData((old) => ({ ...old, ...newData, [name]: null }));
                getShipper();
                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  const handleSetTotalWeight = (totalWeight: string | number) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      totalWeight: totalWeight,
    }));
  };

  return (
    <>
      <div className="w-[393px] border-l border-utilityGray200 flex flex-col h-full">
        <div className="px-4 py-3.5 border-b border-utilityGray200">
          <h6 className="text-grayLight900 text-base font-medium">
            Create New Quote
          </h6>
          <p className="text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5">
            Fill out the quote request details below to access quoting tools.
          </p>
        </div>

        <ul className="flex-1 px-4 py-6 flex flex-col gap-y-4 overflow-y-auto custom-scrollbar-v2">
          {/* Quote main fields section */}
          <li>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
              <SelectBox
                name="serviceTypeGroup"
                id="serviceTypeGroup"
                placeholder="Select Service Type"
                className="form_control shadow"
                size="sm"
                options={serviceTypeArr}
                onChangeFunc={handleSelectChange('classification')}
                value={
                  serviceTypeArr.find(
                    (st) => st.value === formData?.classification
                  ) || null
                }
                label="Service Type"
                labelClassName="form_label block mb-1.5"
                parentClassName={`text-xs`}
                inlineSelect={true}
                errorText={!validForm.classification ? true : false}
                required
                isShowAiStar={
                  quoteAndShipperExtractedData?.classification &&
                  formData?.classification ===
                    quoteAndShipperExtractedData?.classification
                }
              />

              {formData?.classification !== 'other' ? (
                <>
                  <SelectBox
                    name="urgencyTypeGroup"
                    id="urgencyTypeGroup"
                    className="form_control shadow"
                    size="sm"
                    options={urgencyTypeArr}
                    inlineSelect={true}
                    onChangeFunc={handleSelectChange('urgency')}
                    value={
                      urgencyTypeArr.find(
                        (ut) => ut.value === formData?.urgency
                      ) || null
                    }
                    label="Urgency"
                    labelClassName="form_label block mb-1.5"
                    parentClassName={`text-xs`}
                    errorText={!validForm.urgency ? true : false}
                    required
                    isShowAiStar={true}
                  />

                  <SelectBox
                    className="form_control shadow"
                    labelClassName="form_label block mb-1.5"
                    parentClassName={`text-xs`}
                    isClearable
                    name="customerId"
                    placeholder="Select a customer"
                    required
                    label="Customer"
                    inlineSelect={true}
                    isSearchable={true}
                    isLoading={isCustomerLoading}
                    options={customerOptions}
                    value={
                      customerOptions.find(
                        (cusOptions: any) =>
                          cusOptions.value === formData.customerId
                      ) || null
                    }
                    onChangeFunc={handleSelectChange('customerId')}
                    errorText={!validForm.customerId ? true : false}
                    isShowAiStar={
                      formData?.customerId ===
                      quoteAndShipperExtractedData?.customerId
                    }
                  />

                  <div>
                    <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                      <div className="flex">
                        <label className="form_label mb-0">Pickup Date</label>
                        <span className="text-red-600 ms-[2px] leading-4 text-xs">
                          *
                        </span>
                      </div>
                      <div
                        className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                      >
                        {pickup1pmError && (
                          <>
                            <span className="text-xs text-primary font-normal flex items-center">
                              {tomorrowDayName === 'Saturday'
                                ? `Set to Monday`
                                : 'Set to Tomorrow'}
                            </span>
                            <a
                              onClick={() => selectNextDay(true)}
                              className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                            >
                              Yes
                            </a>
                            &nbsp;
                            {'/'}
                            <a
                              onClick={() => selectNextDay(false)}
                              className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                            >
                              No
                            </a>
                            <TooltipCmp
                              message={'Pickup is unlikely after 1pm.'}
                            >
                              <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                            </TooltipCmp>
                          </>
                        )}
                      </div>
                    </div>

                    <DateSelect
                      inputName="pickupDate"
                      className={`form_control ${
                        !validForm.pickupDate.valid
                          ? 'border border-red-500 mb-1 border-solid'
                          : ''
                      }`}
                      placeholder={DATE_FORMAT}
                      dateFormat="dd/MM/yyyy"
                      minDate={moment().toDate()}
                      selected={
                        formData.pickupDate
                          ? moment(formData.pickupDate).toDate()
                          : null
                      }
                      value={getDateWithSuffixFormat(
                        getFormattedPickupDate(formData.pickupDate)
                      )}
                      errorText={''}
                      onChangeFunc={handleDateChange}
                      icon={<Calendar className="h-5 w-5" />}
                      parentClassName={`w-full one-month-datepicker`}
                      isShowAiStar={
                        quoteAndShipperExtractedData?.pickupDate &&
                        moment(formData?.pickupDate).isSame(
                          quoteAndShipperExtractedData?.pickupDate,
                          'day'
                        )
                      }
                    />
                  </div>
                </>
              ) : (
                false
              )}
            </div>
          </li>

          {/* Quote shipper details section */}
          {formData?.classification !== 'other' ? (
            <li>
              <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
                Shipper Details
              </h6>
              <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
                <ShipperAddressSection
                  formData={formData}
                  shipperOptions={shipperOptions}
                  validForm={validForm}
                  handleSelectChange={handleSelectChange}
                  selectFormData={formData}
                  handleActionType={handleActionType}
                  saveShipperDetails={saveShipperDetails}
                  isShipperBtnDisable={isShipperBtnDisable}
                  handleAutocompleteChange={handleAutocompleteChange}
                  handleInputChange={handleInputChange}
                  shipperAddress={shipperAddress}
                  setShipperAddress={setShipperAddress}
                  isShipperLoading={isShipperLoading}
                  status={status}
                  isShowAiStarFullAddress={
                    !!shipperAddress &&
                    shipperAddress ===
                      quoteAndShipperExtractedData?.shipperAddress?.fullAddress
                  }
                />
              </div>
            </li>
          ) : (
            false
          )}

          {/* Quote consignee details section */}
          {formData?.classification !== 'other' ? (
            <li>
              <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
                Consignee Details
              </h6>
              <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
                <ConsigneeAddressSection
                  formData={formData}
                  consigneeOptions={consigneeOptions}
                  validForm={validForm}
                  handleSelectChange={handleSelectChange}
                  selectFormData={formData}
                  handleActionType={handleActionType}
                  saveShipperDetails={saveShipperDetails}
                  isConsigneeBtnDisable={isConsigneeBtnDisable}
                  handleAutocompleteChange={handleAutocompleteChange}
                  handleInputChange={handleInputChange}
                  consigneeAddress={consigneeAddress}
                  setConsigneeAddress={setConsigneeAddress}
                  isShipperLoading={isShipperLoading}
                  status={status}
                  isShowAiStarFullAddress={
                    !!consigneeAddress &&
                    consigneeAddress ===
                      quoteAndShipperExtractedData?.consigneeAddress
                        ?.fullAddress
                  }
                />
              </div>
            </li>
          ) : (
            false
          )}

          {/* Quote consignee details section */}
          {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
          formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
            <li>
              <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
                Load details
              </h6>
              <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
                <SelectBox
                  name="equipmentTypeId"
                  label="Equipment Type"
                  placeholder="Select Equipment Types"
                  labelClassName="form_label mb-1.5 block"
                  options={equipmentTypeOptions}
                  inlineSelect={true}
                  value={equipmentTypeOptions.filter((item) =>
                    formData.equipmentTypeId?.includes(item.value)
                  )}
                  isLoading={isEquipmentTypeLoading}
                  isMultipleSelection={true}
                  onChangeFunc={(event: any) => {
                    const newValue = event.map((e: any) => e.value);
                    const newLabelValue = event.map((e: any) => e.label);

                    setFormData((old) => ({
                      ...old,
                      equipmentTypeId: newValue,
                      equipmentTypeFullNames: newLabelValue,
                    }));

                    setValidForm({ ...validForm, equipmentTypeId: true });
                  }}
                  errorText={!validForm.equipmentTypeId ? true : false}
                  isShowAiStar={
                    !!formData?.equipmentTypeId?.length &&
                    !!quoteAndShipperExtractedData?.equipmentTypeId?.length &&
                    areStringArraysEqual(
                      formData?.equipmentTypeId ?? [],
                      quoteAndShipperExtractedData?.equipmentTypeId ?? []
                    )
                  }
                />

                <InputText
                  inputName="totalWeight"
                  inputType="number"
                  label="Total Weight (lbs)"
                  placeholder={'Enter total weight of packages'}
                  labelClassName="mb-1.5 block"
                  className={`!pr-20 ${
                    !validForm.totalWeight
                      ? 'border border-red-500 mb-0 border-solid '
                      : ''
                  }`}
                  value={formData.totalWeight}
                  required={true}
                  onChangeFunc={(e) =>
                    handleSelectChange('totalWeight')(e?.target)
                  }
                  disabled={
                    formData?.classification === QUOTE_CLASSIFICATION.LTL &&
                    !!dimensionsFormData?.length
                  }
                  isShowAiStar={
                    !!formData?.totalWeight &&
                    formData?.totalWeight ==
                      quoteAndShipperExtractedData?.totalWeight
                  }
                  shouldFormatNumber={true}
                />

                {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
                  <InputText
                    inputName="linearFootage"
                    inputType="number"
                    label="Linear Footage"
                    placeholder={'Enter linear footage'}
                    labelClassName="mb-1.5 block"
                    className={`!pr-20 `}
                    value={formData.linearFootage}
                    required={true}
                    onChangeFunc={(e) =>
                      handleSelectChange('linearFootage')(e?.target)
                    }
                    shouldFormatNumber={true}
                  />
                )}
              </div>
            </li>
          ) : (
            false
          )}

          {/* Quote consignee details section */}
          {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
            <DimensionSection
              ref={dimensionComponentRef}
              formData={dimensionsFormData}
              setFormData={setDimensionsFormData}
              setIsCreateQuoteLoading={setIsCreateQuoteLoading}
              handleSubmitCreateQuoteRequest={handleSubmit}
              dimensionsExtractedData={dimensionsExtractedData}
              handleSetTotalWeight={handleSetTotalWeight}
            />
          )}

          {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
          formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
            <li>
              <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
                <Toggle
                  isChecked={formData?.isInsureLoad}
                  labelPosition="left"
                  label="Would you like to insure this load?"
                  labelTextClassName="text-textSecondary text-sm"
                  labelClassName="justify-between"
                  onChange={(e: any) =>
                    handleSelectChange('isInsureLoad')(e?.target)
                  }
                />

                {formData?.isInsureLoad && (
                  <div className="w-full">
                    <label className="form_label mb-1.5 block">
                      Commercial Value
                      <span className="text-red-600 leading-4">*</span>
                    </label>
                    <div className="form_control p-0 relative !h-auto">
                      <InputText
                        inputName="insuranceCommercialValue"
                        inputType="number"
                        className={`!h-auto !pr-20 ${
                          !validForm.insuranceCommercialValue
                            ? 'border border-red-500 mb-0 border-solid '
                            : '!border-0'
                        }`}
                        placeholder={'Enter a commercial value'}
                        value={formData.insuranceCommercialValue}
                        required={true}
                        onChangeFunc={(e) =>
                          handleSelectChange('insuranceCommercialValue')(
                            e?.target
                          )
                        }
                        shouldFormatNumber={true}
                      />

                      <SelectBox
                        name="insuranceCurrencyType"
                        labelClassName="form_label block mb-1.5"
                        parentClassName="absolute right-0 top-0 w-[80px] dropdown-within-input"
                        id="insuranceCurrencyType"
                        classComp="rounded-none rounded-r-lg"
                        size="sm"
                        options={currencyTypeOptions}
                        onChangeFunc={handleSelectChange(
                          'insuranceCurrencyType'
                        )}
                        value={currencyTypeOptions.filter(
                          (val: any) =>
                            formData.insuranceCurrencyType === val.value
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </li>
          ) : (
            false
          )}
        </ul>

        <div className="px-4 py-3 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200">
          {formData?.classification === 'other' ? (
            <ButtonCmp
              icon={<Plus className="w-4 h-4" />}
              className="btn_primary w-full"
              onClick={handleDiscardEmail}
              loading={isCreateQuoteLoading}
              disabled={isCreateQuoteLoading}
            >
              Next
            </ButtonCmp>
          ) : (
            <ButtonCmp
              icon={<Plus className="w-4 h-4" />}
              className="btn_primary w-full"
              onClick={(e: any) => handleSubmit(e)}
              loading={isCreateQuoteLoading}
              disabled={isCreateQuoteLoading}
            >
              Create Quote
            </ButtonCmp>
          )}
        </div>
      </div>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? formData.consigneeCompanyName
              : formData.shipperCompanyName
          } From Wal HQ `}
        />
      )}
    </>
  );
};

export default CreateQuoteFromMailSection;
