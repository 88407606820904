import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';

const ShipperAndConsigneeAddressModal = ({
  control,
  errors,
  data,
  onAutocompleteChange,
  onAddressInputChange,
  title,
  // setAction,
  status,
  handleActionType,
  isAddressExits,
  isValidAddress,
  selectBoxOptionList,
  handleSelectChange,
}: any) => {
  const { mapService } = useContext(BasicContext);
  const { clearErrors, setError } = useFormContext();

  return (
    <div>
      <h6 className="text-textSecondary text-sm font-medium mb-1.5">{title}</h6>
      <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs p-4 mb-4 last:mb-0">
        <div className="flex flex-wrap sm:-m-1.5">
          <Controller
            name={`${data}.id`}
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <SelectBox
                label={`${title}`}
                labelClassName="form_label block mb-1.5"
                name={`${data}Id`}
                id={`${data}Id`}
                size="sm"
                placeholder="Select Additional Charge"
                noOptionMessage="No Additional Charge Found"
                isSearchable={true}
                className="form_control"
                parentClassName="flex-1 hidden"
                isClearable={true}
                options={selectBoxOptionList}
                onChangeFunc={handleSelectChange(`${data}Id`)}
                value={selectBoxOptionList?.find((e: any) => e?.value == value)}
                errorText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name={`${data}.companyName`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="companyName"
                label="Company Name"
                placeholder="Enter Company Name"
                value={value}
                labelClassName="block mb-1.5"
                parentClassName="sm:p-1.5 sm:w-1/2"
                onChangeFunc={onChange}
                errorText={errors?.[data]?.companyName?.message}
              />
            )}
          />

          {status && (
            <Controller
              name={`${data}.${data}Address.fullAddress`}
              control={control}
              render={({ fieldState: { error } }) =>
                mapService === 'trimble_map' ? (
                  <AutoCompleteTrimble
                    inputName={`fullAddress`}
                    required
                    placeholder="Enter address"
                    dropdownWrapClass="!top-[unset] bottom-[65%]"
                    className={`form_control truncate ${
                      !isValidAddress?.[`${data}Address`] || error?.message
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="mb-1.5 block w-full"
                    mapParentClass="sm:p-1.5 sm:w-1/2"
                    labelRight={
                      <button
                        type="button"
                        className="text-primary700 text-xs flex font-semibold gap-2.5 z-[5]"
                        onClick={() => {
                          handleActionType(
                            data,
                            isAddressExits ? 'addressUpdate' : 'addressCreate'
                          )();
                        }}
                      >
                        {isAddressExits ? 'Change' : 'Select'}
                        Address
                      </button>
                    }
                    onChangeFunc={onAutocompleteChange}
                    onBlur={onAutocompleteChange}
                    isClearable
                    onAddressInputChange={onAddressInputChange}
                    value={isAddressExits}
                    errorText={error ? error.message : null}
                  />
                ) : (
                  <AutoCompleteGoogle
                    inputName={`fullAddress`}
                    required
                    placeholder="Enter address"
                    dropdownWrapClass="!top-[unset] bottom-[65%]"
                    className={`form_control truncate ${
                      !isValidAddress?.[`${data}Address`] || error?.message
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="mb-1.5 block w-full"
                    mapParentClass="sm:p-1.5 sm:w-1/2"
                    labelRight={
                      <button
                        type="button"
                        className="text-primary700 text-xs flex font-semibold gap-2.5 z-[5]"
                        onClick={() => {
                          handleActionType(
                            data,
                            isAddressExits ? 'addressUpdate' : 'addressCreate'
                          )();
                        }}
                      >
                        Select Address
                      </button>
                    }
                    onChangeFunc={onAutocompleteChange}
                    onBlur={onAutocompleteChange}
                    isClearable
                    onAddressInputChange={onAddressInputChange}
                    value={isAddressExits}
                    errorText={error ? error.message : null}
                  />
                )
              }
            />
          )}

          <Controller
            name={`${data}.contactName`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="contact_name"
                label="Contact Name"
                placeholder="Enter Contact Name"
                labelClassName="block mb-1.5"
                parentClassName="sm:p-1.5 sm:w-1/3"
                value={value}
                onChangeFunc={onChange}
                errorText={errors?.[data]?.contactName?.message}
              />
            )}
          />

          <div className="sm:p-1.5 sm:w-1/3">
            <label className="form_label block mb-1.5">Phone number</label>
            <Controller
              name={`${data}.phoneNumber`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="phone"
                  onNumberChange={(
                    country: any,
                    phone: any,
                    code: any,
                    isValid: boolean
                  ) => {
                    onChange(`+${code}${phone}`);

                    if (isValid || phone === '') {
                      clearErrors(`${data}.phoneNumber`);
                    } else {
                      setError(`${data}.phoneNumber`, {
                        type: 'manual',
                        message: 'Contact phone number is invalid.',
                      });
                    }
                  }}
                  errors={errors?.[data]?.phoneNumber?.message}
                  value={value}
                />
              )}
            />
          </div>

          <Controller
            name={`${data}.email`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="email"
                label="Email"
                placeholder="Enter Email"
                value={value}
                labelClassName="block mb-1.5"
                parentClassName="sm:p-1.5 sm:w-1/3"
                onChangeFunc={onChange}
                errorText={errors?.[data]?.email?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipperAndConsigneeAddressModal;
