import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const dimensionsOrderDetailRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4">{data?.handlingUnitNo}</td>
    <td className="px-5 py-4">{data?.handlingUnit}</td>
    <td className="px-5 py-4">
      {data?.length} X {data?.width} X {data?.height}
    </td>
    <td className="px-5 py-4">-</td>
    <td className="px-5 py-4">
      {data?.totalWeight} ({data?.weightMeasurement})
    </td>
    <td className="px-5 py-4">{data?.refNumber ?? '-'}</td>
    <td className="px-5 py-4">{data?.description ?? '-'}</td>
  </>
);

export default dimensionsOrderDetailRow;
