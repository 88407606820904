import { CheckCircle, Stars02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';

interface IProps {
  handleClose: any;
  setAction?: any;
}

const AppointmentSuccessModal = ({ handleClose, setAction }: IProps) => (
  <>
    <CommonModal
      title={''}
      titleDesc={''}
      handleClose={handleClose}
      headerIcon={''}
      size={''}
      modalClassName="pb-20"
    >
      <NotFoundUI
        title="Appointment Set!"
        desc="Your carrier appointment was successfully set. Would you like to draft an email to notify your customer?"
        containerClassName="!my-0 flex-1 pt-32"
        contentClassName="before:!bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-[280px] before:bottom-12"
        iconClassName="!hidden"
        iconSecondaryClassName="!flex"
        iconSecondary={<CheckCircle className="w-7 h-7 text-primary" />}
        titleClassName="!text-lg"
        descClass="!text-sm !max-w-[361px] !p-0"
      />
      <div className="flex justify-center gap-x-3 mt-4">
        <ButtonCmp className="btn_secondary_black" onClick={handleClose}>
          Close
        </ButtonCmp>
        <ButtonCmp
          className="btn_primary"
          icon={<Stars02 className="w-4 h-4" />}
          onClick={() => {
            setAction((old: any) => ({
              ...old,
              mode: 'appointment-darft-modal',
            }));
          }}
        >
          Draft Email
        </ButtonCmp>
      </div>
    </CommonModal>
  </>
);

export default AppointmentSuccessModal;
