import { CalendarCheck01, XClose } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { CURRENCY } from 'src/constants/common';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

const FilterDrawer = ({
  teamListOptions,
  userList,
  handleClose,
  params,
  // rates,
  currency,
  // setCurrency,
  setParams,
  setFilterCount,
}: any) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [memberId, setMemberId] = useState(params?.member ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);

  // const filterArr: any = [
  //   {
  //     value: CURRENCY.CAD,
  //     name: 'CAD',
  //     tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
  //   },
  //   {
  //     value: CURRENCY.USD,
  //     name: 'USD',
  //     tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
  //   },
  // ];

  const handleSave = () => {
    let fCount = 0;

    if (
      uiState.currentBreakpoint === 'XLM' ||
      uiState.currentBreakpoint === '2XL'
    ) {
      if (currency !== CURRENCY.CAD) fCount += 1;
    } else {
      if (currency !== CURRENCY.CAD) fCount += 1;
      if (teamId !== 'all') fCount += 1;
      if (memberId !== 'all') fCount += 1;
    }
    // console.log('fCount :>> ', fCount);
    setFilterCount(fCount);
    setParams((old: any) => ({
      ...old,
      page: 1,
      segment: '',
      limit: 50,
      team: teamId,
      member: memberId,
    }));
    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  const optionArr = [
    { label: 'Option 5', value: 'Option 5' },
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  return (
    <>
      <li className="px-5 py-3.5 border-b border-utilityGray200 flex gap-x-1">
        <div className="flex-1">
          <h6 className="text-grayLight900 text-lg font-semibold">Filters</h6>
          <p className="text-grayLight600 text-sm font-normal">
            Please change as per your requirement.
          </p>
        </div>
        <XClose className="w-4 h-4 flex-none" />
      </li>
      <li className="p-5 flex flex-col gap-y-2.5 flex-1 overflow-y-auto custom-scrollbar-v2">
        <SelectBox
          label="Team"
          labelClassName="form_label mb-1.5 block"
          name="teamListGroup"
          id="teamListGroup"
          className="form_control"
          size="sm"
          placeholder="All Teams"
          noOptionMessage="No Teams Found"
          parentClassName=""
          isClearable={true}
          isSearchable={true}
          options={teamListOptions}
          onChangeFunc={(event: any) => {
            setTeamId(event?.value ?? 'all');
            setMemberId('all');
          }}
          value={
            teamId
              ? teamListOptions.filter((val: any) => teamId === val.value)
              : null
          }
        />
        <SelectBox
          label="Users"
          labelClassName="form_label mb-1.5 block"
          name="userListGroup"
          id="userListGroup"
          size="sm"
          placeholder="All Users"
          className="form_control"
          isClearable={true}
          isSearchable={true}
          options={teamMember}
          parentClassName=""
          noOptionMessage="No Users Found"
          onChangeFunc={(event: any) => setMemberId(event?.value ?? 'all')}
          value={
            memberId
              ? teamMember.filter((user: any) => memberId === user.value)
              : null
          }
        />
        <SelectBox
          label="Order Created"
          labelClassName="form_label mb-1.5 block"
          name="orderCreated"
          id="orderCreated"
          size="sm"
          placeholder="Order Created"
          className="form_control"
          isClearable={true}
          isSearchable={true}
          options={optionArr}
          parentClassName=""
          noOptionMessage="No Order Created"
          onChangeFunc={() => {}}
          value={'Option 5'}
        />
        <SelectBox
          label="Customer"
          labelClassName="form_label mb-1.5 block"
          name="customer"
          id="customer"
          size="sm"
          placeholder="Customer"
          className="form_control"
          isClearable={true}
          isSearchable={true}
          options={optionArr}
          parentClassName=""
          noOptionMessage="No Customer Found"
          onChangeFunc={() => {}}
          value={'Option 5'}
        />
        <SelectBox
          label="Shipper"
          labelClassName="form_label mb-1.5 block"
          name="shipper"
          id="shipper"
          size="sm"
          placeholder="Shipper"
          className="form_control"
          isClearable={true}
          isSearchable={true}
          options={optionArr}
          parentClassName=""
          noOptionMessage="No Shipper Found"
          onChangeFunc={() => {}}
          value={'Option 5'}
        />
        <SelectBox
          label="Consignee"
          labelClassName="form_label mb-1.5 block"
          name="consignee"
          id="consignee"
          size="sm"
          placeholder="Consignee"
          className="form_control"
          isClearable={true}
          isSearchable={true}
          options={optionArr}
          parentClassName=""
          noOptionMessage="No Consignee Found"
          onChangeFunc={() => {}}
          value={'Option 5'}
        />
        <DateSelect
          inputName="Due_Date"
          className="form_control"
          parentClassName="datepicker-w-auto one-month-datepicker "
          label="Pickup Date"
          // selected={}
          onChangeFunc={() => {}}
          // minDate={moment().toDate()}
          labelClassName="block"
          placeholder="Select Due Date"
          dateFormat="dd/MM/yyyy"
          // errorText={errors.dueDate?.message}
          icon={<CalendarCheck01 className="h-5 w-5" />}
          value={'value'}
        />
        <DateSelect
          inputName="Due_Date"
          className="form_control"
          parentClassName="datepicker-w-auto one-month-datepicker "
          label="Delivery Date"
          // selected={}
          onChangeFunc={() => {}}
          // minDate={moment().toDate()}
          labelClassName="block"
          placeholder="Select Due Date"
          dateFormat="dd/MM/yyyy"
          // errorText={errors.dueDate?.message}
          icon={<CalendarCheck01 className="h-5 w-5" />}
          value={'value'}
        />
      </li>
      <li className="p-5 flex gap-3 border-t border-utilityGray200">
        <ButtonCmp className="btn_secondary_black flex-1" onClick={handleClose}>
          Cancel
        </ButtonCmp>
        <ButtonCmp className="btn_primary flex-1" onClick={handleSave}>
          Save
        </ButtonCmp>
      </li>
    </>
  );
};

export default FilterDrawer;
