// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FilterLines,
  SearchSm,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
// import mapboxgl from 'mapbox-gl';
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  classOptions,
  scriptId,
} from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address.interface';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';

import AutoCompleteGoogle from '../AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from '../AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from '../ButtonCmp';
import InputText from '../InputText/InputText';
import SelectBox from '../SelectBox/SelectBox';

import MapFilterModal from './MapFilterModal';

const initValidForm = {
  from: true,
  to: true,
  linearFootage: true,
  equipmentId: true,
  classification: true,
};

const SearchBox = ({
  hasSearch,
  onSearch,
  setSearchResult,
  setHasSearched,
  isSearchLoading,
  onClear,
  searchResult,
  routeFilters,
  setRouteFilters,
  isDisplayCustomerSearch,
  setParams,
}: any) => {
  const { mapService, googleKey } = useContext(BasicContext);
  const [isDisplayClear, setIsDisplayClear] = useState(hasSearch);
  const filterRef: any = useRef(null);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  // const [from, setFrom] = useState('');
  // const [to, setTo] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [customerName, setCustomerName] = useState(null);
  const [toAddress, setToAddress] = useState('');
  const [radius, setRadius] = useState(1500);
  const [classification, setClassification] = useState('ltl');
  const [isDisplayFromSuggestion, setIsDisplayFromSuggestion] = useState(false);
  const [isDisplayToSuggestion, setIsDisplayToSuggestion] = useState(false);
  const [isShowMapFilterModal, setIsShowMapFilterModal] = useState(false);
  const [validForm, setValidForm] = useState(initValidForm);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      from: true,
      to: true,
    };

    if (searchResult?.from === '' || searchResult?.from === null) {
      validFormData.from = false;
      valid = false;
    }

    if (searchResult?.to === '' || searchResult?.to === null) {
      validFormData.to = false;
      valid = false;
    }
    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (isDisplayCustomerSearch) {
      setIsDisplayClear(true);
      setParams((old: any) => ({
        ...old,
        search: customerName,
      }));
    } else {
      if (isFormValid()) {
        setIsDisplayClear(true);

        setSearchResult((old: any) => ({
          ...old,
          fromAddressObj: fromAddress,
          toAddressObj: toAddress,
        }));
        onSearch(fromAddress, toAddress);
      }
    }
  };

  useEffect(() => {
    let timeOutId: any;
    let handleOutsideClick: any;
    let handleEscapePress: any;

    if (isShowMapFilterModal) {
      setTimeout(() => {
        handleOutsideClick = (event: any) => {
          if (
            isShowMapFilterModal &&
            filterRef.current &&
            !filterRef.current.contains(event.target)
          ) {
            setIsShowMapFilterModal(false);
          }
        };

        handleEscapePress = (event: any) => {
          if (isShowMapFilterModal && event.key === 'Escape') {
            setIsShowMapFilterModal(false);
          }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscapePress);
      }, 0);
    }

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowMapFilterModal]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    let suggestionBox: any = document.querySelector(`suggestion-box`);

    if (suggestionBox && !suggestionBox.contains(event.targte)) {
      setIsDisplayFromSuggestion(false);
      setIsDisplayToSuggestion(false);
    }
  };

  const clearSearch = () => {
    if (hasSearch) {
      setSearchResult((old: any) => ({
        ...old,
        from: '',
        to: '',
      }));
      setFromAddress('');
      setToAddress('');
      onClear();
    }

    if (isDisplayCustomerSearch) {
      setCustomerName(null);
      setSearchResult((old: any) => ({
        ...old,
        search: '',
      }));
      setIsDisplayClear(false);
    }
  };

  useEffect(() => {
    if (isDisplayFromSuggestion) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
  }, [isDisplayFromSuggestion, isDisplayToSuggestion]);

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    getVehicleTypeOptions();
    setSearchResult((old: any) => ({
      ...old,
      classification: 'ltl',
    }));
  }, []);

  // useEffect(() => {
  //   console.log('from :>> ', from, ' ', fromAddress, ' ', toAddress);
  //   setSearchResult((old: any) => ({
  //     ...old,
  //     from: from,
  //     to: to,
  //   }));
  // }, [from, to]);

  return (
    <div className="absolute top-[50px] w-full px-4">
      <form onSubmit={handleSubmit}>
        <div className="max-w-[1140px] mx-auto shadow-lg px-6 py-4 border border-borderPrimary rounded-2xl bg-white flex items-center relative [&>div:last-child]:pr-0 [&>div:first-child]:pl-0 [&>div]:px-4 [&>div:first-child]:border-l-0 [&>div]:border-l [&>div]:border-utilityGray200 ">
          {isDisplayCustomerSearch ? (
            <>
              <InputText
                parentClassName="flex-1 w-1/5"
                inputName="customerName"
                label="Search Customer"
                labelClassName="text-grayLight900 text-sm font-semibold mb-0.5 "
                placeholder={'Customer Name'}
                className={` !border-0 !outline-0 !p-0 !h-auto !leading-[20px]`}
                value={customerName ?? ''}
                required={true}
                onChangeFunc={(e) => setCustomerName(e?.target?.value)}
              />
              <div className="flex gap-x-4">
                {isDisplayClear ? (
                  <ButtonCmp
                    className="btn_primary rounded-full lg:!px-[9px] !px-2"
                    type="button"
                    onClick={clearSearch}
                    // loading={isSearchLoading}
                    // disabled={isSearchLoading}
                  >
                    <XClose className="w-4 h-4" />
                  </ButtonCmp>
                ) : (
                  <ButtonCmp
                    className="btn_primary rounded-full lg:!px-[9px] !px-2"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSearchLoading}
                    disabled={isSearchLoading}
                  >
                    <SearchSm className="w-4 h-4" />
                  </ButtonCmp>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex-1 relative w-1/5">
                <label className="text-grayLight900 text-sm font-semibold mb-0.5">
                  From {isDisplayCustomerSearch}
                </label>
                {status &&
                  (mapService === 'trimble_map' ? (
                    <AutoCompleteTrimble
                      inputName="from"
                      placeholder="Search Origin"
                      // dropdownWrapClass="!top-[unset] bottom-[65%]"
                      className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                        !validForm.from
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      onChangeFunc={(e: any) => {
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e?.fullAddress,
                        }));
                        setFromAddress(e);
                      }}
                      onBlur={(e: any) => {
                        // setFrom(e);
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e,
                        }));
                      }}
                      onAddressInputChange={(e: any) => {
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e,
                        }));
                      }}
                      errorText={
                        !validForm.from ? 'from address is required' : null
                      }
                      // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                      value={searchResult?.from}
                    />
                  ) : (
                    <AutoCompleteGoogle
                      inputName="from"
                      placeholder="Enter Origin"
                      className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                        !validForm.from
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      listClassName="mapit-address-list"
                      onChangeFunc={(e: any) => {
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e?.fullAddress,
                        }));
                        setFromAddress(e);
                      }}
                      onBlur={(e: any) => {
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e.target?.value,
                        }));
                      }}
                      onAddressInputChange={(e: any) => {
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          from: e ?? '',
                        }));
                        setFromAddress(e);
                      }}
                      errorText={
                        !validForm.from ? 'from address is required' : null
                      }
                      value={searchResult?.from}
                    />
                  ))}
              </div>
              <div className="flex-1 relative w-1/5">
                <label className="text-grayLight900 text-sm font-semibold mb-0.5">
                  To
                </label>
                {status &&
                  (mapService === 'trimble_map' ? (
                    <AutoCompleteTrimble
                      inputName="to"
                      placeholder="Search Destination"
                      // dropdownWrapClass="!top-[unset] bottom-[65%]"
                      className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0`}
                      onChangeFunc={(e: any) => {
                        setHasSearched(false);
                        // setTo(e?.fullAddress ?? '');
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e?.fullAddress ?? '',
                        }));
                        setToAddress(e);
                      }}
                      onBlur={(e: any) => {
                        // setTo(e);
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e,
                        }));
                      }}
                      onAddressInputChange={(e: any) => {
                        // setTo(e ?? '');
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e ?? '',
                        }));
                      }}
                      errorText={
                        !validForm.to ? 'to address is required' : null
                      }
                      // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                      value={searchResult?.to}
                    />
                  ) : (
                    <AutoCompleteGoogle
                      inputName="to"
                      placeholder="Enter Destination"
                      className={`placeholder:text-grayLight900 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                        !validForm.to
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      listClassName="mapit-address-list"
                      onChangeFunc={(e: any) => {
                        // setTo(e?.fullAddress ?? '');
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e?.fullAddress ?? '',
                        }));
                        setToAddress(e);
                      }}
                      onBlur={(e: any) => {
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e.target?.value ?? '',
                        }));
                      }}
                      onAddressInputChange={(e: any) => {
                        // setTo(e ?? '');
                        setHasSearched(false);
                        setSearchResult((old: any) => ({
                          ...old,
                          to: e ?? '',
                        }));
                        setToAddress(e);
                      }}
                      errorText={
                        !validForm.to ? 'to address is required' : null
                      }
                      value={searchResult?.to}
                    />
                  ))}
              </div>
              <SelectBox
                name="classification"
                required
                label="Service Type"
                classComp={`${
                  !validForm.to ? 'border border-red-500 border-solid' : ''
                }`}
                labelClassName="text-grayLight900 text-sm font-semibold mb-0.5"
                options={classOptions}
                value={classOptions.filter(
                  (val: any) => classification === val.value
                )}
                onChangeFunc={(e: any) => {
                  setClassification(e?.value);
                }}
                parentClassName=" single-select-x-scroll selectbox-without-border flex-1 w-1/5"
              />

              {classification === QUOTE_CLASSIFICATION.FTL ||
              classification === QUOTE_CLASSIFICATION.LTL ? (
                <SelectBox
                  parentClassName="selectbox-without-border flex-1 w-1/5"
                  isClearable
                  name="equipmentTypeId"
                  label="Equipment Type"
                  classComp={` ${
                    !validForm.to ? 'border border-red-500 border-solid' : ''
                  }`}
                  selectValueClass="text-xs"
                  labelClassName="text-grayLight900 text-sm font-semibold mb-0.5"
                  options={equipmentTypeOptions}
                  value={equipmentTypeOptions.filter((item) =>
                    searchResult?.equipmentTypeId?.includes(item.value)
                  )}
                  isLoading={isEquipmentTypeLoading}
                  isMultipleSelection={true}
                  onChangeFunc={(event: any) => {
                    const newValue = event.map((e: any) => e.value);
                    const newLabel = event.map((e: any) => e.label);
                    setSearchResult((old: any) => ({
                      ...old,
                      equipmentTypeId: newValue,
                      equipmentTypeFullNames: newLabel,
                    }));
                  }}
                />
              ) : (
                false
              )}

              {classification === QUOTE_CLASSIFICATION.LTL && (
                <InputText
                  parentClassName="flex-1 w-1/5"
                  inputName="linearFootage"
                  inputType="number"
                  label="Linear Footage"
                  labelClassName="text-grayLight900 text-sm font-semibold mb-0.5 "
                  placeholder={'Select linear footage'}
                  className={` !border-0 !outline-0 !p-0 !h-auto !leading-[20px] ${
                    !validForm.linearFootage
                      ? 'border border-red-500 mb-0 border-solid '
                      : ''
                  }`}
                  value={searchResult?.linearFootage ?? ''}
                  required={true}
                  onChangeFunc={(e) =>
                    // handleSelectChange('linearFootage')(e?.target)
                    setSearchResult((old: any) => ({
                      ...old,
                      linearFootage: e?.target?.value,
                    }))
                  }
                />
              )}
              <div className="flex gap-x-4">
                <ButtonCmp
                  className="btn_secondary_black rounded-full lg:!px-[9px] !px-2"
                  type="button"
                  onClick={() => setIsShowMapFilterModal(true)}
                  // loading={isSearchLoading}
                  // disabled={isSearchLoading}
                >
                  <FilterLines className="w-4 h-4" />
                </ButtonCmp>

                {isDisplayClear &&
                searchResult?.status === 'success' &&
                hasSearch ? (
                  <ButtonCmp
                    className="btn_primary rounded-full lg:!px-[9px] !px-2"
                    type="button"
                    onClick={clearSearch}
                    // loading={isSearchLoading}
                    // disabled={isSearchLoading}
                  >
                    <XClose className="w-4 h-4" />
                  </ButtonCmp>
                ) : (
                  <ButtonCmp
                    className="btn_primary rounded-full lg:!px-[9px] !px-2"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSearchLoading}
                    disabled={isSearchLoading}
                  >
                    <SearchSm className="w-4 h-4" />
                  </ButtonCmp>
                )}
              </div>
            </>
          )}

          {/* {isDisplayClear &&
            searchResult?.status === 'success' &&
            hasSearch ? (
              <ButtonCmp
                className="btn-primary-light rounded-full lg:!px-[9px] !px-2"
                type="button"
                onClick={clearSearch}
                // loading={isSearchLoading}
                // disabled={isSearchLoading}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            ) : (
              <ButtonCmp
                className="btn-primary-light rounded-full lg:!px-[9px] !px-2"
                type="submit"
                onClick={handleSubmit}
                loading={isSearchLoading}
                disabled={isSearchLoading}
              >
                <SearchSm className="w-4 h-4" />
              </ButtonCmp>
            )} */}
          {/* </div> */}
          {/* )} */}
        </div>
        <div className="flex items-center hidden">
          <InputText
            inputName="radius"
            inputType="number"
            value={radius}
            onChangeFunc={(e) =>
              setRadius(Math.max(1, parseInt(e.target.value)))
            }
            placeholder="Km"
            min="1"
          />
          {/* 
       
          <label htmlFor="radius" style={{ marginLeft: '5px', fontSize: '14px' }}>
            km
          </label> */}
        </div>
      </form>
      {isShowMapFilterModal ? (
        <MapFilterModal
          handleClose={() => setIsShowMapFilterModal(false)}
          routeFilters={routeFilters}
          setRouteFilters={setRouteFilters}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchBox;
