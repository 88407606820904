import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface IProps {
  data: {
    graph: {
      labels: string[];
      value: {
        hit: number[];
        requests: number[];
        orders: number[];
        quotes: number[];
      };
    };
  };
}

const TotalQuotesGraph = ({ data }: IProps) => {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    markers: {
      size: 0,
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      row: {
        colors: ['#f9f9f9', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#3538CD', '#444CE7', '#8098F9', '#A4BCFD'],
    yaxis: {
      min: 0,
      labels: {
        formatter: (val: number) => val.toString(),
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        stops: [0, 50, 90, 100],
      },
    },
    xaxis: {
      categories: data.graph.labels,
      tickPlacement: 'on',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: 'light',
      y: {
        formatter: (val: number, { seriesIndex, w }) => {
          const total = w.globals.series[seriesIndex].reduce(
            (acc: number, curr: number) => +acc + +curr,
            0
          );
          const percentage = ((+val / +total) * 100).toFixed(1);

          return `${val} (${percentage}%)`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
  };

  const series = [
    {
      name: 'Hits',
      data: data.graph.value.hit,
    },
    {
      name: 'Requests',
      data: data.graph.value.requests,
    },
    {
      name: 'Quotes',
      data: data.graph.value.quotes,
    },
    {
      name: 'Orders',
      data: data.graph.value.orders,
    },
  ];

  return (
    <div className="rounded-xl border border-utilityGray200 mt-4">
      <div className="px-4 py-6">
        <div className="w-full justify-between xls:items-center items-start flex sm:flex-row flex-col mb-2.5 xxl:gap-4 gap-2 relative xs:mt-0 mt-5">
          <div className=" text-grayLight900 sm:text-lg text-base font-semibold gap-2 xs:justify-between xs:items-center xl:pr-4 mdm:flex-none flex-1">
            <p className="flex xs:flex-row flex-col xs:items-center justify-between">
              Total Quotes
            </p>
            <p className="text-grayLight600 text-sm font-normal">
              View quote request history over time.
            </p>
          </div>
          <div className="mdm:justify-center justify-end items-center mdm:gap-5 gap-2 sms:order-none -order-1 flex mr-1 sms:w-auto w-full sm:relative absolute sm:top-0 sm:right-[unset] xs:top-1 right-0 -top-5">
            {series.map((item: any, index: number) => (
              <div
                key={index}
                className="rounded-[10px] justify-end items-center gap-2 flex"
              >
                <div className="w-2 h-2 rounded-[10px]" />
                <span
                  className="w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: options.colors
                      ? options.colors[index]
                      : '#000', // Fallback color if options.colors is undefined
                  }}
                ></span>
                <div className="text-textSecondary text-xs font-normal leading-none">
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalQuotesGraph;
