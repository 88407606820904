import { ArrowUp, ArrowDown } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
} from 'src/utils/CommonFunctions';

const UserCommissionReportRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 min-w-52">
      <div className="flex items-center gap-3">
        {data?.name == null || data?.name === '' ? (
          <div className="flex items-center">
            <div className="table-profile-empty-circle-xl rounded-md"></div>
            <div className="pl-2 max-w-32 text-xs font-medium">
              <p>Unassigned</p>
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-3">
            {data?.userImage ? (
              <img
                src={data?.userImageUrl + data?.userImage}
                className="table-profile-icn-circle-xl rounded-md"
                alt="User"
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data?.name}`)}
              </div>
            )}
            <h6 className="text-grayLight900 font-semibold truncate max-w-44">
              {data?.name}
            </h6>
          </div>
        )}
      </div>
    </td>
    <td className="px-5 py-4">
      <span
        dangerouslySetInnerHTML={{
          __html: data?.periodStartDate
            ? getDateWithSuffixFormat(
                getFormattedDate(
                  data?.periodStartDate,
                  `MMM Do YYYY`,
                  true,
                  false
                )
              )
            : '-',
        }}
      ></span>
      {' - '}
      <span
        dangerouslySetInnerHTML={{
          __html: data?.periodEndDate
            ? getDateWithSuffixFormat(
                getFormattedDate(
                  data?.periodEndDate,
                  `MMM Do YYYY`,
                  true,
                  false
                )
              )
            : '-',
        }}
      ></span>
      {/* <span>
        {getDateWithSuffixFormat(
          getFormattedDate(data?.periodStartDate, `MMM Do - YYYY`, true, false)
        )}
        -
        {getDateWithSuffixFormat(
          getFormattedDate(data?.periodEndDate, `MMM Do - YYYY`, true, false)
        )}
      </span> */}
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span>${getFormattedNumber(data?.totalMargin)}</span>
        {data?.marginPercentageDifference >= 0 ? (
          <BadgeCmp
            style="pill"
            type="success"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowUp className="w-3.5 h-3.5 -mt-0.5" />
            {data?.marginPercentageDifference}%
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="pill"
            type="error"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowDown className="w-3.5 h-3.5 -mt-0.5" />
            {data?.marginPercentageDifference}%
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span>
          {getFormattedNumber(
            parseFloat(data?.averageMarginPercentage).toFixed(2)
          )}
          %
        </span>
        {data?.avgMarginPercentageDifference >= 0 ? (
          <BadgeCmp
            style="pill"
            type="success"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowUp className="w-3.5 h-3.5 -mt-0.5" />
            {data?.avgMarginPercentageDifference}%
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="pill"
            type="error"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowDown className="w-3.5 h-3.5 -mt-0.5" />
            {data?.avgMarginPercentageDifference}%
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span>${parseFloat(data?.averageMargin).toFixed(2)}</span>
        {data?.avgMarginDifference >= 0 ? (
          <BadgeCmp
            style="pill"
            type="success"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowUp className="w-3.5 h-3.5 -mt-0.5" />
            {data?.avgMarginDifference}%
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="pill"
            type="error"
            badgeTextColor="!text-xs"
            mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
          >
            <ArrowDown className="w-3.5 h-3.5 -mt-0.5" />
            {data?.avgMarginDifference}%
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span>{data?.numberOfOrders}</span>
        <div className="flex items-center">
          {data?.avgMarginPercentageDifference >= 0 ? (
            <BadgeCmp
              style="pill"
              type="success"
              badgeTextColor="!text-xs"
              mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
            >
              <ArrowUp className="w-3.5 h-3.5 -mt-0.5" />
              {data?.avgMarginPercentageDifference}%
            </BadgeCmp>
          ) : (
            <BadgeCmp
              style="pill"
              type="error"
              badgeTextColor="!text-xs"
              mainClassName={`ml-1 bg-transparent border-0 !p-0 !shadow-none`}
            >
              <ArrowDown className="w-3.5 h-3.5 -mt-0.5" />
              {data?.avgMarginPercentageDifference}%
            </BadgeCmp>
          )}
        </div>
      </div>
    </td>
  </>
);

export default UserCommissionReportRow;
