import {
  Edit05,
  Trash01,
  Settings01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import {
  updateRateGathering,
  getLoadLinkPostLeads,
  deleteLoadLinkPosting,
} from 'src/services/LoadLinkService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import aiStar from '../../../../../assets/img/ai-gradient-star.svg';
import KateImage from '../../../../../assets/img/kate-image.svg';
import WalToast from '../../../../../utils/WalToast';

import CreateLoadPostingFrom from './CreateLoadPostingFrom';
import { IProps, ITransportLead, LoadBoardCellId } from './LoadBoard.interface';
import LoadBoardRateRow from './LoadBoardRateRow';
import LoadBoardRatesLoader from './LoadBoardRatesLoader';
import LoadBoardRow from './LoadBoardRow';
import LoadBoardRowLoader from './LoadBoardRowLoader';
import TruckFindingAi from './TruckFindingAi';

const initParams = {
  sortType: 'desc',
  sortField: LoadBoardCellId.Availability,
};

const PostToLoadBoard = ({
  activePostIndex,
  setActivePostIndex,
  loadLinkPosts,
  loadLinkPostingDetail,
  setLoadLinkPostingDetail,
  addressDetails,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  syncLiveData,
  setSyncLiveData,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  isCreateLoadPost,
  setIsCreateLoadPost,
  setIsRefresh,
  isRefresh,
  rateGatheringWithAi,
}: IProps) => {
  const [leadsData, setLeadsData] = useState<ITransportLead[]>([]);
  const [leadCount, setLeadCount] = useState<number>(0);
  const [rateCount, setRateCount] = useState<number>(0);
  const [inboundCount, setInboundCount] = useState<number>(0);
  const [outboundCount, setOutboundCount] = useState<number>(0);
  const [timerActiveIndex, setTimerActiveIndex] = useState<number>(0);
  const [truckFindingAiDetail, setTruckFindingAiDetail] = useState<any>({});
  const [callStats, setCallStats] = useState<any>([
    {
      key: 'inprogressInboundCalls',
      count: 0,
      text: 'Inbound Calls In Progress',
    },
    {
      key: 'rateCollectedInboundCalls',
      count: 0,
      text: 'Inbound Calls Rates Gathered',
    },
    {
      key: 'inprogressOutboundCalls',
      count: 0,
      text: 'Outbound Calls In Progress',
    },
    {
      key: 'rateCollectedOutboundCalls',
      count: 0,
      text: 'Outbound Calls Rates Gathered',
    },
  ]);

  const dispatch = useDispatch();
  const [isSyncingLiveData, setIsSyncingLiveData] = useState<boolean>(true);
  const [params, setParams] = useState<any>(initParams);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeLeadType, setActiveLeadType] = useState<string>('leads');
  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [filterByPostOptions, setFilterByPostOptions] = useState<any>([]);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);
  const [isRateUpdateLoading, setIsRateUpdateLoading] = useState(false);

  const setCallStatsResponse = (res: any) => {
    setCallStats((prevStats: any) =>
      prevStats.map((stat: any) => {
        let text = '';

        if (stat.key == 'inprogressInboundCalls') {
          text =
            res[stat.key] && res[stat.key] == 1
              ? 'Inbound Call In Progress'
              : 'Inbound Calls In Progress';
        } else if (stat.key == 'rateCollectedInboundCalls') {
          text =
            res[stat.key] && res[stat.key] == 1
              ? 'Inbound Call Rates Gathered'
              : 'Inbound Calls Rates Gathered';
        } else if (stat.key == 'inprogressOutboundCalls') {
          text =
            res[stat.key] && res[stat.key] == 1
              ? 'Outbound Call In Progress'
              : 'Outbound Calls In Progress';
        } else if (stat.key == 'rateCollectedOutboundCalls') {
          text =
            res[stat.key] && res[stat.key] == 1
              ? 'Outbound Call Rates Gathered'
              : 'Outbound Calls Rates Gathered';
        }

        return {
          ...stat,
          count: res[stat.key] ?? stat.count,
          text,
        };
      })
    );
  };

  const getLead = () => {
    if (loadLinkPostingDetail?.id) {
      getLoadLinkPostLeads(
        { ...params, isRefresh: isSyncingLiveData, activeLeadType },
        loadLinkPostingDetail.id
      )
        .then((res: any) => {
          setLeadsData(res.data);
          setLeadCount(res.leadCount);
          setRateCount(res.rateCount);
          setInboundCount(res.inboundCount);
          setOutboundCount(res.outboundCount);
          setCallStatsResponse(res);
        })
        .finally(() => {
          setIsSyncingLiveData(false);
          setIsLoading(false);
          setSyncLiveData(false);
        })
        .catch(() => {});
    } else {
      setIsSyncingLiveData(false);
      setIsLoading(false);
      setSyncLiveData(false);
    }
  };

  useEffect(() => {
    if (syncLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      setInboundCount(0);
      setOutboundCount(0);
      setIsLoading(true);
      setIsSyncingLiveData(syncLiveData);
      setIsUpdateLoadPost(false);
      setIsCreateLoadPost(false);
    }
  }, [syncLiveData]);

  useEffect(() => {
    setTruckFindingAiDetail(
      loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
    );
    const intervalId = setInterval(() => {
      setIsSyncingLiveData(true);
    }, 180000); // 3 minutes in milliseconds

    const rateTimer = setInterval(() => {
      setTimerActiveIndex((prevIndex) => {
        if (prevIndex === 3) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000);

    fetchJsFromCDN(
      'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
      ['io']
    )
      .then(([io]: any) => {
        io.sails.url = window.SERVER_URL;

        io.socket.on('connect', function socketConnected() {
          dispatch(setIsSocketConnected(true));
          dispatch(setSocketIo(io.socket));

          io.socket.get(
            `/subscribe/globalRoom`,
            function (data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
            }
          );
        });

        // Listen for transferWithoutPickup event
        io.socket.on(
          `lead_rate_${loadLinkPostingDetail.id}`,
          (response: any) => {
            console.log('response', response);

            if (activeLeadType == 'rates') {
              setLeadsData(response.rates);
            } else if (activeLeadType == 'inbound') {
              setLeadsData(response.inboundCalls);
            } else if (activeLeadType == 'outbound') {
              setLeadsData(response.outboundCalls);
            }

            setRateCount(response.rates.length);
            setInboundCount(response.inboundCalls.length);
            setOutboundCount(response.outboundCalls.length);
            setCallStatsResponse(response);
          }
        );

        return () => {
          io.socket.off(`lead_rate_${loadLinkPostingDetail.id}`);
        };
      })
      .catch((error) => {
        console.error('Failed to load Sails socket library:', error);
      });

    return () => {
      clearInterval(intervalId);
      clearInterval(rateTimer);
    };
  }, []);

  useEffect(() => {
    if (!isSyncingLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      setInboundCount(0);
      setOutboundCount(0);
      setIsLoading(true);
      getLead();
    }
  }, [params, isRefresh, activeLeadType, activePostIndex]);

  useEffect(() => {
    if (isSyncingLiveData) {
      getLead();
    }
  }, [isSyncingLiveData]);

  useEffect(() => {
    if (loadLinkPosts && loadLinkPosts.length > 0) {
      const selectBoxOptions = loadLinkPosts.map((loadLink: any) => ({
        value: loadLink.id,
        label: `${loadLink.refNumber}`,
        customLabel: `REF#: `,
        datetime: `${moment(loadLink.createdAt).format('MMM D, YYYY h:mm A')}`,
      }));

      setFilterByPostOptions(selectBoxOptions);
    }
  }, [loadLinkPosts]);

  const LoadBoardLeadsCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Origin,
        name: 'Origin',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFO,
        name: 'DFO',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Destination,
        name: 'Destination',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFD,
        name: 'DFD',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Comment,
        name: 'Comments',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Availability,
        name: 'Availability',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Posted,
        name: 'Posted',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const LoadBoardRatesCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: 'carrierRating',
        name: 'Rating',
        sortable: true,
      },
      {
        id: LoadBoardCellId.CallStatus,
        name: 'Call Status',
        sortable: true,
      },
      {
        id: LoadBoardCellId.OutreachMethod,
        name: 'Outreach Method',
        sortable: true,
      },
      {
        id: LoadBoardCellId.CarrierContactPerson,
        name: 'Contact Person',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Rate,
        name: 'Rate',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EstDeliveryDate,
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Availability,
        name: 'Availability',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Comment,
        name: 'Comments',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    loadLinkPostingDetail.truckFindingAiData = formDataPayload;
    const truckFindingAiData = formDataPayload.truckFindingAiData;
    truckFindingAiData.loadLinkPostingId = loadLinkPostingDetail.id;
    truckFindingAiData.acceptInboundCallDuration =
      truckFindingAiData.acceptInboundCallDuration
        ? truckFindingAiData.acceptInboundCallDuration.replace(/ minutes?$/, '')
        : null;
    truckFindingAiData.outgoingCallStartAfter =
      truckFindingAiData.outgoingCallStartAfter
        ? truckFindingAiData.outgoingCallStartAfter.replace(/ minutes?$/, '')
        : null;
    truckFindingAiData.totalRates = truckFindingAiData.totalRates
      ? truckFindingAiData.totalRates.replace(/ rates?$/, '')
      : null;
    truckFindingAiData.budgetCost = truckFindingAiData.budgetCost
      ? truckFindingAiData.budgetCost.replace(/[$,\s]/g, '')
      : null;

    console.log('truckFindingAiData', truckFindingAiData);
    setIsShowTruckFindingAiModal(false);
    setIsRateUpdateLoading(true);
    console.log('form submitted');
    updateRateGathering(truckFindingAiData)
      .then((response: any) => {
        WalToast.success(response?.message);
        console.log('loadLinkPostingDetail', loadLinkPostingDetail);
        console.log('response.data', response.data);

        setTruckFindingAiDetail(response.data);
      })
      .finally(() => {
        setIsRateUpdateLoading(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  const handleLoadPostingDelete = (status: boolean) => {
    if (status) {
      console.log('loadLinkPostingDetail', loadLinkPostingDetail);

      // deleteLoadLinkPosting
      setIsLoadLinkPostingDeleteLoading(true);
      deleteLoadLinkPosting(loadLinkPostingDetail?.id)
        .then(() => {
          WalToast.success(
            `Load link posting was removed from WALHQ & Load Link`
          );
          setLoadLinkPostingDetail(undefined);
          setIsRefresh(true);
        })
        .catch((e) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
    }
  };

  const leadClassificationArray = [
    {
      value: 'leads',
      name: (
        <div data-value="leads">
          Leads
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="leads"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {leadCount}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'rates',
      name: (
        <div data-value="rates">
          Rates
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="rates"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {rateCount}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'inbound',
      name: (
        <div data-value="inbound">
          Inbound
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="inboundcal"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {inboundCount}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'outbound',
      name: (
        <div data-value="outbound">
          Outbound
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="outbound"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {outboundCount}
          </BadgeCmp>
        </div>
      ),
    },
  ];

  return (
    <>
      {(isCreateLoadPost && !loadLinkPostingDetail) ||
      (isUpdateLoadPost && loadLinkPostingDetail) ? (
        <CreateLoadPostingFrom
          addressDetails={addressDetails}
          validatePostToLoadBoardForm={validatePostToLoadBoardForm}
          setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
          setIsPostCreateLoading={setIsPostCreateLoading}
          setIsRefresh={setIsRefresh}
          loadLinkPostingDetail={loadLinkPostingDetail}
          isUpdateLoadPost={isUpdateLoadPost}
          setIsUpdateLoadPost={setIsUpdateLoadPost}
          setIsCreateLoadPost={setIsCreateLoadPost}
          rateGatheringWithAi={rateGatheringWithAi}
        />
      ) : (
        <>
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left">
              <TabButton
                className="min-w-28"
                // activeClassName="!bg-utilityGray100"
                tabArray={leadClassificationArray}
                // parentClassName="table-tabs"
                isTab={true}
                isActive={activeLeadType}
                handleOnClick={(e: any) => {
                  setActiveLeadType(e?.target?.dataset?.value);
                }}
              />
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              ></div>
            </div>

            <div className="ai-gradient-border rounded-lg border border-borderPrimary flex gap-2 items-center p-[7px] w-80">
              <img
                src={KateImage}
                className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              <div className="flex items-center flex-1">
                <p className="text-grayLight600 text-sm font-semibold line-clamp-1">
                  {`${callStats[timerActiveIndex].count} ${callStats[timerActiveIndex].text}`}
                </p>
                <img src={aiStar} className="w-4 h-4 text-primary ml-1" />
              </div>
              <ButtonCmp
                className="btn-outline-primary !p-0"
                loading={isRateUpdateLoading}
                disabled={isRateUpdateLoading}
                onClick={() => {
                  setIsShowTruckFindingAiModal(true);
                }}
              >
                {!isRateUpdateLoading && (
                  <Settings01 className="w-4 h-4 text-textSecondary" />
                )}
              </ButtonCmp>
            </div>

            <div className="table-selectbox min-w-[215px]">
              <SelectBox
                className="form_control"
                size="md"
                placeholder="Filter by post"
                isClearable={false}
                isSearchable={false}
                options={filterByPostOptions}
                onChangeFunc={(event: any) => {
                  const index = filterByPostOptions.findIndex(
                    (option: any) => event?.value === option.value
                  );
                  setLoadLinkPostingDetail(loadLinkPosts[index]);
                  setActivePostIndex(index);
                }}
                value={filterByPostOptions.filter(
                  (option: any) =>
                    loadLinkPosts.length > 0 &&
                    loadLinkPosts[activePostIndex].id === option.value
                )}
              />
            </div>

            <ButtonCmp
              className="btn_secondary_black min-w-[67px]"
              onClick={() => setIsUpdateLoadPost(true)}
              disabled={isUpdateLoadPost}
            >
              <Edit05 className="w-4 h-4" />
            </ButtonCmp>
            <ButtonCmp
              className="btn_secondary_black min-w-[67px]"
              onClick={() => setIsShowLoadLinkPostingDeleteConfirmation(true)}
              disabled={isLoadLinkPostingDeleteLoading}
              loading={isLoadLinkPostingDeleteLoading}
            >
              <Trash01 className="w-4 h-4" />
            </ButtonCmp>
          </div>
          {/* <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            onClick={() => setSyncLiveData(true)}
            disabled={syncLiveData}
            loading={syncLiveData}
          >
            {!syncLiveData && <RefreshCcw04 className="w-4 h-4" />}
          </ButtonCmp> */}

          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={
                  activeLeadType == 'leads'
                    ? LoadBoardLeadsCells
                    : LoadBoardRatesCells
                }
                tableDataArr={leadsData}
                // TableLoaderRowCmp={LoadBoardRowLoader}
                TableLoaderRowCmp={
                  activeLeadType == 'leads'
                    ? LoadBoardRowLoader
                    : LoadBoardRatesLoader
                }
                TableRowCmp={
                  activeLeadType == 'leads' ? LoadBoardRow : LoadBoardRateRow
                }
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
              />
            </div>

            {!leadsData?.length && !isLoading && (
              <NotFoundUI
                title={`No ${
                  activeLeadType.charAt(0).toUpperCase() +
                  activeLeadType.slice(1)
                }${
                  activeLeadType == 'inbound' || activeLeadType == 'outbound'
                    ? ' Calls'
                    : ''
                } Found`}
                desc={`There are no ${activeLeadType}${
                  activeLeadType == 'inbound' || activeLeadType == 'outbound'
                    ? ' calls'
                    : ''
                } available for this load.`}
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>
        </>
      )}

      {isShowLoadLinkPostingDeleteConfirmation && (
        <CommonModal
          title="Remove Load Link Posting"
          titleDesc="This action will remove load link posting from WAL HQ and Load Link."
          handleClose={() =>
            isLoadLinkPostingDeleteLoading
              ? false
              : handleLoadPostingDelete(false)
          }
          secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleLoadPostingDelete(true)}
          primaryBtnLoading={isLoadLinkPostingDeleteLoading}
          primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {isShowTruckFindingAiModal && (
        <TruckFindingAi
          postCreatePayload={{}}
          truckFindingAiDetail={truckFindingAiDetail}
          rateGatheringWithAi={rateGatheringWithAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}
    </>
  );
};

export default PostToLoadBoard;
