import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import { orderList } from 'src/services/OrderService';

// import defaultImage from '../../assets/img/default-image.jpg';

import LeftSidebar from './LeftSidebar';
import OrderDetail from './OrderDetail';

const initParams = {
  search: '',
  sortType: 'asc',
  sortField: 'orderCreateTime',
  filter: 'booked',
  orderDate: moment().format('YYYY-MM-DD'),
};

const Radar = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [orders, setOrders] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [params, setParams] = useState(initParams);
  const [orderId, setOrderId] = useState<string>('');
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [OrderMarkersPin, SetOrderMarkersPin] = useState<any[]>();
  // const [mapLoaded, setMapLoaded] = useState(false);

  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        // setMapLoaded(true);
        const layers = mapRef.current?.getStyle()?.layers;
        const labelLayer = layers?.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        );

        if (labelLayer && mapRef.current) {
          mapRef.current.addLayer(
            {
              id: 'add-3d-buildings',
              source: 'composite',
              'source-layer': 'building',
              filter: ['==', 'extrude', 'true'],
              type: 'fill-extrusion',
              minzoom: 15,
              paint: {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height'],
                ],
                'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
            },
            labelLayer.id
          );
        }

        if (mapRef.current) {
          mapRef.current.addSource('points', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-77.03238901390978, 38.913188059745586],
                  },
                  properties: {
                    title: 'Mapbox DC',
                  },
                },
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-122.414, 37.776],
                  },
                  properties: {
                    title: 'Mapbox SF',
                  },
                },
              ],
            },
          });
        }
      });
    }
  }, []);

  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    console.log('geocodeAddress address', address);
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  const setOrderMarker = async () => {
    let orderMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < orders.length; i++) {
      const order: any = orders[i];
      console.log('customer', order, '  ', isDisplayDetail);

      let fullAddress = [
        order?.orderFromCity,
        order?.orderFromState,
        order?.orderFromPostal,
      ]
        .filter(Boolean)
        .join(', ');

      console.log('custAddress', fullAddress);

      const cityCoords = await geocodeAddress(fullAddress);

      if (!cityCoords || cityCoords.length < 2) {
        console.warn(`Could not find coordinates for address: ${fullAddress}`);
        continue; // Skip this order if coordinates are not found
      }

      // Create marker container
      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker';
      parentDiv.id = order?.id || '';

      // Create marker element
      const el = document.createElement('div');
      el.className = 'marker';
      el.id = order?.id || '';

      // Create customer image container
      const customerImageDiv = document.createElement('div');
      customerImageDiv.className = 'customer-image';
      customerImageDiv.id = order?.fullId || '';
      customerImageDiv.innerHTML = `
        <div class="bg-utilityGray100 rounded border border-utilityGray200 shadow-xs  mt-0.5 w-5 h-5 flex justify-center items-center">
          <svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  fill="none"
  viewBox="0 0 24 24"
  className="link_icon active_icon"
>
  <path
    fill="transparent"
    fillOpacity="0.01"
    d="M11.223 2.4318c.2836-.1576.4254-.2364.5756-.2673a1.0002 1.0002 0 0 1 .4029 0c.1501.031.2919.1097.5755.2673l7.4 4.111c.2996.1665.4493.2497.5584.368a1 1 0 0 1 .2141.364c.0505.1528.0505.324.0505.6667v8.1171c0 .3427 0 .514-.0505.6668a.999.999 0 0 1-.2141.3639c-.1091.1184-.2588.2016-.5584.368l-7.4 4.1111c-.2836.1576-.4254.2363-.5755.2672a.9977.9977 0 0 1-.4029 0c-.1502-.0309-.292-.1096-.5756-.2672l-7.4-4.1111c-.2995-.1664-.4493-.2496-.5584-.368a.999.999 0 0 1-.2141-.3639C3 16.5726 3 16.4013 3 16.0586v-8.117c0-.3427 0-.514.0505-.6668a1.0002 1.0002 0 0 1 .2141-.364c.109-.1183.2588-.2015.5584-.368l7.4-4.111Z"
  ></path>
  <path
    stroke="#444CE7"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M20.5 7.2778 12 12.0001m0 0L3.5 7.2778m8.5 4.7223v9.5m9-5.4415v-8.117c0-.3427 0-.514-.0505-.6668a1 1 0 0 0-.2141-.364c-.1091-.1183-.2588-.2015-.5584-.368l-7.4-4.111c-.2836-.1576-.4254-.2364-.5755-.2673a1.0002 1.0002 0 0 0-.4029 0c-.1502.031-.292.1097-.5756.2673l-7.4 4.111c-.2995.1665-.4493.2497-.5584.368a1.0002 1.0002 0 0 0-.2141.364C3 7.4276 3 7.5988 3 7.9415v8.1171c0 .3427 0 .514.0505.6668a.999.999 0 0 0 .2141.3639c.109.1184.2588.2016.5584.368l7.4 4.1111c.2836.1576.4254.2363.5756.2672a.9977.9977 0 0 0 .4029 0c.1501-.0309.2919-.1096.5755-.2672l7.4-4.1111c.2996-.1664.4493-.2496.5584-.368a.999.999 0 0 0 .2141-.3639C21 16.5726 21 16.4013 21 16.0586ZM16.5 9.5l-9-5"
  ></path>
</svg>
        </div>
      `;

      parentDiv.appendChild(customerImageDiv); // Append customer image to parent div

      // Create and configure the Mapbox marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', () => {
        console.log('Clicked marker:', order.id);
        setOrderId(order.id);
        setIsDisplayDetail(true);
      });

      orderMarkers.push(marker);

      // Center the map and adjust zoom after placing the last marker
      if (i === orders.length - 1) {
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(5);
      }
    }

    SetOrderMarkersPin(orderMarkers);
  };

  const removeCustomerMarkers = () => {
    console.log('remove', OrderMarkersPin);
    OrderMarkersPin?.forEach((marker: any) => {
      console.log('marker pin', marker);
      marker.remove();
    });
    SetOrderMarkersPin([]);
  };
  useEffect(() => {
    setOrderMarker();
  }, [orders]);

  useEffect(() => {
    console.log('orderId', orderId);
    let activeElement = document.getElementById(orderId);

    if (activeElement) {
      activeElement.className = 'active-order';
    }
  }, [orderId]);

  const getOrderList = () => {
    setIsOrdersLoading(true);
    setOrders([]);
    orderList({ ...params, ...{ isPagination: true } })
      .then((response: any) => {
        setOrders(response.data ?? []);
        setIsOrdersLoading(false);
        setOrderMarker();
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersLoading(false);
      });
  };

  useEffect(() => {
    removeCustomerMarkers();
    getOrderList();
  }, [params]);

  useEffect(() => {
    initializeMap();
  }, []);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Radar"
          desc="Effortlessly monitor your orders from pickup to delivery."
        />
      }
      contentClassName="h-[calc(100vh_-_97px)] !px-0 !pb-0"
    >
      <div className="w-full flex h-full">
        <div className="w-[540px] flex flex-col gap-y-4 [&>div]:pr-7 [&>div]:pl-5 px-px">
          {orderId ? (
            <OrderDetail setOrderId={setOrderId} />
          ) : (
            <LeftSidebar
              orders={orders}
              isOrdersLoading={isOrdersLoading}
              setParams={setParams}
              params={params}
              setOrderId={setOrderId}
            />
          )}
        </div>

        <div className=" h-full flex-1">
          <div
            id="map"
            ref={mapContainerRef}
            style={{ width: '100%', height: '100%' }}
          ></div>
        </div>
        <div className="absolute bottom-5 right-5 max-w-64 rounded-[10px] border border-borderPrimary bg-white shadow-lgc p-4 flex flex-col gap-y-1.5">
          <StatusDotProfileCmp
            label="Latest Location Update"
            dotColor="green"
          />
          <p className="text-textSecondary text-xs font-medium">
            8001 S Orange Blossom Trl, Orlando, FL 32809, USA
          </p>
          <p className="text-gray500 text-xs font-normal text-right">
            Today, 2:30 PM
          </p>
        </div>
      </div>
    </PageSectionLayout>
  );
};
export default Radar;
