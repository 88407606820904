import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '..';
import CarrierModal from '../../Common/Carrier/CarrierModal';
import SalesModal from '../../Common/Sales/SalesModal';

import carrierRow from './Carrier/carrierRow';
import salesPriceRow from './Sales/salesPriceRow';

const CarrierAndSalesPrice = () => {
  const { currentLegData, id, isCustomerLoading, customerList, order } =
    useContext<any>(OrderContext);

  const [isSaleFormOpen, setIsSaleFormOpen] = useState<boolean>(false);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [orderSalesPrice, setOrderSalesPrice] = useState<any>([]);
  const [orderCarrierPrice, setOrderCarrierPrice] = useState<any>([]);
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [salesEditData, setSalesEditData] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);

  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (currentLegData?.salesPrice) {
      setValue('salesPrice', currentLegData?.salesPrice);
      setOrderSalesPrice(currentLegData?.salesPrice);
    }

    if (currentLegData?.carrierPrice) {
      setOrderCarrierPrice(currentLegData?.carrierPrice);
      setValue('carrierPrice', currentLegData?.carrierPrice);
    }
  }, [currentLegData]);

  useEffect(() => {
    if (order?.customerId) {
      const cusotmerId = order?.customerId;
      setCustomer(cusotmerId);
    }

    if (order?.salesPrice) {
      setValue('salesPrice', order?.salesPrice);
      setOrderSalesPrice(order?.salesPrice);
    }
  }, [order]);

  const handleSalesClose = () => {
    setIsSaleFormOpen(false);
  };

  const handleCarrierClose = () => {
    setIsCarrierFormOpen(false);
    setCarrierEditData(null);
  };

  const handleAddSalesPrice = (newSalePrice: any) => {
    setOrderSalesPrice(() => [newSalePrice]);
    setValue('salesPrice', [newSalePrice]);
  };

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    setOrderCarrierPrice((prev: any[]) => {
      const existingIndex = prev.findIndex(
        (carrierPrice) => carrierPrice.uniqueKey === newCarrierPrice.uniqueKey
      );

      if (existingIndex !== -1) {
        const updatedCarrierPrices = [...prev];
        updatedCarrierPrices[existingIndex] = newCarrierPrice;

        return updatedCarrierPrices;
      }

      return [...prev, newCarrierPrice];
    });

    const existingCarrierPrices = getValues('carrierPrice') || [];
    const existingIndex = existingCarrierPrices.findIndex(
      (carrierPrice: any) =>
        carrierPrice.uniqueKey === newCarrierPrice.uniqueKey
    );

    if (existingIndex !== -1) {
      const updatedCarrierPrices = [...existingCarrierPrices];
      updatedCarrierPrices[existingIndex] = newCarrierPrice;
      setValue('carrierPrice', updatedCarrierPrices);
    } else {
      setValue('carrierPrice', [...existingCarrierPrices, newCarrierPrice]);
    }

    setIsCarrierFormOpen(false);
  };

  const salesHeadCells = useMemo(
    () => [
      {
        id: 'customer',
        name: 'Customer',
      },
      {
        id: 'baseSalePrice',
        name: 'Base Sale Price',
      },
      {
        id: 'Additionals',
        name: 'Additionals',
      },
      {
        id: 'totalSalePrice',
        name: 'Total Sale Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const carrierHeadCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
      },
      {
        id: 'carrierType',
        name: 'Carrier Type',
      },
      {
        id: 'quoteID',
        name: 'Quote ID',
      },
      {
        id: 'proNumber',
        name: 'PRO Number',
      },
      {
        id: 'totalCost',
        name: 'Total Cost',
      },
      {
        id: 'additionals',
        name: 'Additionals',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const handleCarrierEdit = (data: any) => {
    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleSalesEdit = (data: any) => {
    setSalesEditData(data);
    setIsSaleFormOpen(true);
  };

  return (
    <>
      <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
        <h5 className="text-grayLight900 text-xl font-semibold">
          Carrier & Sale Price
        </h5>

        {orderSalesPrice && orderSalesPrice?.length > 0 && (
          <>
            <h6 className="text-textSecondary text-xs font-medium">
              Total Sale Price
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={salesHeadCells}
                  tableDataArr={orderSalesPrice}
                  // TableLoaderRowCmp={CarriersListLoading}
                  TableRowCmp={salesPriceRow}
                  tableRowCmpProps={{ handleSalesEdit, customerList }}
                  numberOfSkeletonRows={1}
                />
              </div>
            </div>
          </>
        )}

        {orderCarrierPrice && orderCarrierPrice?.length > 0 && (
          <>
            <div className="flex justify-between items-end">
              <h6 className="text-textSecondary text-xs font-medium text-end">
                Total Cost
              </h6>
              {/* <ButtonCmp
                className="rounded-lg btn-outline-primary shadow p-2 flex justify-center items-center"
                icon={<Plus className="text-primary w-4 h-4" />}
                onClick={() => setIsCarrierFormOpen(true)}
              >
                Add Carrier
              </ButtonCmp> */}
            </div>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={carrierHeadCells}
                  tableDataArr={orderCarrierPrice}
                  TableRowCmp={carrierRow}
                  tableRowCmpProps={{
                    setIsCarrierFormOpen,
                    handleCarrierEdit,
                  }}
                  numberOfSkeletonRows={1}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-wrap sm:-m-1.5">
          {!(orderCarrierPrice && orderCarrierPrice?.length > 0) && (
            <div className="sm:p-1.5 sm:flex-1">
              <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                Total Cost
              </h6>
              <div
                className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer"
                onClick={() => setIsCarrierFormOpen(true)}
              >
                <Plus className="w-4 h-4 text-primary700" />
                <p className="text-grayLight900 text-sm font-medium">
                  Add a carrier
                </p>
              </div>
            </div>
          )}

          {!(orderSalesPrice && orderSalesPrice?.length > 0) && (
            <div className="sm:p-1.5 sm:flex-1">
              <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                Total Sale Price
              </h6>
              <div
                className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer"
                onClick={() => setIsSaleFormOpen(true)}
              >
                <Plus className="w-4 h-4 text-primary700" />
                <p className="text-grayLight900 text-sm font-medium">
                  Add Sale price
                </p>
              </div>
            </div>
          )}
        </div>
      </li>

      {isSaleFormOpen && (
        <SalesModal
          handleClose={handleSalesClose}
          handleAddSalesPrice={handleAddSalesPrice}
          orderSalesPrice={salesEditData}
          masterOrderId={id}
          customerList={customerList}
          isCustomerLoading={isCustomerLoading}
          customerId={customer}
        />
      )}

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={handleCarrierClose}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          customerId={order?.cusotmerId}
        />
      )}
    </>
  );
};

export default CarrierAndSalesPrice;
