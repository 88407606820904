import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listDispute = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.DISPUTES.LIST, {
    params,
    signal,
  });

  return response;
};

export const listDisputeCount = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.DISPUTES.LIST_COUNT, {
    params,
    signal,
  });

  return response;
};

export const updateDisputeStatus = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.DISPUTES.DISPUTE_UPDATE_STATUS,
    data
  );

  return response;
};

export const getDisputeData = async (id: number) => {
  const response = await axiosInterceptor.get(
    `${API.DISPUTES.DISPUTE_DETAILS}/${id}`
  );

  return response;
};

export const modifyDisputeAmount = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.DISPUTES.MODIFY_DISPUTE_AMOUNT,
    data
  );

  return response;
};
