import { AlertCircle, AlertHexagon } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';

import {
  dangerTypeList,
  initServiceValidForm,
  packagingGroupOptions,
} from './additionalServices.const';

const DangerousMoreModal = ({
  setServicesValidForm,
  setIsDangerousFormSubmitted,
  setUnitedNationsNumber,
  setGoodsClass,
  setDescription,
  setEmergencyContactName,
  setEmergencyContactNumber,
  setEmergencyContactExt,
  setIsDangerousMore,
  setIsDangerousMoreModalOpen,
  handleChangeRadio,
  dangerType,
  validServicesForm,
  isDangerousGoodsFormValid,
  setIsSubmitForm,
  unitedNationsNumber,
  setPackagingGroup,
  packagingGroup,
  goodsClass,
  description,
  emergencyContactName,
  handleDangerPhoneChange,
  isDangerousFormSubmitted,
  emergencyContactNumber,
  emergencyContactExt,
}: any) => (
  <CommonModal
    title={'Dangerous Goods'}
    titleDesc={'Please provide dangerous goods details'}
    handleClose={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="dangerous_goods"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsDangerousFormSubmitted(false);
      setUnitedNationsNumber('');
      setGoodsClass('');
      setDescription('');
      setEmergencyContactName('');
      setEmergencyContactNumber('');
      setEmergencyContactExt('');
      setIsDangerousMore(false);
      setIsDangerousMoreModalOpen(false);
    }}
    headerIcon={<AlertHexagon />}
    size={'max-w-[688px]'}
    secondaryBtnText="Cancel"
    primaryBtnText="Save Changes"
    primaryBtnOnClick={() => {
      setIsDangerousFormSubmitted(true);

      if (isDangerousGoodsFormValid()) {
        setIsDangerousMoreModalOpen(false);
        setIsSubmitForm(true);
      }
    }}
    secondaryBtnOnClick={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="dangerous_goods"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsDangerousFormSubmitted(false);
      setUnitedNationsNumber('');
      setGoodsClass('');
      setDescription('');
      setEmergencyContactName('');
      setEmergencyContactNumber('');
      setEmergencyContactExt('');
      setIsDangerousMore(false);
      setIsDangerousMoreModalOpen(false);
    }}
  >
    <div className="p-5 flex flex-col md:gap-5 gap-3">
      <p className="text-grayLight600 font-normal text-xs ">
        Please provide the Dangerous Goods details, as these details will show
        up on the BOL. Failure to enter this data may result in delayed pickups.
      </p>
      <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3 py-[12.5px] ">
        {dangerTypeList.map((val: any, i) => (
          <div className="flex gap-2" key={`danger_${i}`}>
            <Radio
              id={`danger_${i}`}
              onChangeFunc={handleChangeRadio}
              inputName={'dangerType'}
              value={val.value}
              checked={dangerType === val.value}
            />
            <label
              htmlFor={`danger_${i}`}
              className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
            >
              {val.name}
            </label>
          </div>
        ))}
      </div>
      <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 ">
        <InputText
          inputName="unitedNationsNumber"
          placeholder="UN0000"
          className={`form_control ${
            !validServicesForm.unitedNationsNumber
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          required={true}
          label="UN #"
          labelClassName="block mb-1.5"
          value={unitedNationsNumber}
          icon={
            !validServicesForm.inbondName ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
            ) : null
          }
          // errorText={!validServicesForm.unitedNationsNumber ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setUnitedNationsNumber(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />

        <div className="content">
          <SelectBox
            name="packagingGroup"
            required
            label="Packaging Group"
            labelClassName="form_label block mb-1.5"
            id="packagingGroup"
            className="form_control"
            options={packagingGroupOptions}
            onChangeFunc={(event: any) => setPackagingGroup(event.value)}
            value={packagingGroupOptions.find(
              (val) => val.value === packagingGroup
            )}
          />
        </div>

        <InputText
          inputType="number"
          inputName="goodsClass"
          placeholder=""
          className={`form_control ${
            !validServicesForm.goodsClass
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          label="Class"
          required={true}
          labelClassName="block mb-1.5"
          value={goodsClass}
          icon={
            !validServicesForm.goodsClass ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
            ) : null
          }
          // errorText={!validServicesForm.goodsClass ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setGoodsClass(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />
      </div>
      <div className="w-full">
        <InputText
          inputName="description"
          placeholder=""
          className={`form_control ${
            !validServicesForm.description
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          label="Technical Name or Description"
          required={true}
          labelClassName="block mb-1.5"
          value={description}
          icon={
            !validServicesForm.description ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
            ) : null
          }
          // errorText={!validServicesForm.description ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setDescription(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />
      </div>
      <div className="w-full ">
        <InputText
          inputName="emergencyContactName"
          placeholder=""
          className={`form_control ${
            !validServicesForm.emergencyContactName
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          label="24-hr Emergency Contact Name"
          required={true}
          labelClassName="block mb-1.5"
          icon={
            !validServicesForm.emergencyContactName ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
            ) : null
          }
          value={emergencyContactName}
          // errorText={!validServicesForm.emergencyContactName ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setEmergencyContactName(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />
      </div>
      <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
        <div className="md:flex flex-col">
          <label className="form_label ">Phone number*</label>
          <PhoneInput
            onNumberChange={handleDangerPhoneChange}
            name="phone"
            errors={
              isDangerousFormSubmitted &&
              !validServicesForm.emergencyContactNumber
                ? 'Phone number is required'
                : isDangerousFormSubmitted &&
                  !validServicesForm.isEmergencyPhoneValid
                ? 'Invalid Number'
                : null
              // !validServicesForm.isEmergencyPhoneValid
              //   ? 'Invalid Number'
              //   : !validServicesForm.emergencyContactNumber
              //   ? 'Phone number is required'
              //   : null
            }
            value={emergencyContactNumber || ''}
          />
        </div>
        <InputText
          inputName="emergencyContactExt"
          placeholder=""
          className="form_control"
          label="Ext"
          labelClassName="block mb-1.5"
          value={emergencyContactExt}
          onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
        />
      </div>
    </div>
  </CommonModal>
);

export default DangerousMoreModal;
