import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import DateSelect from 'src/components/DateSelect/DateSelect';
import TabButton from 'src/components/TabButton';
import { getCustomerHeatMapAnalytics } from 'src/services/CustomerService';

function generateDailyHeatmapData(dailyOrderData: any, setSeries: any) {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dailyData: any = {};

  function getWeekOfMonth(date: any) {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    return Math.ceil((date.getDate() + startOfMonth.getDay()) / 7);
  }

  // Aggregate daily data, skipping weekends
  dailyOrderData.forEach(({ order_date, daily_order_count }: any) => {
    const date = new Date(order_date);
    const dayOfWeek = date.getDay();
    // const weekOfMonth = Math.ceil(date.getDate() / 7); // Calculate week of the month
    const weekOfMonth = getWeekOfMonth(date);

    // Only include Monday (1) to Friday (5)
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      const monthName = months[date.getMonth()];
      const dayLabel = `Week ${weekOfMonth} ${
        days[dayOfWeek - 1]
      } of ${monthName}`;

      if (!dailyData[dayLabel]) {
        dailyData[dayLabel] = 0;
      }
      dailyData[dayLabel] += daily_order_count;
    }
  });

  // Prepare series data for heatmap
  const series = days.map((day) => ({
    name: day,
    data: months.flatMap((month) =>
      [1, 2, 3, 4, 5].map((week) => {
        const label = `Week ${week} ${day} of ${month}`;

        return {
          x: label,
          y: dailyData[label] || 0,
        };
      })
    ),
  }));

  setSeries(series);

  return series;
}

function generateDummySeries() {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dummyData = days.map((day) => ({
    name: day,
    data: months.flatMap((month) =>
      [1, 2, 3, 4, 5].map((week) => ({
        x: `Week ${week} ${day} of ${month}`,
        y: Math.floor(Math.random() * 10),
      }))
    ),
  }));

  return dummyData;
}

const mapFilterArr: any = [
  {
    value: 'OrderHeatMap',
    name: 'Order Heat Map',
    isAllow: true,
  },
  {
    value: 'QuoteHeatMap',
    name: 'Quote Heat Map',
    isAllow: true,
  },
];

const HeatMap = ({ customerId }: any) => {
  const dummySeries = generateDummySeries();

  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapActiveTab, setMapActiveTab] = useState('OrderHeatMap');
  const [selectedDate, setSelectedDate] = useState(moment().toDate());

  const getHeatMapData = (param: any) => {
    setIsLoading(true);
    getCustomerHeatMapAnalytics(param)
      .then((response) => {
        if (response.data) {
          generateDailyHeatmapData(response.data, setSeries);
        }
      })
      .catch((e: any) => {
        console.log('e', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const param = {
      customerId,
      type: mapActiveTab,
      timeFrame: moment(selectedDate).year(),
    };
    getHeatMapData(param);
  }, [mapActiveTab, selectedDate]);

  const options: any = {
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            { from: 0, to: 0, color: '#C7D7FE' },
            { from: 1, to: 10, color: '#8098F9' },
            { from: 11, to: 20, color: '#6172F3' },
            { from: 21, to: 30, color: '#444CE7' },
            { from: 31, to: 60, color: '#2D3282' },
          ],
        },
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
        formatter: (val: any) => {
          if (val.includes('Week 3')) {
            return val.split(' of ')[1];
          }

          return '';
        },
        style: {
          fontWeight: 'bold',
        },
      },
    },
  };

  return (
    <div className="rounded-xl border border-utilityGray200 mt-4">
      <div className="px-4 py-3 flex justify-between gap-x-2 border-b border-utilityGray200">
        <TabButton
          activeClassName="text-grayLight900 bg-utilityGray100"
          tabArray={mapFilterArr}
          handleOnClick={(e: any) => {
            setMapActiveTab(e.target.dataset.value);
          }}
          isActive={mapActiveTab}
        />

        <DateSelect
          inputName="current_year"
          selected={selectedDate}
          className="form_control"
          parentClassName=" react-year-picker-main"
          onChangeFunc={(e: any) => setSelectedDate(e.value)}
          labelClassName="block"
          showYearPicker
          placeholder="Select Year"
          dateFormat="yyyy"
          showIcon={false}
        />
      </div>
      <div className="px-4 py-6">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={isLoading ? dummySeries : series}
            type="heatmap"
            height={200}
            className={isLoading ? `custom-loading-heat-map` : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default HeatMap;
