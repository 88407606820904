import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const QuoteDetailSubRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
      <span className="text-grayLight900 font-medium">
        {data.handlingUnitNo}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[18%] max-w-[1px] w-[18%]">
      <p className="text-grayLight900 font-medium min-h-8 flex items-center">
        {data.handlingUnitName}
      </p>
    </td>
    <td className="px-5 py-4 min-w-[20%] max-w-[1px] w-[20%]">
      <span className="text-grayLight900 font-medium">
        {' '}
        {data.weight}
        {/* {data.weightMeasurement &&
          getLabelByValue(data.weightMeasurement)?.toLowerCase()} */}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="text-grayLight900 font-medium">
        {' '}
        {data.freightClass}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="text-grayLight900 font-medium">
        {' '}
        {data.itemClassForDayross}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="text-grayLight900 font-medium">
        {' '}
        {`${data.itemLength}L x ${data.width}W x ${data.height}`}H
      </span>
    </td>

    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="text-grayLight900 font-medium">{data.description}</span>
    </td>
    <td className="px-5 py-4 min-w-[65px] w-[65px] max-w-[65px]">
      <span className="text-grayLight900 font-medium"></span>
    </td>
  </>
);

export default QuoteDetailSubRow;
