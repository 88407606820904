import { ChevronUp, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const CostBreakdownlRow = ({ data, subDataVisibility }: any) => (
  <>
    <td className="px-5 py-4 ">
      {subDataVisibility[data.id] ? (
        <ChevronUp className="w-4 h-4 flex-none" />
      ) : (
        <ChevronDown className="w-4 h-4 flex-none" />
      )}
    </td>
    <td className="px-5 py-4 w-64 min-w-64 max-w-64">
      <div className="flex items-center min-w-92 ">
        {data?.image ? (
          <img
            src={`${data?.imageUrl}${data?.image}`}
            className="table-profile-icn-circle-sm "
          />
        ) : (
          <div className="table-profile-user-circle-sm ">
            {getShortName(data?.name)}
          </div>
        )}
        <div className="pl-2 ">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate ">
            {data?.name}
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className=""></span>
    </td>
    <td className="px-5 py-4">
      <span className="">
        {data?.carrierDiffCAD && `$${data?.carrierDiffCAD}`}
      </span>{' '}
      <span
        className={`${
          data?.carrierRatePercentageInCAD >= 0
            ? 'text-successSecondary'
            : 'text-danger'
        }`}
      >
        {data?.carrierRatePercentageInCAD &&
          `(${Math.abs(data?.carrierRatePercentageInCAD)}%)`}
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="">
        {data?.carrierDiffUSD && `$${data?.carrierDiffUSD}`}
      </span>{' '}
      <span
        className={`${
          data?.carrierRatePercentageInUSD >= 0
            ? 'text-successSecondary'
            : 'text-danger'
        }`}
      >
        {data?.carrierRatePercentageInUSD &&
          `(${Math.abs(data?.carrierRatePercentageInUSD)}%)`}
      </span>
    </td>
  </>
);

export default CostBreakdownlRow;
