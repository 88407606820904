import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js';

export const usePhone = () => {
  const setFormatPhone = (
    code: string,
    phone: string,
    regionCode: CountryCode
  ) => {
    try {
      if (phone) {
        const number = parsePhoneNumber(phone, regionCode);

        return number.isValid()
          ? number.format('NATIONAL', { nationalPrefix: false })
          : new AsYouType(regionCode).input(phone);
      }

      return new AsYouType(regionCode).input(phone);
    } catch {
      return new AsYouType(regionCode).input(phone);
    }
  };

  const isPhoneValid = (phone: any, regionCode: any): boolean => {
    try {
      const number = parsePhoneNumber(phone, regionCode);

      return number.isValid();
    } catch {
      return false;
    }
  };

  const isGetCountryCallingCode = (regionCode: any): any => {
    try {
      if (regionCode) {
        const countryCallingCode = getCountryCallingCode(regionCode);

        return `+${countryCallingCode}`;
      }
    } catch {
      return `+1`;
    }
  };

  const phoneInfo = (phone: string, regionCode: CountryCode) => {
    try {
      return parsePhoneNumber(phone, regionCode);
    } catch {
      return false;
    }
  };

  const getCustomFormatPhone = (phone: string, regionCode: CountryCode) => {
    try {
      const info: any = phoneInfo(phone, regionCode);
      const formattedPhoneNumber = setFormatPhone(
        info.countryCallingCode,
        info.nationalNumber,
        info.country
      );

      return `(+${info.countryCallingCode}) ${formattedPhoneNumber}`;
    } catch {
      return false;
    }
  };

  const getFormatPhone = (phone: string, regionCode: CountryCode) =>
    new AsYouType(regionCode).input(phone);

  return {
    setFormatPhone,
    getFormatPhone,
    isPhoneValid,
    phoneInfo,
    getCustomFormatPhone,
    isGetCountryCallingCode,
  };
};
