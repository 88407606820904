import { Move } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import mapboxgl, { SourceSpecification } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchBox from 'src/components/SearchBox';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { CustomerList } from 'src/services/CustomerService';
// import { getPopulationData } from 'src/services/GeographyService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetter,
  getSelectBoxOptions,
  getTime,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import defaultImage from '../../../assets/img/default-image.jpg';

import CustomerFilterModal from './CustomerFilterModal';
import FilterModal from './InTransit/FilterModal';

const initParams: any = {
  segment: [],
  team: 'all',
  member: 'all',
  search: '',
};

const initTransitParams: any = {
  startDate: '',
  endDate: '',
  team: 'all',
  member: 'all',
};

const MapBox = ({
  setCustomerId,
  customerId,
  searchResult,
  setHasSearched,
  setSearchResult,
  isDisplayCustomers,
  isDisplayDetail,
  setIsDisplayDetail,
  setIsCustomerLoading,
  mapFilters,
  setMapFilters,
  setAllRoutes,
  routeFilters,
  // allRoutes,
  hasSearched,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const { hasRoleV2 } = useRolePermission();
  const [filters, setFilters] = useState({
    max_height: 0,
    max_width: 0,
    max_weight: 0,
    exclude: [],
  });
  // const googleMapsApiKey = window?.GOOGLE_API_KEY;
  // const [fromLong, setFromLong] = useState(0);

  // const [fromLat, setFromLat] = useState(0);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [customers, setCustomers] = useState<any>();
  const [params, setParams] = useState(initParams);
  const [inTransitparams, setInTransitParams] = useState(initTransitParams);
  const [customerMarkerPin, setCustomerMarkerPin] = useState<any>([]);
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  // const [isCustomerLoading, setIsCustomerLoading] = useState(false);

  const handleClear = () => {
    if (abortController) {
      abortController.abort(); // Abort the ongoing requests
      setAbortController(null); // Reset the controller if needed
    }
  };

  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
        projection: 'mercator', // Change projection to flat
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        setMapLoaded(true);
        const layers = mapRef.current?.getStyle()?.layers;
        const labelLayer = layers?.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        );

        if (labelLayer && mapRef.current) {
          mapRef.current.addLayer(
            {
              id: 'add-3d-buildings',
              source: 'composite',
              'source-layer': 'building',
              filter: ['==', 'extrude', 'true'],
              type: 'fill-extrusion',
              minzoom: 15,
              paint: {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height'],
                ],
                'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
            },
            labelLayer.id
          );
        }

        if (mapRef.current) {
          mapRef.current.addSource('points', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-77.03238901390978, 38.913188059745586],
                  },
                  properties: {
                    title: 'Mapbox DC',
                  },
                },
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-122.414, 37.776],
                  },
                  properties: {
                    title: 'Mapbox SF',
                  },
                },
              ],
            },
          });
        }
      });
    }
  }, []);

  // Function to calculate distance between two points using Haversine formula
  // const getDistance = async (
  //   point1: [number, number],
  //   point2: [number, number],
  //   controller: any // Add the AbortController here
  // ): Promise<number> => {
  //   const [lon1, lat1] = point1;
  //   const [lon2, lat2] = point2;

  //   try {
  //     const query = await fetch(
  //       `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${lon1},${lat1};${lon2},${lat2}?access_token=${mapboxgl.accessToken}&geometries=geojson`,
  //       { signal: controller?.signal } // Pass signal here
  //     );

  //     const json = await query.json();

  //     // Extract the distance (in meters) from the response
  //     const distance = json?.routes?.[0]?.distance; // Distance in meters

  //     // Convert the distance to kilometers
  //     return distance ? distance / 1000 : 0;
  //   } catch (error: any) {
  //     if (error.name !== 'AbortError') {
  //       console.error('Error fetching distance data:', error);
  //     } else {
  //       console.log('Distance request aborted');
  //     }

  //     return 0;
  //   }
  // };

  // Function to get population from Google Places API using latitude and longitude
  // const getPopulation = async (lat: number, lng: number) => {
  //   try {
  //     const apiParams = { lat, lng };

  //     try {
  //       const population = await getPopulationData(apiParams)
  //         .then((response) => response?.data?.population || 0)
  //         .catch((error) => {
  //           console.error('Error fetching population data:', error);

  //           return 0; // Return 'Unknown' on error
  //         });

  //       return population;
  //     } catch (error) {
  //       console.error('Error in getPopulation function:', error);

  //       return 0;
  //     }
  //   } catch (error) {
  //     console.error('Error fetching population data:', error);

  //     return 'Unknown';
  //   }
  // };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  // function getCityPopulation(cityName: string) {
  //   const geoNamesUrl = `http://api.geonames.org/searchJSON?q=${encodeURIComponent(
  //     cityName
  //   )}&maxRows=1&username=bhuvabhavana`;

  //   return fetch(geoNamesUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const cityData = data.geonames[0];

  //       if (cityData) {
  //         return cityData.population;

  //         // return {
  //         //   city: cityName,
  //         //   distance: distance.toFixed(2),
  //         //   population: population,
  //         // };
  //       }

  //       return null;
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching population data:', error);

  //       return null;
  //     });
  //   // return 100;
  // }

  // Function to find cities along the route within a specified radius
  // const getCitiesAlongRoute = async ({
  //   route,
  //   radius,
  //   controller,
  // }: {
  //   route: [number, number][];
  //   radius: number;
  //   controller?: any; // Pass the AbortController here
  // }) => {
  //   const cities: any = [];
  //   const checkedCities = new Set();

  //   // const numSamples = Math.min(2000, route.length);
  //   // const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));
  //   let numSamples = 200;

  //   // if (route.length >= 500 && route.length <= 1000) {
  //   //   numSamples = 50;
  //   // } else if (route.length >= 1000 && route.length <= 10000) numSamples = 50;
  //   // else if (route.length >= 10000 && route.length <= 25000) numSamples = 150;
  //   // else if (route.length >= 25000 && route.length <= 50000) numSamples = 250;
  //   // else if (route.length >= 50000 && route.length <= 100000) numSamples = 500;
  //   // else if (route.length >= 100000 && route.length <= 1000000)
  //   //   numSamples = 1000;
  //   // else numSamples = 1500;

  //   const sampleInterval = numSamples;

  //   const addCity = (
  //     cityName: string,
  //     fullName: string,
  //     straightLineDistance: number,
  //     population: number,
  //     cityCoords: [number, number]
  //   ) => {
  //     const cityInfo = {
  //       name: cityName,
  //       fullName,
  //       distance: straightLineDistance,
  //       population: population !== null ? population : 'Unknown',
  //       coordinates: cityCoords,
  //     };
  //     cities.push(cityInfo);
  //     checkedCities.add(fullName);
  //   };

  //   for (let i = 0; i < route.length; i += sampleInterval) {
  //     const [lon, lat] = route[i];

  //     try {
  //       const query = await fetch(
  //         `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`,
  //         { signal: controller?.signal } // Pass signal here
  //       );
  //       const json = await query.json();

  //       if (json.features && json.features.length > 0) {
  //         for (const feature of json.features) {
  //           const cityName = feature.text;
  //           const fullName = feature.place_name || 'Unknown Place';
  //           const cityCoords = feature.center;

  //           if (fullName && !checkedCities.has(fullName)) {
  //             const straightLineDistance = await getDistance(
  //               route[0],
  //               cityCoords,
  //               abortController // Pass controller to getDistance
  //             );

  //             // console.log('population:: ', cityName, '  ', population);

  //             if (radius > 0) {
  //               if (straightLineDistance <= radius) {
  //                 const population = await getPopulation(lat, lon);
  //                 console.log('population:: ', population);

  //                 if (routeFilters?.population > 0) {
  //                   if (population <= routeFilters?.population) {
  //                     addCity(
  //                       cityName,
  //                       fullName,
  //                       straightLineDistance,
  //                       population,
  //                       cityCoords
  //                     );
  //                   }
  //                 } else {
  //                   addCity(
  //                     cityName,
  //                     fullName,
  //                     straightLineDistance,
  //                     population,
  //                     cityCoords
  //                   );
  //                 }
  //               }
  //             } else if (routeFilters?.population > 0) {
  //               const population = await getPopulation(lat, lon);

  //               if (population <= routeFilters?.population) {
  //                 addCity(
  //                   cityName,
  //                   fullName,
  //                   straightLineDistance,
  //                   population,
  //                   cityCoords
  //                 );
  //               }
  //             } else {
  //               console.log('else');
  //               const population = await getPopulation(lat, lon);
  //               addCity(
  //                 cityName,
  //                 fullName,
  //                 straightLineDistance,
  //                 population,
  //                 cityCoords
  //               );
  //             }
  //           }
  //         }
  //       }
  //     } catch (error: any) {
  //       if (error.name !== 'AbortError') {
  //         console.error('Error fetching place data:', error);
  //       } else {
  //         console.log('Request aborted');
  //       }
  //     }
  //   }
  //   console.log('cities', cities);
  //   const sortedCities = cities
  //     .sort((a: any, b: any) => (b.population || 0) - (a.population || 0))
  //     .slice(0, 10);

  //   return sortedCities;
  // };

  const setCustomerMarker = async () => {
    let customerMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      // console.log('customer', customer);
      // let custAddress = {
      //   address1: customer?.address1,
      //   city: customer?.city,
      //   postal: customer?.postal,
      //   state: customer?.state,
      //   countryCode: customer?.country,
      // };
      let fullAddress = [
        customer.address1,
        customer.city,
        customer.state,
        customer.postal,
        customer.countryCode,
      ]
        .filter(Boolean)
        .join(', ');

      // console.log('custAddress', fullAddress);

      const cityCoords = await geocodeAddress(fullAddress);
      const el = document.createElement('div');
      el.className = 'marker';

      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker'; // Assign a class for additional styling
      parentDiv.id = customer?.id;
      el.id = customer?.id;
      const csutomerImageDiv = document.createElement('div');
      csutomerImageDiv.className = 'customer-image'; // Assign a class for additional styling
      csutomerImageDiv.id = customer?.id;

      // Set marker image based on customer data
      if (customer.image) {
        el.style.backgroundImage = `url(${customer.imageUrl + customer.image})`;
      } else {
        el.style.backgroundImage = `url(${defaultImage})`;
      }
      csutomerImageDiv.appendChild(el); // Append the marker to the parent div
      parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div

      // Create marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // if (navigator.geolocation) {
      //   navigator.geolocation.watchPosition(
      //     function (position) {
      //       const lng = position.coords.longitude;
      //       const lat = position.coords.latitude;

      //       // Safely update marker's position
      //       marker.setLngLat([lng, lat]);

      //       // Center the map at the new location
      //       mapRef?.current?.setCenter([lng, lat]);
      //     },
      //     function (error) {
      //       console.error('Error watching position: ', error);
      //     },
      //     {
      //       enableHighAccuracy: true, // Enable high accuracy for better precision
      //     }
      //   );
      // } else {
      //   console.error('Geolocation is not supported by this browser.');
      // }

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;

        setCustomerId(elementId);
        if (!isDisplayDetail) setIsDisplayDetail(true);
      });

      // Add zoom out functionality to the zoom out button
      document
        .getElementById(`zoomOutButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          // Assuming you have a map object, apply zoom out
          if (mapRef?.current)
            mapRef?.current.setZoom(mapRef?.current.getZoom() + 2); // Adjust this method according to your map library
        });

      document
        .getElementById(`backButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          let ele = document.getElementById(`customer_${customer.id}`);

          if (ele) {
            ele.classList.add('d-none'); // Toggle the 'd-none' class to show/hide the card box
          }
        });

      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;
        setIsDisplayDetail(true);
        setCustomerId(elementId);
      });

      customerMarkers.push(marker);

      if (i >= customers.length - 1) {
        marker.setLngLat([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(5);
      }

      // Center the map at the new location
      // mapRef?.current?.flyTo({
      //   center: [cityCoords[0], cityCoords[1]],
      //   zoom: 14,
      // });
    }

    setCustomerMarkerPin(customerMarkers);
  };

  useEffect(() => {
    if (customers) {
      setCustomerMarker();
    }
  }, [customers]);

  useEffect(() => {
    if (
      searchResult &&
      searchResult?.from !== undefined &&
      searchResult?.to !== undefined &&
      searchResult?.status !== 'success' &&
      hasSearched
    ) {
      setHasSearched(false);
    }

    if (searchResult?.from === '' && searchResult?.to === '') {
      if (mapRef.current) {
        setHasSearched(false);
        setIsDisplayDetail(false);
        // setSearchResult(null); // Clear search result

        // Remove route layer and source if they exist
        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        // Reset map to default view
        mapRef.current.jumpTo({
          center: [-95, 55],
          zoom: 4,
          pitch: 0,
          bearing: 0,
          padding: { left: 0, right: 0, top: 0, bottom: 0 },
        });
      }
    }
  }, [searchResult?.from, searchResult?.to]);

  const removeCustomerMarkers = () => {
    customerMarkerPin.forEach((marker: any) => {
      // console.log('marker pin', marker);
      marker.remove();
    });
    setCustomerMarkerPin([]);
  };

  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: any, to: any, urlString: string = '') => {
      removeCustomerMarkers();
      localStorage?.removeItem('customerId');
      localStorage?.removeItem('mapFilters');

      if (!mapLoaded || !mapRef.current) {
        console.warn('Map not fully loaded yet');
        setSearchResult({
          status: 'error',
          error: 'Map not fully loaded yet. Please try again.',
        });

        return;
      }

      try {
        setHasSearched(true);
        setSearchResult({
          isBasicDetail: false,
          status: 'loading',
          message: 'Fetching route...',
        });

        const [fromLongitude, fromLatitude] = await geocodeAddress(
          from?.fullAddress
        );
        const [toLongitude, toLatitude] = await geocodeAddress(to?.fullAddress);
        // setFromLong(fromLongitude);
        // setFromLat(fromLatitude);

        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}${urlString}`,
          { method: 'GET' }
        );

        // `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiZXhhbXBsZXMiLCJhIjoiY20zc3d6ZXozMDJrcjJrb2JycmxsOW1vbiJ9.LY7szK0K0hnF0NE3WhVGZw
        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];
        const route = data.geometry.coordinates;

        setSearchResult({
          status: 'loading',
          message: 'Fetching routes...',
          isBasicDetail: true,
          from: from?.fullAddress,
          to: to?.fullAddress,
          fromAddressObj: from,
          toAddressObj: to,
          distance: Math.round(data.distance / 1000),
          duration: `${getTime(Math.round(data.duration))}`,
          cities: [],
          radius: routeFilters?.radius,
        });

        setAllRoutes(route);
        const geojson: SourceSpecification = {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        };

        mapRef.current?.addSource('route', geojson);
        mapRef.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#444CE7',
            'line-width': 3,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }
        let routeDistance = Math.round(data.distance / 1000);

        if (routeDistance > 1000 && routeDistance < 2200) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 5,
            linear: true,
          });
        } else if (routeDistance >= 2200 && routeDistance < 3500) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 3500 && routeDistance < 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 3.5,
            linear: true,
          });
        } else {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            // zoom: routeDistance > 3000 ? 3.5 : 4,
            linear: true,
          });
        }

        // const cities = await getCitiesAlongRoute({
        //   route,
        //   radius: routeFilters?.radius,
        // });

        setIsDisplayDetail(true);
        setSearchResult((old: any) => ({
          ...old,
          status: 'success',
          // cities: cities,
        }));
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded]
  );

  useEffect(() => {
    var filter = '';

    if (filters?.max_height > 0) {
      filter += `&max_height=${filters?.max_height}`;
    }

    if (filters?.max_weight > 0) {
      filter += `&max_weight=${filters?.max_weight}`;
    }

    if (filters?.max_width > 0) {
      filter += `&max_width=${filters?.max_width}`;
    }

    if (filters?.exclude?.length > 0) {
      filter += `&exclude=${filters?.exclude?.join(',')}`;
    }

    setTimeout(() => {
      handleSearch(searchResult?.from, searchResult?.to, filter);
      // handleSearch(searchResult?.from, searchResult?.to);
    });
  }, [filters]);

  const getCustomer = async (signal: any) => {
    setIsCustomerLoading(true);
    setIsCustomerLoading(true);
    handleClear();

    try {
      setHasSearched(false);
      setSearchResult(null);

      if (mapRef.current?.getLayer('route')) {
        mapRef.current.removeLayer('route');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current.removeSource('route');
      }
      const result = await CustomerList(params, signal);

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);

        if (filteredData?.length === 1) {
          setIsDisplayDetail(true);
          setCustomerId(filteredData?.[0]?.id);
        }
        setCustomers(filteredData);
        setIsCustomerLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsCustomerLoading(false);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;
    let custId = localStorage?.getItem('customerId');

    if (!custId) {
      setIsDisplayDetail(false);
      // setCustomerId(null);
    }

    if (isDisplayCustomers) {
      setHasSearched(false);
      removeCustomerMarkers();

      if (!custId || !customerId) {
        getCustomer(signal);
      }
    } else {
      // console.log('customer Id null');
      setCustomerId(null);
      removeCustomerMarkers();
    }

    return () => {
      controller.abort();
    };
  }, [isDisplayCustomers, params]);

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);

    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES)
      .map((keyValue: any) => {
        if (keyValue?.[1] !== 'test') {
          return {
            id: keyValue?.[1],
            value: capitalizeFirstLetter(keyValue?.[1]),
          };
        }

        return null; // Return null for 'test' values
      })
      .filter((item) => item !== null);
    setSegmentListOption(getSelectBoxOptions(statusArray, 'id', 'value'));
  }, []);

  // const getFilteredCity = async () => {
  //   console.log('getFiltersCity');
  //   setSearchResult((old: any) => ({
  //     ...old,
  //     message: 'Fetching Cities as per filter',
  //     status: 'loading',
  //   }));
  //   const controller = new AbortController();
  //   setAbortController(controller);

  //   const cities = await getCitiesAlongRoute({
  //     route: allRoutes,
  //     // fromLatLong: [fromLong, fromLat],
  //     radius: routeFilters.radius,
  //     controller: controller, // Pass the controller
  //   });
  //   console.log('getFiltersCity cities', cities);
  //   setSearchResult((old: any) => ({
  //     ...old,
  //     status: 'success',
  //     cities: cities,
  //   }));
  // };

  // useEffect(() => {
  //   if (hasSearched) getFilteredCity();
  // }, [routeFilters]);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );

      const unassignedSelectBoxOption = {
        value: 'unassigned',
        label: 'Unassigned',
        image: null,
        isImage: false,
      };

      if (hasRoleV2('admin')) {
        setUserList([unassignedSelectBoxOption, ...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  const setDefaultMap = () => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        // center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
      });
    }
  };

  return (
    <div className="relative h-full w-full geography-map">
      {/* {searchResult?.status === 'loading' ? (
        <div className="absolute top-0 left-0 h-full w-full z-[3] flex items-center justify-center ">
          <div className="relative z-[3]">
            <div className="w-[50px] h-[50px] rounded-full absolute border-[7px] border-dotted border-black border-opacity-10"></div>
            <div className="w-[50px] h-[50px] rounded-full animate-spin absolute border-[7px] border-dotted border-black border-t-transparent"></div>
          </div>
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-[2px]"></div>
        </div>
      ) : ( */}
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      {/* )} */}

      <SearchBox
        onSearch={handleSearch}
        onClear={handleClear}
        routeFilters={filters}
        setRouteFilters={setFilters}
        setHasSearched={setHasSearched}
        setParams={setParams}
        hasSearch={hasSearched}
        searchResult={searchResult}
        isDisplayCustomerSearch={mapFilters?.isDisplayCustomers}
        setSearchResult={setSearchResult}
        isSearchLoading={searchResult?.status === 'loading' ? true : false}
      />

      {mapFilters.filterType === 'customer_detail' && (
        <CustomerFilterModal
          handleClose={() =>
            setMapFilters((old: any) => ({
              ...old,
              filterType: '',
            }))
          }
          params={params}
          setParams={setParams}
          teamListOptions={teamListOptions}
          userList={userList}
          segmentListOption={segmentListOption}
        />
      )}

      {mapFilters.filterType === 'in_transit' && (
        <FilterModal
          handleClose={() =>
            setMapFilters((old: any) => ({
              ...old,
              filterType: '',
            }))
          }
          params={inTransitparams}
          setParams={setInTransitParams}
          teamListOptions={teamListOptions}
          userList={userList}
        />
      )}
      <div className="fixed p-[7px] z-[2] cursor-pointer rounded-md border top-[115px] right-2 bg-white shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1)]">
        <Move
          className="w-4 h-4 text-gray500 cursor-pointer"
          onClick={setDefaultMap}
        />
      </div>
    </div>
  );
};

export default MapBox;
