import { Edit01, CurrencyDollar } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import TimerBadge from 'src/components/TimerBadge';
import TooltipCmp from 'src/components/TooltipCmp';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { modifyQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
  onError,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const QuotingDashboardRow = ({
  onRowClick,
  data,
}: // setAction,
// setSingleQuote,
any) => {
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();

  const modifyQuoteStatus = (quoteStatus: any) => () => {
    modifyQuote({ quoteId: quoteStatus.id })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatus.id;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', quoteStatus?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          if (response.data?.classification === QUOTE_CLASSIFICATION.FTL) {
            navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          } else {
            navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  // const handleActionType =
  //   (keyAction: any, mode: any = null, quote: any = {}) =>
  //   () => {
  //     setSingleQuote(quote);
  //     setAction((old: any) => ({ ...old, [keyAction]: true, mode }));
  //   };

  return (
    <>
      <td
        className="px-5 py-4 min-w-44 w-44 max-w-44"
        onClick={onRowClick(data.id)}
      >
        <div className="flex items-center">
          {data?.urgency ? (
            data?.urgency === 'live' ? (
              <TooltipCmp message={data?.urgency} parentClassName="capitalize">
                <p className="w-3 h-3 bg-successSecondary rounded-full border-[3px] border-utilitySuccess100"></p>
              </TooltipCmp>
            ) : data?.urgency === 'rfq' ? (
              <TooltipCmp message={data?.urgency} parentClassName="uppercase">
                <p className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100"></p>
              </TooltipCmp>
            ) : (
              <TooltipCmp message={data?.urgency} parentClassName="uppercase">
                <p className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100"></p>
              </TooltipCmp>
            )
          ) : (
            <p className="w-3 h-3"></p>
          )}

          {data.image ? (
            <>
              <img
                className="table-profile-icn-circle-xl ml-3"
                src={data.imageUrl + data.image}
                alt={data.firstName}
                title={data.firstName}
                onError={onError}
              />
            </>
          ) : (
            <div className="table-profile-user-circle-xl ml-3">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
          <div className="ml-3 text-xs font-medium max-w-32">
            <div
              data-test-id="wal-QuotingDashboard-fullName"
              className="text-grayLight900 font-medium leading-tight truncate"
            >{`${data.fullName}`}</div>
            <div
              data-test-id="wal-QuotingDashboard-code"
              className="text-grayLight600 font-normal leading-tight truncate"
            >
              #{data.code}
            </div>
          </div>
        </div>
      </td>
      <td
        className="px-5 py-4 min-w-[100px] max-w-[100px] w-[100px]"
        onClick={onRowClick(data.id)}
      >
        {data.classification === 'ltl' && (
          <BadgeCmp style="modern" type="success">
            {data?.classification?.toUpperCase()}
          </BadgeCmp>
        )}
        {data.classification === 'ftl' && (
          <BadgeCmp style="modern" type="lightblue">
            {data?.classification?.toUpperCase()}
          </BadgeCmp>
        )}
      </td>
      <td
        data-test-id="wal-QuotingDashboard-customerName"
        className="px-5 py-4 max-w-40 min-w-40 w-40"
        onClick={onRowClick(data.id)}
      >
        <TooltipCmp
          message={data.customerName.length > 24 ? data.customerName : null}
          parentClassName=""
        >
          <p className="truncate">{data.customerName}</p>
        </TooltipCmp>
      </td>
      {/* <td
        className="px-5 py-4 xxl:max-w-48 xxl:w-48 xxl:min-w-48 w-40 max-w-40 min-w-40 "
        onClick={onRowClick(data.id)}
      >
        <TooltipCmp
          message={data.shipperFullAddress}
          type="dark"
          parentClassName=""
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              {data.shipperFullAddress}
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          message={data.consigneeFullAddress}
          type="dark"
          parentClassName="max-w-90"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              {data.consigneeFullAddress}
            </span>
          </p>
        </TooltipCmp>
      </td> */}
      <td
        data-test-id="wal-QuotingDashboard-createdAt"
        className="px-5 py-4 min-w-36 w-36 max-w-36"
        onClick={onRowClick(data.id)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.createdAt,
                    `MMM Do , hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      </td>

      <td
        data-test-id="wal-QuotingDashboard-createdAt"
        className="px-3 py-4 min-w-36 w-36 max-w-36"
        onClick={onRowClick(data.id)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.pickupDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(data.pickupDate, `MMMM Do, YYYY`)
                )
              : '-',
          }}
        />
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalUnits"
        className="pl-3 pr-1 py-4 min-w-28 w-28 max-w-28"
        onClick={onRowClick(data.id)}
      >
        {data?.classification === 'ltl' ? (
          <span className="">
            {data.totalUnits}{' '}
            {data.handlingUnitTypes?.length
              ? data.handlingUnitTypes[0]
              : 'Unit'}
          </span>
        ) : (
          <TooltipCmp message={data.equipmentTypeFullNames}>
            <span className="block_ellipsis ">
              {data.equipmentTypeFullNames}
            </span>
          </TooltipCmp>
        )}
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalWeight"
        className="px-3 py-4 min-w-28 w-28 max-w-28"
        onClick={onRowClick(data.id)}
      >
        {data.totalWeight && (
          <span className="">{`${data.totalWeight}  ${
            data.weightMeasurement
              ? data.weightMeasurement?.toLowerCase()
              : 'lbs'
          }`}</span>
        )}
      </td>

      <td
        className={`px-5 py-4 min-w-28 w-28 max-w-28`}
        onClick={onRowClick(data.id)}
      >
        <TimerBadge badgeDate={data.createdAt} />
      </td>

      {hasRoleV2('admin') && (
        <td className="px-5 py-4 pl-0 w-[84px] max-w-[84px] min-w-[84px]">
          {/* NEED TO ADD CONDITION AND DYNAMIC BELOW HIDDEN CODE WILL BE USED WHEN TO-QUOTE TAB IS SELECTED */}
          <div className="justify-center items-center gap-2 flex hidden">
            <TooltipCmp message="Add Quick Quote" type="dark">
              <CurrencyDollar
                className="mt-0.5 w-4 h-4 relative cursor-pointer"
                onClick={modifyQuoteStatus(data)}
              />
            </TooltipCmp>
            {/* {hasPermissionV2(PERMISSION.DELETE_SMART_QUOTE) && (
              <TooltipCmp message="No Quote" type="dark">
                <XClose
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('quote', 'delete', data)}
                />
              </TooltipCmp>
            )} */}
          </div>
          <div className="justify-center items-center gap-2 flex ">
            <TooltipCmp message="Edit" type="dark">
              <Edit01
                className="mt-0.5 w-4 h-4 relative cursor-pointer"
                onClick={modifyQuoteStatus(data)}
              />
            </TooltipCmp>
            {/* {hasPermissionV2(PERMISSION.DELETE_SMART_QUOTE) && (
              <TooltipCmp message="Delete" type="dark">
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('quote', 'delete', data)}
                />
              </TooltipCmp>
            )} */}
          </div>
        </td>
      )}
    </>
  );
};

export default QuotingDashboardRow;
