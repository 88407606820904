import React, { useContext, useState } from 'react';

import { OrderContext } from '..';

import LegOrderDetailsModal from './LegOrderDetailsModal';
import SetMiddlePointModal from './SetMiddlePointModal';
import SplitLegModal from './SplitLegModal';

interface SplitLegDetailsProps {
  isShowSplitLegModal: boolean;
  setIsShowSplitLegModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SplitLegDetails: React.FC<SplitLegDetailsProps> = ({
  isShowSplitLegModal,
  setIsShowSplitLegModal,
}: SplitLegDetailsProps) => {
  const { currentLegData, legData, handlingUnitOptions, serviceTypes } =
    useContext<any>(OrderContext);

  const [splitLegData, setSplitLegData] = useState(null);
  const [isShowSetMiddlePointModal, setIsShowSetMiddlePointModal] =
    useState(false);
  const [isShowLegOrderDetailModal, setIsShowLegOrderDetailModal] =
    useState(false);

  const combinedOrderDimensions = [
    ...(currentLegData?.order_dimensions || []).map((dimension: any) => ({
      ...dimension,
      shipper: currentLegData?.shipper?.shipperAddress || null,
      consignee: currentLegData?.consignee?.consigneeAddress || null,
      legId: currentLegData?.orderId || null,
    })),
    ...legData.flatMap((leg: any) =>
      (leg?.order_dimensions || []).map((dimension: any) => ({
        ...dimension,
        shipper: leg?.shipper?.shipperAddress || null,
        consignee: leg?.consignee?.consigneeAddress || null,
        legId: leg?.orderId || null,
      }))
    ),
  ];

  const handleSubmit = () => {
    setIsShowSplitLegModal(false);
    setIsShowSetMiddlePointModal(true);
  };

  return (
    <>
      {isShowSplitLegModal && (
        <SplitLegModal
          setSplitLegData={setSplitLegData}
          splitLegData={splitLegData}
          handleClose={() => setIsShowSplitLegModal(false)}
          handleSubmit={handleSubmit}
        />
      )}

      {isShowSetMiddlePointModal && (
        <SetMiddlePointModal
          handleClose={() => setIsShowSetMiddlePointModal(false)}
          splitLegData={splitLegData}
          handleSubmit={() => {
            setIsShowSetMiddlePointModal(false);
            setIsShowLegOrderDetailModal(true);
          }}
        />
      )}

      {isShowLegOrderDetailModal && (
        <LegOrderDetailsModal
          handleClose={() => {
            setIsShowSetMiddlePointModal(false);
          }}
          combinedOrderDimensions={combinedOrderDimensions}
          handlingUnitOptions={handlingUnitOptions}
          serviceTypes={serviceTypes}
        />
      )}
    </>
  );
};

export default SplitLegDetails;
