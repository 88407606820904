import { yupResolver } from '@hookform/resolvers/yup';
import {
  CoinsStacked02,
  CurrencyDollar,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as yup from 'yup';

import AdditionalCharge from '../../OrderDetails/AdditionalCharge';

const schema = yup.object().shape({
  customerId: yup.number().required('This field is required'),
  baseSalePrice: yup
    .number()
    .required('This field is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  fuel: yup
    .number()
    .required('This field is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalSalePrice: yup
    .number()
    .required('This field is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup
        .string()
        .required('charge is required')
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
      price: yup
        .number()
        .required('price is required')
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
    })
  ),
});

interface IProps {
  handleClose: () => void;
  orderSalesPrice: any;
  handleAddSalesPrice: any;
  masterOrderId: any;
  customerList: any;
  isCustomerLoading: any;
  customerId: any;
}

interface IFormData {
  customerId: number;
  baseSalePrice: number;
  fuel: number;
  totalSalePrice: number;
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    price: null | number;
  }>;
}

const SalesModal = ({
  handleClose,
  handleAddSalesPrice,
  orderSalesPrice,
  masterOrderId,
  customerList,
  isCustomerLoading,
  customerId,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isFieldsInitialized, setIsFieldsInitialized] = useState(false);

  const headerIcon = <CoinsStacked02 className="w-7 h-7" />;

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      customerId: undefined,
      baseSalePrice: undefined,
      fuel: undefined,
      totalSalePrice: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          price: null,
        },
      ],
    },
  });
  const { handleSubmit, control, setValue, watch } = methods;

  const { replace } = useFieldArray({
    control,
    name: 'additional_charge',
  });

  const watchAdditionalCharges: any = watch('additional_charge');
  const watchBaseSalesRate: any = watch('baseSalePrice');
  const watchFuel: any = watch('fuel');

  useEffect(() => {
    if (orderSalesPrice) {
      const orderToEdit = orderSalesPrice;

      setValue('baseSalePrice', orderToEdit.baseSalePrice);
      setValue('fuel', orderToEdit.fuel);
      setValue('totalSalePrice', orderToEdit.totalSalePrice);

      if (
        orderToEdit.additional_charge &&
        orderToEdit.additional_charge.length > 0
      ) {
        const transformedAdditionalCharges = orderToEdit.additional_charge.map(
          (charge: any) => ({
            id: charge.id,
            charge: charge.charge,
            price: charge.price,
          })
        );

        replace(transformedAdditionalCharges);
        setIsFieldsInitialized(true);
      }
    } else {
      setIsFieldsInitialized(true);
    }
  }, [orderSalesPrice]);

  useEffect(() => {
    if (customerList.length > 0) {
      setValue('customerId', customerId);
    }
  }, [customerList]);

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const id = orderSalesPrice?.id ? orderSalesPrice?.id : null;
    const param = { ...data, id, masterOrderId };

    handleAddSalesPrice(param);
    handleClose();
    setIsLoading(false);
  };

  const handleTotalPrice = () => {
    const additionalPrice = watchAdditionalCharges
      .filter(
        (item: any) =>
          item.price !== null &&
          item.price !== undefined &&
          !isNaN(Number(item.price))
      )
      .reduce((acc: any, item: any) => acc + Number(item.price), 0);

    const baseSalesRate = watchBaseSalesRate || 0;
    const fuel = watchFuel || 0;

    if (
      additionalPrice !== null &&
      !isNaN(additionalPrice) &&
      baseSalesRate !== null &&
      !isNaN(baseSalesRate) &&
      fuel !== null &&
      !isNaN(fuel)
    ) {
      const totalPrice = additionalPrice + Number(baseSalesRate) + Number(fuel);

      setValue('totalSalePrice', totalPrice);
    }
  };

  return (
    <CommonModal
      title={
        orderSalesPrice && orderSalesPrice.length
          ? 'Edit Sale Price'
          : 'Add Sale Price'
      }
      titleDesc={
        orderSalesPrice && orderSalesPrice.length
          ? 'You can modify the existing sales price for this order.'
          : 'Attach a sales price to this order, if applicable at this time.'
      }
      handleClose={handleClose}
      headerIcon={headerIcon}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px]'}
      primaryBtnText={
        orderSalesPrice && orderSalesPrice.length ? 'Save' : 'Assign'
      }
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={handleClose}
    >
      <FormProvider {...methods}>
        <div className="p-5 flex flex-col gap-y-4">
          <Controller
            name="customerId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                label="Customer"
                labelClassName="form_label block mb-1.5"
                name="customerId"
                id="customerId"
                size="sm"
                placeholder="All Customers"
                noOptionMessage="No Customers Found"
                isSearchable={true}
                className="form_control"
                isClearable={true}
                options={customerList}
                onChangeFunc={(e: any) => onChange(e?.value)}
                value={customerList?.find((e: any) => e?.value == value)}
                isLoading={isCustomerLoading}
                isDisabled
                errorText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="baseSalePrice"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Base Sale Price "
                inputName="base_sale_price"
                inputType="number"
                placeholder="Enter Base Sale Price"
                parentClassName=""
                className="pl-8"
                value={value}
                onChangeFunc={onChange}
                onBlur={handleTotalPrice}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />
          {isFieldsInitialized && (
            <AdditionalCharge isSelectBox={false} onBlur={handleTotalPrice} />
          )}

          <Controller
            name="fuel"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Fuel"
                inputName="fuel"
                inputType="number"
                placeholder="Enter Fuel Value"
                parentClassName=""
                className="pl-8 w-full"
                value={value}
                onChangeFunc={onChange}
                onBlur={handleTotalPrice}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />

          <Controller
            name="totalSalePrice"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Total Sale Price"
                inputName="total_sale_price"
                inputType="number"
                placeholder="Enter Total Sale Price"
                parentClassName=""
                className="pl-8 w-full"
                value={value}
                onChangeFunc={onChange}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
                readOnly
              />
            )}
          />
        </div>
      </FormProvider>
    </CommonModal>
  );
};

export default SalesModal;
