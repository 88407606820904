import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { calculateDistance } from 'src/utils/CommonFunctions';

const LegDetailsRow = ({ data }: any) => {
  console.log('data', data);

  return (
    <>
      <td className="px-5 py-4 w-[20%] min-w-[20%] max-w-[20%]">
        <span>#{data?.orderId}</span>
      </td>
      <td className="px-5 py-4 w-[27%] min-w-[27%] max-w-px">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            <img className="table-profile-icn-circle-xl" src={''} />
            {/* IF NO IMAGE FOUND BELOW COMMENTED CODE WILL BE USED */}
            {/* <div className="table-profile-customer-circle-xl">WA</div> */}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              XPO Logistics Freight, Inc.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #WQ085020145
            </p>
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-[26%] min-w-[26%] max-w-px">
        <TooltipCmp
          type="dark"
          parentClassName=""
          message={data?.shipper?.shipperAddress?.address1}
        >
          <p className="flex mr-3">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate ">
              {data?.shipper?.shipperAddress?.address1}
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          type="dark"
          parentClassName="max-w-90"
          message={data?.consignee?.consigneeAddress?.address1}
        >
          <p className="flex mr-3">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate ">
              {data?.consignee?.consigneeAddress?.address1}
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 w-[15%] min-w-[15%] max-w-[15%]">
        <span>
          {calculateDistance(
            data?.shipper?.shipperAddress?.latitude,
            data?.shipper?.shipperAddress?.longitude,
            data?.consignee?.consigneeAddress?.latitude,
            data?.consignee?.consigneeAddress?.longitude
          )}{' '}
          mi
        </span>
      </td>
      <td className="px-5 py-4 w-[175px] min-w-[175px] max-w-[175px]">
        <span>November 31th, 2024</span>
      </td>
    </>
  );
};

export default LegDetailsRow;
