import {
  AlertCircle,
  Copy04,
  Eye,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Loader from 'src/components/Loader';
import Toggle from 'src/components/Toggle/Toggle';
import { getLabelByValue } from 'src/utils/CommonFunctions';

import ButtonCmp from '../../../../../components/ButtonCmp';
import InputText from '../../../../../components/InputText/InputText';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import TooltipCmp from '../../../../../components/TooltipCmp';
import { getFreightClass } from '../../../../../services/QuoteService';
import VisualizeUnit from '../../../QuotingDashboard/VisualizeUnit';

interface IProps {
  formData: any;
  validForm: any;
  initFormData: any;
  initValidForm: any;
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
  setFormData: any;
  setValidForm: any;
  lengthMeasurement: any;
  weightMeasurement: any;
  dimensionsExtractedData: any;
}

const DimensionItems = ({
  formData,
  validForm,
  initFormData,
  initValidForm,
  handlingUnitOptions,
  isHandlingUnitLoading,
  setFormData,
  setValidForm,
  lengthMeasurement,
  weightMeasurement,
  dimensionsExtractedData,
}: IProps) => {
  const [currentItem, setCurrentItem] = useState<any>({
    index: null,
    data: null,
  });
  const [isShowVisualUnit, setIsShowVisualUnit] = useState(false);
  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);

  const addItems = () => {
    const initFormDataWithPallet = {
      ...initFormData,
      handlingUnit: handlingUnitOptions?.[0]?.value,
    };
    setFormData((old: any) => [...old, initFormDataWithPallet]);
    setIsFreightClassLoading((old: any) => [...old, { isLoading: false }]);
    setValidForm((old: any) => [...old, initValidForm]);
  };

  const duplicateItems = () => {
    if (formData.length > 0) {
      const lastItem = formData[formData.length - 1];
      delete lastItem.id;
      setFormData((old: any) => [...old, lastItem]);
      setValidForm((old: any) => [...old, validForm[validForm.length - 1]]);
    } else {
      setFormData((old: any) => [...old, initFormData]);
      setValidForm((old: any) => [...old, initValidForm]);
    }
  };

  const removeItems = (id: any) => {
    const form = formData.filter(
      (formDataField: any, index: number) => index !== id
    );
    setFormData(form);
  };

  const handleToggle = (index: number) => (event: any) => {
    const { name, checked } = event.target;
    setFormData((old: any) => {
      const oldData = [...old];
      oldData[index] = {
        ...oldData[index],
        [name]: checked,
      };

      return oldData;
    });
  };

  const handleSelectChange = (index: number, name: string) => (event: any) => {
    const value = event?.value;
    setFormData((old: any) => {
      const oldData = [...old];
      oldData[index] = {
        ...oldData[index],
        [name]: value,
      };
      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            required: Boolean(oldData[index][name]),
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const handleInputChange = (index: number) => (event: any) => {
    const { target, type: eventType } = event;
    let { name, value } = target;

    value = value ? parseFloat(value) : eventType === 'blur' ? 0 : value;

    setFormData((old: any) => {
      const oldData = [...old];
      let totalWeight = oldData[index].totalWeight;

      if (name === 'weight') {
        totalWeight = value * oldData[index]?.handlingUnitNo;
      } else if (name === 'handlingUnitNo') {
        totalWeight = value * oldData[index].weight;
      }

      oldData[index] = {
        ...oldData[index],
        [name]: value,
        totalWeight: parseFloat(totalWeight),
      };

      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            ...{
              required: Boolean(oldData[index][name]),
              valid: Boolean(oldData[index][name]),
            },
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const checkFreightClass = (index: number) => async () => {
    const oldData = [...formData];
    let freightClass = oldData[index]?.freightClass;
    let itemClassForDayross = oldData[index]?.itemClassForDayross;
    let weight = oldData[index]?.weight;
    let itemLength = oldData[index]?.itemLength;
    let width = oldData[index]?.width;
    let height = oldData[index]?.height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading]; // Create a copy of the array
      updatedArray[index] = { isLoading: true }; // Update the value at the specified index
      setIsFreightClassLoading(updatedArray);

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: lengthMeasurement,
        weightUnit: weightMeasurement,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading]; // Create a copy of the array
          updatedArr[index] = { isLoading: false }; // Update the value at the specified index
          setIsFreightClassLoading(updatedArr);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    oldData[index] = {
      ...oldData[index],
      freightClass,
      itemClassForDayross,
    };
    setFormData(oldData);
  };

  useEffect(() => {
    if (currentItem.data) {
      setIsShowVisualUnit(true);
    }
  }, [currentItem]);

  const handleUnitChange = async () => {
    const newFormData = await Promise.all(
      formData.map(async (form: any, index: number) => {
        const oldData = [...formData];
        let freightClass = form?.freightClass;
        let itemClassForDayross = form?.itemClassForDayross;
        let weight = form?.weight;
        let itemLength = form?.itemLength;
        let width = form?.width;
        let height = form?.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading]; // Create a copy of the array
          updatedArray[index] = { isLoading: true }; // Update the value at the specified index
          setIsFreightClassLoading(updatedArray);

          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading]; // Create a copy of the array
              updatedArr[index] = { isLoading: false }; // Update the value at the specified index
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        oldData[index] = {
          ...oldData[index],
          freightClass,
          itemClassForDayross,
        };

        return oldData[index];
      })
    );

    setFormData(newFormData);
  };

  useEffect(() => {
    handleUnitChange();
  }, [lengthMeasurement]);

  return (
    <>
      {formData.map((form: any, index: number) => (
        <div
          key={`quoting_req_create_quote_form_dimensions${index}`}
          className="p-3 rounded-lg border border-utilityGray200 bg-white"
        >
          <div className="flex flex-wrap flex-1 -m-1">
            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="handlingUnitNo"
              label="№ of handling units"
              labelClassName="block mb-1.5"
              min="0"
              className={`${
                !validForm[index]?.handlingUnitNo.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.handlingUnitNo.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.handlingUnitNo.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={form?.handlingUnitNo || null}
              onChangeFunc={handleInputChange(index)}
              onBlur={checkFreightClass(index)}
              required
              isShowAiStar={
                !!form?.handlingUnitNo &&
                form?.handlingUnitNo ===
                  dimensionsExtractedData?.[index]?.handlingUnitNo
              }
              shouldFormatNumber={true}
            />

            <SelectBox
              parentClassName="w-1/2 p-1"
              isSearchable
              name="handlingUnit"
              id="handlingUnit"
              inlineSelect={true}
              label="Handling unit"
              labelClassName="form_label mb-1.5 block"
              className="form_control"
              classComp={`mt-0 ${
                !validForm[index]?.handlingUnit.required ||
                !validForm[index]?.handlingUnit.valid
                  ? ''
                  : ''
              }`}
              options={handlingUnitOptions}
              icon={
                !validForm[index]?.handlingUnit.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500 bg-white" />
                ) : null
              }
              onChangeFunc={handleSelectChange(index, 'handlingUnit')}
              value={
                handlingUnitOptions
                  ? handlingUnitOptions?.find(
                      (handlingUnitOption: any) =>
                        handlingUnitOption.value ===
                          parseInt(form?.handlingUnit) || ''
                    )
                  : null
              }
              isLoading={isHandlingUnitLoading}
              required
              isShowAiStar={
                !!form?.handlingUnit &&
                form?.handlingUnit ===
                  dimensionsExtractedData?.[index]?.handlingUnit
              }
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="itemLength"
              label={`Length (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              className={`${
                !validForm[index]?.itemLength.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.itemLength.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.itemLength.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={form?.itemLength || ''}
              onChangeFunc={handleInputChange(index)}
              onBlur={checkFreightClass(index)}
              required
              isShowAiStar={
                !!form?.itemLength &&
                form?.itemLength ===
                  dimensionsExtractedData?.[index]?.itemLength
              }
              shouldFormatNumber={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="width"
              label={`Width (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              className={`${
                !validForm[index]?.width.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.width.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.width.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={form?.width || ''}
              onChangeFunc={handleInputChange(index)}
              onBlur={checkFreightClass(index)}
              isShowAiStar={
                !!form?.width &&
                form?.width === dimensionsExtractedData?.[index]?.width
              }
              shouldFormatNumber={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="height"
              label={`Height (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              className={`${
                !validForm[index]?.height.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.height.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.height.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={form?.height || ''}
              onChangeFunc={handleInputChange(index)}
              onBlur={checkFreightClass(index)}
              isShowAiStar={
                !!form?.height &&
                form?.height === dimensionsExtractedData?.[index]?.height
              }
              shouldFormatNumber={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="weight"
              label={`Weight per unit (${getLabelByValue(
                weightMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              className={`${
                !validForm[index]?.weight.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.weight.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.weight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={form?.weight || ''}
              onChangeFunc={handleInputChange(index)}
              onBlur={checkFreightClass(index)}
              isShowAiStar={
                !!form?.weight &&
                form?.weight === dimensionsExtractedData?.[index]?.weight
              }
              shouldFormatNumber={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="totalWeight"
              label={`Total weight (${getLabelByValue(
                weightMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              value={form?.totalWeight}
              className={`${
                !validForm[index]?.totalWeight.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.totalWeight.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.totalWeight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              readOnly
              isShowAiStar={
                !!form?.totalWeight &&
                form?.totalWeight ===
                  dimensionsExtractedData?.[index]?.totalWeight
              }
              shouldFormatNumber={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputName="freightClass"
              inputType="number"
              label={
                <div className="flex gap-2">
                  Freight Class
                  {isFreightClassLoading[index]?.isLoading && (
                    <div className="relative h-3 w-3 mr-3">
                      <Loader spinnerClass={'h-4 w-4'} />
                    </div>
                  )}
                </div>
              }
              labelClassName="mb-1.5 block"
              min={0}
              value={form?.freightClass}
              onChangeFunc={handleInputChange(index)}
              className={`${
                !validForm[index]?.weight.required
                  ? 'border border-red-500 border-solid'
                  : !validForm[index]?.weight.valid &&
                    'border border-red-500 border-solid'
              }`}
              icon={
                !validForm[index]?.weight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              shouldFormatNumber={true}
            />
          </div>

          <div className="flex items-center gap-4 mt-3 justify-between">
            <Toggle
              name="isStack"
              label="Do not stack"
              labelPosition="right"
              labelTextClassName="!text-xs"
              isChecked={form?.isStack}
              onChange={handleToggle(index)}
            />
            <div className="flex gap-1">
              <TooltipCmp message="Visualize Unit">
                <div
                  className="delete cursor-pointer p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary"
                  onClick={() => {
                    setCurrentItem({ ...{ index: index, data: form } });
                  }}
                >
                  <Eye className="w-4 h-4" />
                </div>
              </TooltipCmp>

              <div
                className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer`}
                onClick={() => removeItems(index)}
              >
                <Trash01 className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex gap-3 ">
        <ButtonCmp
          type="submit"
          className="btn-outline-primary !p-0 !border-0 !bg-transparent !shadow-none gap-1.5"
          onClick={addItems}
          icon={<Plus className="w-4 h-4" />}
        >
          Add Items
        </ButtonCmp>
        <ButtonCmp
          type="submit"
          className="btn_secondary_black !p-0 !border-0 !bg-transparent !shadow-none gap-1.5"
          onClick={duplicateItems}
          icon={<Copy04 className="w-4 h-4" />}
        >
          Duplicate
        </ButtonCmp>
      </div>

      {isShowVisualUnit && currentItem && (
        <VisualizeUnit
          setFormData={setFormData}
          formData={formData}
          currentItem={currentItem}
          unitArr={handlingUnitOptions}
          handleClose={() => {
            setIsShowVisualUnit(false);
            setCurrentItem({ index: null, data: null });
          }}
          weightMeasurement={weightMeasurement}
          lengthMeasurement={lengthMeasurement}
        />
      )}
    </>
  );
};

export default DimensionItems;
