import { Device, Call } from '@twilio/voice-sdk';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import DialPad from './dialpad';

type CallParams = Record<string, string>;

interface TokenResponse {
  token: string;
}

const VoiceCall = () => {
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isCallInProgress, setIsCallInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [incomingCall, setIncomingCall] = useState<Call | null>(null);
  const [countryCode, setCountryCode] = useState<string>('+91');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingSid, setRecordingSid] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [onHold, setOnHold] = useState<boolean>(false);
  const [conferenceName, setConferenceName] = useState<string>('');
  const [conferenceId, setConferenceId] = useState<string>('');
  const [participants, setParticipants] = useState<any>([]);
  const [holdStatus, setHoldStatus] = useState<boolean>(false);
  const [supervisorSid, setSupervisorSid] = useState<string>('');

  console.log('⭐️ ~ VoiceCall ~ conferenceName:', conferenceName);
  console.log('⭐️ ~ file: index.tsx:23 ~ VoiceCall ~ setOnHold:', setOnHold);
  // const [recordingDetails, setRecordingDetails] = useState<null>(null);
  // const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const callingToken = useRef<string | null>(null);
  const device = useRef<Device | null>(null);
  const callInstance = useRef<Call | null>(null);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const initializeDevice = (token: string) => {
    if (device.current) {
      device.current.destroy();
    }

    device.current = new Device(token, {
      disableAudioContextSounds: false,
    });

    device.current.on('ready', () => {
      console.log('Device ready for incoming calls');
    });

    device.current.on('error', (error: any) => {
      console.error('Twilio Device Error:', error);
      setErrorMessage(error.message);
    });

    device.current.on('incoming', (call: Call) => {
      console.log('1. Incoming call:', call);
      setIncomingCall(call);

      if (isSocketConnected && !!socketIO?.on?.on) {
        console.log('2. socker is connected');

        socketIO.removeAllListeners();
        socketIO.on.on('transferWithoutPickup', function (conference: any) {
          console.log('3. ⭐️ ~ file: index.tsx:123 ~ conference:', conference);
          setConferenceName(conference?.conferenceName);
          setConferenceId(conference?.conferenceSid);
          setParticipants(conference?.participants);
        });
      } else {
        console.log('3.socker is not connected');
        fetchJsFromCDN(
          'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
          ['io']
        )
          .then(([io]: any) => {
            io.sails.url = window.SERVER_URL;

            io.socket.on('connect', function socketConnected() {
              dispatch(setIsSocketConnected(true));
              dispatch(setSocketIo(io.socket));

              io.socket.get(
                `/subscribe/globalRoom`,
                function (data: any, jwr: any) {
                  if (jwr.error) {
                    return;
                  }
                }
              );
            });

            // Listen for transferWithoutPickup event
            io.socket.on('transferWithoutPickup', (conference: any) => {
              console.log('4. ⭐️ ~ io.socket.on ~ conference:', conference);
              setConferenceName(conference?.conferenceName);
              setConferenceId(conference?.conferenceSid);
            });

            // Clean up on component unmount
            return () => {
              io.socket.off('transferWithoutPickup');
            };
          })
          .catch((error) => {
            console.error('Failed to load Sails socket library:', error);
          });
      }
      console.log('5. conference details get successfully');
    });

    device.current.register();
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post<TokenResponse>(
          `${window.API_URL}/twilio/get-token`,
          { identity: 'device' }
        );
        callingToken.current = response.data.token;

        // Initialize Twilio Device
        initializeDevice(response.data.token);
      } catch (err) {
        setErrorMessage('Error fetching token. Please try again.');
        console.error('Error fetching token:', err);
      }
    };

    fetchToken();

    return () => {
      if (device.current) {
        device.current.destroy();
      }
    };
  }, []);

  const handleCall = async () => {
    try {
      console.log('in ountgoing call');

      if (!callingToken.current || !phoneNumber) {
        throw new Error('Invalid token or phone number');
      }

      if (!device.current) {
        throw new Error('Device is not initialized');
      }

      const params: CallParams = {
        To: countryCode + phoneNumber,
        intent: 'call-out',
      };
      console.log('🚀 ~ handleCall ~ params:', params);

      callInstance.current = await device.current.connect({ params });
      setIsCallInProgress(true);

      if (isSocketConnected && !!socketIO?.on?.on) {
        console.log('socker is connected');

        socketIO.removeAllListeners();
        socketIO.on.on('conferenceCreated', function (conference: any) {
          console.log('⭐️ ~ file: index.tsx:123 ~ conference:', conference);
          setConferenceName(conference?.conferenceName);
          setConferenceId(conference?.conferenceSid);
          setParticipants(conference?.participants);
        });
      } else {
        console.log('socker is not connected');
        fetchJsFromCDN(
          'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
          ['io']
        )
          .then(([io]: any) => {
            io.sails.url = window.SERVER_URL;

            io.socket.on('connect', function socketConnected() {
              dispatch(setIsSocketConnected(true));
              dispatch(setSocketIo(io.socket));

              io.socket.get(
                `/subscribe/globalRoom`,
                function (data: any, jwr: any) {
                  if (jwr.error) {
                    return;
                  }
                }
              );
            });

            // Listen for ConferenceCreated event
            io.socket.on('conferenceCreated', (conference: any) => {
              console.log(
                '⭐️ ~ file: index.tsx:123 ~ conference:',
                conference
              );
              setConferenceName(conference?.conferenceName);
              setConferenceId(conference?.conferenceSid);
              setParticipants(conference?.participants);
            });

            // Clean up on component unmount
            return () => {
              io.socket.off('conferenceCreated');
            };
          })
          .catch((error) => {
            console.error('Failed to load Sails socket library:', error);
          });
      }

      callInstance.current.on('disconnect', (e) => {
        console.log('Call disconnected', e, callInstance);
        setIsCallInProgress(false);
        callInstance.current = null;
      });
    } catch (err) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unknown error occurred'
      );
      console.error('Error making call:', err);
    }
  };

  // if (isSocketConnected && !!socketIO?.on?.on) {
  //   console.log('2. socker is connected');

  //   socketIO.removeAllListeners();
  //   socketIO.on.on('scheduleCall', function (scheduleCall: any) {
  //     console.log('3. ⭐️ ~ file: index.tsx:123 ~ conference:', scheduleCall);
  //   });

  //   const params: CallParams = {
  //     To: countryCode + phoneNumber,
  //     intent: 'call-out',
  //   };
  //   console.log('🚀 ~ handleCall ~ params:', params);

  //   callInstance.current = await device.current.connect({ params });
  //   setIsCallInProgress(true);
  // } else {
  //   console.log('3.socker is not connected');
  //   fetchJsFromCDN(
  //     'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
  //     ['io']
  //   )
  //     .then(([io]: any) => {
  //       io.sails.url = window.SERVER_URL;

  //       io.socket.on('connect', function socketConnected() {
  //         dispatch(setIsSocketConnected(true));
  //         dispatch(setSocketIo(io.socket));

  //         io.socket.get(
  //           `/subscribe/globalRoom`,
  //           function (data: any, jwr: any) {
  //             if (jwr.error) {
  //               return;
  //             }
  //           }
  //         );
  //       });

  //       // Listen for scheduleCall event
  //       io.socket.on('scheduleCall', (scheduleCall: any) => {
  //         console.log('4. ⭐️ ~ io.socket.on ~ conference:', scheduleCall);
  //       });

  //       // Clean up on component unmount
  //       return () => {
  //         io.socket.off('transferWithoutPickup');
  //       };
  //     })
  //     .catch((error) => {
  //       console.error('Failed to load Sails socket library:', error);
  //     });
  // }

  const handleHangup = () => {
    if (callInstance.current) {
      setIsCallInProgress(false);
      callInstance.current.disconnect();
    }
  };

  const acceptIncomingCall = () => {
    if (incomingCall) {
      console.log('4. ⭐️ ~ acceptIncomingCall ~ incomingCall:', incomingCall);
      console.log('3. Accepting call');

      incomingCall.accept();
      setIsCallInProgress(true);
      console.log('4. Call Accepted successfully');
      // setIncomingCall(null);
    }
  };

  const rejectIncomingCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      setIncomingCall(null);
    }
  };

  // Transfer call For outgoing
  const handleTransferCall = async () => {
    console.log('callInstance.current', callInstance.current);
    console.log('incomingCall', incomingCall);

    // if (!incomingCall?.parameters?.CallSid) {
    //   setErrorMessage('Please ensure a call is in progress.');

    //   return;
    // }

    try {
      const callSid =
        callInstance.current?.parameters?.CallSid ||
        incomingCall?.parameters.CallSid;

      // const callSid = incomingCall?.parameters?.CallSid;
      console.log('handleTransferCall ~ callSid:', callSid);
      console.log('conferenceName', conferenceName);

      // transfer the call
      await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/transfer-call',
        {
          conferenceName: conferenceName,
          currentParticipantCallSid: callSid,
          newParticipantNumber: '+918200843427',
          conferenceSid: conferenceId,
          removeCurrentParticipant: true,
        }
      );

      rejectIncomingCall();

      console.log(`8. Call successfully transferred`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the call transfer.'
      );
      console.error('Error transferring call:', error);
    }
  };

  const startRecording = async (callSid: string) => {
    try {
      const response = await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/start-recording',
        { callSid }
      );
      setRecordingSid(response.data.recordingSid);
      setIsRecording(true);
      console.log('Recording started:', response.data);
    } catch (error) {
      console.error('Error starting recording:', error);
      setErrorMessage('Unable to start recording. Please try again.');
    }
  };

  const stopRecording = async () => {
    try {
      const callSid = incomingCall?.parameters?.CallSid;

      if (!callSid) {
        throw new Error('No Call SID available.');
      }
      const response = await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/stop-recording',
        { callSid, recordingSid },
        { responseType: 'blob' }
      );
      setIsRecording(false);

      // Create a blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

      // Create a URL for the blob
      const url = URL.createObjectURL(audioBlob);

      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `recording_${recordingSid}.mp3`; // Set the file name
      document.body.appendChild(anchor); // Append the anchor to the body
      anchor.click(); // Trigger the download
      document.body.removeChild(anchor); // Clean up

      // Revoke the blob URL to release memory
      URL.revokeObjectURL(url);
      console.log('Recording stopped');
    } catch (error) {
      console.error('Error stopping recording:', error);
      setErrorMessage('Unable to stop recording. Please try again.');
    }
  };

  const toggleMute = () => {
    if (callInstance.current) {
      const muteState = !isMuted;
      callInstance.current.mute(muteState);
      setIsMuted(muteState);
    }
  };

  const toggleHold = async () => {
    setHoldStatus(!holdStatus);
    console.log(
      '⭐️ ~ toggleHold ~ callInstance.current:',
      callInstance.current
    );

    try {
      const myCallSid =
        callInstance.current?.parameters?.CallSid ||
        incomingCall?.parameters.CallSid;
      console.log('⭐️ ~ toggleHold ~ myCallSid:', myCallSid);

      const holdParticipantId = participants.find(
        (parties: any) => parties.callSid != myCallSid
      );
      console.log('⭐️ ~ toggleHold ~ holdParticipantId:', holdParticipantId);

      const response = await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/hold-call',
        {
          conferenceSid: conferenceId,
          participantSid: holdParticipantId?.callSid,
          hold: holdStatus,
        }
      );
      console.log('⭐️ ~ toggleHold ~ response:', response);
    } catch (error) {
      console.error('Error toggling hold:', error);
    }
  };

  const handleTransferWithoutPickup = async () => {
    try {
      const callSid = incomingCall?.parameters.CallSid;

      // const callSid = incomingCall?.parameters?.CallSid;
      console.log('6. handleTransferWithoutPickup ~ callSid:', callSid);
      // console.log('conferenceName', conferenceName);

      // transfer the call
      await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/transfer-call',
        {
          currentParticipantCallSid: callSid,
          newParticipantNumber: '+919427420518',
          conferenceSid: conferenceId,
          removeCurrentParticipant: false,
        }
      );

      rejectIncomingCall();

      console.log(`7. Call successfully transferred`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the call transfer.'
      );
      console.error('Error transferring call:', error);
    }
  };

  // add participant in conference (barge)
  const addParticipantInConference = async () => {
    try {
      // const callSid =
      //   callInstance.current?.parameters?.CallSid ||
      //   incomingCall?.parameters.CallSid;

      // const callSid = incomingCall?.parameters?.CallSid;
      // console.log('handleTransferCall ~ callSid:', callSid);
      console.log('conferenceName', conferenceName);

      // transfer the call
      await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/add-participant',
        {
          newParticipantNumber: '+918200843427',
          conferenceSid: conferenceId,
        }
      );

      console.log(`8. Call successfully transferred`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the call transfer.'
      );
      console.error('Error transferring call:', error);
    }
  };

  // add supervisor in conference (whisper)
  const addSupervisorInConference = async (role: string) => {
    try {
      console.log('⭐️ ~ addSupervisorInConference ~ role:', role);
      const callSid =
        callInstance.current?.parameters?.CallSid ||
        incomingCall?.parameters.CallSid;

      // const callSid = incomingCall?.parameters?.CallSid;
      // console.log('handleTransferCall ~ callSid:', callSid);

      const body: {
        newParticipantNumber: string;
        conferenceSid: string;
        role: string;
        agentCallSid?: string;
        supervisorSid?: string;
      } = {
        newParticipantNumber: '+919106919088',
        conferenceSid: conferenceId,
        agentCallSid: callSid,
        role,
      };

      if (role === 'whisper') {
        body.agentCallSid = callSid;
        body.supervisorSid = supervisorSid;
      }

      const response = await axios.post(
        'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/add-supervisor',
        body
      );
      console.log('⭐️ ~ addSupervisorInConference ~ response:', response);

      setSupervisorSid(response?.data?.supervisorSid);

      console.log(`8. Call successfully transferred`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the call transfer.'
      );
      console.error('Error transferring call:', error);
    }
  };

  // const fetchRecordingDetails = async () => {
  //   // Fetches recording metadata from the backend
  //   try {
  //     // if (!recordingSid) {
  //     //   throw new Error('No recording SID available');
  //     // }

  //     const response = await axios.post(
  //       'http://localhost:1337/api/v1/twilio/fetch-recording',
  //       { recordingSid: 'REf687658cb797cb344461492ea2757bbe' }
  //     );

  //     setRecordingDetails(response.data);
  //   } catch (error) {
  //     console.error('Error fetching recording details:', error);
  //     setErrorMessage('Unable to fetch recording details. Please try again.');
  //   }
  // };

  // const downloadRecording = async () => {
  //   try {
  //     const response = await axios.post(
  //       'http://localhost:1337/api/v1/twilio/download-recording',
  //       { recordingSid: 'REa9fee210adb53eeb8ecc23cea741cdb4' },
  //       { responseType: 'blob' }
  //     );

  //     // Create a blob from the response data
  //     const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

  //     // Create a URL for the blob
  //     const url = URL.createObjectURL(audioBlob);

  //     // Create a temporary anchor element
  //     const anchor = document.createElement('a');
  //     anchor.href = url;
  //     anchor.download = `recording_${recordingSid}.mp3`; // Set the file name
  //     document.body.appendChild(anchor); // Append the anchor to the body
  //     anchor.click(); // Trigger the download
  //     document.body.removeChild(anchor); // Clean up

  //     // Revoke the blob URL to release memory
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Error downloading recording:', error);
  //     setErrorMessage('Unable to download recording. Please try again.');
  //   }
  // };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Voice Call</h1>

      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {errorMessage}
        </div>
      )}

      {incomingCall && !isCallInProgress && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded mb-4 flex items-center gap-4">
          <p className="flex-1">Incoming Call...</p>
          <button
            onClick={acceptIncomingCall}
            className="bg-emerald-500 hover:bg-emerald-600 px-4 py-2 rounded"
          >
            Accept
          </button>
          <button
            onClick={rejectIncomingCall}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
          >
            Reject
          </button>
          <button
            onClick={handleTransferWithoutPickup}
            className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
          >
            Transfer Call
          </button>
        </div>
      )}

      {/* Call in progress UI */}
      {isCallInProgress && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          <div className="flex items-center justify-between">
            <p className="font-medium">
              Call in progress with {incomingCall?.parameters.From}...
            </p>
            <div className="flex gap-2">
              <button
                onClick={toggleMute}
                className={`${
                  isMuted ? 'bg-gray-500' : 'bg-sky-500'
                } hover:bg-sky-600 text-white px-4 py-2 rounded`}
              >
                {isMuted ? 'Unmute' : 'Mute'}
              </button>
              <button
                onClick={handleHangup}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
              >
                End Call
              </button>
              <button
                onClick={handleTransferCall}
                className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
              >
                Transfer Call
              </button>
              <button
                onClick={addParticipantInConference}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded"
              >
                Add Participants - Barge
              </button>
              <button
                onClick={() =>
                  addSupervisorInConference(
                    supervisorSid ? 'whisper' : 'listen'
                  )
                }
                className="bg-neutral-700 hover:bg-neutral-950 text-white px-4 py-2 rounded"
              >
                Add Supervisor - Whisper
              </button>
              <button
                onClick={toggleHold}
                className="bg-neutral-400 hover:bg-neutral-500 text-white px-4 py-2 rounded"
              >
                {onHold ? 'Resume Call' : 'Hold Call'}
              </button>
              {!isRecording ? (
                <button
                  onClick={() =>
                    startRecording(
                      callInstance.current?.parameters?.CallSid ||
                        incomingCall?.parameters.CallSid ||
                        ''
                    )
                  }
                  className="bg-violet-500 hover:bg-violet-600 text-white px-4 py-2 rounded"
                >
                  Start Recording
                </button>
              ) : (
                <button
                  onClick={stopRecording}
                  className="bg-gray-500 hover:bg-gray-600 px-4 py-2 rounded"
                >
                  Stop Recording
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <button
        onClick={fetchRecordingDetails}
        className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
      >
        Fetch Recording
      </button>

      <button
        onClick={downloadRecording}
        className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
      >
        Download Recording
      </button> */}

      <DialPad
        onCall={handleCall}
        onHangup={handleHangup}
        isCallInProgress={isCallInProgress}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </div>
  );
};

export default VoiceCall;
