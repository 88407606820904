import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import {
  formatAddress,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const NewOrderRow = ({
  data,
  navigate,
  setIsOrderDelete,
  setDeletOrderId,
}: any) => {
  const getAddressWholeString = (
    first: string,
    second: string,
    third: string
  ) =>
    [first, second, third].filter((e) => e).length
      ? [first, second, third].filter((e) => e).toString()
      : false;

  const { currency } = useContext(BasicContext);

  return (
    <>
      <td
        className={`px-5 py-4 min-w-44 w-44  ${
          data.orderStatus !== 'draft' && data?.orderStatus !== 'cancelled'
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => {
          if (
            data.orderStatus !== 'draft' &&
            data?.orderStatus !== 'cancelled'
          ) {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <div className="flex items-center">
          {data.salesImage ? (
            <div className="table-profile-wrap notification-mark-wrap">
              <img
                className="table-profile-icn-circle-xl"
                src={data.salesImageUrl + data.salesImage}
                alt={data.salesFirstName}
                title={data.salesFirstName}
                onError={onError}
              />
              {data?.hasUnreadNotification ? (
                <span className={`notification-dot`}></span>
              ) : (
                ''
              )}
            </div>
          ) : data.salesFirstName ? (
            <div className="table-profile-wrap notification-mark-wrap">
              <div className="table-profile-user-circle-xl">
                {getShortName(`${data.salesFirstName} ${data.salesLastName}`)}
              </div>
            </div>
          ) : (
            <div className="table-profile-wrap notification-mark-wrap">
              <div className="table-profile-empty-circle-xl">&nbsp;</div>
            </div>
          )}
          <div className="pl-3 text-xs font-medium max-w-32">
            <h6 className="text-grayLight900 font-medium leading-tight truncate flex">
              {data.salesFirstName
                ? `${data.salesFirstName} ${data.salesLastName}`
                : 'Unassigned'}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.orderId}
            </p>
          </div>
        </div>
      </td>
      <td
        className={`px-5 py-4 min-w-[110px] max-w-[110px] w-[110px]  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        {data.orderStatus === 'completed' && (
          <BadgeCmp style="modern" type="gray">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'draft' || data.orderStatus === 'cancelled') && (
          <BadgeCmp style="modern" type="pink">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {(data.orderStatus === 'tender' ||
          data.orderStatus === 'booked' ||
          data.orderStatus === 'invoice-paid' ||
          data.orderStatus === 'delivered') && (
          <BadgeCmp style="modern" type="lightblue">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {data.orderStatus === 'in-transit' && (
          <BadgeCmp style="modern" type="error">
            {data.orderStatus}
          </BadgeCmp>
        )}
        {/* Tags */}
        {data.orderStatus === 'dispatched' && (
          <BadgeCmp style="modern" type="pink">
            {data.orderStatus}
          </BadgeCmp>
        )}
      </td>
      <td
        className={`px-5 py-4 max-w-36 min-w-36 w-36  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        {data.customerName?.length > 22 ? (
          <TooltipCmp
            childrenClassName="!block truncate max-w-32"
            message={data.customerName}
          >
            {data.customerName}
          </TooltipCmp>
        ) : (
          <div>{data.customerName}</div>
        )}
      </td>
      <td
        className={`px-5 py-4 max-w-36 min-w-36 w-36  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        {data.carrierName?.length > 22 ? (
          <TooltipCmp
            childrenClassName="!block truncate max-w-32"
            message={data.carrierName}
          >
            {data.carrierName}
          </TooltipCmp>
        ) : (
          <div>{data.carrierName ?? '-'}</div>
        )}
      </td>
      <td
        className={`px-5 py-4 max-w-40 min-w-40 w-40  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        {data.shipperCity ? (
          <>
            <TooltipCmp
              message={formatAddress(
                getAddressWholeString(
                  data.shipperCity,
                  data.shipperState,
                  data.shipperPostal
                )
              )}
              type="dark"
              parentClassName=""
            >
              <p className="flex">
                <span className="text-gray500 font-normal">From:&nbsp;</span>
                <span className="font-medium truncate max-w-[130px]">
                  {`${
                    getAddressWholeString(
                      data.shipperCity,
                      data.shipperState,
                      data.shipperPostal
                    )
                      ? formatAddress(
                          getAddressWholeString(
                            data.shipperCity,
                            data.shipperState,
                            data.shipperPostal
                          )
                        )
                      : '-'
                  }`}
                </span>
              </p>
            </TooltipCmp>
            <TooltipCmp
              message={formatAddress(
                getAddressWholeString(
                  data.consigneeCity,
                  data.consigneeState,
                  data.consigneePostal
                )
              )}
              type="dark"
              parentClassName="max-w-90"
            >
              <p className="flex">
                <span className="text-gray500 font-normal">To:&nbsp;</span>
                <span className="font-medium truncate max-w-[130px]">{`${
                  getAddressWholeString(
                    data.consigneeCity,
                    data.consigneeState,
                    data.consigneePostal
                  )
                    ? formatAddress(
                        getAddressWholeString(
                          data.consigneeCity,
                          data.consigneeState,
                          data.consigneePostal
                        )
                      )
                    : '-'
                }`}</span>
              </p>
            </TooltipCmp>
          </>
        ) : (
          '-'
        )}
      </td>
      <td
        className={`px-5 py-4 min-w-48 w-48 max-w-48  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.createdAt,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>

      <td
        className={`px-5 py-4 font-normal min-w-28 w-28 max-w-28 ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <span>
          $
          {data.combinedRevenue
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedRevenue
                  : data.combinedRevenueUSD,
                true,
                true
              )
            : '0.00'}
          {/* {currency} */}
        </span>
      </td>
      <td
        className={`px-5 py-4 font-normal min-w-28 w-28 max-w-28  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <span>
          $
          {data.combinedCost
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedCost
                  : data.combinedCostUSD,
                true,
                true
              )
            : '0.00'}{' '}
          {/* {currency} */}
        </span>
      </td>
      <td
        className={`px-5 py-4 font-normal min-w-28 max-w-28 w-28  ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <div className="flex gap-1">
          {data.combinedMarginPercentage
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedMarginPercentage
                  : data.combinedMarginPercentageUSD,
                true,
                true
              )
            : 0}{' '}
          %
        </div>
      </td>
      <td
        className={`px-5 py-4 font-semibold min-w-28 max-w-28 w-28 ${
          data.orderStatus !== 'draft' ? 'cursor-pointer' : ''
        } `}
        onClick={() => {
          if (data.orderStatus !== 'draft') {
            navigate(`${PATH.ORDER_VIEW}/${data?.id}`);
          }
        }}
      >
        <span>
          $
          {data.combinedMargin
            ? getFormattedNumber(
                currency === CURRENCY.CAD
                  ? data.combinedMargin
                  : data.combinedMarginUSD,
                true,
                true
              )
            : '0.00'}{' '}
        </span>
      </td>
      <td className={`px-5 py-4 min-w-28 w-28 max-w-28  `}>
        <div className="justify-center items-center gap-2 flex">
          {data?.orderStatus !== 'cancelled' ? (
            <TooltipCmp message="Edit">
              <Edit01
                className={`w-4 h-4 cursor-pointer flex items-center font-semibold flex-none`}
                onClick={() => {
                  navigate(`${PATH.ORDER_DETAILS}/${data?.id}`);
                }}
              />
            </TooltipCmp>
          ) : (
            <div
              className={`w-4 h-4 cursor-pointer flex items-center font-semibold flex-none`}
            ></div>
          )}
          <TooltipCmp message="Delete" type="dark">
            <Trash01
              className="w-4 h-4 relative cursor-pointer"
              onClick={() => {
                setDeletOrderId(data?.id);
                setIsOrderDelete(true);
              }}
            />
          </TooltipCmp>
        </div>
      </td>
    </>
  );
};

export default NewOrderRow;
