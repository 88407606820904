import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import { getCustomerAnalytics } from 'src/services/CustomerService';

import AnlyticsData from './anlyticsData';
import AnlyticsDataLoading from './anlyticsDataLoading';
import HeatMap from './heatMap';

interface IProps {
  customerId: string;
}

const CustomerDetailAnalyticsTab = ({ customerId }: IProps) => {
  const [analytics, setAnalytics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // date range picker
  const [activeFilter, setActiveFilter] = useState<any>('monthly');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('monthly');
  const [startDate, setStartDate] = useState<any>(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState<any>(moment().endOf('month').toDate());

  const getCustomerAnalyticsData = (param: any) => {
    setIsLoading(true);

    getCustomerAnalytics(param)
      .then((response: any) => {
        if (response.data) {
          setAnalytics(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const StartingDate = moment(startDate).local().format('YYYY-MM-DD');
    const EndingDate = moment(endDate).local().format('YYYY-MM-DD');

    getCustomerAnalyticsData({
      customerId,
      startDate: StartingDate,
      endDate: EndingDate,
    });
  }, [startDate, endDate]);

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    setActiveFilter(timeRange);
    setStartDate(start);
    setEndDate(end);

    if (!end) {
      setEndDate(start);
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between flex-wrap">
        <div>
          <h6 className="text-grayLight900 text-lg font-semibold sm:mb-0 mb-1">
            Analytics
          </h6>
        </div>

        <div>
          <DateRangePicker
            isDisplayAsBox={false}
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={moment()} // TODO : pass onboarding
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="-translate-x-1/2 left-1/2"
            parentClassName="sales-datepicker"
          />
        </div>
      </div>

      {isLoading ? (
        <AnlyticsDataLoading />
      ) : (
        <AnlyticsData analytics={analytics} activeFilter={activeFilter} />
      )}

      <HeatMap customerId={customerId} />
    </>
  );
};

export default CustomerDetailAnalyticsTab;
