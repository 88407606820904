import { Edit05, Plus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';

interface ActionButtonsProps {
  currentLegData: any;
  isCarrierAssigned: boolean;
  isOrderInReview: boolean;
  setAction: React.Dispatch<any>;
  handleEditAppointMent: (mode: string, data: any) => void;
  documents: any;
  dispute: any;
  claim: any;
  isInvoiced: any;
  isProgressLoading: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  currentLegData,
  isCarrierAssigned,
  setAction,
  handleEditAppointMent,
  documents,
  isOrderInReview,
  dispute,
  claim,
  isInvoiced,
  isProgressLoading,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-x-2">
      {isProgressLoading ? (
        <>
          <ButtonCmp className={`btn-outline-primary custom-loading`}>
            Assign Carrier
          </ButtonCmp>
          <ButtonCmp className={`btn-outline-primary custom-loading`}>
            Assign Carrier
          </ButtonCmp>
          <ButtonCmp className={`btn-outline-primary custom-loading`}>
            Assign Carrier
          </ButtonCmp>
        </>
      ) : (
        <>
          {currentLegData?.orderStatus === 'booked' && !isCarrierAssigned && (
            <ButtonCmp
              className="btn-outline-primary"
              onClick={() => {
                setAction((old: any) => ({ ...old, mode: 'assign-carrier' }));
              }}
            >
              Assign Carrier
            </ButtonCmp>
          )}

          {currentLegData?.orderStatus === 'booked' && (
            <>
              {!currentLegData?.carrierPickupAppointmentDatetime &&
                !currentLegData?.shipperAppointmentDatetime && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    icon={<Plus className="w-4 h-4" />}
                    onClick={() =>
                      setAction((old: any) => ({
                        ...old,
                        mode: 'carrier-pickup-appointment',
                      }))
                    }
                  >
                    Set Appointment
                  </ButtonCmp>
                )}

              {currentLegData?.carrierPickupAppointmentDatetime &&
                !currentLegData?.shipperAppointmentDatetime && (
                  <>
                    <ButtonCmp
                      className="btn-outline-primary"
                      icon={<Edit05 className="w-4 h-4" />}
                      onClick={() => {
                        setAction((old: any) => ({
                          ...old,
                          mode: 'carrier-pickup-appointment',
                        }));

                        handleEditAppointMent('carrier-pickup-appointment', {
                          date: currentLegData?.carrierPickupAppointmentDatetime,
                        });
                      }}
                    >
                      Edit Appointment
                    </ButtonCmp>
                    <ButtonCmp
                      className="btn-outline-primary"
                      icon={<Plus className="w-4 h-4" />}
                      onClick={() =>
                        setAction((old: any) => ({
                          ...old,
                          mode: 'shipper-appointment',
                        }))
                      }
                    >
                      Set Shipper Appointment
                    </ButtonCmp>
                  </>
                )}

              {currentLegData?.carrierPickupAppointmentDate && (
                <>
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'send-pick-up-reminder',
                      }));
                    }}
                  >
                    Send Pickup Reminder
                  </ButtonCmp>
                  {/* <ButtonCmp
                  className="btn-outline-primary"
                  icon={<Edit05 className="w-4 h-4" />}
                  onClick={() => {
                    setAction((old: any) => ({
                      ...old,
                      mode: 'carrier-pickup-appointment',
                    }));

                    handleEditAppointMent('carrier-pickup-appointment', {
                      date: currentLegData?.carrierPickupAppointmentDate,
                      time: currentLegData?.carrierPickupAppointmentTime,
                    });
                  }}
                >
                  Edit Pickup Appointment
                </ButtonCmp> */}
                </>
              )}
            </>
          )}

          {currentLegData?.orderStatus === 'dispatched' && (
            <>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={() => {
                  setAction((old: any) => ({
                    ...old,
                    mode: 'upload-customs-documents',
                  }));
                }}
              >
                <div>
                  Upload Customs Documents
                  <span className="text-red-600 ms-[2px] leading-4 text-xs">
                    *
                  </span>
                </div>
              </ButtonCmp>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={() => {
                  setAction((old: any) => ({
                    ...old,
                    mode: 'update-location',
                  }));
                }}
              >
                Update Location
              </ButtonCmp>
              {(!currentLegData?.claimId || !currentLegData?.disputeId) && (
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setAction((old: any) => ({
                      ...old,
                      mode: 'report-interruption',
                    }));
                  }}
                >
                  Report Interruption
                </ButtonCmp>
              )}
              {!currentLegData?.deliveryAppointmentDatetime && (
                <ButtonCmp
                  className="btn-outline-primary"
                  icon={<Plus className="w-4 h-4" />}
                  onClick={() =>
                    setAction((old: any) => ({
                      ...old,
                      mode: 'delivery-appointment',
                    }))
                  }
                >
                  Set Appointment
                </ButtonCmp>
              )}
            </>
          )}
          {/* BELOW TEXT WILL BE SHOWN AFTER POD UPLOADED */}
          {/* Review POD */}
          {/* BELOW TEXT WILL BE SHOWN AFTER POD APPROVED */}
          {/* View POD */}
          {currentLegData?.orderStatus === 'in-transit' && (
            <>
              {((documents &&
                documents?.filter((doc: any) => doc?.documentType === 'POD')
                  ?.length === 0) ||
                (documents &&
                  documents?.length > 0 &&
                  documents?.filter(
                    (doc: any) =>
                      doc?.documentType === 'POD' && !doc?.isApproved
                  )?.length > 0)) && (
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setAction((old: any) => ({
                      ...old,
                      mode: 'upload-pod',
                    }));
                  }}
                >
                  Upload POD
                </ButtonCmp>
              )}

              {!(
                documents &&
                documents?.length > 0 &&
                documents?.filter((doc: any) => doc?.documentType === 'POD')
                  ?.length > 0
              ) && (
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setAction((old: any) => ({
                      ...old,
                      mode: 'request-pod',
                    }));
                  }}
                >
                  Request POD
                </ButtonCmp>
              )}

              {documents &&
                documents?.length > 0 &&
                documents?.filter(
                  (doc: any) => doc?.documentType === 'POD' && !doc?.isApproved
                )?.length > 0 && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'review-pod',
                      }));
                    }}
                  >
                    Review POD
                  </ButtonCmp>
                )}

              {documents &&
                documents?.length > 0 &&
                documents?.filter(
                  (doc: any) => doc?.documentType === 'POD' && doc?.isApproved
                )?.length > 0 && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'view-pod',
                      }));
                    }}
                  >
                    View POD
                  </ButtonCmp>
                )}

              {documents &&
                documents?.length > 0 &&
                documents?.filter(
                  (doc: any) => doc?.documentType === 'POD' && doc?.isApproved
                )?.length > 0 && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'invoice-customer',
                      }));
                    }}
                    disabled
                  >
                    Invoice Customer
                  </ButtonCmp>
                )}
            </>
          )}

          {isOrderInReview && (
            <>
              {claim && claim?.status !== 'Resolved' && (
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    if (claim?.status === 'New') {
                      navigate(ROUTES.CLAIMS_AND_DISPUTES_DETAILS, {
                        state: { claimId: claim?.claimID },
                      });
                    } else {
                      navigate(`${PATH.CLAIM_DETAILS}/${claim?.id}`);
                    }
                  }}
                >
                  View Claim
                </ButtonCmp>
              )}

              {claim &&
                claim?.status !== 'New' &&
                claim?.status !== 'Resolved' && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'resolve-claim',
                      }));
                    }}
                  >
                    Resolve Claim
                  </ButtonCmp>
                )}

              {dispute && dispute?.status !== 'Resolved' && (
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    if (dispute?.status === 'New') {
                      navigate(ROUTES.CLAIMS_AND_DISPUTES_DETAILS, {
                        state: { disputeId: dispute?.disputeID },
                      });
                    } else {
                      navigate(`${PATH.DISPUTE_DETAILS}/${dispute?.id}`);
                    }
                  }}
                >
                  View Dispute
                </ButtonCmp>
              )}

              {dispute &&
                dispute?.status !== 'New' &&
                dispute?.status !== 'Resolved' && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'resolve-dispute',
                      }));
                    }}
                  >
                    Resolve Dispute
                  </ButtonCmp>
                )}
            </>
          )}

          {isInvoiced && (
            <ButtonCmp className="btn-outline-primary" disabled>
              View Customer Invoice
            </ButtonCmp>
          )}

          {/* WHEN STATUS IS In Review */}
          {/* WHEN STATUS IS In Invoiced */}
        </>
      )}
    </div>
  );
};

export default ActionButtons;
