import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listUser = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.USER.LIST, {
    params,
    signal,
  });

  return response;
};

export const listUserCount = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.USER.LIST_COUNT, {
    params,
    signal,
  });

  return response;
};

export const getUserDetail = async (id: any) => {
  const response = await axiosInterceptor.get(`${API.USER.GET_DETAIL}/${id}`);

  return response;
};

export const createUser = async (data: any) => {
  const response = await axiosInterceptor.post(API.USER.CREATE, data);

  return response;
};

export const updateUser = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.USER.UPDATE}/${id}`,
    data
  );

  return response;
};

export const deleteUser = async (id: number) => {
  const response = await axiosInterceptor.delete(`${API.USER.DELETE}/${id}`);

  return response;
};

export const updateUserPermission = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.USER.PERMISSION}/${id}`,
    data
  );

  return response;
};

export const removeUserFromTeam = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.USER.REMOVE_FROM_TEAM}`,
    data
  );

  return response;
};
