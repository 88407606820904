import React from 'react';
import { updateOrCreateCarrierPrice } from 'src/services/OrderService';

import CarrierModal from '../../Common/Carrier/CarrierModal';

interface IProps {
  currentLegData: any;
  action: any;
  setAction: any;
  setCarrierData: any;
  masterOrderId: any;
  order: any;
}

const AssignCarrier = ({
  currentLegData,
  order,
  action,
  setAction,
  setCarrierData,
  masterOrderId,
}: IProps) => {
  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    updateOrCreateCarrierPrice({
      ...newCarrierPrice,
      orderLegId: currentLegData?.id,
      masterOrderId: masterOrderId,
    })
      .then((response) => {
        if (response?.data) {
          let carrierPrice = {};

          if (response?.data && response?.data.length > 0) {
            carrierPrice = response.data.map((data: any) => ({
              ...data,
              additional_charge: data.additional_charge
                ? JSON.parse(data.additional_charge)
                : [],
              carrier: data.carrier ? JSON.parse(data.carrier) : {},
            }));
          }

          setAction((old: any) => ({ ...old, mode: null }));
          setCarrierData(carrierPrice);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      {action.mode === 'assign-carrier' && (
        <CarrierModal
          handleClose={() => {
            setAction((old: any) => ({ ...old, mode: null }));
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={null}
          customerId={order?.cusotmerId}
        />
      )}
    </>
  );
};

export default AssignCarrier;
