import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { ONBOARDING_CUSTOMER_STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

// import Streaks from '../../assets/img/streaks.svg';

const CreditCustomerRow = ({ data, openCustomerPage, setAction }: any) => (
  <>
    <td
      className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]`}
      onClick={() => openCustomerPage(data)}
    >
      <div className={`flex items-center gap-3 cursor-pointer`}>
        <div className="table-profile-wrap notification-mark-wrap">
          {data.userImage ? (
            <img
              className="table-profile-icn-circle-xl"
              src={data.userImageUrl + data.userImage}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
        </div>

        {(data.firstName + data.lastName).length > 20 ? (
          <TooltipCmp
            message={`${data.firstName} ${data.lastName}`}
            childrenClassName="text-grayLight900 font-medium "
          >
            <p className="truncate">{data.name}</p>
          </TooltipCmp>
        ) : (
          <p className="truncate text-grayLight900 font-semibold leading-5">
            {`${data.firstName} ${data.lastName}`}
          </p>
        )}
      </div>
    </td>
    <td
      className={`px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]`}
      onClick={() => openCustomerPage(data)}
    >
      <div className="flex flex-wrap gap-1">
        {data.status === ONBOARDING_CUSTOMER_STATUS.APPROVED && (
          <BadgeCmp style="modern" type="success">
            Approved
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.FORM_COMPLETED && (
          <BadgeCmp style="modern" type="primary">
            Form Completed
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.FORM_PENDING && (
          <BadgeCmp style="modern" type="blue">
            Form Pending
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.IN_REVIEW && (
          <BadgeCmp style="modern" type="primary">
            In Review
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.NEW && (
          <BadgeCmp style="modern" type="blue">
            New
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.EXPIRED && (
          <BadgeCmp style="modern" type="error">
            Expired
          </BadgeCmp>
        )}
        {data.status === ONBOARDING_CUSTOMER_STATUS.DENIED && (
          <BadgeCmp style="modern" type="red">
            Denied
          </BadgeCmp>
        )}
      </div>
    </td>
    <td
      className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]`}
      onClick={() => openCustomerPage(data)}
    >
      <div className={`flex items-center gap-3 cursor-pointer`}>
        {/* <div className="table-profile-wrap notification-mark-wrap">
          {data.userImage ? (
            <img
              className="table-profile-icn-circle-sm"
              src={data.userImageUrl + data.userImage}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-sm">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
        </div> */}
        {data.businessName?.length > 20 ? (
          <TooltipCmp
            message={data?.businessName}
            childrenClassName="text-grayLight900 "
          >
            <p className="truncate text-grayLight900 leading-5">
              {data?.businessName}
            </p>
          </TooltipCmp>
        ) : (
          <p className="truncate text-grayLight900 leading-5">
            {data?.businessName}
          </p>
        )}
      </div>
    </td>
    <td
      className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]`}
      onClick={() => openCustomerPage(data)}
    >
      {data?.contactName}
    </td>
    <td
      className={`px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px] truncate`}
      onClick={() => openCustomerPage(data)}
    >
      {data?.contactEmail}
    </td>
    <td
      className={`px-5 py-4 min-w-[170px] w-[17.75%] max-w-[17.75%]`}
      onClick={() => openCustomerPage(data)}
    >
      {data.createdAt ? (
        <span
          dangerouslySetInnerHTML={{
            __html: data.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.createdAt,
                    `MMM Do , hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      ) : (
        ''
      )}
    </td>
    <td
      className={`px-5 py-4  min-w-[220px] w-[17.75%] max-w-[17.75%]`}
      onClick={() => openCustomerPage(data)}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: data.form_view_date
            ? getDateWithSuffixFormat(
                getFormattedDate(
                  data.form_view_date,
                  `MMM Do , hh:mm A`,
                  true,
                  true
                )
              )
            : '-',
        }}
      />
    </td>
    <td
      className={`px-5 py-4 min-w-[195px] w-[17.75%] max-w-[17.75%]`}
      onClick={() => openCustomerPage(data)}
    >
      <span>{data.contactEmail}</span>
    </td>

    <td className={`px-5 py-4 w-20 max-w-20 min-w-20`}>
      {data?.status === ONBOARDING_CUSTOMER_STATUS.APPROVED ? (
        <CheckCircle
          className="text-green500 w-4 h-4 cursor-pointer"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ mode: 'next-task', id: data?.id },
            }))
          }
        />
      ) : (
        <CheckCircle className="text-green500 w-4 h-4 invisible cursor-pointer" />
      )}
    </td>
  </>
);
export default CreditCustomerRow;
