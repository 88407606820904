import { getSalesPrice } from 'src/services/OrderService';

export const appointmentDetails: any = {
  'carrier-pickup-appointment': {
    title: 'Carrier Pickup Appointment',
    description: 'Assign a carrier to complete your shipment',
    draftTitle: 'RE: Request to Set Carrier Pickup Appointment',
    sendMailTo: 'carrier',
  },
  'carrier-delivery-appointment': {
    title: 'Carrier Delivery Appointment',
    description: 'Assign a carrier to complete your shipment',
    draftTitle: 'RE: Request to Set Carrier delivery Appointment',
    sendMailTo: 'carrier',
  },
  'shipper-appointment': {
    title: 'Shipper Appointment',
    description: 'Assign a shipper to complete your shipment',
    draftTitle: 'RE: Request to Set Shipper Pickup Appointment',
    sendMailTo: 'shipper',
  },

  'delivery-appointment': {
    title: 'Consignee Appointment',
    description: 'Assign a consignee to complete your shipment',
    draftTitle: 'RE: Request to Set Delivery Appointment',
    sendMailTo: 'consignee',
  },
};

export const OrderStatusAction = {
  MARK_AS_PICKED_UP: {
    title: 'Mark as Picked Up',
    value: 'mark-as-picked-up',
    toolTip: null,
  },
  MARK_AS_DISPATCHED: {
    title: 'Mark as Dispatched',
    value: 'mark-as-dispatched',
    toolTip: 'Revert Picked Up',
  },
  MARK_AS_IN_TRANSIT: {
    title: 'Mark as In-transit',
    value: 'mark-as-in-transit',
    toolTip: 'Revert Delivery',
  },
  MARK_AS_DELIVERED: {
    title: 'Mark as Delivered',
    value: 'mark-as-delivered',
    toolTip: 'Revert Picked Up',
  },
  MARK_AS_COMPLETED: {
    title: 'Mark as Completed',
    value: 'mark-as-completed',
    toolTip: 'Revert Delivery',
  },
};

export const constructOrderParam = (
  masterOrder: any,
  id: number,
  weightMeasurement: string,
  lengthMeasurement: string
) => ({
  shipperCompanyName: masterOrder?.shipper?.companyName,
  shipperContactName: masterOrder?.shipper?.contactName,
  shipperContactNumber: masterOrder?.shipper?.phoneNumber,
  shipperEmail: masterOrder?.shipper?.email,
  shipperFullAddress: masterOrder?.shipper?.shipperAddress?.fullAddress,
  shipperAddress1: masterOrder?.shipper?.shipperAddress?.address1,
  shipperCity: masterOrder?.shipper?.shipperAddress?.city,
  shipperState: masterOrder?.shipper?.shipperAddress?.state,
  shipperCountry: masterOrder?.shipper?.shipperAddress?.country,
  shipperPostal: masterOrder?.shipper?.shipperAddress?.postal,
  shipperLatitude: masterOrder?.shipper?.shipperAddress?.latitude,
  shipperLongitude: masterOrder?.shipper?.shipperAddress?.longitude,
  shipperStateCode: masterOrder?.shipper?.shipperAddress?.stateCode,
  shipperCountryCode: masterOrder?.shipper?.shipperAddress?.countryCode,
  consigneeCompanyName: masterOrder?.consignee?.companyName,
  consigneeContactName: masterOrder?.consignee?.contactName,
  consigneeContactNumber: masterOrder?.consignee?.phoneNumber,
  consigneeEmail: masterOrder?.consignee?.email,
  consigneeFullAddress: masterOrder?.consignee?.consigneeAddress?.fullAddress,
  consigneeAddress1: masterOrder?.consignee?.consigneeAddress?.address1,
  consigneeCity: masterOrder?.consignee?.consigneeAddress?.city,
  consigneeState: masterOrder?.consignee?.consigneeAddress?.state,
  consigneeCountry: masterOrder?.consignee?.consigneeAddress?.country,
  consigneePostal: masterOrder?.consignee?.consigneeAddress?.postal,
  consigneeLatitude: masterOrder?.consignee?.consigneeAddress?.latitude,
  consigneeLongitude: masterOrder?.consignee?.consigneeAddress?.longitude,
  consigneeStateCode: masterOrder?.consignee?.consigneeAddress?.stateCode,
  consigneeCountryCode: masterOrder?.consignee?.consigneeAddress?.countryCode,
  id,
  order_dimensions: masterOrder?.order_dimensions,
  declaredValue: masterOrder?.declaredValue,
  declaredValueUnit: masterOrder?.declaredValueUnit,
  equipmentType: masterOrder?.equipmentType,
  linearFootage: masterOrder?.linearFootage,
  orderType: masterOrder?.orderType,
  poNumber: masterOrder?.poNumber,
  refNumber: masterOrder?.refNumber,
  specialNotes: masterOrder?.specialNotes,
  weightMeasurement,
  lengthMeasurement,
  additionalServices: masterOrder?.additionalServices,
  carrierPrice: masterOrder?.carrierPrice,
  salesPrice: masterOrder?.salesPrice,
});

export const getSalesPriceAPI = (params: any, handleSubmitFunction: any) => {
  getSalesPrice(params)
    .then((response) => {
      if (response?.data) {
        handleSubmitFunction();
      }
    })
    .catch(console.error);
};

export const ORDER_STATUS_TAB: { value: string; name: string }[] = [
  {
    value: 'all',
    name: 'View All',
  },
  {
    value: 'draft',
    name: 'Drafted',
  },
  {
    value: 'booked',
    name: 'Booked',
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
  },
  {
    value: 'in-transit',
    name: 'In-Transit',
  },
  {
    value: 'delivered',
    name: 'Delivered',
  },
  {
    value: 'cancelled',
    name: 'Canceled',
  },
  {
    value: 'completed',
    name: 'Completed',
  },
];

export const ORDER_TYPE_TAB: { value: string; name: string }[] = [
  {
    value: 'rose-rocket-order',
    name: 'RoseRocket Order',
  },
  {
    value: 'new-order',
    name: 'End To End Order',
  },
];

export const END_TO_END_ORDER_HEAD_CELLS = [
  {
    id: 'salesRepId',
    name: 'User',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'orderStatus',
    name: 'Status',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'customerName',
    name: 'Customer',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'carrierName',
    name: 'Carrier',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'orderFromOrgName',
    name: 'Address',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'createdAt',
    name: 'Order Created',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'os.totalSalePrice',
    name: 'Total Sales Price',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'carrierTotalPrice',
    name: 'Total Cost',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMarginPercentage',
    name: 'Margin %',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'combinedMargin',
    name: 'Margin',
    rowClassName: '',
    sortable: false,
  },
  {
    id: '',
    name: '',
    rowClassName: '',
    sortable: false,
  },
];

export const ROSE_ROCKET_ORDER_HEAD_CELLS = [
  {
    id: 'orderCommissionees',
    name: 'User',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderStatus',
    name: 'Status',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'customerName',
    name: 'Customer',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderFromOrgName',
    name: 'Address',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderCreateTime',
    name: 'Order Created',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedRevenue',
    name: 'Total Revenue',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedCost',
    name: 'Cost',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMarginPercentage',
    name: 'Margin %',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMargin',
    name: 'Margin',
    rowClassName: '',
    sortable: true,
  },
];

export const INIT_ROSE_ROCKET_ORDER_PARAMS: any = {
  search: '',
  sortType: 'desc',
  sortField: 'orderCreateTime',
  page: 1,
  limit: 25,
  team: 'all',
  member: 'all',
};

export const INIT_E2E_ORDER_PARAMS: any = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  team: 'all',
  member: 'all',
};

export const getEmailMessageForAppointMent = (
  actionType: any,
  currentLegData: any
) => {
  switch (actionType) {
    case 'carrier-pickup-appointment':
      return `I hope this message finds you well. We would like to arrange a carrier appointment for pick-up of your order: #${currentLegData?.legId}. Please let us know when you are available.`;
    case 'carrier-delivery-appointment':
      return `I hope this message finds you well. We would like to arrange a carrier appointment for delivery of your order: #${currentLegData?.legId}. Please let us know when you are available.`;
    case 'shipper-appointment':
      return `This is to inform you that your shipment with order #${currentLegData?.legId} is scheduled for pick-up. Kindly confirm the available time for pick-up.`;
    case 'delivery-appointment':
      return `We would like to confirm the delivery details for your order #${currentLegData?.legId}. Please provide us with the suitable time for the delivery.`;
    default:
      return `I hope this message finds you well. Please provide us with the required details for order #${currentLegData?.legId}.`;
  }
};
