import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const salesPriceRow = ({ data, handleSalesEdit, customerList }: any) => {
  // console.log('data', data);
  const currentCustomer =
    customerList?.find(
      (customer: any) => customer.value === data?.customerId
    ) ?? null;

  const additionalChargesTotal = Array.isArray(data?.additional_charge)
    ? data?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  return (
    <>
      <td className="px-5 py-4 w-[23.86%] max-w-[1px] min-w-[23.86%]">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            {currentCustomer?.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={`${currentCustomer.image}`}
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(currentCustomer?.label)}
              </div>
            )}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {currentCustomer?.label}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              USD Billing Account
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>${data?.baseSalePrice}</span>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>${additionalChargesTotal}</span>
      </td>
      <td className="px-5 py-4 w-[23.86%] max-w-[23.86%] min-w-[23.86%]">
        <span>${data?.totalSalePrice}</span>
      </td>
      <td className="px-5 py-4 w-[56px] min-w-[56px] max-w-[56px]">
        <Edit01
          className="w-4 h-4 cursor-pointer"
          onClick={() => {
            handleSalesEdit(data);
          }}
        />
      </td>
    </>
  );
};

export default salesPriceRow;
