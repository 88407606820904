import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CustomerSplit from 'src/app/SalesDashboard/OverView/CustomerSplit';
import DateRangePicker from 'src/components/DateRangePicker';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
// import SelectBox from 'src/components/SelectBox/SelectBox';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { TIME_FILTER } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import useCountUp from 'src/hooks/useCountUp';
import { getAnalytics } from 'src/services/QuoteService';
import { getSalesData, getTeamList } from 'src/services/SalesDashboard';
import {
  convertSecondsToTime,
  getDateRange,
  getFormattedNumber,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

import KpiCard from './KpiCard';
import QuotesGraphs from './QuotesGraph';
import RequestGraph from './RequestGraph';
import QuotingTeamTable from './Table';
import TotalQuotesGraph from './TotalQuotesGraph';

const TABS: any = [
  {
    value: 'requests',
    name: 'Requests',
  },
  {
    value: 'quotes',
    name: 'Quotes',
  },
];
const teamTabArray: any[] = [
  {
    value: 'teamPerformance',
    name: 'Team Performance',
    count: 0,
  },
  {
    value: 'customerSplit',
    name: 'Customer Split',
    count: 0,
  },
];
interface IAnalytics {
  totalHits: number;
  totalOrders: number;
  totalQuotes: number;
  totalRequests: number;
  totalWonLoss: number;
  totalHitsGrowth: number;
  totalQuotesGrowth: number;
  totalOrdersGrowth: number;
  totalWonLossGrowth: number;
  totalRequestsGrowth: number;
  avgTimeToClassify: number;
  avgTimeToQuote: number;
  avgTimeToClassifyGrowth: number;
  avgTimeToQuoteGrowth: number;
  quotingPercentage: number;
  graph: {
    labels: string[];
    value: {
      hit: number[];
      requests: number[];
      orders: number[];
      quotes: number[];
    };
  };
  requestQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
  totalQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
}

const QuotingAnalytics = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].value);
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [activeTableTab, setActiveTableTab] = useState(teamTabArray[0].value);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isSalesLoading, setIsSalesLoading] = useState<boolean>(true);

  const [analyticsData, setAnalyticsData] = useState<IAnalytics>({
    totalHits: 0,
    totalOrders: 0,
    totalQuotes: 0,
    totalRequests: 0,
    totalWonLoss: 0,
    totalHitsGrowth: 0,
    totalQuotesGrowth: 0,
    totalOrdersGrowth: 0,
    totalWonLossGrowth: 0,
    totalRequestsGrowth: 0,
    avgTimeToClassify: 0,
    avgTimeToQuote: 0,
    avgTimeToClassifyGrowth: 0,
    avgTimeToQuoteGrowth: 0,
    quotingPercentage: 0,
    graph: {
      labels: [],
      value: {
        hit: [],
        requests: [],
        orders: [],
        quotes: [],
      },
    },
    requestQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
    totalQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
  });
  const { appearance } = useContext(BasicContext);
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const [boxLabel, setBoxLabel] = useState('Yesterday');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [startDate, setStartDate] = useState<any>(() => '');
  const [endDate, setEndDate] = useState<any>(() => '');
  const [userList, setUserList] = useState<any[]>([]);
  const { hasRoleV2 } = useRolePermission();
  useEffect(() => {
    setFilterAfterPrevNext(activeFilter);

    if (activeFilter !== '') {
      const { start, end } = getDateRange(activeFilter);
      console.log(start, end);

      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    }

    if (activeFilter === 'weekly') {
      setBoxLabel('Last Week');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'monthly') {
      setBoxLabel('Last Month');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'yearly') {
      setBoxLabel('Last Year');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'daily') {
      setBoxLabel('Yesterday');

      setSelectedTimeRange('custom');
    }
  }, [activeFilter]);

  // const [selectedTeam, setSelectedTeam] = useState('');
  // const [selectedUser, setSelectedUser] = useState('');
  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamOptions(teamData);
        // setSelectedTeam('all');
        // setSelectedUser('all');
      })
      .catch(console.error);
  }, []);

  const rightSideContent = (
    <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center ">
      <TabButton
        className="flex-1 lg:max-w-[none] sm:max-w-28 w-full flex justify-center items-center"
        tabArray={TABS}
        isActive={activeTab}
        activeClassName="bg-primary text-white"
        parentClassName="w-full "
        tabParentClassName="w-full justify-end"
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
      />
    </div>
  );
  const salesDataController = new AbortController();
  const salesDataSignal = salesDataController.signal;

  const getCustomerList = () => {
    const data = {
      order: 'projected',
      team: selectedTeam ?? 'all',
      member: selectedUser ?? 'all',
      startDate:
        selectedTimeRange === 'custom'
          ? moment(startDate).local().format('YYYY-MM-DD')
          : moment(startDate).format('YYYY-MM-DD'),
      endDate:
        selectedTimeRange === 'custom'
          ? moment(endDate).local().format('YYYY-MM-DD')
          : moment(endDate).format('YYYY-MM-DD'),
    };
    getSalesData({ ...data, type: activeFilter }, salesDataSignal)
      .then((response: any) => {
        setCustomerList(response.customer);
        setIsSalesLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsSalesLoading(false);

        if (e.response?.data.message) {
          WalToast.error(e.response.data.message, '');
        }
      });
  };

  useEffect(() => {
    if (teamOptions && teamOptions?.length > 0) {
      let uniqueArr = [];

      if (selectedTeam === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamOptions.map((tlo) => tlo.members);
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
        setSelectedUser('all');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamOptions.find((tlo) => tlo.value === selectedTeam);
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setUserList(updatedListAllMembers);
    }
  }, [selectedTeam, teamOptions]);

  const getFilterRange = (type?: string) => {
    setFilterAfterPrevNext(activeFilter);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      // setIsNextYearDisable(false);
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      // if (moment(endDT, "DD-MM-YYYY").isSameOrAfter(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))) {
      //     setIsNextYearDisable(true);
      // }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const getAnalyticsData = async () => {
    try {
      const param = {
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
        userId: selectedUser === 'all' ? null : selectedUser,
        teamId: selectedTeam === 'all' ? null : selectedTeam,
      };
      const response: any = await getAnalytics(param);

      if (response?.data) {
        console.log('response', response);
        setAnalyticsData({
          totalHits: response?.data?.totalHits,
          totalOrders: response?.data?.totalOrders,
          totalQuotes: response?.data?.totalQuotes,
          totalRequests: response?.data?.totalRequests,
          totalWonLoss: +response?.data?.totalWonLoss,
          totalHitsGrowth: +response?.data?.totalHitsGrowth,
          totalQuotesGrowth: +response?.data?.totalQuotesGrowth,
          totalOrdersGrowth: +response?.data?.totalOrdersGrowth,
          totalWonLossGrowth: +response?.data?.totalWonLossGrowth,
          totalRequestsGrowth: +response?.data?.totalRequestsGrowth,
          avgTimeToClassify: +response?.data?.avgTimeToClassify,
          avgTimeToQuote: +response?.data?.avgTimeToQuote,
          avgTimeToClassifyGrowth: +response?.data?.avgTimeToClassifyGrowth,
          avgTimeToQuoteGrowth: +response?.data?.avgTimeToQuoteGrowth,
          quotingPercentage: +response?.data?.quotingPercentage,
          graph: {
            labels: response?.data?.categories,
            value: response?.data?.data,
          },
          totalQuotePieChartData: response?.data?.totalQuotePieChartData,
          requestQuotePieChartData: response?.data?.requestQuotePieChartData,
        });
      }
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    }
  };
  useEffect(() => {
    if (activeTableTab === teamTabArray[0].value) {
      getAnalyticsData();
    } else {
      getCustomerList();
    }
  }, [startDate, endDate, selectedUser, selectedTeam, activeTableTab]);
  console.log('startDate', startDate);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quoting Analytics"
          desc="Current quoting analytics and activity."
          rightSideContent={rightSideContent}
          mainContainerClassName="!flex-row "
          leftClassName="!w-auto"
          rightClassName="xxl:!w-[30%] xlm:!w-[32%] xl:!w-[27%] sm:!w-auto !w-full"
        />
      }
    >
      <div className="h-full w-full xxl:gap-4 xl:gap-3 gap-3 flex flex-col">
        <div
          className={`w-full justify-between sm:items-center flex  xls:flex-row flex-col flex-wrap xxl:gap-4 xl:gap-3 gap-3 `}
        >
          <div
            className={`flex-1 flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full`}
          >
            <TabButton
              className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
              activeClassName="!bg-utilityGray100"
              tabArray={TIME_FILTER}
              parentClassName="w-full"
              tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
              isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
              handleOnClick={(e: any) => {
                if (activeFilter === e.target.dataset.value) getFilterRange();
                setActiveFilter(e.target.dataset.value);
              }}
            />
            <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full items-center cursor-pointer justify-between flex">
              <DateRangePicker
                isDisplayAsBox={false}
                handleDatePickerChange={(dates: any, type?: string) =>
                  handleDatePickerChange(dates, type)
                }
                selectedTimeRange={selectedTimeRange}
                startDate={startDate}
                endDate={endDate}
                allTimeStartDate={''}
                showYearDropdown={false}
                isToday={activeFilter === 'daily'}
                isShowDropDownIcon={false}
                containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
                parentClassName="sales-datepicker"
                isPrevDisabled={isPrevYearDisable}
                onPrevNextClick={(type: string) => getFilterRange(type)}
              />
            </div>
          </div>

          <div className="flex sm:flex-row  flex-wrap xxl:gap-4 gap-3 sm:justify-end xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full empty:hidden ">
            <>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  options={teamOptions}
                  onChangeFunc={(event: any) => {
                    setSelectedTeam(event?.value ?? 'all');
                    setSelectedUser('all');
                  }}
                  value={teamOptions.filter(
                    (val) => val?.value == selectedTeam
                  )}
                />
              </div>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  noOptionMessage="No Users Found"
                  isSearchable={true}
                  className="form_control"
                  isClearable={true}
                  options={userList}
                  onChangeFunc={(event: any) => {
                    setSelectedUser(event?.value ?? 'all');
                  }}
                  value={userList.filter((val) => val?.value == selectedUser)}
                />
              </div>
            </>
          </div>
        </div>
        <div className="flex flex-wrap xxl:gap-4 gap-3">
          <div className="w-full flex flex-col xxl:gap-3 gap-3">
            <div className="w-full grid grid-cols-4 gap-3">
              <KpiCard
                headerTitle="Total Hits"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalHits.toFixed(2))
                )}
                growth={analyticsData.totalHitsGrowth}
                fromDate={boxLabel}
                tooltipText="Total Hits"
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Total Quote Requests"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalRequests.toFixed(2))
                )}
                growth={analyticsData.totalRequestsGrowth}
                fromDate={boxLabel}
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Total Quotes"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalQuotes.toFixed(2))
                )}
                growth={analyticsData.totalQuotesGrowth}
                fromDate={boxLabel}
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Total Orders"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalOrders.toFixed(2))
                )}
                growth={analyticsData.totalOrdersGrowth}
                fromDate={boxLabel}
                // isLoading={isLoading}
              />
            </div>
            <div className="w-full grid grid-cols-2 gap-3">
              <RequestGraph
                graphData={analyticsData.requestQuotePieChartData}
              />
              <QuotesGraphs graphData={analyticsData.totalQuotePieChartData} />
            </div>

            <div className="w-full grid grid-cols-4 gap-3">
              <KpiCard
                headerTitle="Avg. Time to Classify"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToClassify
                )}
                growth={analyticsData.avgTimeToClassifyGrowth}
                fromDate={boxLabel}
                tooltipText="Avg. Time to Classify"
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Quoting Percentage"
                tooltipText="Quoting Percentage"
                contentLable={`${getFormattedNumber(
                  useCountUp(+analyticsData.quotingPercentage.toFixed(2))
                )}`}
                growth={-36}
                signIcon={'%'}
                fromDate={boxLabel}
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Avg. Time to Quote"
                tooltipText="Avg. Time to Quote"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToQuote
                )}
                growth={analyticsData.avgTimeToQuoteGrowth}
                fromDate={boxLabel}
                // isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Win/Loss"
                tooltipText="Win/Loss"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalWonLoss.toFixed(2))
                )}
                growth={analyticsData.totalWonLossGrowth}
                fromDate={boxLabel}
                signIcon={'%'}
                // isLoading={isLoading}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <TotalQuotesGraph data={analyticsData} />
        </div>

        <TabButton
          className=""
          tabArray={teamTabArray}
          isActive={activeTableTab}
          activeClassName="!bg-utilityGray100"
          handleOnClick={(e: any) => {
            setActiveTableTab(e?.target?.dataset?.value);
          }}
        />

        {activeTableTab === teamTabArray[0].value ? (
          <QuotingTeamTable
            startDate={startDate}
            endDate={endDate}
            userId={selectedUser}
            teamId={selectedTeam}
          />
        ) : (
          <div className="w-full bg-white rounded-xl border border-utilityGray200 shadow flex">
            <CustomerSplit
              customerList={customerList}
              isDataLoading={isSalesLoading}
            />
          </div>
        )}
      </div>
    </PageSectionLayout>
  );
};
export default QuotingAnalytics;
