import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { deleteAppointment } from 'src/services/OrderService';

import { OrderContext } from '..';
import CommonAppointmentDetails from '../../Common/Appointment/CommonAppointmentDetails';
import DraftMailModal from '../../Common/DraftMailModal';
import { appointmentDetails } from '../../order.constant';

import AppointmentModal from './AppointmentModal';
import AppointmentSuccessModal from './AppointmentSuccessModal';

interface IProps {
  action: any;
  setAction: any;
  orderLegId: any;
}

export const initAction = {
  mode: null,
  type: null,
};

const AppointmentDetails = ({ action, setAction, orderLegId }: IProps) => {
  const { setValue } = useFormContext();

  const {
    currentLegData,
    setCurrentLegData,
    setTabValues,
    activeLeg,
    tabValues,
    isLoading,
  } = useContext<any>(OrderContext);

  const [localLegData, setLocalLegData] = useState(currentLegData || {});

  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });

  useEffect(() => {
    setLocalLegData(currentLegData);
  }, [currentLegData]);

  const handleDeleteAppointment = (type: any) => {
    deleteAppointment({ orderLegId, type: type })
      .then((response) => {
        if (response?.data) {
          const updatedLegData = { ...localLegData };

          if (type === 'shipper-appointment') {
            updatedLegData.shipperAppointmentDatetime = null;
            setValue('shipperAppointmentDatetime', null);
          } else if (type === 'carrier-pickup-appointment') {
            updatedLegData.carrierPickupAppointmentDatetime = null;
            setValue('carrierPickupAppointmentDatetime', null);
          } else if (type === 'carrier-delivery-appointment') {
            updatedLegData.carrierDeliveryAppointmentDatetime = null;
            setValue('carrierDeliveryAppointmentDatetime', null);
          } else if (type === 'delivery-appointment') {
            updatedLegData.deliveryAppointmentDatetime = null;
            setValue('deliveryAppointmentDatetime', null);
          }

          setLocalLegData(updatedLegData);
          setCurrentLegData((old: any) => ({ ...old, ...updatedLegData }));

          const updatedTabLegData = {
            ...tabValues[activeLeg],
            shipperAppointmentDate: null,
            shipperAppointmentTime: null,
          };

          setTabValues((prevTabValues: any) => ({
            ...prevTabValues,
            [activeLeg]: updatedTabLegData,
          }));
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <h5 className="text-grayLight900 text-xl font-semibold">
        Set Your Appointments
      </h5>

      <CommonAppointmentDetails
        handleDeleteAppointment={handleDeleteAppointment}
        setAction={setAction}
        localLegData={localLegData}
        setEditMode={setEditMode}
        isShowDraftEmail={false}
        isLoading={isLoading}
      />

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={orderLegId}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let date = '';
            let type = action.mode;

            if (type === 'carrier-pickup-appointment') {
              date = 'carrierPickupAppointmentDatetime';
            } else if (type === 'carrier-delivery-appointment') {
              date = 'carrierDeliveryAppointmentDatetime';
            } else if (type === 'shipper-appointment') {
              date = 'shipperAppointmentDatetime';
            } else if (type === 'delivery-appointment') {
              date = 'deliveryAppointmentDatetime';
            }

            const updatedLegData = {
              ...currentLegData,
              [`${date}`]: formattedData.combinedDatetime,
            };

            if (setValue) {
              setValue(`${date}`, formattedData.combinedDatetime);
            }

            if (setCurrentLegData) {
              setCurrentLegData(updatedLegData);
            }

            if (setTabValues) {
              setTabValues((prevTabValues: any) => ({
                ...prevTabValues,
                [activeLeg]: {
                  ...prevTabValues[activeLeg],
                  [`${type}Datetime`]: formattedData.combinedDatetime,
                },
              }));
            }

            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={`Set Appointment`}
          DraftDetails={{
            subjectLine: 'RE: Request to Set Carrier Appointment',
            toEmailAddress: `${currentLegData?.customerEmail}`,
            emailDetails: `I hope this message finds you well. We would like to arrange a carrier appointment for pick-up of your order: #${currentLegData?.legId}. Please let us know when you are available.`,
          }}
        />
      )}
    </>
  );
};

export default AppointmentDetails;
