import { Minimize02, Truck01 } from '@untitled-ui/icons-react/build/cjs';
import { Package } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import { orderList } from 'src/services/OrderService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

const initParams = {
  search: '',
  sortType: 'asc',
  sortField: 'orderCreateTime',
  filter: 'in-transit',
  isPagination: false,
};

const InTransit = ({ handleClose, orderId, setOrderId }: any) => {
  const [orders, setOrders] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [params, setParams] = useState(initParams);
  // const [orderId, setOrderId] = useState<string>('');

  console.log('first', setParams);

  const getOrderList = () => {
    setIsOrdersLoading(true);
    setOrders([]);
    orderList({ ...params, ...{ isPagination: true } })
      .then((response: any) => {
        setOrders(response.data ?? []);
        setIsOrdersLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersLoading(false);
      });
  };

  useEffect(() => {
    console.log('orderId', orderId);
    let activeElement = document.getElementById(orderId);

    if (activeElement) {
      activeElement.className = 'active-order';
    }
  }, [orderId]);

  useEffect(() => {
    getOrderList();
  }, [params]);

  return (
    <div className="fixed bottom-10 right-10 w-[450px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex items-start gap-2">
          <div className="bg-utilityGray100 rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
            <Truck01 className="w-4 h-4" />
          </div>
          <div className="flex-1">
            <h6 className="text-grayLight900 text-base font-semibold">
              InTransit Shipments
            </h6>
            <p className="text-gray500 text-xs font-normal">
              Here is an overview of intransit shipments.
            </p>
          </div>
          <ButtonCmp className="!p-1.5 btn_secondary_black">
            <Minimize02
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={handleClose}
            />
          </ButtonCmp>
        </div>
        <ul>
          {orders.map((value: any, index: any) => (
            <li
              key={index}
              className="rounded-lg border border-utilityGray200 p-3 mt-3 cursor-pointer"
              onClick={() => setOrderId(value?.fullId)}
            >
              <div className="flex items-center gap-x-2.5 mb-4">
                <div className="rounded-md border border-utilityGray200 bg-utilityGray100 p-2">
                  <Package className="w-4 h-4 text-grayLight600" />
                </div>
                <div className="flex-1">
                  <Link
                    to={'#'}
                    className={`text-primary underline text-sm font-medium ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    #{value?.fullId}
                  </Link>
                </div>
                {/* Tags */}
                {value?.orderStatus === 'in-transit' && (
                  <BadgeCmp style="modern" type="pink">
                    {value?.orderStatus}
                  </BadgeCmp>
                )}
              </div>
              <div className="flex flex-col gap-y-1.5">
                <div className="flex justify-between gap-x-3 gap-y-1">
                  <p className="text-grayLight600 text-xs font-normal">
                    Shipment
                  </p>
                  <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium">
                    ({value?.orderFromCity}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="9"
                      viewBox="0 0 11 9"
                      fill="none"
                    >
                      <path
                        d="M10.3536 4.68688C10.5488 4.49162 10.5488 4.17504 10.3536 3.97977L7.17157 0.797794C6.97631 0.602532 6.65973 0.602532 6.46447 0.797794C6.2692 0.993057 6.2692 1.30964 6.46447 1.5049L9.29289 4.33333L6.46447 7.16176C6.2692 7.35702 6.2692 7.6736 6.46447 7.86886C6.65973 8.06412 6.97631 8.06412 7.17157 7.86886L10.3536 4.68688ZM0 4.83333H10V3.83333H0V4.83333Z"
                        fill="#101828"
                      />
                    </svg>
                    {value?.orderToCity})
                  </div>
                </div>
                <div className="flex justify-between gap-x-3 gap-y-1 ">
                  <p className="text-grayLight600 text-xs font-normal">
                    Total Price
                  </p>
                  <div className="flex items-center gap-x-1 text-grayLight900 text-xs font-medium">
                    $
                    {value?.combinedRevenue
                      ? getFormattedNumber(value?.combinedRevenue)
                      : 0}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default InTransit;
