import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BasicContext } from 'src/context/BasicContext';
import { updateOrderLocation } from 'src/services/OrderService';

import { initAction, scriptId } from '../order.interface';

import Address from './Address';

interface IProps {
  action: any;
  setAction: any;
  addressData: any;
  orderLegId: any;
}

const UpdateLocation = ({
  action,
  setAction,
  addressData,
  orderLegId,
}: IProps) => {
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleOnSubmit = (formData: any) => {
    updateOrderLocation({ ...formData, orderLegId })
      .then((response) => {
        if (response?.data) {
          handleDrawerClose();
        }
      })
      .catch(console.error);
  };

  return (
    <Address
      isOpen={action.mode === 'update-location'}
      handleClose={handleDrawerClose}
      addressType={action}
      addressData={addressData}
      status={status}
      handleOnSubmit={handleOnSubmit}
    />
  );
};

export default UpdateLocation;
