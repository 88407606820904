import { Check, ReverseLeft } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getOrderProgress, updateOrderStatus } from 'src/services/OrderService';

import doubleCheck from '../../../../assets/img/double-check.svg';
import { OrderStatusAction } from '../../order.constant';

interface IProps {
  isLoading: any;
  currentLegData: any;
  setCurrentLegData: any;
  setOrder: any;
  isProgressLoading: any;
  setIsProgressLoading: any;
  isOrderCompleted: any;
}

const ProgressList = ({
  isLoading,
  currentLegData,
  setOrder,
  setCurrentLegData,
  isProgressLoading,
  setIsProgressLoading,
  isOrderCompleted,
}: IProps) => {
  const [orderStatusButtonText, setOrderStatusButtonText] = useState<any>(
    OrderStatusAction.MARK_AS_PICKED_UP
  );

  const [progressData, setProgressData] = useState<any>([]);

  const getOrderStatusProgress = (orderLegId: number) => {
    setIsProgressLoading(true);

    getOrderProgress({ orderLegId })
      .then((response) => {
        if (response?.data) {
          setProgressData(response?.data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsProgressLoading(false);
      });
  };

  useEffect(() => {
    if (currentLegData) {
      getOrderStatusProgress(currentLegData?.id);

      if (currentLegData?.orderStatus === 'booked') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_PICKED_UP);
      }

      if (currentLegData?.orderStatus === 'dispatched') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_DELIVERED);
      }

      if (currentLegData?.orderStatus === 'in-transit') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }

      if (currentLegData?.orderStatus === 'delivered') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }

      if (currentLegData?.orderStatus === 'completed') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }
    }
  }, [currentLegData]);

  const ProgressItem = ({
    progressValue,
    status,
    loader,
    iconClass = '',
    progressClass = 'bg-utilityGray200 progress-success',
    labelClass = 'text-grayLight600 text-xs font-normal mt-2.5',
  }: {
    progressValue: number;
    status: string;
    loader?: boolean;
    iconClass?: string;
    progressClass?: string;
    labelClass?: string;
  }) => (
    <li className="w-[13%]">
      <progress
        className={`progress w-56 max-w-full ${progressClass} ${
          loader ? 'custom-loading' : ''
        }`}
        value={progressValue}
        max="100"
      ></progress>
      <div className={`flex gap-x-1 ${labelClass}`}>
        {progressValue === 100 || progressValue === 0 ? (
          <div
            className={`rounded-xl ${iconClass} w-3.5 h-3.5 flex items-center justify-center ${
              loader ? 'custom-loading' : ''
            }`}
          >
            {loader ? (
              <Check className="w-2.5 h-2.5 text-white" />
            ) : (
              progressValue === 100 && (
                <Check className="w-2.5 h-2.5 text-white" />
              )
            )}
          </div>
        ) : (
          <div
            className={`radial-progress text-primary ${iconClass}`}
            style={
              {
                '--value': progressValue,
                '--size': '14px',
                '--thickness': '3px',
              } as React.CSSProperties & { '--value': number }
            }
            role="progressbar"
          ></div>
        )}

        <span className={`${loader ? 'custom-loading' : ''}`}>{status}</span>
      </div>
    </li>
  );

  const handleOrderStatus = (isUndo: boolean) => {
    updateOrderStatus({ orderLegId: currentLegData?.id, isUndo })
      .then((response) => {
        if (response?.data) {
          const orderStatus = response?.data?.orderStatus;

          if (orderStatus === 'booked') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_PICKED_UP);
          }

          if (orderStatus === 'dispatched') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_DELIVERED);
          }

          if (orderStatus === 'in-transit') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          if (orderStatus === 'deliverd') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          if (orderStatus === 'completed') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          setCurrentLegData((curr: any) => ({
            ...curr,
            orderStatus: orderStatus,
          }));

          setOrder((ord: any) => ({
            ...ord,
            orderStatus: orderStatus,
          }));

          getOrderStatusProgress(currentLegData?.id);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <ul className="flex flex-wrap gap-x-10 flex-1">
        <ProgressItem
          progressValue={progressData.Booked ?? 0}
          status="Booked"
          loader={isProgressLoading}
          iconClass={`${
            progressData.Booked === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Booked === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
        />
        <ProgressItem
          progressValue={progressData.Dispatched ?? 0}
          status="Dispatched"
          loader={isProgressLoading}
          iconClass={`${
            progressData.Dispatched === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Dispatched === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          progressClass="bg-utilityGray200 progress-success"
        />
        <ProgressItem
          progressValue={progressData.InTransit ?? 0}
          loader={isProgressLoading}
          status="In-Transit"
          // iconClass="border-2 border-utilityGray200"
          iconClass={`${
            progressData.InTransit === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.InTransit === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
        />
        <ProgressItem
          progressValue={progressData.Delivered ?? 0}
          loader={isProgressLoading}
          status="Delivered"
          iconClass={`${
            progressData.Delivered === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Delivered === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
        />
        <ProgressItem
          progressValue={progressData.Completed ?? 0}
          loader={isProgressLoading}
          status="Completed"
          iconClass={`${
            progressData.Completed === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Completed === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
        />
      </ul>
      {!isOrderCompleted && (
        <div className="flex gap-x-2">
          <TooltipCmp message={orderStatusButtonText?.toolTip}>
            <ButtonCmp
              className={`btn-outline-primary lg:!px-[9px] !px-2 ${
                isLoading ? 'custom-loading' : ''
              }`}
              icon={<ReverseLeft className="w-4 h-4" />}
              onClick={() => {
                handleOrderStatus(true);
              }}
              disabled={!orderStatusButtonText?.toolTip}
            >
              Undo
            </ButtonCmp>
          </TooltipCmp>
          <ButtonCmp
            className={`btn-outline-primary ${
              isLoading ? 'custom-loading' : ''
            }`}
            icon={<img src={doubleCheck} className="w-4 h-4" />}
            onClick={() => {
              handleOrderStatus(false);
            }}
          >
            {orderStatusButtonText?.title}
          </ButtonCmp>
        </div>
      )}
    </>
  );
};

export default ProgressList;
