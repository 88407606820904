import {
  CurrencyDollar,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';

const AdditionalCharge = ({ isSelectBox = true, serviceList, onBlur }: any) => {
  const { control } = useFormContext();
  const {
    fields: informationFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    name: 'additional_charge',
    keyName: 'uuid',
  });

  return (
    <>
      <div>
        <div className="flex flex-col gap-3">
          {informationFields?.map((item: any, index: any) => (
            <div className="flex w-full gap-x-2" key={item.uuid}>
              {isSelectBox ? (
                <Controller
                  name={`additional_charge.${index}.charge`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SelectBox
                      label="Additional Charge"
                      labelClassName="form_label block mb-1.5"
                      name={`additional_charge.${index}.charge`}
                      id={`additional_charge-${index}-charge`}
                      size="sm"
                      placeholder="Select Additional Charge"
                      noOptionMessage="No Additional Charge Found"
                      isSearchable={true}
                      className="form_control"
                      parentClassName="flex-1"
                      // isClearable={true}
                      options={serviceList}
                      onChangeFunc={(e: any) => onChange(e?.value)}
                      value={serviceList?.find((e: any) => e?.value == value)}
                      errorText={error ? error.message : null}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={`additional_charge.${index}.charge`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="charge"
                      inputName="charge"
                      placeholder="Enter Additional Service"
                      className="w-full"
                      value={value}
                      onChangeFunc={onChange}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      parentClassName="flex-1"
                    />
                  )}
                />
              )}

              <Controller
                name={`additional_charge.${index}.price`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    label="Price"
                    inputName="price"
                    placeholder="Enter Price"
                    inputType="number"
                    className="pl-8 w-full"
                    value={value}
                    onChangeFunc={onChange}
                    onBlur={onBlur}
                    errorText={error ? error.message : null}
                    labelClassName="block mb-1.5"
                    parentClassName="flex-1"
                    icon={
                      <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                    }
                  />
                )}
              />

              {informationFields.length > 1 && (
                <div className="lg:h-9 h-[34px] flex items-center mt-[21px]">
                  <div
                    className="p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer"
                    onClick={() => removeOption(index)}
                  >
                    <Trash01 className="w-4 h-4" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className="inline-flex items-center text-primary gap-x-1 text-xs font-medium cursor-pointer mt-2"
          onClick={() =>
            appendOption({
              id: 0,
              additional_charge: null,
              price: null,
            })
          }
        >
          <Plus className="w-4 h-4" />
          Add line Item
        </div>
      </div>
      {/* <ButtonCmp
        type="button"
        onClick={() =>
          appendOption({
            id: 0,
            additional_charge: null,
            price: null,
          })
        }
      ></ButtonCmp> */}
    </>
  );
};
export default AdditionalCharge;
