import { AlertCircle, AlertHexagon } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';

import { initServiceValidForm } from './additionalServices.const';

const ExcessValuationModal = ({
  setServicesValidForm,
  setIsDeclaredValueMore,
  isExcessValuation,
  setIsSubmitForm,
  setIsExcessValuationModalOpen,
  validServicesForm,
  setDeclaredValue,
  declaredValue,
  filterArr,
  setDeclaredValueUnit,
  declaredValueUnit,
}: any) => (
  <CommonModal
    title={'Excess Valuation'}
    titleDesc={'Please provide Declared Value'}
    handleClose={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="excess_valuation"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsDeclaredValueMore(false);
      setIsExcessValuationModalOpen(false);
    }}
    headerIcon={<AlertHexagon />}
    size={'max-w-[688px]'}
    secondaryBtnText="Cancel"
    primaryBtnText="Save Changes"
    primaryBtnOnClick={() => {
      if (validServicesForm && isExcessValuation()) {
        setIsExcessValuationModalOpen(false);
        setIsSubmitForm(true);
      }
    }}
    secondaryBtnOnClick={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="excess_valuation"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsExcessValuationModalOpen(false);
    }}
  >
    <div className="flex flex-col justify-center py-3">
      <div className="xxl:w-full xl:w-2/5 w-full px-4">
        <p className="text-dark m-0 p-0 font-medium pt-2 pb-2 text-xs">
          Declared Value
        </p>
        <div className="flex items-center">
          <div className="mb-4 w-full">
            <InputText
              inputType="number"
              inputName="declaredValue"
              required={true}
              placeholder=""
              className={`form_control ${
                !validServicesForm.declaredValue
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              icon={
                !validServicesForm.declaredValue ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={declaredValue}
              onChangeFunc={(e) => {
                setDeclaredValue(e.target.value);
                setServicesValidForm((old: any) => ({
                  ...old,
                  [e.target.name]: true,
                }));
              }}
              onFocus={() => {
                if (declaredValue === 0) {
                  setDeclaredValue('');
                }
              }}
              onBlur={() => {
                if (declaredValue === '') {
                  setDeclaredValue(0);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full py-2 xl:px-4 px-3">
        <p className="text-dark m-0 p-0 font-medium pb-2 text-xs">Currency</p>
        <TabButton
          tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
          parentClassName="w-full currency-tab quoting-currency"
          childrenClassName="test"
          activeClassName="text-grayLight900 bg-utilityGray100"
          className=""
          isTooltip={true}
          tabArray={filterArr}
          handleOnClick={(e: any) => {
            setDeclaredValueUnit(e.target.dataset.value);
          }}
          isActive={declaredValueUnit}
        />
      </div>
    </div>
  </CommonModal>
);

export default ExcessValuationModal;
