import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';

const TraderOnboarding = ({ data }: any) => {
  const phoneNumber = parsePhoneNumberFromString(String(data?.phone_number));

  return (
    <>
      <td className="px-5 py-4">
        <span>{data.company_name}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.city}</span>
      </td>
      <td className="px-5 py-4">
        <span>{phoneNumber ? phoneNumber.formatInternational() : ''}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.email}</span>
      </td>
    </>
  );
};
export default TraderOnboarding;
