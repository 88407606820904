import {
  Calendar,
  Edit05,
  InfoCircle,
  ReverseRight,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import Toggle from 'src/components/Toggle/Toggle';

interface IProps {
  handleClose: any;
}

const RecurringOrderModal = ({ handleClose }: IProps) => {
  const repeatArr = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
  ];

  const orderListLegArray = [
    {
      value: 'leg1',
      name: 'Leg 1 (#WAL-JCH-12-A)',
    },
    {
      value: 'leg2',
      name: 'Leg 2 (#WAL-JCH-12-B)',
    },
    {
      value: 'leg3',
      name: 'Leg 3 (#WAL-JCH-12-C)',
    },
  ];

  return (
    <CommonModal
      title="Create a Reoccuring Order"
      titleDesc="Managing orders with automated task recurrence"
      handleClose={() => handleClose(false)}
      headerIcon={<ReverseRight />}
      size={'xl:max-w-[688px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText="Assign"
      secondaryBtnText="Cancel"
      primaryBtnOnClick={() => {}}
      isOverflow={true}
    >
      {/* Create a Reoccuring Order modal start*/}
      <div className="p-5 flex flex-col gap-y-4 ">
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Pickup Date
          </h6>
          <DateSelect
            inputName="carrier_booking_date"
            className="form_control"
            parentClassName="datepicker-w-auto one-month-datepicker flex-1"
            onChangeFunc={() => {}}
            placeholder="Select Date"
            dateFormat="dd/MM/yyyy"
            icon={<Calendar className="h-5 w-5" />}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Repeat
          </h6>
          <SelectBox
            name="customerId"
            id="customerId"
            placeholder="All Customers"
            parentClassName="flex-1"
            options={repeatArr}
            onChangeFunc={() => {}}
            value={'Weekly'}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            Every
          </h6>
          <div className="form_control p-0 relative !h-auto flex-1">
            <InputText
              inputName="every"
              placeholder="nter Value"
              className="!border-0 !h-auto !pr-20"
              value={'1'}
              inputType="text"
              // onChangeFunc={handleFilter}
            />
            <div className="absolute right-0 top-0 h-full flex items-center px-3 border-l border-utilityGray200 text-textSecondary text-xs font-normal">
              Week(s)
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            On
          </h6>
          <div className="flex gap-2 flex-1">
            <div className="flex gap-x-1.5 items-center w-[14.28%]">
              <CheckBox
                id={`day`}
                name="on"
                checked={true}
                parentClassName={``}
                value={'Mon'}
                onChangeFunc={() => {}}
              />
              <label
                htmlFor={`day`}
                className={`text-grayLight900 text-xs font-medium cursor-pointer `}
              >
                Sun
              </label>
            </div>
            <div className="flex gap-x-1.5 items-center w-[14.28%]">
              <CheckBox
                id={`day`}
                name="on"
                checked={true}
                parentClassName={``}
                value={'Mon'}
                onChangeFunc={() => {}}
              />
              <label
                htmlFor={`day`}
                className={`text-grayLight900 text-xs font-medium cursor-pointer `}
              >
                Mon
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-x-3 ">
          <h6 className="text-textSecondary text-sm font-medium w-[90px]">
            End Date
          </h6>
          <div className="flex gap-3 flex-1">
            <SelectBox
              name="provider"
              id="provider"
              className="form_control"
              size="sm"
              placeholder="Filter by provider"
              parentClassName="w-[180px]"
              isClearable={true}
              isSearchable={false}
              options={repeatArr}
              onChangeFunc={() => {}}
              value={'On this day'}
            />
            <DateSelect
              inputName="carrier_booking_date"
              className="form_control"
              parentClassName="datepicker-w-auto one-month-datepicker flex-1"
              onChangeFunc={() => {}}
              placeholder="Select Date"
              dateFormat="dd/MM/yyyy"
              icon={<Calendar className="h-5 w-5" />}
            />

            {/* WHEN AFTER IS SELECTED FROM DROPDOWN BELOW CODE WILL BE USED   */}
            {/* <div className="form_control p-0 relative !h-auto flex-1">
              <InputText
                inputName="every"
                placeholder="nter Value"
                className="!border-0 !h-auto !pr-24"
                value={'1'}
                inputType="text"
                // onChangeFunc={handleFilter}
              />
              <div className="absolute right-0 top-0 h-full flex items-center px-3 border-l border-utilityGray200 text-textSecondary text-xs font-normal">
                Occurences
              </div>
            </div> */}
          </div>
        </div>
        <div className="border-t border-utilityGray200 pt-4">
          <div className="rounded-md border border-borderSecondary bg-utilityindigo25 px-3 py-2 flex items-start gap-x-1">
            <InfoCircle className="w-4 h-4" />
            <div className="text-textSecondary text-xs font-normal">
              Occurs every Sunday, Tuesday starting from Tuesday, November 11th,
              2024, for a total of 4 Orders.
            </div>
          </div>
        </div>
      </div>
      {/* Create a Reoccuring Order modal end */}

      {/* Select Recurring Order Details modal start */}
      <div className="p-5 hidden">
        <h6 className="text-grayLight900 text-sm font-medium mb-2">
          Select which fields you would like to copy to new orders
        </h6>
        <div className="rounded-xl border border-utilityGray200 p-3 flex flex-col gap-y-4">
          <TabButton
            tabArray={orderListLegArray}
            parentClassName="p-1 rounded-[10px] border border-gray100 bg-gray50 !w-full whitespace-nowrap overflow-x-auto scrollbar-hide"
            className="!border-0 !bg-gray50 rounded-md flex-1"
            activeClassName="!bg-white shadow-md"
            isActive={'leg2'}
            isTab={true}
            handleOnClick={() => {}}
          />
          <div className="rounded-lg border border-utilityGray200 p-4">
            <div className="flex items-center gap-x-2">
              <h6 className="text-textSecondary text-sm font-semibold flex-1">
                Address Details
              </h6>
              <Toggle isChecked={true} />
            </div>
            <ul className="border-t border-utilityGray200 pt-4 mt-4 grid grid-cols-3 gap-x-4 gap-y-5">
              <li className="flex items-start">
                <CheckBox classes="" onChangeFunc={() => {}} />
                <label className="ml-1.5 text-textSecondary text-xs">
                  Shipper
                </label>
              </li>
              <li className="flex items-start">
                <CheckBox classes="" onChangeFunc={() => {}} />
                <label className="ml-1.5 text-textSecondary text-xs">
                  Consignee
                </label>
              </li>
            </ul>
          </div>
          <div className="rounded-lg border border-utilityGray200 p-4">
            <div className="flex items-center gap-x-2">
              <h6 className="text-textSecondary text-sm font-semibold flex-1">
                Order Details
              </h6>
              <Toggle isChecked={true} />
            </div>
            <ul className="border-t border-utilityGray200 pt-4 mt-4 grid grid-cols-3 gap-x-4 gap-y-5">
              <li className="flex items-start">
                <CheckBox classes="" onChangeFunc={() => {}} />
                <label className="ml-1.5 text-textSecondary text-xs">
                  Order Type
                </label>
              </li>
              <li className="flex items-start">
                <CheckBox classes="" onChangeFunc={() => {}} />
                <label className="ml-1.5 text-textSecondary text-xs">
                  Equipment Type
                </label>
              </li>
            </ul>
          </div>
          <div className="rounded-lg border border-utilityGray200 p-4">
            <div className="flex items-center gap-x-2">
              <h6 className="text-textSecondary text-sm font-semibold flex-1">
                Carriers Cost
              </h6>
              <Toggle isChecked={true} />
            </div>
          </div>
          <div className="rounded-lg border border-utilityGray200 p-4">
            <div className="flex items-center gap-x-2">
              <h6 className="text-textSecondary text-sm font-semibold flex-1">
                Customer Sale Price
              </h6>
              <Toggle isChecked={true} />
            </div>
          </div>
        </div>
      </div>
      {/* Select Recurring Order Details modal end */}

      {/* Review Recurring Orders modal start */}
      <ul className="p-5 flex flex-col gap-y-4 hidden">
        <li className="rounded-lg border border-utilityGray200 p-4 flex">
          <div className="flex flex-wrap -m-2 flex-1">
            <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
              <h6 className="text-grayLight600 text-xs font-medium">
                Order Number
              </h6>
              <p className="text-grayLight900 text-sm font-medium ">1</p>
            </div>
            <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
              <h6 className="text-grayLight600 text-xs font-medium">
                Pickup Date
              </h6>
              <p className="text-grayLight900 text-sm font-medium ">
                Jun. 15th - 2024
              </p>
            </div>
            <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
              <h6 className="text-grayLight600 text-xs font-medium">
                PO Number
              </h6>

              <div className="flex gap-x-1 items-center">
                <p className="text-grayLight900 text-sm font-medium">
                  58923354
                </p>
                <Edit05 className="w-3.5 h-3.5 text-borderPrimary cursor-pointer" />
              </div>

              {/* WHEN CLICK ON EDIT ICON BELOW INPUT WILL BE SHOWN */}
              <InputText placeholder="Enter PO Number" />
            </div>
          </div>
          {/* <div className="flex-none self-center">
            <Edit05 className="w-4 h-4 text-borderPrimary" />
          </div> */}
        </li>
      </ul>
      {/* Review Recurring Orders modal end */}
    </CommonModal>
  );
};

export default RecurringOrderModal;
