import { Copy04, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dimensionObj } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
// import Loader from 'src/components/Loader';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import Toggle from 'src/components/Toggle/Toggle';
import { MEASUREMENT } from 'src/constants/common';
import { getFreightClass } from 'src/services/QuoteService';
import { getLabelByValue } from 'src/utils/CommonFunctions';

import { OrderContext } from '../..';

interface IProps {
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
}

let filterArr = [
  {
    value: 'LBS',
    name: 'lbs',
  },
  {
    value: 'KGS',
    name: 'kgs',
  },
];

const Dimensions = ({ handlingUnitOptions, isHandlingUnitLoading }: IProps) => {
  const { control, getValues, setValue, watch } = useFormContext();
  const {
    setWeightMeasurement,
    setLengthMeasurement,
    lengthMeasurement,
    weightMeasurement,
  } = useContext<any>(OrderContext);

  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);
  const [activeMatrixTab, setActiveMatrixTab] = useState(weightMeasurement);

  const watchOrderDimension = watch('order_dimensions');

  useEffect(() => {
    if (weightMeasurement) {
      setActiveMatrixTab(weightMeasurement);
    }
  }, [weightMeasurement]);

  const addItems = () => {
    const dimensions = [...watchOrderDimension, dimensionObj];
    setValue('order_dimensions', dimensions);
  };

  const duplicateItems = () => {
    const formData = watchOrderDimension || [];

    if (formData.length > 0) {
      const lastItem = { ...formData[formData.length - 1] };
      delete lastItem.id;
      setValue('order_dimensions', [...formData, lastItem]);
    } else {
      setValue('order_dimensions', [dimensionObj]);
    }
  };

  const removeItems = (index: number) => () => {
    const formData = watchOrderDimension || [];

    if (formData && formData.length > 1) {
      const updatedFormData = formData.filter(
        (_: any, idx: any) => idx !== index
      );

      setValue('order_dimensions', updatedFormData);
    }
  };

  const checkFreightClass = (index: number) => async (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const rowData = getValues(`order_dimensions[${index}]`);

    let freightClass = rowData.freightClass;
    let itemClassForDayross = rowData.itemClassForDayross;
    let weight =
      +rowData.totalWeight > 0
        ? +rowData.totalWeight / +rowData.handlingUnitNo
        : 0;
    let itemLength = +rowData.length;
    let width = +rowData.width;
    let height = +rowData.height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading];
      updatedArray[index] = { isLoading: true };
      setIsFreightClassLoading(updatedArray);

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: MEASUREMENT.LENGTH1.value,
        weightUnit: MEASUREMENT.WEIGHT1.value,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading];
          updatedArr[index] = { isLoading: false };
          setIsFreightClassLoading(updatedArr);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    setValue(`order_dimensions[${index}].${name}`, value);
    setValue(`order_dimensions[${index}].freightClass`, freightClass);
    setValue(
      `order_dimensions[${index}].itemClassForDayross`,
      itemClassForDayross
    );
  };

  const handleUnitChange = async () => {
    await Promise.all(
      getValues('order_dimensions').map(async (form: any, index: number) => {
        let freightClass = form.freightClass;
        let itemClassForDayross = form.itemClassForDayross;
        let weight = form.weight;
        let itemLength = form.length;
        let width = form.width;
        let height = form.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading];
          updatedArray[index] = { isLoading: true };
          setIsFreightClassLoading(updatedArray);

          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading];
              updatedArr[index] = { isLoading: false };
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        setValue(`order_dimensions[${index}].freightClass`, freightClass);
        setValue(
          `order_dimensions[${index}].itemClassForDayross`,
          itemClassForDayross
        );
      })
    );
  };

  const handleMeasurement = (lengthMeasurementValue: string, value: any) => {
    setWeightMeasurement(value);
    setLengthMeasurement(lengthMeasurementValue);
    setActiveMatrixTab(value);
    handleUnitChange();
  };

  return (
    <>
      <div className="flex items-end justify-between mb-1.5">
        <h6 className="text-textSecondary text-sm font-medium">
          Please Specify the Dimensions
        </h6>
        <TabButton
          className="leading-4 sm:w-auto min-w-[49px] flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
          activeClassName="!bg-utilityGray100"
          tabArray={filterArr}
          parentClassName="w-full"
          tabParentClassName=""
          isActive={activeMatrixTab}
          handleOnClick={(e: any) => {
            if (e.target.dataset.value == 'lbs') {
              handleMeasurement(
                MEASUREMENT.LENGTH1.value,
                e.target.dataset.value
              );
            } else {
              handleMeasurement(
                MEASUREMENT.LENGTH2.value,
                e.target.dataset.value
              );
            }
          }}
        />
      </div>

      <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs p-4 mb-4 last:mb-0">
        {watchOrderDimension &&
          watchOrderDimension.map((item: any, index: number) => (
            <div
              className="flex flex-wrap sm:-m-1.5 !mb-6 last:mb-0 text-nowrap"
              key={index}
            >
              <Controller
                name={`order_dimensions[${index}].handlingUnit`}
                control={control}
                defaultValue={handlingUnitOptions[0]?.value}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SelectBox
                    name="handling_unit"
                    id="handling_unit"
                    className="form_control"
                    label="Handling unit"
                    labelClassName={'form_label block mb-1.5'}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    size="sm"
                    options={handlingUnitOptions}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value);
                    }}
                    isSearchable={false}
                    value={
                      value
                        ? handlingUnitOptions.find(
                            (val: any) => value === val.value
                          )
                        : handlingUnitOptions[0]
                    }
                    required
                    isLoading={isHandlingUnitLoading}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].handlingUnitNo`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="handlingUnitNo"
                    inputType="number"
                    label="№ of HU"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    required
                    onChangeFunc={(e) => {
                      const handlingUnitNo = e.target.value;
                      const parsedHandlingUnitNo =
                        handlingUnitNo === '' ? null : +handlingUnitNo;
                      onChange(parsedHandlingUnitNo);

                      const weight =
                        getValues(`order_dimensions[${index}].weight`) || 0;
                      setValue(
                        `order_dimensions[${index}].totalWeight`,
                        parsedHandlingUnitNo && weight
                          ? parsedHandlingUnitNo * (parseFloat(weight) || 0)
                          : 0
                      );
                    }}
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Handling unit number is required'
                    //     : null
                    // }
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].length`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="length"
                    inputType="number"
                    label={`Length (${getLabelByValue(
                      lengthMeasurement
                    )?.toLowerCase()})`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    required
                    onChangeFunc={(e) => {
                      const newValue =
                        e.target.value.trim() === '' ? '' : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange('');
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Length is required'
                    //     : null
                    // }
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].width`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="width"
                    inputType="number"
                    label={`Width (${getLabelByValue(
                      lengthMeasurement
                    )?.toLowerCase()})`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    required
                    onChangeFunc={(e) => {
                      const newValue =
                        e.target.value.trim() === '' ? '' : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange('');
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Width is required'
                    //     : null
                    // }
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].height`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="height"
                    inputType="number"
                    label={`Height (${getLabelByValue(
                      lengthMeasurement
                    )?.toLowerCase()})`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    required
                    onChangeFunc={(e) => {
                      const newValue =
                        e.target.value.trim() === '' ? '' : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange('');
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Height is required'
                    //     : null
                    // }
                  />
                )}
              />

              {/* <Controller
                name={`order_dimensions[${index}].weight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="weight"
                    inputType="number"
                    label={`Unit Weight (${getLabelByValue(
                      weightMeasurement
                    )?.toLowerCase()})`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    required
                    onChangeFunc={(e) => {
                      const weight = e.target.value;
                      const parsedWeight = weight === '' ? null : +weight;
                      onChange(parsedWeight);
                      const handlingUnitNo =
                        getValues(
                          `order_dimensions[${index}].handlingUnitNo`
                        ) || 0;
                      setValue(
                        `order_dimensions[${index}].totalWeight`,
                        parsedWeight && handlingUnitNo
                          ? parsedWeight * (parseFloat(handlingUnitNo) || 0)
                          : 0
                      );
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange('');
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                    errorText={
                      (value === 0 || value === '') && error
                        ? error.message
                        : value === 0 && !error
                        ? 'Weight is required'
                        : null
                    }
                  />
                )}
              /> */}

              <Controller
                name={`order_dimensions[${index}].totalWeight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="totalWeight"
                    inputType="number"
                    label={`Total Wt. (${getLabelByValue(
                      weightMeasurement
                    )?.toLowerCase()})`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    onChangeFunc={(e) => onChange(+e.target.value)}
                    onBlur={checkFreightClass(index)}
                    // errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].freightClass`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="freightClass"
                    inputType="number"
                    label="FC"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min flex-1"
                    onChangeFunc={(e) => {
                      const newValue =
                        e.target.value.trim() === '' ? '' : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange('');
                      }
                    }}
                    // labelIconRight={
                    //   isFreightClassLoading[index]?.isLoading && (
                    //     <div className="relative h-3 w-3 mr-3">
                    //       <Loader spinnerClass={'h-4 w-4'} />
                    //     </div>
                    //   )
                    // }
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Freight class is required'
                    //     : null
                    // }
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].refNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="refNumber"
                    inputType="number"
                    label="REF No."
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min flex-1"
                    onChangeFunc={(e) => {
                      const newValue =
                        e.target.value.trim() === '' ? '' : +e.target.value;
                      onChange(newValue);
                    }}
                    // errorText={
                    //   (value === 0 || value === '') && error
                    //     ? error.message
                    //     : value === 0 && !error
                    //     ? 'Reference number is required'
                    //     : null
                    // }
                  />
                )}
              />
              <Controller
                name={`order_dimensions[${index}].description`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="description"
                    label="Description"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min"
                    onChangeFunc={onChange}
                    // errorText={error ? error.message : null}
                  />
                )}
              />
              <div className="flex w-[84px]">
                <div className="sm:p-1.5 w-[46px]">
                  <label className="form_label block mb-1.5">D.N.S</label>
                  <Controller
                    name={`order_dimensions[${index}].isStack`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        isChecked={value ?? true}
                        onChange={onChange}
                        labelClassName="lg:h-[36px] h-[34px] flex items-center"
                        name="isStack"
                      />
                    )}
                  />
                </div>
                <div className="m-1.5 lg:h-9 h-[34px] flex items-center mt-[26px]">
                  <div
                    className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer`}
                    onClick={() => {
                      removeItems(index)();
                    }}
                  >
                    <Trash01 className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="flex gap-x-4">
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Copy04 className="w-4 h-4" />}
            onClick={duplicateItems}
          >
            Duplicate
          </ButtonCmp>
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => addItems()}
          >
            Add Row
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default Dimensions;
