import {
  ArrowUp,
  Copy06,
  InfoCircle,
  Share03,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
// import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

import ServiceDetail from './ServiceDetail';

const WithRateQuote = ({
  data,
  currency,
  addressDetails,
}: // selectedCarrierRates,
any) => {
  const [isNonQuotedServicesExist, setIsNonQuotedServicesExist] =
    useState(false);
  useEffect(() => {
    setIsNonQuotedServicesExist(
      data.services.some((form: any) => !form.isQuoted)
    );
  }, [data]);

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  const getRemainingDays = (expirationDate: string): string => {
    const expDate = new Date(expirationDate);
    const today = new Date();
    expDate.setDate(expDate.getDate() + 1);

    const diffTime = expDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else if (diffDays > 1) {
      return `${diffDays} Days`;
    } else {
      return 'Expired';
    }
  };

  return (
    <>
      <td className={`px-5 py-4 min-w-[240px] w-[240px] max-w-[240px]`}>
        <div className=" flex items-center">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              {getShortName(`${data.name}`)}
            </div>
          )}
          <div className="pl-3">
            {(data.name + (data.banyanPostFix || '')).length > 30 ? (
              <TooltipCmp message={`${data.name} ${data.banyanPostFix || ''}`}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">{`${
                  data.name
                } ${data.banyanPostFix || ''}`}</h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">{`${
                data.name
              } ${data.banyanPostFix || ''}`}</h6>
            )}

            <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
              {data.carrierQuoteId.length > 30 ? (
                <TooltipCmp message={`#${data.carrierQuoteId}`}>
                  <span className="max-w-28 truncate inline-block align-middle ">
                    #{data.carrierQuoteId}
                  </span>
                </TooltipCmp>
              ) : (
                <span className="inline align-middle truncate max-w-28">
                  #{data.carrierQuoteId}
                </span>
              )}
              &nbsp;
              <div className="flex gap-1">
                <TooltipCmp message="Copy">
                  <Copy06
                    onClick={(event) => {
                      event.stopPropagation();
                      copyDebounce(data.carrierQuoteId);
                    }}
                    className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                  />
                </TooltipCmp>
                {data.quoteUrl && (
                  <TooltipCmp message="Open">
                    <a
                      href={data.quoteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                    </a>
                  </TooltipCmp>
                )}
              </div>
            </p>
          </div>
        </div>
      </td>

      <td className={`px-5 py-4 min-w-[160px] w-[160px] max-w-[160px]`}>
        {(data.isFreightcom || data.isFreightcom === 1) && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={FreightcomIcon}
              alt="FC"
            />
            <div className="pl-3 font-medium">Freightcom</div>
          </div>
        )}
        {(data.isBanyan || data.isBanyan === 1) && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={BanyanIcon}
              alt="BANYAN"
            />
            <div className="pl-3 font-medium">Banyan</div>
          </div>
        )}
        {!data.isBanyan && !data.isFreightcom && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={data.image ? `${data.imageUrl}${data.image}` : defaultImage}
              alt="CR"
            />
            <div className="pl-3 font-medium">Direct</div>
          </div>
        )}
      </td>
      <td className={`px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]`}>
        {/* {data.transitTime > 0
          ? `Est. ${data.transitTime} days`
          : data.transitTime === 0
          ? ' Est. Today'
          : '-'} */}
        {data.expirationDate ? (
          <BadgeCmp
            style="modern"
            type={
              moment(data.expirationDate).isBefore(moment().startOf('day'))
                ? 'red'
                : 'warning'
            }
          >
            {getRemainingDays(data.expirationDate)}
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="modern"
            type={
              moment(addressDetails.pickupDate).isBefore(
                moment().startOf('day')
              )
                ? 'red'
                : 'warning'
            }
          >
            {getRemainingDays(data.expirationDate)}
          </BadgeCmp>
        )}
      </td>
      <td className={`px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]`}>
        {data.transitTime > 0 ? (
          <span className={`${isNonQuotedServicesExist ? 'line-through' : ''}`}>
            Est. {data.transitTime} days
          </span>
        ) : (
          '-'
        )}
      </td>
      {/* <td className={`px-5 py-4 ${isNonQuotedServicesExist ? 'bg-utilityGray100' : ''}`}>
        {isFinish &&
          data.tags &&
          (addressDetails.status === STATUS.OPENED ||
            addressDetails.status === STATUS.ACTIVE) &&
          data.tags[0] && (
            <BadgeCmp
              style="modern"
              type={`${data.tags[0] === 'Fastest' ? 'success' : 'error'}`}
            >
              {data.tags[0]}
            </BadgeCmp>
          )}
      </td> */}

      <td className={`px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%] `}>
        {data.transitTime > 0 ? (
          <span
            className={`${isNonQuotedServicesExist ? 'line-through' : ''}`}
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment
                      .utc(data.projectedDeliveryDate)
                      .format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td
        className={`px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%] ${
          isNonQuotedServicesExist ? 'line-through' : ''
        }`}
      >
        {data.totalCharge === 0
          ? '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.totalCharge)}`
          : `$${getFormattedNumber(data.totalChargeUSD.toFixed(2))}`}
      </td>

      <td className={`px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]`}>
        <div
          className={`flex items-center gap-1 ${
            isNonQuotedServicesExist ? 'line-through' : ''
          }`}
        >
          {data?.additionalCharges > 0 || data?.services?.length > 0 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={<ServiceDetail carrierServices={data} />}
            >
              {data.additionalCharges === 0
                ? '$0.00'
                : currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.additionalCharges)}`
                : `$${getFormattedNumber(data.additionalChargesUSD)}`}
            </ActionTooltip>
          ) : data.additionalCharges === 0 ? (
            '$0.00'
          ) : currency === CURRENCY.CAD ? (
            `$${getFormattedNumber(data.additionalCharges)}`
          ) : (
            `$${getFormattedNumber(data.additionalChargesUSD)}`
          )}
          {isNonQuotedServicesExist ? (
            <TooltipCmp message="The carrier did not provide a rate for all additional services specified. Please validate the feasibility of the shipment and/or any additional costs.">
              <InfoCircle className="text-primary w-3 h-3" />
            </TooltipCmp>
          ) : (
            ''
          )}
        </div>
      </td>

      <td
        className={`px-5 py-4 text-sm w-[160px] min-w-[160px] max-w-[160px] ${
          isNonQuotedServicesExist ? 'line-through' : ''
        } `}
      >
        <span className="text-textSecondary font-bold">
          {data.carrierQuoteId
            ? currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data.finalCharge)} CAD`
              : `$${getFormattedNumber(data.finalChargeUSD)} USD`
            : '-'}
        </span>
      </td>
      {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
        <td className={`px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]`}>
          {/* <CheckBox
          checked={selectedCarrierRates.some(
            (form: any) => form.id === data.id
          )}
          onChangeFunc={() => {}}
          parentClassName=""
        /> */}
          <div className="flex justify-end gap-3 ">
            <ArrowUp className="w-4 h-4 text-primary" />
          </div>
        </td>
      )}
    </>
  );
};

export default WithRateQuote;
