import { MEASUREMENT } from 'src/constants/common';
import * as Yup from 'yup';

export interface IMapAddress {
  fullAddress: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  latitude: string;
  longitude: string;
  stateCode: string;
  countryCode: string;
}

export interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

export const initShipperAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

export interface IDimensionProps {
  handlingUnit: number | string;
  handlingUnitNo: number | string;
  // weight: number | string;
  length: number | string;
  width: number | string;
  height: number | string;
  totalWeight: number | string;
  freightClass: number | string;
  refNumber: number | string;
  isStack: boolean | string;
  itemClassForDayross: number;
  description: string | string;
}

export const classOptions = [
  {
    label: 'LTL',
    value: 'ltl',
  },
  {
    label: 'FTL',
    value: 'ftl',
  },
];

export const equipmentTypeOptions = [
  { label: 'Dryvan', value: 'dryvan' },
  { label: 'Flatbed', value: 'flatbed' },
  { label: 'Step-Deck', value: 'step-deck' },
  { label: 'Reefer', value: 'reefer' },
  { label: 'Straight Truck', value: 'straight-truck' },
  { label: 'Roll-tite', value: 'roll-tite' },
  { label: 'RGN/Lowboy', value: 'rgn-lowboy' },
];

export const initAction = {
  mode: null,
  shipper: false,
  consignee: false,
  type: null,
};

export const scriptId = 'google-maps-api';

// export const orderSchema = Yup.object({
//   shipper: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   consignee: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   orderType: Yup.string()
//     .required('Order type is required')
//     .oneOf(['ltl', 'ftl'], 'Invalid order type'),
//   equipmentType: Yup.string()
//     .required('Equipment type is required')
//     .oneOf(['dryvan', 'flatbed', 'reefer'], 'Invalid equipment type'),
//   poNumber: Yup.string().required('PO number is required'),
//   // .matches(
//   //   /^[a-zA-Z0-9]+$/,
//   //   'PO number should contain only alphanumeric characters'
//   // ),
//   refNumber: Yup.string().required('Reference number is required'),
//   linearFootage: Yup.number()
//     .required('Linear footage is required')
//     .min(1, 'Linear footage must be at least 1')
//     .max(100, 'Linear footage cannot exceed 100'),
//   declaredValue: Yup.number()
//     .required('Declared value is required')
//     .min(0, 'Declared value cannot be negative'),
//   specialNotes: Yup.string()
//     .max(500, 'Special notes cannot exceed 500 characters')
//     .nullable(),

//   order_dimensions: Yup.array().of(
//     Yup.object().shape({
//       handlingUnit: Yup.number()
//         .nullable()
//         .required('Handling unit is required'),
//       handlingUnitNo: Yup.number()
//         .nullable()
//         .required('Handling unit number is required'),
//       weight: Yup.number().nullable().required('Weight is required'),
//       length: Yup.number().nullable().required('Length is required'),
//       width: Yup.number().nullable().required('Width is required'),
//       height: Yup.number().nullable().required('Height is required'),
//       totalWeight: Yup.number().nullable().required('Total weight is required'),
//       freightClass: Yup.number()
//         .nullable()
//         .required('Freight class is required'),
//       refNumber: Yup.number()
//         .nullable()
//         .required('Reference number is required'),
//       isStack: Yup.boolean().nullable().required('IsStack is required'),
//     })
//   ),
// });

export const orderSchema = Yup.object({
  shipper: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    shipperAddress: Yup.object({
      fullAddress: Yup.string().required('Shipper address is required'),
    }),
  }),
  consignee: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    consigneeAddress: Yup.object({
      fullAddress: Yup.string().required('Consignee address is required'),
    }),
  }),
  orderType: Yup.string()
    .required('Order type is required')
    .oneOf(['ltl', 'ftl'], 'Invalid order type'),
  equipmentType: Yup.string().required('Equipment type is required'),
  poNumber: Yup.string().required('PO number is required'),
  refNumber: Yup.string().required('Reference number is required'),
  linearFootage: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Linear footage is required')
    .min(1, 'Linear footage must be at least 1')
    .max(100, 'Linear footage cannot exceed 100'),
  declaredValue: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Declared value is required')
    .min(0, 'Declared value cannot be negative'),
  specialNotes: Yup.string()
    .max(500, 'Special notes cannot exceed 500 characters')
    .nullable(),
  order_dimensions: Yup.array().of(
    Yup.object().shape({
      handlingUnit: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      handlingUnitNo: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Handling unit number is required'),
      // weight: Yup.number()
      //   .transform((value, originalValue) =>
      //     originalValue === '' ? null : value
      //   )
      //   .nullable()
      //   .required('Weight is required'),
      length: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Length is required'),
      width: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Width is required'),
      height: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Height is required'),
      totalWeight: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Total weight is required'),
      freightClass: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      refNumber: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .required('Reference number is required'),
      isStack: Yup.boolean().nullable(),
    })
  ),
});

export const dimensionObj: IDimensionProps = {
  handlingUnit: '',
  handlingUnitNo: '',
  // weight: '',
  length: '',
  width: '',
  height: '',
  totalWeight: '',
  freightClass: '',
  refNumber: '',
  isStack: true,
  itemClassForDayross: 0,
  description: '',
};

export const defualtOrderValue = {
  shipper: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
    shipperAddress: initShipperAddress,
  },
  consignee: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
    consigneeAddress: initShipperAddress,
  },
  orderType: 'ltl',
  equipmentType: 'dryvan',
  poNumber: '',
  refNumber: '',
  linearFootage: '',
  declaredValue: '',
  specialNotes: '',
  declaredValueUnit: 'CAD',
  order_dimensions: [dimensionObj],
  additionalServices: null,
  carrierPrice: [],
  salesPrice: [],
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  lengthMeasurement: MEASUREMENT.LENGTH1.value,
};

export const processOrderData = (order: any) => {
  let salesPrice: any = [];
  let carrierPrice: any = [];
  let documents: any = [];
  let claim: any = [];
  let dispute: any = [];

  if (order?.salesPrice && Object.keys(order.salesPrice).length > 0) {
    salesPrice = [
      {
        ...order.salesPrice,
        additional_charge: order.salesPrice.additional_charge
          ? JSON.parse(order.salesPrice.additional_charge)
          : [],

        customerSalesRep: order.salesPrice.customerSalesRep
          ? JSON.parse(order.salesPrice.customerSalesRep)
          : [],

        customer: order.salesPrice.customer
          ? JSON.parse(order.salesPrice.customer)
          : {},
      },
    ];
  }

  if (order?.carrierPrice && order?.carrierPrice?.length > 0) {
    carrierPrice = order.carrierPrice.map((carrier: any) => {
      const additionalCharge = carrier.additional_charge
        ? JSON.parse(carrier.additional_charge)
        : [];

      const carrierData = carrier.carrier ? JSON.parse(carrier.carrier) : [];

      return {
        ...carrier,
        additional_charge: additionalCharge,
        carrier: carrierData,
      };
    });
  }

  if (order?.documents) {
    documents = Array.isArray(order?.documents)
      ? order?.documents
      : JSON.parse(order?.documents);
  }

  if (order?.claim) {
    claim = Array.isArray(order?.claim)
      ? order?.claim
      : JSON.parse(order?.claim);
  }

  if (order?.dispute) {
    dispute = Array.isArray(order?.dispute)
      ? order?.dispute
      : JSON.parse(order?.dispute);
  }

  return {
    shipper: {
      companyName: order?.shipperCompanyName || '',
      contactName: order?.shipperContactName || '',
      phoneNumber: order?.shipperContactNumber || '',
      email: order?.shipperEmail || '',
      shipperAddress: {
        fullAddress: order?.shipperFullAddress || '',
        address1: order?.shipperAddress1 || '',
        city: order?.shipperCity || '',
        state: order?.shipperState || '',
        country: order?.shipperCountry || '',
        postal: order?.shipperPostal || '',
        latitude: order?.shipperLatitude || '',
        longitude: order?.shipperLongitude || '',
        stateCode: order?.shipperStateCode || '',
        countryCode: order?.shipperCountryCode || '',
      },
    },
    consignee: {
      companyName: order?.consigneeCompanyName || '',
      contactName: order?.consigneeContactName || '',
      phoneNumber: order?.consigneeContactNumber || '',
      email: order?.consigneeEmail || '',
      consigneeAddress: {
        fullAddress: order?.consigneeFullAddress || '',
        address1: order?.consigneeAddress1 || '',
        city: order?.consigneeCity || '',
        state: order?.consigneeState || '',
        country: order?.consigneeCountry || '',
        postal: order?.consigneePostal || '',
        latitude: order?.consigneeLatitude || '',
        longitude: order?.consigneeLongitude || '',
        stateCode: order?.consigneeStateCode || '',
        countryCode: order?.consigneeCountryCode || '',
      },
    },
    orderType: order?.orderType || 'ltl',
    equipmentType: order?.equipmentType || 'dryvan',
    poNumber: order?.poNumber || '',
    refNumber: order?.refNumber || '',
    linearFootage: order?.linearFootage || undefined,
    declaredValue: order?.declaredValue || undefined,
    specialNotes: order?.specialNotes || '',
    declaredValueUnit: order?.declaredValueUnit || 'CAD',
    order_dimensions:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions
        : [dimensionObj],
    additionalServices: order?.additionalServices || null,
    salesPrice,
    carrierPrice,
    carrierPickupAppointmentDatetime: order?.carrierPickupAppointmentDatetime,
    carrierDeliveryAppointmentDatetime:
      order?.carrierDeliveryAppointmentDatetime,
    shipperAppointmentDatetime: order?.shipperAppointmentDatetime,
    deliveryAppointmentDatetime: order?.deliveryAppointmentDatetime,
    id: order?.id ?? 0,
    orderId: order?.orderId ?? null,
    legId: order?.legId ?? null,
    orderStatus: order?.orderStatus,
    customerId: order?.customerId,
    cadRate: order?.cadRate,
    usdRate: order?.usdRate,
    customerName: order?.customerName,
    customerEmail: order?.customerEmail,
    customerImage: order?.customerImage,
    customerImageUrl: order?.customerImageUrl,
    claimId: order?.claimId,
    disputeId: order?.disputeId,
    createdAt: order?.createdAt,
    documents: documents,
    claim: claim[0] ? Object.assign({}, claim[0]) : {},
    dispute: dispute[0] ? Object.assign({}, dispute[0]) : {},
    salesRepEmail: order?.salesRepEmail,
    salesRepFirstName: order?.salesRepFirstName,
    salesRepId: order?.salesRepId,
    salesRepLastName: order?.salesRepLastName,
    weightMeasurement:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions?.[0]?.weightMeasurement
        : MEASUREMENT.WEIGHT1.value,
    lengthMeasurement:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions?.[0]?.lengthMeasurement
        : MEASUREMENT.LENGTH1.value,
  };
};
export const ordersTimeFilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];
export interface IOrderProps {
  handleClose: () => void;
  onCreate?: () => void;
}

export const handleInputChange = (e: any, type: string, onChange: any) => {
  const { target } = e;
  let { value: currentValue } = target;

  let newValue = currentValue;

  if (type === 'blur' && !currentValue) {
    newValue = 0;
  } else {
    newValue = currentValue;

    if (!newValue.includes('.')) {
      newValue = Number(newValue);
    }
  }

  if (type === 'blur') {
    newValue = parseFloat(newValue).toFixed(2);
  }

  onChange(newValue);
};

export interface IOrderForm {
  customerId: number;
}
