import { yupResolver } from '@hookform/resolvers/yup';
import {
  FilePlus01,
  InfoCircle,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useCallback, useState, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  uploadFinanceStatement,
  businessCreditApplicationDetail,
} from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface IProps {
  setFinanceStatementArr: any;
  handleClose: any;
  customerId: any;
}

const quarterOption = [
  { label: 'Q1', value: 'q1' },
  { label: 'Q2', value: 'q2' },
  { label: 'Q3', value: 'q3' },
  { label: 'Q4', value: 'q4' },
];

const YearListOption: any[] = [];

let endYear = parseInt(moment().format('Y'));

for (let year = endYear; year >= 1998; year--) {
  YearListOption.push({ label: year.toString(), value: year });
}

const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1; // getMonth() returns 0-11, so we add 1

  return `q${Math.ceil(month / 3)}`; // Divide the month by 3 and round up to get the quarter
};

const StatementModal = memo(
  ({ handleClose, customerId, setFinanceStatementArr }: IProps) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const SUPPORTED_FORMATS = [
      'application/pdf',
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // 'application/vnd.ms-excel',
      // 'text/plain',
    ];

    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object().shape({
      year: yup.string().required('year is required.'),
      quarter: yup.string().required('quarter is required.'),
      file: yup
        .mixed()
        .test({
          name: 'fileFormat',
          message: 'Unsupported image format.',
          test: async (value: any) => {
            if (!value || !(value instanceof File)) return true;
            console.log('value.type :>> ', value.type);

            return SUPPORTED_FORMATS.includes(value.type);
          },
        })
        .test({
          name: 'fileSize',
          message: 'File size should be less than 2MB.',
          test: async (value: any) => {
            if (!value || !(value instanceof File)) return true;

            return value.size <= MAX_FILE_SIZE;
          },
        })
        .required('file is required'),
    });

    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        year: moment().format('Y'),
        quarter: getCurrentQuarter(),
      },
    });

    const onDrop = useCallback((acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setValue('file', file, { shouldValidate: true });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: 1,
      multiple: false,
    });

    const getStatementDetail = useCallback(() => {
      console.log('getStatementDetail called');
      setIsLoading(true);
      businessCreditApplicationDetail(customerId)
        .then((response) => {
          console.log('Response from API:', response);
          setFinanceStatementArr(response?.data?.financeStatement); // Update state with new data
        })
        .catch((e) => {
          console.error('Error fetching statement detail:', e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong, please try again'
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [customerId, setFinanceStatementArr]);

    const onSubmit = async (formData: any) => {
      setIsLoading(true);
      const appendFormData: any = new FormData();
      Object.entries(formData).forEach((data) => {
        const [key, value] = data;
        if (key !== 'file') appendFormData.append(key, value);
      });
      appendFormData.append('custId', customerId);
      appendFormData.append('fileOriginalName', formData.file?.name);
      appendFormData.append('size', formData.file?.size);
      appendFormData.append('file', formData.file);

      uploadFinanceStatement(appendFormData)
        .then(() => {
          handleClose(formData);
          getStatementDetail();
        })
        .catch(() =>
          WalToast.error('something went wrong please try again later..!!')
        )
        .finally(() => {
          setIsLoading(false);
        });
    };
    const fileData: any = watch('file');

    // useEffect(() => {
    //   if (errors?.file?.message) WalToast.error(errors?.file?.message);
    // }, [errors]);

    return (
      <CommonModal
        title={'Upload Financial Statements'}
        titleDesc={'View and manage your claim details'}
        handleClose={() => handleClose(false)}
        headerIcon={<FilePlus01 />}
        size={'max-w-[688px]'}
        modalClassName=""
        primaryBtnOnClick={handleSubmit(onSubmit)}
        primaryBtnDisabled={isLoading}
        primaryBtnLoading={isLoading}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={() => handleClose(false)}
        primaryBtnText="Upload"
        isOverflow={false}
      >
        <div className="w-full p-5 flex flex-col">
          <div className="-mx-1.5 flex flex-wrap mb-4">
            <Controller
              name="year"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="year"
                  id=""
                  className="form_control"
                  label="Select Year"
                  labelClassName="form_label mb-1.5 block"
                  placeholder="Select Year"
                  onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                  isError={errors.year ? true : false}
                  parentClassName="sm:w-1/2 sm:px-1.5 max-sm:mb-4"
                  options={YearListOption}
                  value={YearListOption.filter((val) => val?.value == value)}
                />
              )}
            />
            <Controller
              name="quarter"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="quarter"
                  id=""
                  className="form_control"
                  label="Select Quarter"
                  labelClassName="form_label mb-1.5 block"
                  placeholder="Select Quarter"
                  onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                  isError={errors.quarter ? true : false}
                  parentClassName="sm:w-1/2 sm:px-1.5 max-sm:mb-4"
                  options={quarterOption}
                  value={quarterOption.filter((val) => val?.value == value)}
                />
              )}
            />
          </div>
          <div className="flex items-center mb-1.5">
            <label className="form_label mb-0">Financial Statements</label>
            {errors.file ? (
              <InfoCircle className="text-fgErrorPrimary w-3.5 h-3.5 pl-0.5" />
            ) : (
              ''
            )}
          </div>
          <div
            className={`cursor-pointer flex-auto rounded-xl border  py-3.5 px-5 text-center bg-white hover:border-grayText ${
              errors.file ? 'border-fgErrorPrimary' : 'border-gray100'
            }`}
            {...getRootProps()}
          >
            {watch('file') && !errors.file ? (
              <>
                <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                  <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                </div>
                <div className="text-grayLight600 text-xs font-normal">
                  <p>{fileData && fileData?.name}</p>
                </div>
              </>
            ) : (
              <>
                <label>
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">PDF file</p>
                  </div>
                </label>
                <input accept=".pdf" {...getInputProps()} />
              </>
            )}
          </div>
          <div className="mt-1.5">
            {errors.file && <ErrorMsg errorText={errors.file.message} />}
          </div>
        </div>
      </CommonModal>
    );
  }
);

export default StatementModal;
