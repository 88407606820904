import { CurrencyDollar, Zap } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { increaseCustomerCreditLimit } from 'src/services/CustomerService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const creditIncreaseTypeArr = [
  { label: 'General Increase', value: 'general' },
  { label: 'Temporary Increase', value: 'temporary' },
];

const creditBoostExpirationTime = [
  { label: 'In 1 hour', value: '1' },
  { label: 'In 6 hours', value: '6' },
  { label: 'In 12 hours', value: '12' },
  { label: 'In 24 hours', value: '24' },
  { label: 'In 48 hours', value: '48' },
  { label: 'In 72 hours', value: '78' },
];

interface IProps {
  customerId: any;
  customerCreditDetail: any;
  handleClose: any;
}

const CustomerCreditIncreaseModal = ({
  customerId,
  customerCreditDetail,
  handleClose,
}: IProps) => {
  const [creditLimit, setCreditLimit] = useState<any>(0);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [boostExpirationTime, setBoostExpirationTime] = useState<any>();
  const [increaseType, setIncreaseType] = useState('general');

  const handleCustomerCreditIncrease = () => {
    setIsApiLoading(true);
    increaseCustomerCreditLimit({
      customerId: customerId,
      creditLimitToIncrease: creditLimit,
      boostExpirationTime:
        increaseType === 'temporary' ? boostExpirationTime : undefined,
      currentAccountReceivable:
        customerCreditDetail?.currentAccountReceivable ?? 0,
      increaseType: increaseType,
    })
      .then((response: any) => {
        WalToast.success(response?.message);
        handleClose(true);
      })
      .catch((e: any) => {
        console.error(e);
        handleClose(false);
        WalToast.error(
          'Something went wrong while updating customer credit limit'
        );
      })
      .finally(() => {
        setIsApiLoading(false);
      });
  };

  return (
    <CommonModal
      title={'Increase Credit Limit'}
      titleDesc={`Increase customer's credit limit.`}
      handleClose={() => (isApiLoading ? false : handleClose(false))}
      headerIcon={<Zap />}
      size={'max-w-[607px] overflow-unset'}
      isOverflow={false}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Apply"
      primaryBtnOnClick={handleCustomerCreditIncrease}
      primaryBtnLoading={isApiLoading}
      secondaryBtnDisabled={isApiLoading}
    >
      <div className="w-full p-5 ">
        <div className="-mx-1.5 flex flex-col gap-2">
          <div className="sm:px-1.5 w-full max-sm:mt-4">
            <label className={`form_label`}>Select Type</label>
            <SelectBox
              name="increaseType"
              id="increaseType"
              className="form_control"
              size="sm"
              placeholder="Select Credit Increase Type"
              options={creditIncreaseTypeArr}
              onChangeFunc={(event: any) => {
                if (event?.value === 'general') {
                  setBoostExpirationTime(undefined);
                }
                setIncreaseType(event?.value);
              }}
              isDisabled={customerCreditDetail?.boostedCreditLimit}
              value={creditIncreaseTypeArr.filter(
                (val: any) => increaseType === val.value
              )}
            />
          </div>

          <InputText
            label="Current Credit Limit"
            labelClassName="block mb-1.5"
            placeholder="Enter credit increase limit"
            parentClassName="sm:px-1.5 w-full max-sm:mt-4"
            value={
              customerCreditDetail?.creditLimit
                ? getFormattedNumber(
                    customerCreditDetail?.creditLimit,
                    true,
                    true
                  )
                : '0.00'
            }
            className="pl-7"
            iconLeft={
              <CurrencyDollar className="h-4 w-4 cursor-pointer absolute left-2 mr-2 text-black/90" />
            }
            disabled={true}
            inputType="string"
          />

          <InputText
            label="Set Credit Increase Limit"
            labelClassName="block mb-1.5"
            placeholder="Enter credit increase limit"
            parentClassName="sm:px-1.5 w-full max-sm:mt-4"
            value={creditLimit}
            onFocus={() => creditLimit === 0 && setCreditLimit('')}
            onBlur={() => creditLimit === '' && setCreditLimit(0)}
            onChangeFunc={(e) => setCreditLimit(e?.target?.value)}
            inputType="number"
          />

          {increaseType === 'temporary' && (
            <div className="sm:px-1.5 w-full max-sm:mt-4">
              <label className={`form_label`}>Expiration Time</label>
              <SelectBox
                name="boostExpirationTime"
                id="boostExpirationTime"
                className="form_control"
                size="sm"
                placeholder="Select Credit Increase Type"
                options={creditBoostExpirationTime}
                onChangeFunc={(event: any) => {
                  setBoostExpirationTime(event?.value);
                }}
                value={creditBoostExpirationTime.filter(
                  (val: any) => boostExpirationTime === val.value
                )}
              />
            </div>
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerCreditIncreaseModal;
