import { ArrowRight, Minimize02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Link } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';

import defaultimage from '../../../assets/img/default-image.jpg';
import star from '../../../assets/img/Star.svg';

const LaneActivity = ({ handleClose }: any) => {
  console.log('first');

  return (
    <div className="fixed bottom-10 right-10 w-[450px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <ButtonCmp className="!p-1.5 btn_secondary_black absolute top-3 right-3 z-[3]">
        <Minimize02
          className="w-4 h-4 text-primary cursor-pointer"
          onClick={handleClose}
        />
      </ButtonCmp>
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 mt-2">
        <div className="flex items-center">
          <div className="flex-1">
            <p className="text-gray500 text-xs font-normal">From</p>
            <p className="text-textSecondary text-xs font-medium">
              Montreal Eaton Centre, West, Montreal, QC, Canada
            </p>
          </div>
          <ButtonCmp className="lg:!px-[9px] !px-2 btn_secondary_black mx-3">
            <ArrowRight className="w-4 h-4" />
          </ButtonCmp>
          <div className="pl-3 flex-1">
            <p className="text-gray500 text-xs font-normal">To</p>
            <p className="text-textSecondary text-xs font-medium">
              8001 S Orange Blossom Trl, Orlando, FL 32809, USA
            </p>
          </div>
        </div>
      </div>
      <div className="my-5 px-4  flex flex-col flex-1 overflow-y-auto  custom-scrollbar-v2 ">
        <h6 className="text-grayLight600 text-sm font-medium mb-1.5">
          Lane Details
        </h6>
        <div className="rounded-lg border border-utilityGray200 p-3 mb-5">
          <Link to={'#'} className="text-primary text-sm font-medium">
            #WAL-DMIF-1
          </Link>
          <ul className="mt-5 flex flex-wrap gap-y-4">
            <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
              <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
                Mileage
              </h6>
              <p className="text-grayLight900 text-sm font-medium">1869.8 mi</p>
            </li>
            <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
              <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
                Duration
              </h6>
              <p className="text-grayLight900 text-sm font-medium">8hrs23min</p>
            </li>
            <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
              <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
                Average Cost
              </h6>
              <p className="text-grayLight900 text-sm font-medium">$5,234</p>
            </li>
            <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
              <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
                CPM
              </h6>
              <div className="text-grayLight900 text-xs font-medium flex items-center">
                <p className="text-grayLight900 text-sm font-medium">$20.01</p>
                <p className="text-gray500 text-xs font-normal">
                  &nbsp;($26.12)
                </p>
              </div>
            </li>
          </ul>
        </div>
        <h6 className="text-grayLight600 text-sm font-medium mb-1.5">
          Carriers
        </h6>
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-y-3 last:mb-0 mb-5">
          <div className="flex gap-x-2.5">
            <div className="">
              <img
                src={defaultimage}
                className="w-8 h-8 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
              />

              {/* IF NO IMAGE FOUND THEN BELOW COMMENTED CODE WILL BE USED */}
              {/* <div className="w-8 h-8 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
                WA
              </div> */}
            </div>
            <div className="flex-1 self-center">
              <p className="inline mr-1">XPO Logistics Freight, Inc.</p>
              <div className="inline-flex items-center gap-x-0.5 rounded-md border border-utilityGray200 text-gray500 text-xs font-normal px-1 py-[1px]">
                <p className="mt-0.5">4.5</p>
                <img src={star} className="" />
              </div>
            </div>
          </div>
          <ul className="flex flex-col gap-y-1">
            <li className="flex justify-between gap-x-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Total Cost
              </h6>
              <p className="text-grayLight900 text-xs font-medium">$752</p>
            </li>
            <li className="flex justify-between gap-x-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Total Price
              </h6>
              <p className="text-grayLight900 text-xs font-medium">$905.80</p>
            </li>
            <li className="flex justify-between gap-x-3">
              <h6 className="text-grayLight600 text-xs font-normal">Margin</h6>
              <p className="text-grayLight900 text-xs font-medium">$56</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default LaneActivity;
