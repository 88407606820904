import {
  // CheckCircle,
  ChevronDown,
  ChevronUp,
  // DotsVertical,
  // Download03,
  // File05,
  Plus,
  Target05,
  // Upload01,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { groupBy } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InternalChatAndNotes from 'src/app/Claims&Disputes/InternalChatAndNotes';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
// import TrackTraceAiCmp from 'src/components/TrackTraceAiCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { listHandlingUnit, listService } from 'src/services/CommonService';
import { CustomersAllList } from 'src/services/CustomerService';
import { cancelOrder, getOrder } from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getSelectBoxOptions,
  getShortName,
} from 'src/utils/CommonFunctions';

import defaultimage from '../../../assets/img/default-image.jpg';
import { defualtOrderValue, processOrderData } from '../order.interface';

import AddressDetails from './AddressDetails';
import Appointment from './Appointment';
import CarrierAndSalesPrice from './CarrierAndSalesPrice';
import DocumentDetails from './DocumentDetails';
import LegDetails from './LegDetails';
import OrderCostBreakDown from './OrderCostBreakDown';
import OrderDetails from './OrderDetails';
import OrderStatus from './OrderStatus';
import RecurringOrderModal from './RecurringOrder/RecurringOrderModal';
import SplitLegDetails from './SplitLegDetails';

// eslint-disable-next-line max-lines-per-function
export const OrderContext = createContext({});

const OrderView = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const [order, setOrder] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderCanceling, setIsOrderCanceling] = useState(false);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] =
    useState<any>(false);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [serviceList, setServiceList] = useState([]);
  const [isServiceLoading, setIsServiceLoading] = useState<any>(false);
  const [carrierData, setCarrierData] = useState<any[]>([]);

  const [isShowOrderActionsDropDown, setIsShowOrderActionsDropDown] =
    useState(false);
  const [isOrderRecurringModal, setIsOrderRecurringModal] = useState(false);
  const [isShowSplitLegModal, setIsShowSplitLegModal] = useState(false);
  const [isDisplayOrderDetail, setIsDisplayOrderDetail] = useState(false);

  // const [
  //   isShowAppointmentActionsDropDown,
  //   setIsShowAppointmentActionsDropDown,
  // ] = useState(false);
  const [orderLegs, setOrderLegs] = useState([
    { value: 'leg1', name: 'Master (#Leg 1)' },
    { value: 'leg1', name: 'Master (#Leg 1)' },
    { value: 'leg1', name: 'Master (#Leg 1)' },
  ]);
  const [activeLeg, setActiveLeg] = useState<any>(null);
  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [tabValues, setTabValues] = useState<{ [key: string]: any }>({
    leg1: {},
  });
  const [legData, setLegData] = useState<any>([]);

  const [isMasterOrder, setIsMasterOrder] = useState(true);
  const [isOrderHasLeg, setIsOrderHasLeg] = useState(false);

  const getOrderData = () => {
    setIsLoading(true);
    const param = { id };

    getOrder(param)
      .then((response) => {
        if (response?.data) {
          const masterOrder = response?.data;
          let orderLegsData: any = [];

          if (masterOrder?.orderLegs) {
            orderLegsData = Array.isArray(masterOrder?.orderLegs)
              ? masterOrder?.orderLegs
              : JSON.parse(masterOrder?.orderLegs);
          }

          setOrder({ ...masterOrder, orderLegs: orderLegsData });

          let orderLegItems = [];

          orderLegItems = [
            ...orderLegsData.map((subOrder: any, index: number) => ({
              value: subOrder?.legId,
              name:
                index === 0
                  ? `Master (#${subOrder?.legId})`
                  : `Leg${index + 1} (#${subOrder?.legId})`,
            })),
          ];

          setActiveLeg(masterOrder?.orderId);
          setOrderLegs(orderLegItems);

          const childOrderData = orderLegsData.reduce(
            (acc: any, subOrder: any, index: number) => {
              if (index === 0) {
                const resetOrderData = processOrderData(subOrder);
                setCurrentLegData(resetOrderData);
              }

              acc[subOrder.legId] = processOrderData(subOrder);

              return acc;
            },
            {}
          );

          const initialTabValues = {
            ...childOrderData,
          };

          setLegData(Object.values(childOrderData));
          setTabValues(initialTabValues);

          if (childOrderData?.length > 1) {
            setIsOrderHasLeg(true);
          }

          // const masterOrder = response?.data?.masterOrder;
          // const childOrders = response?.data?.childOrders || [];

          // const orderLegItems = [
          //   {
          //     value: masterOrder?.orderId,
          //     name: `Master (#${masterOrder?.orderId})`,
          //   },
          //   ...childOrders.map((subOrder: any, index: number) => ({
          //     value: subOrder?.orderId,
          //     name: `Leg${index + 1} (#${subOrder?.orderId})`,
          //   })),
          // ];

          // setActiveLeg(masterOrder?.orderId);
          // setOrderLegs(orderLegItems);
          // const resetOrderData = processOrderData(masterOrder);
          // setOrder(resetOrderData);
          // setCarrierData(resetOrderData?.carrierPrice);
          // setCurrentLegData(resetOrderData);

          // if (childOrders?.length > 0) {
          //   setIsOrderHasLeg(true);
          // }

          // const childOrderData = childOrders.reduce(
          //   (acc: any, subOrder: any) => {
          //     acc[subOrder.orderId] = processOrderData(subOrder);

          //     return acc;
          //   },
          //   {}
          // );

          // setLegData(Object.values(childOrderData));

          // const initialTabValues = {
          //   [masterOrder?.orderId]: resetOrderData,
          //   ...childOrderData,
          // };
          // setTabValues(initialTabValues);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsServiceLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getOrderData();
    getCustomerList();
    getListService();
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  const initTabArray: any = [
    {
      value: 'overview',
      name: 'Overview',
      count: 0,
    },
    {
      value: 'pricingTools',
      name: 'Pricing Tools',
      count: 0,
    },
    {
      value: 'emailThreads',
      name: 'Email Threads',
      count: 0,
    },
  ];

  const handleTabSwitch = async (tabValue: string) => {
    if (order?.orderId === tabValue) {
      setIsMasterOrder(true);
    } else {
      setIsMasterOrder(false);
    }

    const currentTabValue = tabValues[tabValue] ?? defualtOrderValue;
    setActiveLeg(tabValue);
    setCurrentLegData(currentTabValue);
  };

  const cancelCurrentOrder = () => {
    setIsOrderCanceling(true);
    const param = { masterOrderId: id };

    cancelOrder(param)
      .then((response) => {
        if (response) {
          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsOrderCanceling(false);
      });
  };

  return (
    <OrderContext.Provider
      value={{
        currentLegData,
        id,
        customerList,
        isCustomerLoading,
        isMasterOrder,
        isOrderHasLeg,
        legData,
        tabValues,
        handlingUnitOptions,
        isHandlingUnitLoading,
        serviceTypes,
        serviceList,
        isServiceLoading,
        setCurrentLegData,
        setTabValues,
        isLoading,
        carrierData,
        setCarrierData,
        order,
        setOrder,
      }}
    >
      <PageSectionLayout
        header={
          <Header
            leftClassName="!w-auto"
            title={
              <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
                Order:
                <span className={`${isLoading ? 'custom-loading' : ''}`}>
                  #{order ? order.orderId : 'CL-AOI1-141'}
                </span>
                <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                  {order?.customerName && (
                    <TooltipCmp message={order?.customerName}>
                      {order?.customerImage ? (
                        <div className="rounded-full bg-white">
                          <img
                            src={order?.customerImageUrl + order?.customerImage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultimage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                  {order?.salesRepFirstName && (
                    <TooltipCmp
                      message={`${order?.salesRepFirstName} ${order?.salesRepLastName}`}
                    >
                      {order?.salesImage ? (
                        <div className="rounded-full bg-white">
                          <img
                            src={order?.salesImageUrl + order?.salesImage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultimage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                </div>
                <BadgeCmp
                  style="modern"
                  mainClassName={`rounded-md ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  type="success"
                >
                  {order ? order.orderStatus : 'Opened'}
                </BadgeCmp>
              </div>
            }
            desc={`Detailed review of items, prices, and order status`}
            rightSideContent={
              <>
                <OutsideClickHandler
                  onOutsideClick={() => setIsShowOrderActionsDropDown(false)}
                  containerClassName="relative"
                >
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() =>
                      setIsShowOrderActionsDropDown((prev) => !prev)
                    }
                    loading={isOrderCanceling}
                    disabled={isOrderCanceling}
                  >
                    Order Actions
                  </ButtonCmp>
                  <ul
                    className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                      isShowOrderActionsDropDown ? '' : 'hidden'
                    } `}
                  >
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowOrderActionsDropDown(false);
                        navigate(`${PATH.ORDER_DETAILS}/${order?.id}`);
                      }}
                    >
                      Edit
                    </li>
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowOrderActionsDropDown(false);
                        cancelCurrentOrder();
                      }}
                    >
                      Cancel
                    </li>
                    {/* <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowOrderActionsDropDown(false);
                      }}
                    >
                      Duplicate
                    </li>
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowOrderActionsDropDown(false);
                        setIsOrderRecurringModal(true);
                      }}
                    >
                      Recurring
                    </li> */}
                  </ul>
                </OutsideClickHandler>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => {
                    localStorage.setItem(
                      TABLE_IDS.ORDER_LIST,
                      JSON.stringify('new-order')
                    );

                    navigate(`${ROUTES.ORDERS}`);
                  }}
                >
                  <XClose className="w-4 h-4" />
                </ButtonCmp>
              </>
            }
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <div className="flex h-full w-full">
          <div className="flex-1 xl:pr-8 pb-5 overflow-auto custom-scrollbar-v2 pt-6 flex flex-col gap-y-4">
            <TabButton
              parentClassName="!bg-gray50 !w-full "
              className="rounded-md text-gray500 !border-0 min-w-[182px] "
              tabParentClassName={`!w-full ${
                window.MODE === 'production' ? '!hidden' : ''
              }`}
              activeClassName="!bg-primary100 !text-primary700"
              tabArray={initTabArray}
              isActive={'overview'}
              isTab={true}
              handleOnClick={() => {}}
            />
            {/* NEED TO DO FUNCTIONALITY FOR ACCORDION - REMOVE "H-[84PX]" CLASS WHEN CLICK ON ARROW ChevronUp */}
            <div className="flex ml-1 overflow-hidden h-[84px] flex-none">
              <div className="flex-1 flex flex-wrap -mx-1">
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200 ">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Customer
                  </h6>

                  <Link
                    to={`${PATH.CUSTOMER_DETAILS}/${order?.customerId}`}
                    className="text-primary font-medium"
                  >
                    {order?.customerName && order?.customerName.length > 24 ? (
                      <TooltipCmp
                        message={'customer name'}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p
                          className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                        >
                          {!isLoading && order?.customerName
                            ? order?.customerName
                            : 'DSS Marine Incorporated (USD)'}
                        </p>
                      </TooltipCmp>
                    ) : (
                      <p
                        className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {!isLoading && order?.customerName
                          ? order?.customerName
                          : 'DSS Marine Incorporated (USD)'}
                      </p>
                    )}
                  </Link>
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Sales Rep.
                  </h6>
                  <div className="flex items-center gap-x-1">
                    {order?.salesImage && order?.salesImageUrl ? (
                      <img
                        src={order?.salesImageUrl + order?.salesImage}
                        className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                        {getShortName(
                          `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                        )}
                      </div>
                    )}

                    <p
                      className={`text-sm ${isLoading ? 'custom-loading' : ''}`}
                    >
                      {order?.salesRepId
                        ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Created At
                  </h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: order
                        ? getDateWithSuffixFormat(
                            getFormattedDate(
                              order.createdAt,
                              `Do MMMM YYYY`,
                              true,
                              true
                            )
                          )
                        : '-',
                    }}
                    className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  ></p>
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200 relative">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    From
                  </h6>

                  {order?.shipperAddress?.address1 &&
                  order?.shipperAddress?.address1.length > 24 ? (
                    <TooltipCmp
                      message={`${order?.shipperAddress?.address1}`}
                      parentClassName="md:!p-3 !p-1.5 max-w-90"
                    >
                      <p
                        className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {order?.shipperAddress?.address1
                          ? order?.shipperAddress?.address1
                          : '-'}
                      </p>
                    </TooltipCmp>
                  ) : (
                    <p
                      className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {order?.shipperAddress?.address1
                        ? order?.shipperAddress?.address1
                        : '-'}
                    </p>
                  )}
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    To
                  </h6>
                  {order?.consigneeAddress?.address1 &&
                  order?.consigneeAddress?.address1.length > 24 ? (
                    <TooltipCmp
                      message={`${order?.consigneeAddress?.address1}`}
                      parentClassName="md:!p-3 !p-1.5 max-w-90"
                    >
                      <p
                        className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {order?.consigneeAddress?.address1
                          ? order?.consigneeAddress?.address1
                          : '-'}
                      </p>
                    </TooltipCmp>
                  ) : (
                    <p
                      className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {order?.consigneeAddress?.address1
                        ? order?.consigneeAddress?.address1
                        : '-'}
                    </p>
                  )}
                </div>
              </div>
              <div
                className="relative flex-none pt-5 px-1 min-h-[84px] self-start"
                onClick={() => {
                  setIsDisplayOrderDetail(!isDisplayOrderDetail);
                }}
              >
                {isDisplayOrderDetail ? (
                  <ChevronUp className="w-4 h-4 cursor-pointer " />
                ) : (
                  <ChevronDown className="w-4 h-4 cursor-pointer" />
                )}
              </div>
            </div>
            {isDisplayOrderDetail && (
              <div className="flex">
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Claim ID
                  </h6>
                  <p>
                    {currentLegData?.claim?.claimID
                      ? `#${currentLegData?.claim?.claimID}`
                      : '-'}
                  </p>
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Dispute ID
                  </h6>
                  <p>
                    {currentLegData?.dispute?.disputeID
                      ? `#${currentLegData?.dispute?.disputeID}`
                      : '-'}
                  </p>
                </div>
                <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                  <h6 className="text-xs font-normal text-grayLight600 mb-1">
                    Load Insurance ID
                  </h6>
                  <p>-</p>
                </div>
              </div>
            )}
            {/* AFTER CREATION BOOKED FLOW */}
            <OrderStatus />
            <Appointment />

            {/* BELOW COMPONENT ALSO USED IN ORDER DETAIL */}
            {/* <TrackTraceAiCmp /> */}

            {/* AFTER CREATION BOOKED FLOW END*/}
            <div className="flex items-center flex-wrap gap-x-2">
              <div className="flex-1">
                <h6 className="text-grayLight900 text-base font-semibold">
                  Order Details
                </h6>
                {/* <p className="text-grayLight600 text-sm font-medium">
                  Track and Review Every Detail of Your Order
                </p> */}
              </div>
              <div className="flex flex-wrap gap-2">
                <ButtonCmp
                  className="btn-outline-primary"
                  icon={<Plus className="w-4 h-4" />}
                  onClick={() => setIsShowSplitLegModal(true)}
                  disabled
                >
                  Add Stop
                </ButtonCmp>
              </div>
            </div>
            <TabButton
              parentClassName="!bg-gray50 !w-full "
              className={`!rounded-md !border-0 [&>span]:text-gray400 min-w-[200px] ${
                isLoading ? 'custom-loading' : ''
              }`}
              activeClassName={`!bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md  ${
                isLoading ? 'custom-loading' : ''
              }`}
              tabArray={orderLegs}
              tabParentClassName=""
              handleOnClick={(e: any) => {
                const tabValue = e.target.dataset.value;
                handleTabSwitch(tabValue);
              }}
              isActive={activeLeg}
              isTab={true}
            />
            <ul className="flex flex-col gap-y-5">
              {((!isMasterOrder && isOrderHasLeg) ||
                (isMasterOrder && !isOrderHasLeg)) && <AddressDetails />}

              {isMasterOrder && isOrderHasLeg && <LegDetails />}

              <OrderDetails />
              <CarrierAndSalesPrice />
              <DocumentDetails />

              <OrderCostBreakDown />
            </ul>
          </div>
          <div className="flex flex-col bg-gray25">
            <div className="pl-5 py-5 border-b border-l border-utilityGray200">
              <div className="rounded-lg relative h-[190px] overflow-hidden">
                <div className="h-full bg-gray200">Map</div>
                <div className="flex gap-1.5 items-center mt-2 px-1.5 py-1 rounded shadow-sm bg-white absolute bottom-2.5 left-2.5">
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <span className="text-xs text-grayLight900 font-medium">
                    Last update: Today, 11:03 AM
                  </span>
                </div>
                <Link
                  to={ROUTES.HOME}
                  className="flex gap-1.5 items-center px-1.5 py-1 rounded-md border border-borderPrimary bg-white absolute top-2.5 right-2.5"
                >
                  <Target05 className="w-4 h-4 text-grayLight600" />
                  <p className="text-textSecondary text-xs font-medium">
                    Open in Radar
                  </p>
                </Link>
              </div>
            </div>
            <InternalChatAndNotes
              // claim={claim}
              isClaim={true}
              notesCount={<></>}
              setNotesCount={<></>}
              isShowChatAndNotesSideBar={<></>}
              setIsShowChatAndNotesSideBar={<></>}
              chatWrapClassName="overflow-x-auto"
            />

            {/* <InternalChatAndNotes
              data={[]}
              chatType={'order'}
              chatChannelName={'order_'}
              isShowInternalNote={false}
              notesCount={0}
              setNotesCount={<></>}
              isShowChatAndNotesSideBar={<></>}
              setIsShowChatAndNotesSideBar={<></>}
              chatWrapClassName="overflow-x-auto"
            /> */}
          </div>
        </div>

        {isOrderRecurringModal && (
          <RecurringOrderModal
            handleClose={() => setIsOrderRecurringModal(false)}
          />
        )}

        <SplitLegDetails
          isShowSplitLegModal={isShowSplitLegModal}
          setIsShowSplitLegModal={setIsShowSplitLegModal}
        />
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderView;
