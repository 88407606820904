import {
  ArrowDown,
  ArrowUp,
  InfoCircle,
} from '@untitled-ui/icons-react/build/cjs';
import React, { FC } from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

export interface IKpiCard {
  headerTitle: string;
  contentLable: string;
  contentDescription?: string;
  growth: any;
  fromDate: any;
  secondaryContentLabel?: string;
  secondaryContentDescription?: string;
  signIcon?: any;
  growthDescription?: string;
  isActive?: boolean;
  isAnalytics?: boolean;
  isLoading?: boolean;
  tooltipText?: string;
}

const KpiCard: FC<IKpiCard> = ({
  signIcon,
  headerTitle,
  contentLable,
  growth,
  fromDate,
  isLoading = false,
  tooltipText,
}) =>
  !isLoading ? (
    <>
      <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full xxl:p-5 xl:p-3 p-4 bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1">
        <div className="w-full justify-between font-medium flex  items-start">
          <h2 className="xxl:text-sm xl:text-[11px] text-xs font-medium xxl:pr-4 pr-2 flex">
            {headerTitle}
            {tooltipText && (
              <TooltipCmp message={tooltipText}>
                <InfoCircle className="text-grayText h-4 w-4 ml-2" />
              </TooltipCmp>
            )}
          </h2>
          {/* <div className="flex text-[11px] items-center text-primary font-semibold cursor-pointer flex-none">
            View Graph <ArrowUpRight className="h-4 w-4" />
          </div> */}
        </div>
        <div className="pt-4 text-start">
          <span
            className={`font-semibold xxl:text-3xl text-2xl ${
              isLoading ? 'custom-loading pl-8' : ''
            }`}
          >
            {contentLable}
          </span>
          <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
            {signIcon}
          </sup>
          <div
            className={`w-fit flex items-center mt-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {growth >= 0 ? (
              <ArrowUp className="w-4 h-4 text-green500 flex-none " />
            ) : (
              <ArrowDown className="w-4 h-4 text-danger flex-none " />
            )}
            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
              <span className="xxl:text-sm text-xs">
                {`${Math.abs(growth)}%`}
              </span>
              {`vs. ${fromDate}`}
            </p>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h1>loading...</h1>
  );
export default KpiCard;
