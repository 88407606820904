export const API = {
  USER: {
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    CODE: '/user/code',
    GET: '/user/get',
    LIST: '/user',
    LIST_COUNT: '/user-count',
    CREATE: '/user',
    UPDATE: '/user',
    DELETE: '/user',
    PERMISSION: '/user/permissions',
    GET_DETAIL: '/user/get-detail',
    REMOVE_FROM_TEAM: '/user/remove-from-team',
    USER_STATUS: '/user/status',
    ENTITY_COUNT: '/entity-counts',
    SWITCH_ROLE: '/user/switch-role',
  },
  TEAM: {
    LIST: '/team',
    LIST_ALL: '/team/list',
    CREATE: '/team/create',
    UPDATE: '/team/update',
    DELETE: '/team',
    DETAIL: '/team',
    ORG_CHART: '/ORGANIZATIONAL-chart',
  },
  CARRIER: {
    LIST: '/carrier',
    LIST_COUNT: '/carrier-count',
    CREATE: '/carrier',
    UPDATE: '/carrier',
    DELETE: '/carrier',
    CARRIER_LIST: '/carrier-list',
    ROSEROCKET_CARRIER_SEARCH: '/roserocket-carrier-search',
    UPDATE_IMAGE: '/carrier/image',
    GET_SERVICE_PRICES: '/carrier-service-prices',
    UPDATE_SERVICE_PRICES: '/carrier-service-prices',
  },
  CARRIER_CONTACT: {
    LIST: '/carrier-details',
    CREATE: '/carrier-details',
    UPDATE: '/carrier-details',
    DELETE: '/carrier-details',
  },
  QUOTE_EMAIL: {
    GET: '/quote-email',
    SEND_REPLY: '/send-reply',
    SET_EMAIL_READ: '/set-message-read',
    FETCH_EMAIL_DETAILS: '/fetch-email-details',
    GET_OUTLOOK_INTEGRATION: '/get-outlook-integration',
    INTEGRATE_OUTLOOK: '/integrate-outlook',
    DELETE_QUOTE_EMAIL: 'quote-email/delete',
    REMOVE_FROM_QUOTE: '/quote/remove-from-quote',
    DRAFT_EMAIL:
      'https://wal-py-ai-gbabdjc2dgh4htgs.canadacentral-01.azurewebsites.net/draft_email/',
  },
  QUOTE: {
    GET: '/quote',
    GET_USER_QUOTES: '/user/quotes',
    LIST: '/quote',
    QUOTELIST_COUNT: '/quote-count',
    GET_ANALYTICS: 'quote/analytics',
    GET_ANALYTICS_TEAM: '/quote/team',
    CREATE: '/quote/address',
    UPDATE: '/quote/address',
    CREATE_UPDATE_DIMENSION: '/quote/dimension',
    CREATE_UPDATE_SERVICE: '/quote/service',
    CREATE_UPDATE_SUMMARY: '/quote/summary',
    CREATE_RATE: '/quote/rate',
    UPDATE_RATE: '/quote/rate',
    CANCEL: '/quote/cancel',
    QUICK_QUOTE: '/quote/quick-quote',
    ACTIVE_QUOTE: '/quote/active-quote',
    DELETE_ACTIVE_QUOTE: '/quote/delete-active-quote',
    CREATE_PDF: '/quote/pdf',
    UPDATE_PDF: '/quote/pdf',
    LIST_PDF: '/quote/pdf',
    UPDATE_STATUS: '/quote/status',
    PUSH_TO_ROSE_ROCKET: '/quote/roserocket-order',
    BOOK_ORDER: '/quote/book-order',
    CHECK_BOL: '/quote/check-shipment-bol',
    DELETE: '/quote',
    MODIFY_QUOTE: '/quote/modifyquote',
    SPOT_QUOTE: '/quote/spot-quote-request',
    SPOT_QUOTE_UPDATE: '/quote/update-spot-quote',
    GET_FREIGHT_CLASS: '/quote/dimension/get-freight-class',
    GET_PREDICTIVE_PRICING: '/quote/predictive-pricing',
    MAKE_NO_QUOTE: '/quote/make-no-quote',
    LOAD_LINK: {
      GET_VEHICLE_TYPE: '/load-link/vehicle_type',
      GET_VEHICLE_SIZE: '/load-link/vehicle_size',
      GET_VEHICLE_ATTRIBUTE: '/load-link/vehicle_attribute',
      CREATE_POSTING: '/load-link/create-load-link-post',
      DELETE_POSTING: '/load-link/delete-load-link-post',
      GET_LEADS: '/load-link/leads',
      UPDATE_RATE_GATHERING: '/load-link/update-rate-gathering',
    },
  },
  SHIPPER: {
    LIST: '/shipper',
    CREATE: '/shipper',
    DELETE: '/shipper',
  },
  CUSTOMER: {
    LIST: '/customer',
    UPDATE_CUSTOMER: '/customer/update-customer',
    TOP_CUSTOMERS: '/top-customers',
    GET: 'customer-list',
    GET_CUSTOMER_COUNT: 'customer-list-count',
    GET_DETAIL: '/get-customer',
    DETAIL: 'customer-detail',
    SEGMENT: 'customer-segment-total',
    DELETE: '/customer',
    UPDATE_IMAGE: '/customer/image',
    ORDER_KEY_METRICS: 'customer-detail/key-metrics',
    LIST_SALES_REP_HISTORY: '/customer/sales-rep-history',
    LIST_SALES_REP_HISTORY_COUNT: '/customer/sales-rep-history-count',
    SALES_REP: 'customer/sales-rep',
    UPDATE_SALES_REP: 'customer/update-sales-rep',
    REASSIGN_SALES_REP: 'customer/reassign-sales-rep',
    CUSTOMERS_BY_USER_ID: '/customers-by-user-id',
    ANALYTICS: {
      GET: '/customer-detail/analytics',
      GET_HEAT_MAP_DATA: '/customer-detail/heat-map',
    },
    CONTACTS: {
      GET: '/customer/contacts',
      UPDATE: '/customer/contact',
      LIST: '/customer/contacts',
      LIST_COUNT: '/customer/contacts-count',
      DELETE_CONTACT: '/customer/contacts',
    },
    CREDIT: {
      CUSTOMER_CREDIT_DETAIL: '/customer/credit-details',
      LINK_COMPANY_TO_CREDIT_SAFE: '/customer/link-company-to-credit-safe',
      CREDIT_ORDER_LIST: '/customer/list-credit-orders',
      CREDIT_ORDER_LIST_COUNT: '/customer/list-credit-orders-count',
      LIST_CREDIT_SAFE_COMPANIES: '/customer/list-credit-safe-companies',
      ENABLE_CUSTOMER_CREDIT: '/customer/enable-customer-credit',
      BOOST_CUSTOMER_CREDIT: '/customer/boost-customer-credit',
    },
    GROUP: {
      CUSTOMER_GROUP_DETAIL: '/customer/groups-detail',
      DELETE_CUSTOMER_GROUP_LIST: '/customer/groups',
      CREATE_UPDATE_CUSTOMER_GROUP: '/customer/groups',
    },
  },
  CREDIT_INCREASE_REQUEST: {
    CREATE: '/credit-increase-request/create',
    LIST: '/credit-increase-request/list',
    LIST_COUNT: '/credit-increase-request/list-count',
    DECLINE: '/credit-increase-request/decline-request',
    APPROVE: '/credit-increase-request/approve-request',
  },
  CUSTOMER_ONBOARDING: {
    CREATE: '/customer-onboarding',
    SEND_MAIL: '/customer-onboarding/send-mail',
    RESEND_MAIL: '/customer-onboarding/resend-mail',
    VERIFY_OTP: '/customer-onboarding/verify-otp',
    UPDATE: '/customer-onboarding/update',
    DELETE_STATEMENT: '/customer-onboarding/delete-statement',
    UPLOAD_FINANCE_STATEMENT: '/customer-onboarding/upload-finance-statement',
    BUSINESS_CREDIT_APPLICATION:
      '/customer-onboarding/business-credit-application',
    BUSINESS_CREDIT_APPLICATION_DETAIL:
      '/customer-onboarding/business-credit-application-detail',
    CREDIT_REFERENCE_ATTACHMENT:
      '/customer-onboarding/credit-reference-attachment',
    DELETE_ATTACHMENT: '/customer-onboarding/delete-attachment',
    GET_CREDIT_REFERENCE_ATTACHMENT:
      '/customer-onboarding/get-credit-reference-attachment',
    CREDIT_REFERENCE_NOTE: '/customer-onboarding/create-credit-reference-note',
    GET_CREDIT_REFERENCE_NOTE:
      '/customer-onboarding/get-credit-reference-notes',
    AI_API: 'api/generate',
    CREDIT: {
      CUSTOMER_CREDIT_DETAIL: '/customer-onboarding/credit-limit-detail',
      LIST_CREDIT_SAFE_COMPANIES:
        '/customer-onboarding/list-credit-safe-companies',
    },
  },
  GEOGRAPHY: {
    GET: '/population-data',
  },
  HANDLING_UNIT: {
    LIST: '/handling-unit',
  },
  ROLE: {
    LIST: '/role',
  },
  PERMISSION: {
    LIST: '/permissions',
  },
  SERVICES: {
    LIST: '/service',
  },
  CURRENCY_RATE: {
    GET: '/currency/rate',
  },
  FEEDBACK: {
    CREATE: '/user/feedback',
    GET: '/user/feedback',
    UPDATE_STATUS: '/user/feedback-status',
  },
  CREDIT_DASHBOARD: {
    LIST: '/customer-onboarding',
    LIST_COUNT: '/customer-onboarding-count',
  },
  LOAD_INSURANCE: {
    LIST: '/load-insurance',
    LIST_COUNT: '/load-insurance-count',
    CREATE: '/load-insurance/create',
    GET: '/load-insurance',
    UPDATE: '/load-insurance/update',
    UPLOAD: '/load-insurance/upload-files',
    DELETE_FILE: '/load-insurance/delete-files',
    GETFILES: '/load-insurance/get-files',
    CREATEPDF: '/load-insurance/create-pdf',
    DOWNLOADPDF: '/load-insurance/download',
  },
  SALES_DASHBOARD: {
    UPLOAD: '/sales/upload-data',
    ASSIGN_SALES_REPS: '/sales/assign-sales-reps',
    UPLOAD_LIST: '/sales/get-upload-history',
    TEAM_PERFORMANCE_LIST: '/sales/team-performance-list',
    SALES_DATA: '/sales/get-data',
    SALES_ANALYTICS_DATA: '/sales/get-sales-data',
    INDIVIDUAL_GRAPH: '/sales/graph-data',
    TEAM_LIST: 'sales/team-list',
    DELETE_DATA: 'sales/reset-data',
    DOWNLOAD_FILE: 'sales/download-file',
    CONFIRM_UPLOAD: 'sales/confirm-upload-data',
    SEND_DOWNLOAD_OTP: '/sales/send-download-otp',
    VERIFY_DOWNLOAD_OTP: '/sales/verify-download-otp',
    LAST_SYNC: '/sales/upload-detail',
    CHECK_PENDING_UPLOAD: '/sales/check-pending-upload',
    TOTAL_GROSS_PROFIT_CHART: '/sales/get-total-gross-profit-chart-data',
  },
  SALES_HISTORY: {
    LIST: '/sales-history/list-sales-history',
    LIST_COUNT: '/sales-history/list-sales-history-count',
  },
  ORDERS: {
    GET_UNASSIGNED_ORDERS_BY_FILE: '/orders/get-unassigned-orders-by-file',
    LIST: '/orders/get-orders-list',
    LIST_COUNT: '/orders/get-orders-list-count',
    GET_ORDERS: '/orders/get-orders',
    CREATE: '/orders/create',
    UPDATE: '/orders/update',
    DELETE: '/orders/delete',
    ORDER_DRAFT_EMAIL: '/orders/draft-mail/draft-mail',
    GET_RECENT_ORDER: '/orders/get-recent-orders',
    GET_ORDER_DETAILS: '/orders/get-order-details',
    GET_ROSEROCKET_ORDER: '/orders',
    GET_NEW_ORDER_LIST: '/orders/get-new-orders',
    UPDATE_ORDER_STATUS: '/orders/update-order-status',
    GET_ORDER_PROGRESS: '/orders/get-order-progress',
    UPDATE_LOCATION: '/orders/update-location/update',
    ADD_INTERRUPTION: '/orders/add-interruption',
    UPLOAD_DOCUMENTS: '/orders/upload-documents',
    APPROVE_DOCUMENTS: '/orders/approve-documents',
    ORDER_SAVE_AS_DRAFT: '/orders/order-save-as-draft',
    CANCEL_ORDER: '/orders/cancel-order',
    APPOINTMENT: {
      CREATE_OR_UPDATE: '/orders/appointment/createOrUpdate',
      DELETE: '/orders/appointment/delete',
    },
    SALES: {
      CREATE: '/orders/sales/addSalesPrice',
      GET: '/orders/sales/get',
      UPDATE: '/orders/sales/update',
    },
    CARRIER: {
      GETACTIVEQUOTE: '/orders/carrier/get-active-quote',
      CREATE_OR_UPDATE: '/orders/carrier/create-and-update-carrier',
    },
  },
  INTEGRATIONS: {
    LIST: '/integrations',
    TYPE: '/integrations/type',
    MAP_SERVICE: '/integrations/map-service',
    SET_CONFIGURATIONS: '/integrations/configurations',
    ENABLE_INTEGRATION: '/integrations/enable',
  },

  TARGET: {
    LIST: '/sales/target-list',
    LIST_COUNT: '/sales/target-list-count',
    SET: '/sales/set-target',
    CUSTOMER_LIST: '/sales/customer-list',
  },

  CLAIMS: {
    LIST: '/claims-list',
    LIST_COUNT: '/claims-list-count',
    CLAIM_UPDATE_STATUS: '/claim/update-status',
    MODIFY_CLAIM_AMOUNT: '/claim/modify-claim-amount',
    CLAIM_DETAILS: '/claim',

    CLAIM_FILE_UPLOAD: {
      LIST: '/claim-dispute/attachment',
      LIST_COUNT: '/claim-dispute/attachment-count',
      DELETE_FILE: '/claim-dispute/attachment',
      CLAIM_ADD_FILE: '/claim-dispute/claim-attachment-create',
      DISPUTE_ADD_FILE: '/claim-dispute/dispute-attachment-create',
    },
  },

  DISPUTES: {
    LIST: '/disputes-list',
    LIST_COUNT: '/disputes-list-count',
    DISPUTE_UPDATE_STATUS: '/dispute/update-status',
    MODIFY_DISPUTE_AMOUNT: '/dispute/modify-dispute-amount',
    DISPUTE_DETAILS: '/dispute',
  },

  CLAIMS_AND_DISPUTE: {
    CHAT_MESSAGE: {
      LIST: '/chat-messages',
      SEND_MESSAGE: '/chat-messages/send',
      MARK_MESSAGE_AS_READ: '/mark-message-as-read',
    },
    INTERNAL_NOTES: {
      LIST: '/claim-and-dispute/get-internal-notes',
      CREATE: '/claim-and-dispute/create-internal-notes',
      MARK_NOTE_AS_READ: '/claim-dispute-internal-notes/mark-note-as-read',
    },
    CLAIMS_DISPUTE_TASK: {
      LIST: '/claim-dispute/action-item',
      LIST_COUNT: '/claim-dispute/action-item-count',
      ADD_TASK: '/claim-dispute/action-item',
      UPDATE_TASK: '/claim-dispute/action-item',
      DELETE_TASK: '/claim-dispute/action-item',
    },
  },

  NOTIFICATION: {
    LIST: '/notification',
    MARK_AS_READ_ALL: '/notifications/mark-as-read-all',
    READ_NEW_CLAIM_DISPUTE_NOTIFICATION:
      '/notifications/update-claim-and-dispute-status',
    READ_NEW_NOTIFICATION_STATUS: '/notifications/read-new-notification-status',
  },

  COMMISSION: {
    COMMISSION_ORDER_STATS: '/commission/orders/stats',
    MONTHLY_COMMISSION_REPORT: '/commission/monthly-report',
    MONTHLY_COMMISSION_REPORT_COUNT: '/commission/monthly-report-count',
    ALL_MONTH_REPORT: '/commission/all-month',
    ALL_MONTH_REPORT_COUNT: '/commission/all-month-list-count',
    SALES_REP_CONFLICTS: '/commission/sales-rep/conflicts',
    SALES_REP_ORDERS: '/commission/sales-rep/orders',
    SALES_REP_ORDERS_COUNT: '/commission/sales-rep/orders-count',
    RESOLVE_CONFLICT: '/commission/conflict/resolve',
    REMOVE_CONFLICT: '/commission/conflict/remove',
    EXPORT_MONTHLY: '/commission/monthly-export',
    EXPORT_SALES_REP: '/commission/sales-rep-export',
    REMOVE_FROM_COMMISSIONED: '/commission/remove-from-commissioned',
  },
  APPEARANCE: {
    CREATE: '/appearance/create',
    GET: '/appearance/get',
  },
  AI_CHATBOT: {
    QUESTION: '/sql_query/',
  },
  TWILIO_VOICE: {
    QUESTION: '/sql_query/',
  },
};
