import {
  CheckCircle,
  DotsVertical,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import TooltipCmp from 'src/components/TooltipCmp';

interface IProps {
  localLegData: any;
  setAction: any;
  handleDeleteAppointment: any;
  setEditMode: any;
  isShowDraftEmail: any;
  isLoading: any;
}

const CommonAppointmentDetails = ({
  localLegData,
  setAction,
  handleDeleteAppointment,
  setEditMode,
  isShowDraftEmail,
  isLoading,
}: IProps) => {
  const [
    isShowAppointmentActionsDropDown,
    setIsShowAppointmentActionsDropDown,
  ] = useState<any>({ mode: null });

  const handleEdit = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));

    setIsShowAppointmentActionsDropDown({
      mode: null,
    });
  };

  return (
    <div className="flex flex-wrap sm:-m-1.5">
      {!localLegData?.shipperAppointmentDatetime ? (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Shipper Pickup Appointment
          </h6>
          <div
            className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer ${
              isLoading ? 'custom-loading' : ''
            }`}
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'shipper-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Add Date and Time
            </p>
          </div>
        </div>
      ) : (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Shipper Pickup Appointment
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-xs px-3 py-2 flex items-center gap-x-1.5">
            <div className="text-xs text-[11px] font-semibold rounded p-0.5 border border-utilityGray200 h-9 w-9 text-primary700  flex flex-col items-center justify-center">
              <p>
                {localLegData?.shipperAppointmentDatetime
                  ? moment(localLegData.shipperAppointmentDatetime).format(
                      'MMM'
                    )
                  : '--'}
              </p>
              <p className="font-normal text-[10px] leading-3">
                {localLegData?.shipperAppointmentDatetime
                  ? moment(localLegData.shipperAppointmentDatetime).format(
                      'YYYY'
                    )
                  : '--'}
              </p>
            </div>
            <div className="flex-1 text-grayLight900 text-xs font-medium">
              <h6>
                {localLegData?.shipperAppointmentDatetime
                  ? moment(localLegData.shipperAppointmentDatetime).format(
                      'dddd, MMMM Do'
                    )
                  : 'No Date'}
              </h6>
              <p>
                {localLegData?.shipperAppointmentDatetime
                  ? moment(
                      localLegData.shipperAppointmentDatetime,
                      'YYYY-MM-DD HH:mm'
                    ).format('HH:mm')
                  : 'No Time'}
              </p>
            </div>
            <div className="flex gap-x-0.5">
              <TooltipCmp message={'Appointment Confirmed'}>
                <CheckCircle className="w-4 h-4 cursor-pointer text-primary" />
              </TooltipCmp>
              <OutsideClickHandler
                onOutsideClick={() =>
                  isShowAppointmentActionsDropDown?.mode ===
                    'shipper-appointment' &&
                  setIsShowAppointmentActionsDropDown({
                    mode: null,
                  })
                }
                containerClassName="relative"
              >
                <>
                  <DotsVertical
                    className="w-4 h-4 cursor-pointer text-gray500"
                    onClick={() =>
                      setIsShowAppointmentActionsDropDown({
                        mode: 'shipper-appointment',
                      })
                    }
                  />
                  <ul
                    className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                      isShowAppointmentActionsDropDown?.mode ===
                      'shipper-appointment'
                        ? ''
                        : 'hidden'
                    } `}
                  >
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        console.log('edit clickted');

                        handleEdit('shipper-appointment', {
                          date: localLegData?.shipperAppointmentDatetime,
                          time: localLegData?.shipperAppointmentDatetime,
                        });
                      }}
                    >
                      Edit
                    </li>
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowAppointmentActionsDropDown({
                          mode: null,
                        });
                        handleDeleteAppointment('shipper-appointment');
                      }}
                    >
                      Delete
                    </li>
                    {isShowDraftEmail && (
                      <li
                        className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                        onClick={() => {
                          setIsShowAppointmentActionsDropDown({
                            mode: null,
                          });
                          setAction((old: any) => ({
                            ...old,
                            mode: 'appointment-darft-modal',
                            type: 'shipper-appointment',
                          }));
                        }}
                      >
                        Draft Email
                      </li>
                    )}
                  </ul>
                </>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}

      {!localLegData?.carrierPickupAppointmentDatetime ? (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Pickup Appointment
          </h6>
          <div
            className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer ${
              isLoading ? 'custom-loading' : ''
            }`}
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'carrier-pickup-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Add Date and Time
            </p>
          </div>
        </div>
      ) : (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Pickup Appointment
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-xs px-3 py-2 flex items-center gap-x-1.5">
            <div className="text-xs text-[11px] font-semibold rounded p-0.5 border border-utilityGray200 h-9 w-9 text-primary700  flex flex-col items-center justify-center">
              <p>
                {localLegData?.carrierPickupAppointmentDatetime
                  ? moment(
                      localLegData.carrierPickupAppointmentDatetime
                    ).format('MMM')
                  : '--'}
              </p>
              <p className="font-normal text-[10px] leading-3">
                {localLegData?.carrierPickupAppointmentDatetime
                  ? moment(
                      localLegData.carrierPickupAppointmentDatetime
                    ).format('YYYY')
                  : '--'}
              </p>
            </div>
            <div className="flex-1 text-grayLight900 text-xs font-medium">
              <h6>
                {localLegData?.carrierPickupAppointmentDatetime
                  ? moment(
                      localLegData.carrierPickupAppointmentDatetime
                    ).format('dddd, MMMM Do')
                  : 'No Date'}
              </h6>
              <p>
                {localLegData?.carrierPickupAppointmentDatetime
                  ? moment(
                      localLegData.carrierPickupAppointmentDatetime,
                      'YYYY-MM-DD HH:mm'
                    ).format('HH:mm')
                  : 'No Time'}
              </p>
            </div>
            <div className="flex gap-x-0.5">
              <TooltipCmp message={'Appointment Confirmed'}>
                <CheckCircle className="w-4 h-4 cursor-pointer text-primary" />
              </TooltipCmp>
              <OutsideClickHandler
                onOutsideClick={() =>
                  isShowAppointmentActionsDropDown?.mode ===
                    'carrier-pickup-appointment' &&
                  setIsShowAppointmentActionsDropDown({
                    mode: null,
                  })
                }
                containerClassName="relative"
              >
                <DotsVertical
                  className="w-4 h-4 cursor-pointer text-gray500"
                  onClick={() =>
                    setIsShowAppointmentActionsDropDown({
                      mode: 'carrier-pickup-appointment',
                    })
                  }
                />
                <ul
                  className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                    isShowAppointmentActionsDropDown?.mode ===
                    'carrier-pickup-appointment'
                      ? ''
                      : 'hidden'
                  } `}
                >
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });

                      handleEdit('carrier-pickup-appointment', {
                        date: localLegData?.carrierPickupAppointmentDatetime,
                        time: localLegData?.carrierPickupAppointmentDatetime,
                      });
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });
                      handleDeleteAppointment('carrier-pickup-appointment');
                    }}
                  >
                    Delete
                  </li>
                  {isShowDraftEmail && (
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowAppointmentActionsDropDown({
                          mode: null,
                        });

                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-darft-modal',
                          type: 'carrier-pickup-appointment',
                        }));
                      }}
                    >
                      Draft Email
                    </li>
                  )}
                </ul>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}

      <div className="mx-1.5 bg-borderPrimary w-[1px]"></div>

      {!localLegData?.carrierDeliveryAppointmentDatetime ? (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Delivery Appointment
          </h6>
          <div
            className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer ${
              isLoading ? 'custom-loading' : ''
            }`}
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'carrier-delivery-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Add Date and Time
            </p>
          </div>
        </div>
      ) : (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Carrier Delivery Appointment
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-xs px-3 py-2 flex items-center gap-x-1.5">
            <div className="text-xs text-[11px] font-semibold rounded p-0.5 border border-utilityGray200 h-9 w-9 text-primary700  flex flex-col items-center justify-center">
              <p>
                {localLegData?.carrierDeliveryAppointmentDatetime
                  ? moment(
                      localLegData.carrierDeliveryAppointmentDatetime
                    ).format('MMM')
                  : '--'}
              </p>
              <p className="font-normal text-[10px] leading-3">
                {localLegData?.carrierDeliveryAppointmentDatetime
                  ? moment(
                      localLegData.carrierDeliveryAppointmentDatetime
                    ).format('YYYY')
                  : '--'}
              </p>
            </div>
            <div className="flex-1 text-grayLight900 text-xs font-medium">
              <h6>
                {localLegData?.carrierDeliveryAppointmentDatetime
                  ? moment(
                      localLegData.carrierDeliveryAppointmentDatetime
                    ).format('dddd, MMMM Do')
                  : 'No Date'}
              </h6>
              <p>
                {localLegData?.carrierDeliveryAppointmentDatetime
                  ? moment(
                      localLegData.carrierDeliveryAppointmentDatetime,
                      'YYYY-MM-DD HH:mm'
                    ).format('HH:mm')
                  : 'No Time'}
              </p>
            </div>
            <div className="flex gap-x-0.5">
              <TooltipCmp message={'Appointment Confirmed'}>
                <CheckCircle className="w-4 h-4 cursor-pointer text-primary" />
              </TooltipCmp>
              <OutsideClickHandler
                onOutsideClick={() =>
                  isShowAppointmentActionsDropDown?.mode ===
                    'carrier-delivery-appointment' &&
                  setIsShowAppointmentActionsDropDown(false)
                }
                containerClassName="relative"
              >
                <DotsVertical
                  className="w-4 h-4 cursor-pointer text-gray500"
                  onClick={() =>
                    setIsShowAppointmentActionsDropDown({
                      mode: 'carrier-delivery-appointment',
                    })
                  }
                />
                <ul
                  className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                    isShowAppointmentActionsDropDown?.mode ===
                    'carrier-delivery-appointment'
                      ? ''
                      : 'hidden'
                  } `}
                >
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });

                      handleEdit('carrier-delivery-appointment', {
                        date: localLegData?.carrierDeliveryAppointmentDatetime,
                        time: localLegData?.carrierDeliveryAppointmentDatetime,
                      });
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });
                      handleDeleteAppointment('carrier-delivery-appointment');
                    }}
                  >
                    Delete
                  </li>
                  {isShowDraftEmail && (
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowAppointmentActionsDropDown({
                          mode: null,
                        });

                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-darft-modal',
                          type: 'carrier-delivery-appointment',
                        }));
                      }}
                    >
                      Draft Email
                    </li>
                  )}
                </ul>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}
      {!localLegData?.deliveryAppointmentDatetime ? (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Consignee Delivery Appointment
          </h6>
          <div
            className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-4 px-3 flex items-center gap-x-3 cursor-pointer ${
              isLoading ? 'custom-loading' : ''
            }`}
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: 'delivery-appointment',
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-sm font-medium">
              Add Date and Time
            </p>
          </div>
        </div>
      ) : (
        <div className="sm:p-1.5 sm:flex-1">
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Consignee Delivery Appointment
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-xs px-3 py-2 flex items-center gap-x-1.5">
            <div className="text-xs text-[11px] font-semibold rounded p-0.5 border border-utilityGray200 h-9 w-9 text-primary700  flex flex-col items-center justify-center">
              <p>
                {localLegData?.deliveryAppointmentDatetime
                  ? moment(localLegData.deliveryAppointmentDatetime).format(
                      'MMM'
                    )
                  : '--'}
              </p>
              <p className="font-normal text-[10px] leading-3">
                {localLegData?.deliveryAppointmentDatetime
                  ? moment(localLegData.deliveryAppointmentDatetime).format(
                      'YYYY'
                    )
                  : '--'}
              </p>
            </div>
            <div className="flex-1 text-grayLight900 text-xs font-medium">
              <h6>
                {localLegData?.deliveryAppointmentDatetime
                  ? moment(localLegData.deliveryAppointmentDatetime).format(
                      'dddd, MMMM Do'
                    )
                  : 'No Date'}
              </h6>
              <p>
                {localLegData?.deliveryAppointmentDatetime
                  ? moment(
                      localLegData.deliveryAppointmentDatetime,
                      'HH:mm'
                    ).format('HH:mm')
                  : 'No Time'}
              </p>
            </div>
            <div className="flex gap-x-0.5">
              <TooltipCmp message={'Appointment Confirmed'}>
                <CheckCircle className="w-4 h-4 cursor-pointer text-primary" />
              </TooltipCmp>
              <OutsideClickHandler
                onOutsideClick={() =>
                  isShowAppointmentActionsDropDown?.mode ===
                    'delivery-appointment' &&
                  setIsShowAppointmentActionsDropDown(false)
                }
                containerClassName="relative"
              >
                <DotsVertical
                  className="w-4 h-4 cursor-pointer text-gray500"
                  onClick={() =>
                    setIsShowAppointmentActionsDropDown({
                      mode: 'delivery-appointment',
                    })
                  }
                />
                <ul
                  className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                    isShowAppointmentActionsDropDown?.mode ===
                    'delivery-appointment'
                      ? ''
                      : 'hidden'
                  } `}
                >
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });

                      handleEdit('delivery-appointment', {
                        date: localLegData?.deliveryAppointmentDatetime,
                        time: localLegData?.deliveryAppointmentDatetime,
                      });
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowAppointmentActionsDropDown({
                        mode: null,
                      });
                      handleDeleteAppointment('delivery-appointment');
                    }}
                  >
                    Delete
                  </li>
                  {isShowDraftEmail && (
                    <li
                      className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                      onClick={() => {
                        setIsShowAppointmentActionsDropDown({
                          mode: null,
                        });
                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-darft-modal',
                          type: 'delivery-appointment',
                        }));
                      }}
                    >
                      Draft Email
                    </li>
                  )}
                </ul>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonAppointmentDetails;
