import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getShortName,
  setNumberWithCommas,
  usePhone,
} from 'src/utils/CommonFunctions';

import star from '../../../../../assets/img/Star.svg';

import { ILeadRowProps } from './LoadBoard.interface';

const LoadBoardRateRow = ({ data }: ILeadRowProps) => {
  const { getFormatPhoneWithPlus } = usePhone();
  const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-44 min-w-44">
        <div className="flex items-center truncate">
          <div className="table-profile-customer-circle-xl">
            {getShortName(data.company)}
          </div>
          <div className="truncate ml-3">
            <TooltipCmp message={data.company} parentClassName="">
              <span className="truncate block text-grayLight900 font-semibold leading-5 ">
                {data.company}
              </span>
              <span className="truncate block text-grayLight900 leading-5">
                {getFormatPhoneWithPlus(data.companyPhone)}
              </span>
            </TooltipCmp>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 max-w-24 w-24 min-w-24">
        <div className="rounded-md border border-utilityGray200 px-1 py-0.5 inline-flex items-center gap-x-0.5 text-gray500 font-normal">
          4.5
          <img src={star} className="w-4 h-4" />
        </div>
      </td>
      <td className="px-5 py-4 max-w-32 w-32 min-w-32">
        <BadgeCmp
          style="modern"
          type={
            data.callStatus === 'completed'
              ? 'success'
              : data.callStatus === 'in progress'
              ? 'primary'
              : 'error'
          }
        >
          {data.callStatus}
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 max-w-32 w-32 min-w-32">
        <span>
          {data.callType
            ? data.callType.charAt(0).toUpperCase() + data.callType.slice(1)
            : ''}
        </span>
      </td>
      <td className="px-5 py-4 max-w-[1px] w-32 min-w-32">
        <p className="truncate">{data.carrierContactPerson}</p>
      </td>
      <td className="px-5 py-4 max-w-32 w-32 min-w-32">
        <span>
          {data.offerAmount ? `$${setNumberWithCommas(data.offerAmount)}` : ''}
        </span>
      </td>
      <td className="px-5 py-4 max-w-40 w-40 min-w-40">
        <p className="truncate">{data.vehicleType}</p>
      </td>
      <td className="px-5 py-4 min-w-44 w-44 max-w-44">
        <span>
          {data.estimatedDeliveryDate
            ? moment(data.estimatedDeliveryDate).format('MMM Do')
            : ''}
        </span>
      </td>
      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        <span>{moment(data.dateAvailability).format('MMM Do')}</span>
      </td>
      <td className="px-5 py-4 max-w-44 w-44 min-w-44">
        <TooltipCmp message={data.comment} parentClassName="">
          <div className="truncate">{data.comment || '-'}</div>
        </TooltipCmp>
      </td>
      <td
        className="px-5 py-4 max-w-16 w-16 min-w-16"
        onClick={() => setIsShowTypeInfoModal(true)}
      >
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </td>
      {isShowTypeInfoModal &&
        // <MoveToActive
        //   handleClose={() => setIsShowTypeInfoModal(false)}
        //   selectedCarrierRates={selectedCarrierRates}
        //   currency={currency}
        // />
        null}
    </>
  );
};

export default LoadBoardRateRow;
