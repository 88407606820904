import { Attachment01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { sendOrderEmail } from 'src/services/OrderService';

// import pdf from '../../assets/img/pdf-icon.png';
import TailwindAdvancedEditor from '../AdvancedEditor';
import ButtonCmp from '../ButtonCmp';
// import CheckBox from '../CheckBox';
import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';

interface EmailReplyCmpProps {
  subjectLine?: string;
  toEmailAddress?: string;
  emailDetails?: any;
  handleClose?: any;
}

const EmailReplyCmp = ({
  subjectLine,
  toEmailAddress,
  emailDetails,
  handleClose,
}: EmailReplyCmpProps) => {
  const defaultEditorContent = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [{ type: 'text', text: `Hello, ` }],
      },

      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: emailDetails,
          },
        ],
      },
    ],
  };

  const initFormData = {
    messageId: emailDetails?.id,
    to: toEmailAddress,
    emailType: '',
    emailBody: `<p>Hello,</p><p>${emailDetails}</p>`,
  };

  const initValidForm = {
    to: true,
    emailBody: true,
    cc: true,
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handleOnSubmit = () => {
    const errors: any = {};

    if (!formData.to || formData.to.trim().length === 0) {
      errors.to = 'Email address is required.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else if (!isValidEmail(formData.to)) {
      errors.to = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else {
      setValidForm((prev) => ({ ...prev, to: true }));
    }

    if (formData.cc && !isValidEmail(formData.cc)) {
      errors.cc = 'Please enter a valid CC email address.';
      setValidForm((prev) => ({ ...prev, cc: false }));
    } else {
      setValidForm((prev) => ({ ...prev, cc: true }));
    }

    if (!formData.emailBody || formData.emailBody.trim() === '') {
      errors.emailBody = 'Please enter the email body.';
      setValidForm((prev) => ({ ...prev, emailBody: false }));
    } else {
      setValidForm((prev) => ({ ...prev, emailBody: true }));
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);

      return;
    }

    setIsLoading(true);

    sendOrderEmail({ ...formData })
      .then((response) => {
        if (response) {
          handleClose();
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form
        className="flex flex-col gap-y-4 px-5 py-3"
        onSubmit={handleOnSubmit}
      >
        <div className="flex gap-x-2 w-full">
          <label className="text-gray400 text-sm font-medium form_label_center min-w-6">
            To
          </label>
          <div className="flex-1">
            <InputText
              value={formData?.to}
              parentClassName="flex-1"
              onChangeFunc={(e) => {
                setErrorMessages((old: any) => ({ ...old, to: null }));
                setValidForm((old: any) => ({ ...old, to: true }));

                setFormData((prevData: any) => ({
                  ...prevData,
                  to: e.target.value,
                }));
              }}
              errorText={validForm.to === false ? errorMessages?.to : ''}
            />
          </div>
        </div>

        <div className="flex gap-x-2 w-full">
          <label className="text-gray400 text-sm font-medium form_label_center min-w-6">
            Cc
          </label>
          <InputText
            parentClassName="flex-1"
            onChangeFunc={(e) => {
              if (e.target.value === '') {
                setErrorMessages((old: any) => ({ ...old, cc: null }));
                setValidForm((old: any) => ({ ...old, cc: true }));
              }
              setFormData((prevData: any) => ({
                ...prevData,
                cc: e.target.value,
              }));
            }}
            errorText={validForm.cc === false ? errorMessages?.cc : ''}
          />
        </div>

        <h6 className="text-textSecondary text-sm font-semibold pt-4 border-t  border-utilityGray200">
          {subjectLine}
        </h6>

        <div className="border-t border-utilityGray200">
          <div className="novel-editor-wrap pt-4">
            <TailwindAdvancedEditor
              initialValue={defaultEditorContent}
              handleChange={(newContent: any) => {
                setFormData((prevData: any) => ({
                  ...prevData,
                  emailBody: newContent,
                }));
              }}
              editorWrapClassName="!border-0 !p-0 !min-h-[unset]"
              editorContentClassName="!px-0"
            />
          </div>
        </div>
        {validForm.emailBody === false && (
          <ErrorMsg errorText={errorMessages?.emailBody} />
        )}

        {/* <ul className="!mt-10 !mb-3 flex flex-wrap -m-1.5">
          <li className="p-1.5 w-1/2">
            <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
              <img src={pdf} className="h-8 " />
              <div className="flex-1 w-[calc(100%_-_85px)] ">
                <h6 className="text-textSecondary text-xs font-medium truncate">
                  Shipment_POD_[05/06/24].pdf
                </h6>
                <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                  200 KB
                </p>
              </div>
              <CheckBox classes="" onChangeFunc={() => {}} />
            </div>
          </li>
          <li className="p-1.5 w-1/2 ">
            <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
              <img src={pdf} className="h-8 " />
              <div className="flex-1 w-[calc(100%_-_85px)] ">
                <h6 className="text-textSecondary text-xs font-medium truncate">
                  Shipment_POD_[05/06/24].pdf
                </h6>
                <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                  200 KB
                </p>
              </div>
              <CheckBox classes="" onChangeFunc={() => {}} />
            </div>
          </li>
        </ul> */}
      </form>
      <footer className="px-5 py-[14px] border-t flex flex-wrap gap-3 md:flex-row flex-col-reverse undefined">
        <ButtonCmp
          className="btn_secondary_black min-w-24"
          onClick={() => {
            handleClose();
          }}
        >
          Dismiss
        </ButtonCmp>
        <div className="flex gap-2 flex-1 justify-end">
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            icon={<Attachment01 className="w-4 h-4" />}
          >
            <></>
          </ButtonCmp>
          <ButtonCmp
            className="btn_primary min-w-20"
            onClick={handleOnSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Send
          </ButtonCmp>
        </div>
      </footer>
    </>
  );
};

export default EmailReplyCmp;
