export const APP_ROOT = '/';

export const ROUTES = {
  HOME: `${APP_ROOT}`,
  LOGIN: `${APP_ROOT}login`,
  CALLBACK: `${APP_ROOT}callback`,
  TEAMS: `${APP_ROOT}teams`,
  CARRIERS: `${APP_ROOT}carriers`,
  CONNECTIONS: `${APP_ROOT}connections`,
  CUSTOMERS: `${APP_ROOT}customers`,
  INTEGRATION: `${APP_ROOT}integrations`,
  CUSTOMER_DETAILS: `${APP_ROOT}customers/:id?`,
  CUSTOMER_GROUP: `${APP_ROOT}customers/customers-group/:id?`,
  TEAM_DETAIL: `${APP_ROOT}team-management/:id?`,
  ADDRESS_DETAILS: `${APP_ROOT}quotes/dashboard/address-details/:id?`,
  DIMENSIONS: `${APP_ROOT}quotes/dashboard/dimensions/:id?`,
  MY_QUOTE: `${APP_ROOT}quotes/dashboard/:id?`,
  PREDICTIVE_PRICING: `${APP_ROOT}predictive-pricing/`,
  FEEDBACK_LIST: `${APP_ROOT}feedback`,
  SETTING: `${APP_ROOT}settings`,
  ORDERS: `${APP_ROOT}orders`,
  NEW_ORDERS: `${APP_ROOT}orders-new`,
  RADAR: `${APP_ROOT}radar`,
  ORDER_DETAILS: `${APP_ROOT}order-details/:id?`,
  ORDER_DETAILS_VIEW: `${APP_ROOT}order-view/:id?`,
  QUOTING_DASHBOARD: `${APP_ROOT}quotes/dashboard`,
  QUOTING_ANALYTICS: `${APP_ROOT}quotes/analytics`,
  QUOTING_REQUEST: `${APP_ROOT}quotes/request`,
  RATE_SHOPPING: `${APP_ROOT}rate-shopping`,
  SALES_OVERVIEW: `${APP_ROOT}sales/overview`,
  SALES_HISTORY: `${APP_ROOT}sales/backup`,
  SALES_TARGET: `${APP_ROOT}sales/target`,
  SALES_TARGET_EDIT: `${APP_ROOT}sales/set-target`,
  SALES_COMMISSION_REPORT: `${APP_ROOT}sales/commission-report`,
  SALES_MONTHLY_COMMISSION_REPORT: `${APP_ROOT}sales/commission-report/:id?`,
  SALES_REP_COMMISSION_REPORT: `${APP_ROOT}sales/commission-report/:reportId/sales-rep/:id?`,
  TEAM_MANAGEMENT: `${APP_ROOT}team-management`,
  TEAM_MANAGEMENT_DETAIL: `${APP_ROOT}team-management/:id?`,
  ORGANIZATIONAL_CHART: `${APP_ROOT}organizational-chart`,
  TRACK_AND_TRACE: `${APP_ROOT}track-and-trace`,
  MAPIT: `${APP_ROOT}map-it`,
  LOAD_INSURANCE: `${APP_ROOT}load-insurance`,
  LOAD_INSURANCE_DETAILS: `${APP_ROOT}load-insurance/:id?`,
  CLAIMS_AND_DISPUTES_DETAILS: `${APP_ROOT}claims-and-disputes`,
  CLAIM_DETAILS: `${APP_ROOT}claims-and-disputes/claim-details/:id?`,
  DISPUTE_DETAILS: `${APP_ROOT}claims-and-disputes/dispute-details/:id?`,
  CUSTOMER_ONBOARDING: `${APP_ROOT}customer-onboarding/:id?`,
  SUBMISSION: `${APP_ROOT}customer-onboarding/submission/:id?`,
  CREDIT_DASHBOARD: `${APP_ROOT}credit-dashboard`,
  THANK_YOU: `${APP_ROOT}customer-onboarding/thank-you/:id?`,
  CREDIT_DASHBOARD_DETAIL: `${APP_ROOT}credit-dashboard/:id?`,
  AI_CHATBOT: `${APP_ROOT}ai-chatbot`,
  TWILIO_VOICE: `${APP_ROOT}twilio`,
};
