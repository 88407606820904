import { MarkerPin02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useMemo } from 'react';
import CommonModal from 'src/components/CommonModal';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '..';

import SplitLegDetailsRow from './SplitLegDetailsRow';

const SplitLegModal = ({
  handleSubmit,
  handleClose,
  setSplitLegData,
  splitLegData,
}: any) => {
  const { tabValues } = useContext<any>(OrderContext);

  const splitLegHeadCells = useMemo(
    () => [
      {
        id: 'leg',
        name: 'Leg',
      },
      {
        id: 'mileage',
        name: 'Mileage',
      },
      {
        id: 'origin',
        name: 'Origin',
      },
      {
        id: 'destination',
        name: 'Destination',
      },
      {
        id: '',
        name: '',
      },
    ],
    []
  );

  const legItem = Object.values(tabValues);

  const closeAddress = () => {
    setSplitLegData(null);
    handleClose();
  };

  const handleSplitLegClick = (data: any) => {
    setSplitLegData(data);
  };

  return (
    <>
      <CommonModal
        title="Split a Leg"
        titleDesc="Select which leg you want to split"
        handleClose={handleClose}
        headerIcon={<MarkerPin02 />}
        size={'max-w-[740px]'}
        primaryBtnText="Add Stop"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={closeAddress}
        primaryBtnOnClick={handleSubmit}
        // primaryBtnDisabled={!isValid}
      >
        <div className="p-5 flex flex-col gap-y-3">
          <h6 className="text-textSecondary text-sm font-semibold">
            Which leg would you like to split?
          </h6>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={splitLegHeadCells}
                tableDataArr={legItem}
                TableRowCmp={SplitLegDetailsRow}
                isTableRowClickable
                onRowClick={handleSplitLegClick}
                tableRowCmpProps={{ splitLegData }}
                numberOfSkeletonRows={1}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};
export default SplitLegModal;
