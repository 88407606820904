import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import { Plus } from 'lucide-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import {
  customerCreditIncreaseRequestList,
  customerCreditIncreaseRequestListCount,
} from 'src/services/CreditIncreaseRequestService';

import CreditIncreaseRequestLoaderRow from './CreditIncreaseRequestLoaderRow';
import CreditIncreaseRequestRow from './CreditIncreaseRequestRow';
import RequestCreditIncreaseModal from './RequestCreditIncreaseModal';

const creditIncreaseRequestStatusTypesArr: any = [
  {
    value: null,
    name: 'View All',
  },
  {
    value: 'in_review',
    name: 'In-Review',
  },
  {
    value: 'approved',
    name: 'Approved',
  },
  {
    value: 'denied',
    name: 'Denied',
  },
];

const HeadCells = [
  {
    id: 'firstName',
    name: 'User',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'customerName',
    name: 'Customers',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'creditLimit',
    name: 'Current Credit Limit',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'requestedCreditIncrease',
    name: 'Requested Credit Increase',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'requestedTotalCredit',
    name: 'Requested Credit Total',
    sortable: true,
    visible: true,
    rowClassName: '',
  },
  {
    id: 'action',
    name: '',
    visible: true,
    rowClassName: '',
  },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'id',
  page: 1,
  limit: 25,
  status: null,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const CreditIncreaseRequest = () => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [creditIncreaseRequestData, setCreditIncreaseRequestData] =
    useState<any>([]);
  const [totals, setTotals] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isShowReqCreditModal, setIsShowReqCreditModal] = useState(false);

  const { entityType, setEntityType } = useContext(NotificationsContext);
  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const getCreditIncreaseRequestList = async (signal: any) => {
    setCreditIncreaseRequestData([]);
    setIsLoading(true);

    try {
      const [
        creditIncreaseRequestListResponse,
        creditIncreaseRequestListCountResponse,
      ]: [any, any] = await Promise.all([
        customerCreditIncreaseRequestList(params, signal),
        customerCreditIncreaseRequestListCount(params, signal),
      ]);

      if (creditIncreaseRequestListResponse?.data) {
        setCreditIncreaseRequestData(creditIncreaseRequestListResponse.data);
      }

      setTotals(creditIncreaseRequestListCountResponse.total);

      setIsLoading(false);
      setIsRefresh(false);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }

      setIsLoading(false);
      setIsRefresh(false);
    }
  };

  useEffect(() => {
    if (!isRefresh) {
      return;
    }

    const CIRApiController = new AbortController();
    const CIRApiSignal = CIRApiController.signal;
    getCreditIncreaseRequestList(CIRApiSignal);

    return () => {
      CIRApiController.abort();
    };
  }, [isRefresh]);

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  const searchCustomerIncReqDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchIncCustomer') {
      setSearch(value);
      searchCustomerIncReqDebounce(value);
    }
  };

  useEffect(() => {
    if (entityType && entityType === 'credit_increase_request_created') {
      setIsRefresh(true);
      setEntityType(null);
    }
  }, [entityType]);

  useEffect(() => {
    if (
      creditIncreaseRequestData &&
      creditIncreaseRequestData.length > 0 &&
      !isNotificationLoading
    ) {
      setTimeout(() => {
        if (
          entityCounts?.creditIncreaseRequestCount &&
          entityCounts?.creditIncreaseRequestCount > 0
        ) {
          const param = { type: 'credit_increase_request_created' };
          handleNotificationRead(param);

          const newCreditRequest = creditIncreaseRequestData.map(
            (creditIncreaseRequest: any) => ({
              ...creditIncreaseRequest,
              hasUnreadNotification: false,
            })
          );

          setCreditIncreaseRequestData(newCreditRequest);
        }
      }, 5000);
    }
  }, [creditIncreaseRequestData, entityCounts, isNotificationLoading]);

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap sm:w-auto w-full">
            <div className="table-title-wrap">
              <h5 className="table-title">All Credit Increase Requests</h5>
              <BadgeCmp style="modern" type="success">
                {totals} Requests
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Track your customers credit increase requests
            </p>
          </div>
          <ButtonCmp
            className={`btn-outline-primary`}
            onClick={() => setIsShowReqCreditModal(true)}
          >
            <Plus className="w-4 h-4" />
            Credit Increase Request
          </ButtonCmp>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
              <TabButton
                tabArray={creditIncreaseRequestStatusTypesArr}
                parentClassName=""
                className=""
                activeClassName="!bg-utilityGray100"
                isActive={params.status}
                handleOnClick={(e: any) => {
                  console.log('e?.target?.dataset ', e?.target?.dataset);
                  setParams((old: any) => ({
                    ...old,
                    ...{ status: e?.target?.dataset?.value ?? null, page: 1 },
                  }));
                }}
              />
            </div>
          </div>
          <InputText
            inputName="searchIncCustomer"
            placeholder="Search"
            className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
            icon={
              <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
            }
            value={search}
            inputType="text"
            onChangeFunc={handleFilter}
            parentClassName="table-searchInput"
            isClearable={true}
          />
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              headCells={HeadCells}
              params={params}
              setParams={setParams}
              tableDataArr={creditIncreaseRequestData}
              TableLoaderRowCmp={CreditIncreaseRequestLoaderRow}
              TableRowCmp={CreditIncreaseRequestRow}
              tableRowCmpProps={{ setIsRefresh: setIsRefresh }}
              numberOfSkeletonRows={15}
              isTableDataLoading={isLoading}
              tableHeaderClass=""
              isTableRowClickable={false}
            />
          </div>
          {creditIncreaseRequestData.length <= 0 && !isLoading && (
            <NotFoundUI
              title="No Request Found"
              desc="There are no data for customers's credit increase request."
              containerClassName="min-h-[unset]"
            />
          )}
        </div>

        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={(page: number) =>
              setParams((old) => ({ ...old, page }))
            }
          />
        </div>
      </div>

      {isShowReqCreditModal && (
        <RequestCreditIncreaseModal
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefresh(true);
            }
            setIsShowReqCreditModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreditIncreaseRequest;
