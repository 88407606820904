import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

interface IProps {
  teamListOptions: any;
  userList: any;
  handleClose: any;
  params: any;
  setParams: any;
  setFilterCount: any;
}

const CustomerFilterModal = ({
  teamListOptions,
  userList,
  handleClose,
  params,
  setParams,
  setFilterCount,
}: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const uiState = useSelector((state: any) => state.MobileView);
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const [memberId, setMemberId] = useState(params?.member ?? 'all');

  const handleSave = () => {
    let fCount = 0;

    if (uiState.isMobile || uiState.currentBreakpoint === 'LG') {
      if (teamId != 'all') fCount += 1;
      if (memberId != 'all') fCount += 1;
    }

    setFilterCount(fCount);
    setParams((old: any) => ({
      ...old,
      page: 1,
      limit: 50,
      team: teamId,
      member: memberId,
    }));
    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[535px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5">
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3">
          {!hasRoleV2('user') && (
            <>
              <div className="sm:flex flex-wrap items-center xl:hidden">
                <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36`}>
                  Teams
                </label>
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  parentClassName="smd:flex-1"
                  options={teamListOptions}
                  onChangeFunc={(event: any) => {
                    setTeamId(event?.value ?? 'all');
                    setMemberId('all');
                  }}
                  value={
                    teamId
                      ? teamListOptions.filter(
                          (val: any) => teamId === val.value
                        )
                      : null
                  }
                />
              </div>

              <div className="sm:flex flex-wrap items-center xl:hidden">
                <label className={`form_label sm:mb-0 mb-1.5 xls:w-24 w-36`}>
                  Users
                </label>
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  className="form_control"
                  isClearable={true}
                  isSearchable={true}
                  options={teamMember}
                  parentClassName="smd:flex-1"
                  noOptionMessage="No Users Found"
                  onChangeFunc={(event: any) =>
                    setMemberId(event?.value ?? 'all')
                  }
                  value={
                    memberId
                      ? teamMember.filter(
                          (user: any) => memberId === user.value
                        )
                      : null
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerFilterModal;
