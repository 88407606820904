import { Signal01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';

const MessageBodyLoading = () => (
  <>
    <div className="flex flex-wrap xxl:gap-4 gap-3 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
      <div className="w-11 h-11 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none custom-loading">
        <span className="text-primary text-xl font-semibold">HP</span>
      </div>
      <div className="flex-1 flex flex-wrap xxl:gap-4 gap-2  md:flex-row flex-col ">
        <div className="flex-1">
          {/* <div className="flex flex-wrap gap-x-2 gap-y-1 items-center mb-0.5"> */}
          <h6 className="text-grayLight900 text-base font-medium flex xxl:gap-4 gap-3 items-center custom-loading">
            Harsh Patel
          </h6>
          {/* <div className="rounded-md border border-utilityGray200 bg-gray50 flex px-1.5 py-0.5 custom-loading">
              <a
                href="mailto:katherine.moss@wal.com"
                className="text-textSecondary text-xs font-medium"
              >
                harshpatel@logisticinfotech.co.in
              </a>
            </div> */}
          {/* </div> */}
          <a
            href="mailto:katherine.moss@wal.com"
            className="text-grayLight600 text-xs leading-[1.5] font-normal block w-fit custom-loading"
          >
            harshpatel@logisticinfotech.co.in
          </a>

          {/* <div className="text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5 custom-loading">
            <span className="font-bold mr-1">Subject: </span>
            <span className="font-normal">test</span>
          </div> */}
        </div>
        <div className="flex flex-wrap items-end lg:flex-col gap-1">
          <div className="text-grayLight600 text-xs font-normal  custom-loading">
            Monday, July 29, 2024 8:42 AM
          </div>
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName="rounded-[8px] border border-success200 bg-success50 custom-loading"
            badgeTextColor="!text-success700"
            isHidePillDot
          >
            {/* <ClockPlus className="w-3 h-3 me-1" /> */}
            <p className={`mr-1.5 h-1.5 w-1.5 bg-current rounded-full`}></p>
            00:39:23
          </BadgeCmp>
        </div>
      </div>
    </div>
    <div className="flex-1 w-full flex-col justify-start items-start gap-4 flex p-5 overflow-y-auto custom-scrollbar-v2">
      <div className="text-grayLight600 text-sm leading-[1.5] font-normal mt-0.5 custom-loading">
        <span className="font-bold mr-1">Subject: </span>
        <span className="font-normal">test</span>
      </div>
      <div className="custom-loading h-full w-full"></div>
    </div>
    <div className="flex sm:flex-wrap gap-2 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200 px-5 py-3 justify-end">
      <ButtonCmp
        className="btn_secondary_black custom-loading"
        icon={<Signal01 className="w-4 h-4" />}
      >
        Dismiss
      </ButtonCmp>
      <ButtonCmp
        className="btn_secondary_black custom-loading"
        icon={<Signal01 className="w-4 h-4" />}
      >
        Reply
      </ButtonCmp>
    </div>
  </>
);

export default MessageBodyLoading;
