import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const addLoadInsurance = async (data: any) => {
  const response = await axiosInterceptor.post(API.LOAD_INSURANCE.CREATE, data);

  return response;
};

export const updateLoadInsurance = async (data: any, id: number) => {
  const response = await axiosInterceptor.post(
    `${API.LOAD_INSURANCE.UPDATE}/${id}`,
    data
  );

  return response;
};

export const uploadLoadInsurance = async (data: any, config?: any) => {
  const response = await axiosInterceptor.post(
    `${API.LOAD_INSURANCE.UPLOAD}`,
    data,
    config
  );

  return response;
};

export const getLoadInsuranceFiles = async (id?: number) => {
  const response = await axiosInterceptor.patch(
    `${API.LOAD_INSURANCE.GETFILES}/${id}`
  );

  return response;
};

export const listLoadInsurance = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(`${API.LOAD_INSURANCE.LIST}`, {
    params,
    signal,
  });

  return response;
};

export const listLoadInsuranceCount = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(
    `${API.LOAD_INSURANCE.LIST_COUNT}`,
    {
      params,
      signal,
    }
  );

  return response;
};

export const loadInsuranceDetail = async (id: any) => {
  const response = await axiosInterceptor.patch(
    `${API.LOAD_INSURANCE.GET}/${id}`
  );

  return response;
};

export const downloadInsuranceCertificate = async (id: any) => {
  const response = await axiosInterceptor.get(
    `${API.LOAD_INSURANCE.DOWNLOADPDF}/${id}`
  );

  return response;
};

export const deleteFile = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.LOAD_INSURANCE.DELETE_FILE}/${id}`
  );

  return response;
};

export const createPdf = async (id: any) => {
  const response = await axiosInterceptor.post(
    `${API.LOAD_INSURANCE.CREATEPDF}/${id}`
  );

  return response;
};
