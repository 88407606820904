import { ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  analytics: any;
  activeFilter: string;
}

const AnlyticsData = ({ analytics, activeFilter }: IProps) => {
  const formatTimeDiff = (hoursDiff: any) => {
    const totalSeconds = Math.floor(Math.abs(hoursDiff) * 3600);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(
        2,
        '0'
      )}m ${String(seconds).padStart(2, '0')}s`;
    } else {
      return `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(
        2,
        '0'
      )}s`;
    }
  };

  const formatTimeRange = (timeRange: any) => {
    switch (timeRange) {
      case 'last_30_days':
        return 'Last 30 Days';
      case 'last_month':
        return 'Last Month';
      case 'last_year':
        return 'Last Year';
      case 'this_month':
        return 'Last Month';
      case 'this_year':
        return 'Last Year';
      case 'weekly':
        return 'Last Week';
      case 'monthly':
        return 'Last Month';
      case 'yearly':
        return 'Last Year';
      default:
        return 'Custom Range';
    }
  };

  console.log(formatTimeRange(activeFilter));

  return (
    <ul className="flex -m-2 flex-wrap mt-1.5">
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Total Quote Requests
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.totalQuotesRequest
                    ? analytics?.totalQuotesRequest
                    : 0}
                </span>
              </div>

              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.totalQuotesRequestDiff
                        ? Math.abs(analytics?.totalQuotesRequestDiff)
                        : 0}{' '}
                      %
                    </span>{' '}
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Total Quotes
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.totalQuotes
                    ? getFormattedNumber(analytics?.totalQuotes)
                    : 0}
                </span>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.totalQuotesDiff
                        ? Math.abs(analytics?.totalQuotesDiff)
                        : 0}
                      %
                    </span>{' '}
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Average Time to Quote
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.avgTimeToQuote == 0
                    ? '0m 0s'
                    : formatTimeDiff(analytics?.avgTimeToQuote)}
                </span>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.avgTimeToQuoteDiff > 0
                        ? Math.abs(analytics?.avgTimeToQuoteDiff).toFixed(2)
                        : 0}
                      %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Win/Loss
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.avgWinLossPercentage}
                </span>
                <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                  %
                </sup>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {Math.abs(analytics?.avgWinLossPercentageDiff)} %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Total Orders
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.totalOrders ? analytics?.totalOrders : 0}
                </span>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  {analytics?.totalOrdersDiff >= 0 ? (
                    <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                  ) : (
                    <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                  )}

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.totalOrdersDiff
                        ? Math.abs(analytics?.totalOrdersDiff)
                        : 0}
                      %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Total Margin
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.totalMargin
                    ? getFormattedNumber(analytics?.totalMargin)
                    : 0}
                </span>
                <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                  $
                </sup>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  {analytics?.totalMarginDiff >= 0 ? (
                    <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                  ) : (
                    <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                  )}

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.totalMarginDiff
                        ? Math.abs(analytics?.totalMarginDiff)
                        : 0}
                      %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Margin Percentage
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.avgMarginPercentage
                    ? analytics?.avgMarginPercentage
                    : 0}
                </span>
                <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                  %
                </sup>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  {analytics?.avgMarginPercentageDiff >= 0 ? (
                    <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                  ) : (
                    <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                  )}

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.avgMarginPercentageDiff
                        ? Math.abs(analytics?.avgMarginPercentageDiff)
                        : 0}
                      %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
        <div className="rounded-lg border border-utilityGray200 p-3">
          <div className="w-full justify-between flex items-start">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              Average Order Value
            </h6>
          </div>
          <div className="w-full flex justify-between pt-2 ">
            <div className="w-full flex flex-col justify-end">
              <div>
                <span className="font-semibold text-2xl">
                  {analytics?.avgOrderValue
                    ? getFormattedNumber(analytics?.avgOrderValue)
                    : 0}
                </span>
              </div>
              <div className="w-full flex  justify-between ">
                <div className={`w-fit flex justify-center items-center mt-2 `}>
                  {analytics?.avgOrderValueDiff >= 0 ? (
                    <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                  ) : (
                    <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                  )}

                  <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                    <span className="xxl:text-sm text-xs">
                      {analytics?.avgOrderValueDiff
                        ? Math.abs(analytics?.avgOrderValueDiff)
                        : 0}
                      %
                    </span>
                    &nbsp;vs {formatTimeRange(activeFilter)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default AnlyticsData;
