import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useMemo } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '..';

import dimensionsOrderDetailRow from './dimensionsOrderDetailRow';
import OrderDetailsRow from './OrderDetailsRow';

const OrderDetails = () => {
  const {
    currentLegData,
    isMasterOrder,
    isOrderHasLeg,
    legData,
    isLoading,
    order,
  } = useContext<any>(OrderContext);

  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
        rowClassName: '',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
        rowClassName: '',
      },
      {
        id: 'dimensions',
        name: 'Dimensions (Inches)',
        rowClassName: '',
      },
      {
        id: 'class',
        name: 'Class',
        rowClassName: '',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        rowClassName: '',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
        rowClassName: '',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: '',
      },
    ],
    []
  );

  const orderHeadCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'legId',
        name: 'Leg ID',
      },
      {
        id: 'pickup',
        name: 'Pickup',
      },
      {
        id: 'dropoff',
        name: 'Dropoff',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
    ],
    []
  );

  const combinedOrderDimensions = [
    ...(currentLegData?.order_dimensions || []).map((dimension: any) => ({
      ...dimension,
      shipper: currentLegData?.shipper?.shipperAddress || null,
      consignee: currentLegData?.consignee?.consigneeAddress || null,
      legId: currentLegData?.orderId || null,
    })),
    ...legData.flatMap((leg: any) =>
      (leg?.order_dimensions || []).map((dimension: any) => ({
        ...dimension,
        shipper: leg?.shipper?.shipperAddress || null,
        consignee: leg?.consignee?.consigneeAddress || null,
        legId: leg?.orderId || null,
      }))
    ),
  ];

  return (
    <li>
      <h6 className="text-textSecondary text-xs font-medium mb-1.5">
        Order Details
      </h6>

      {(!isMasterOrder && isOrderHasLeg) ||
      (isMasterOrder && !isOrderHasLeg) ? (
        <AccordionCmp
          title={
            <div className="flex flex-wrap items-start flex-1">
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Order Type
                </h6>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`!flex w-fit ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {currentLegData?.orderType === 'ltl' ? 'LTL' : 'FTL'}
                </BadgeCmp>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  PO number
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading ? currentLegData?.poNumber : '13123556'}
                </p>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Reference Number
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading ? currentLegData?.refNumber : '123456'}
                </p>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Linear Footage
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading ? currentLegData?.linearFootage : '123456'}
                </p>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Declared Value
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading ? order?.declaredValue ?? '-' : '123456'}
                </p>
              </div>
            </div>
          }
          className="!bg-gray25 !p-5"
          contentClassName="!bg-gray25 flex flex-col gap-y-4"
          contentParentClass="!border-utilityGray200 !mt-4"
        >
          <div>
            <h6 className="text-grayLight600 text-xs font-medium mb-1">
              Dimensions
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={headCells}
                  tableDataArr={currentLegData?.order_dimensions ?? []}
                  // TableLoaderRowCmp={CarriersListLoading}
                  TableRowCmp={dimensionsOrderDetailRow}
                />
              </div>
            </div>
          </div>
          {currentLegData?.additionalServices?.servicesData &&
            currentLegData?.additionalServices?.servicesData?.length > 0 && (
              <div>
                <h6 className="text-grayLight600 text-xs font-medium mb-1">
                  Additional Services
                </h6>
                <div className="rounded-xl border border-utilityGray200 bg-white shadow-sm p-3  ">
                  <div className="flex flex-wrap -mx-5">
                    {currentLegData?.additionalServices?.servicesData?.map(
                      (service: any, index: number) => (
                        <div
                          className="flex gap-2 text-grayLight900 truncate px-5 py-2.5 w-1/4"
                          key={index}
                        >
                          <Check className="w-4 h-4 text-primary flex-none" />
                          <h6 className="truncate text-xs"> {service?.name}</h6>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
        </AccordionCmp>
      ) : (
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
            <TableCmp
              headCells={orderHeadCells}
              tableDataArr={combinedOrderDimensions}
              TableRowCmp={OrderDetailsRow}
              numberOfSkeletonRows={1}
            />
          </div>
        </div>
      )}
    </li>
  );
};

export default OrderDetails;
