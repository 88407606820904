import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef } from 'react';

// Import the marker images
import startMarkerImg from '../../../assets/img/Ellipse 1.jpg';
import endMarkerImg from '../../../assets/img/Ellipse 1.jpg';
import middleMarkerImg from '../../../assets/img/Icon.svg';

interface MapboxProps {
  startPoint: { lat: number; lng: number; name: string };
  endPoint: { lat: number; lng: number; name: string };
  midpoint?: { lat: number; lng: number; name: string };
  height?: number;
  className?: string;
  markerRef?: any;
}

const MapboxMap: React.FC<MapboxProps> = ({
  startPoint,
  endPoint,
  midpoint,
  height = 500,
  className,
  markerRef,
}) => {
  const mapRef = useRef<any>(null);
  const mapContainerRef = useRef<any>(null);

  const initializeMap = useCallback(() => {
    if (!window?.MAPBOX_ACCESSTOKEN) {
      console.error('Mapbox access token is missing');

      return;
    }

    mapboxgl.accessToken = window.MAPBOX_ACCESSTOKEN;

    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [startPoint.lng, startPoint.lat], // Initial center set to the start point
        zoom: 5, // Adjust zoom level for better view of both points
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        // Create a custom marker for the start point
        const startMarker = document.createElement('div');
        startMarker.style.width = '40px';
        startMarker.style.height = '40px';
        startMarker.style.backgroundImage = `url(${startMarkerImg})`;
        startMarker.style.backgroundSize = 'cover';
        startMarker.style.borderRadius = '50%';

        // Add start point marker
        const startMarkerObj = new mapboxgl.Marker({ element: startMarker })
          .setLngLat([startPoint.lng, startPoint.lat])
          .addTo(mapRef.current);

        // Create a Popup for the start point with the place name
        const startPopup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setHTML(`<h3>${startPoint.name}</h3>`);

        startMarkerObj.setPopup(startPopup); // Attach popup to marker
        startPopup.addTo(mapRef.current); // Show the popup by default

        // Create a custom marker for the end point
        const endMarker = document.createElement('div');
        endMarker.style.width = '40px';
        endMarker.style.height = '40px';
        endMarker.style.backgroundImage = `url(${endMarkerImg})`;
        endMarker.style.backgroundSize = 'cover';
        endMarker.style.borderRadius = '50%';

        // Add end point marker
        const endMarkerObj = new mapboxgl.Marker({ element: endMarker })
          .setLngLat([endPoint.lng, endPoint.lat])
          .addTo(mapRef.current);

        const endPopup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setHTML(`<h3>${endPoint.name}</h3>`);

        endMarkerObj.setPopup(endPopup);
        endPopup.addTo(mapRef.current);

        if (midpoint?.lat) {
          const midpointMarker = document.createElement('div');
          midpointMarker.style.width = '40px';
          midpointMarker.style.height = '40px';
          midpointMarker.style.backgroundImage = `url(${middleMarkerImg})`;
          midpointMarker.style.backgroundSize = 'cover';

          new mapboxgl.Marker({
            element: midpointMarker,
          })
            .setLngLat([midpoint.lng, midpoint.lat])
            .addTo(mapRef.current);
        }

        const bounds = new mapboxgl.LngLatBounds();
        bounds.extend([startPoint.lng, startPoint.lat]);
        bounds.extend([endPoint.lng, endPoint.lat]);
        mapRef.current.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
        });
      });
    }
  }, [startPoint, endPoint, midpoint]);

  useEffect(() => {
    initializeMap();

    // return () => {
    //   if (mapRef.current) {
    //     mapRef.current.remove();
    //   }
    // };
  }, [initializeMap]);

  useEffect(() => {
    console.log('markerRef.current', markerRef.current);

    if (midpoint?.lat && midpoint?.lng) {
      const midpointMarker = document.createElement('div');
      midpointMarker.style.width = '40px';
      midpointMarker.style.height = '40px';
      midpointMarker.style.backgroundImage = `url(${middleMarkerImg})`;
      midpointMarker.style.backgroundSize = 'cover';

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker({
        element: midpointMarker,
      })
        .setLngLat([midpoint.lng, midpoint.lat])
        .addTo(mapRef.current);
    } else {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    };
  }, [midpoint, middleMarkerImg]);

  return (
    <div
      id="map-container"
      ref={mapContainerRef}
      style={{ width: '100%', height: `${height}px` }}
      className={className}
    />
  );
};

export default MapboxMap;
