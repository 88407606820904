import { PresentationChart03 } from '@untitled-ui/icons-react//build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import CommonModal from '../../../components/CommonModal';
import { getIndividualGraph } from '../../../services/SalesDashboard';
import {
  calculateNiceMaximum,
  getFormattedNumber,
} from '../../../utils/CommonFunctions';

import GrossProfitFilter from './GrossProfitFilter';
import { GraphType, commonOptions } from './OverView.interface';

const GrossProfit = ({
  handleClose,
  graphType,
  graphTitle,
  graphDesc,
  currentFilterType,
  teamId,
  userId,
}: any) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const [activeFilter, setActiveFilter] = useState('daily');
  const [isSalesLoading, setIsSalesLoading] = useState<any>(true);
  const [dateRange, setDateRange] = useState<any>(moment().format('MMMM-YYYY'));
  const [chartWidth, setChartWidth] = useState('100%');
  var unit = '';

  if (graphType === GraphType.MARGIN_PERCENTAGE.value) {
    unit = '%';
  } else if (graphType === GraphType.ORDER_COUNT.value) {
    unit = 'loads';
  }
  var xAxis = {
    categories: [],
    labels: {
      rotateAlways: false,
      hideOverlappingLabels: false,
      rotate: 0,
      formatter: function (value: string) {
        if (
          activeFilter === 'weekly' &&
          typeof value === 'string' &&
          value.includes('-')
        ) {
          const weekArr = value.split(' - ');

          return weekArr[0] ?? value;
        }

        return value;
      },
    },
  };

  // Adjust for mobile devices
  if (uiState.isMobile) {
    xAxis.labels.rotate = -45;
  }
  const grossProfitOption: any = {
    xaxis: xAxis,
    yaxis: {
      min: 0,
      max: 1000,
      tickAmount: 5,
    },
    tooltip: {
      theme: 'dark',
      position: 'top',
      y: {
        formatter: function (val: any) {
          return val ? getFormattedNumber(val.toFixed(2)) : 0;
        },
      },
      custom: function ({ series, dataPointIndex, w }: any) {
        var tooltipLabel: any = null;

        if (activeFilter === 'weekly') {
          const titleArr = w.globals.labels[dataPointIndex].split('-');
          const yearArr = dateRange.split('-');
          tooltipLabel = `${moment(
            `${titleArr[0]} ${yearArr[1]}`,
            'MMM DD YYYY'
          ).format('MMMM DD, YYYY')} - ${moment(
            `${titleArr[1]} ${yearArr[1]}`,
            'MMM DD YYYY'
          ).format('MMMM DD, YYYY')}`;
        } else if (activeFilter === 'monthly') {
          tooltipLabel = `${moment(
            `${w.globals.labels[dataPointIndex]} ${dateRange}`,
            'MMM YYYY'
          ).format('MMMM YYYY')}`;
        } else if (activeFilter === 'daily') {
          tooltipLabel = `${moment(
            w.globals.labels[dataPointIndex],
            'MMM DD'
          ).format('MMMM DD')}`;
        } else if (activeFilter === 'yearly') {
          tooltipLabel = w.globals.labels[dataPointIndex];
        }
        let tooltipContent = `<div class="apexcharts-tooltip-title" style="font-family: Inter, Helvetica, Arial, sans-serif; font-size: 12px; font-style: normal; font-weight: 500; line-height: 18px; color:#101828">
        	${tooltipLabel}
        </div>`;
        w.globals.seriesNames.forEach((seriesName: string, index: number) => {
          if (activeFilter === 'yearly') {
            if (seriesName === 'Previous') {
              tooltipLabel = `${moment(w.globals.labels[dataPointIndex], 'YYYY')
                .subtract(1, 'year')
                .format('YYYY')}`;
              // console.log(
              //   'previous:: ',
              //   w.globals.labels[dataPointIndex],
              //   tooltipLabel
              // );
            }
          }

          if (activeFilter === 'monthly') {
            if (seriesName === 'Previous') {
              tooltipLabel = `${moment(
                `${w.globals.labels[dataPointIndex]} ${dateRange}`,
                'MMM YYYY'
              )
                .subtract(1, 'year')
                .format('MMMM YYYY')}`;
            }
          }

          if (activeFilter === 'weekly') {
            if (seriesName === 'Previous') {
              const titleArr = w.globals.labels[dataPointIndex].split('-');
              const yearArr = dateRange.split('-');
              tooltipLabel = `${moment(
                `${titleArr[0]} ${yearArr[1]}`,
                'MMM DD YYYY'
              )
                .subtract(1, 'year')
                .format('MMMM DD, YYYY')} - ${moment(
                `${titleArr[1]} ${yearArr[1]}`,
                'MMM DD YYYY'
              )
                .subtract(1, 'year')
                .format('MMMM DD, YYYY')}`;
            }
          }
          var amount = '';

          if (
            graphType === GraphType.TOTAL_MARGIN.value ||
            graphType === GraphType.DAILY_MARGIN.value
          ) {
            amount =
              series[index]?.[dataPointIndex] > 0
                ? `$ ${getFormattedNumber(series[index][dataPointIndex])}`
                : '0';
          } else if (
            graphType === GraphType.ORDER_COUNT.value ||
            graphType === GraphType.CUSTOMER.value ||
            graphType === GraphType.NEW_CUSTOMER.value
          ) {
            amount =
              series[index]?.[dataPointIndex] > 0
                ? getFormattedNumber(series[index][dataPointIndex], false)
                : '0';
          } else {
            amount =
              series[index]?.[dataPointIndex] > 0
                ? getFormattedNumber(series[index][dataPointIndex])
                : '0';
          }

          if (graphType === GraphType.ORDER_COUNT.value) {
            unit = series[index]?.[dataPointIndex] > 1 ? 'loads' : 'load';
          }

          tooltipContent += `
        			<div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Inter, Helvetica, Arial, sans-serif; font-size: 12px; font-style: normal; line-height: 18px; color:#101828;">
            		<span class="apexcharts-tooltip-marker" style="height:10px; width:10px; background-color: ${w.globals.colors[index]}">
        			</span>
        			<div class="apexcharts-tooltip-y-group">
        				<span class="apexcharts-tooltip-text-y-label" style="font-weight: 400;">${tooltipLabel}: </span>
        				<span class="apexcharts-tooltip-text-y-value" style="font-weight: 700;"> ${amount} ${unit}</span>
        			</div>
            	</div>`;
        });

        return tooltipContent;
      },
    },
    ...commonOptions,
  };

  const [barGraphOptions, setBarGraphOptions] = useState<any>({
    options: grossProfitOption,
    series: [
      {
        name: `This`,
        data: [120, 250, 450, 650, 780],
      },
      {
        name: `Previous`,
        data: [220, 250, 350, 340, 550],
      },
    ],
  });

  const noDataOption = {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: -50,
    style: { color: undefined, fontSize: '16px', fontFamily: undefined },
  };

  const getChart = (chartGraphType: any, signal: any) => {
    setIsSalesLoading(true);

    const requestData = {
      type: activeFilter,
      order: currentFilterType,
      graph: chartGraphType,
      team: teamId,
      member: userId,
      range: dateRange,
    };
    setBarGraphOptions({
      options: grossProfitOption,
      series: [
        {
          name: `This`,
          data: [120, 250, 450, 650, 780],
        },
        {
          name: `Previous`,
          data: [220, 250, 350, 340, 550],
        },
      ],
    });
    getIndividualGraph(requestData, signal)
      .then((response) => {
        if (!response.data?.length) {
          setBarGraphOptions({
            ...barGraphOptions,
            series: [],
            options: { ...grossProfitOption, noData: noDataOption },
          });

          return;
        }

        const namesArray = response.data.map((item: any) => item.xValue);
        const filteredData =
          activeFilter === 'daily'
            ? response.data.filter((item: any) => item.yValue)
            : response.data.filter(
                (item: any) => item.yValue || item.yPreviousValue
              );

        if (filteredData.length > 0) {
          const valueArrays = response.data.reduce(
            (acc: any, item: any) => {
              acc[0].push(item.yValue > 0 ? item.yValue.toFixed(2) : 0);
              acc[1].push(
                item.yPreviousValue > 0 ? item.yPreviousValue.toFixed(2) : 0
              );

              return acc;
            },
            [[], []]
          );

          const max =
            activeFilter === 'daily'
              ? Math.max(
                  ...filteredData.map((item: any) => Math.max(item.yValue))
                )
              : Math.max(
                  ...filteredData.map((item: any) =>
                    Math.max(item.yValue, item.yPreviousValue || 0)
                  )
                );
          const tickAmount = 5;

          const series =
            activeFilter === 'daily'
              ? [{ name: '', data: valueArrays[0] }]
              : [
                  { name: 'This', data: valueArrays[0] },
                  { name: 'Previous', data: valueArrays[1] },
                ];
          const noDataConfig =
            filteredData.length > 0
              ? {}
              : {
                  text: '',
                  align: 'center',
                  verticalAlign: 'middle',
                  offsetX: 0,
                  offsetY: -50,
                  style: {
                    color: undefined,
                    fontSize: '16px',
                    fontFamily: undefined,
                  },
                };

          setBarGraphOptions({
            ...barGraphOptions,
            series,
            options: {
              ...grossProfitOption,
              xaxis: { ...grossProfitOption.xaxis, categories: namesArray },
              yaxis: {
                min: 0,
                max: calculateNiceMaximum(max, tickAmount),
                tickAmount: tickAmount,
                labels: {
                  formatter: function (value: any) {
                    if (value >= 10000) {
                      return `${(value / 1000).toFixed(0)}k`;
                    }

                    return value;
                  },
                },
              },
              noData: noDataConfig,
            },
          });
        } else {
          const series: any[] = [];

          const noDataConfig = {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: -50,
            style: {
              color: undefined,
              fontSize: '16px',
              fontFamily: undefined,
            },
          };

          setBarGraphOptions({
            ...barGraphOptions,
            series,
            options: {
              ...grossProfitOption,
              xaxis: { ...grossProfitOption.xaxis, categories: namesArray },
              noData: noDataConfig,
            },
          });
        }
        setIsSalesLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsSalesLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    // setBarGraphOptions({ ...{ options: {}, series: [] } });
    getChart(graphType, signal);

    return () => {
      // console.log('abort gross profit');
      controller.abort();
    };
  }, [graphType, currentFilterType, dateRange]);

  useEffect(() => {
    if (barGraphOptions?.options.xaxis?.categories.length > 0) {
      if (
        uiState.isMobile &&
        uiState.currentBreakpoint == 'SM' &&
        barGraphOptions?.options.xaxis.categories.length > 10
      ) {
        setChartWidth(
          `${barGraphOptions.options.xaxis?.categories.length * 60}px`
        );
      } else if (
        uiState.isMobile &&
        barGraphOptions?.options.xaxis.categories.length > 6
      ) {
        setChartWidth(
          `${barGraphOptions.options.xaxis.categories.length * 70}px`
        );
      } else if (
        uiState.isMobile &&
        barGraphOptions?.options.xaxis.categories.length > 50
      ) {
        setChartWidth(
          `${barGraphOptions.options.xaxis.categories.length * 150}px`
        );
      } else {
        setChartWidth(`100%`);
      }
    }
  }, [barGraphOptions]);

  return (
    <CommonModal
      title={graphTitle}
      titleDesc={`View and compare historical ${graphDesc} over time.`}
      headerIcon={<PresentationChart03 />}
      size="max-w-7xl"
      handleClose={handleClose}
    >
      <div className="w-full p-5 bg-white rounded-xl flex-col justify-start items-start gap-4 flex">
        <div className="w-full">
          <GrossProfitFilter
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <div
            className={`sales-chart-container w-full h-full mt-2 lg:pt-0 pt-7 ${
              uiState.isMobile ? 'overflow-x-auto overflow-y-hidden' : ''
            }`}
            id="sales-chart-container"
            key={`${barGraphOptions.series[0]?.name}_${
              isSalesLoading ? 'custom-loading' : 'loaded'
            }`}
          >
            <Chart
              options={barGraphOptions.options}
              series={barGraphOptions.series}
              className={`${
                isSalesLoading ? 'custom-loading graph-loading' : ''
              }`}
              type="bar"
              width={chartWidth}
              height={540}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default GrossProfit;
