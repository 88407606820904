import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';
import { usePhone } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';

const AddressDetails = () => {
  const { currentLegData, isLoading } = useContext<any>(OrderContext);
  const { getFormatPhoneWithPlus } = usePhone();

  const renderContactDetails = (contactData: any, type: string) => (
    <div>
      <h6 className="text-grayLight600 text-xs font-medium mb-1">{type}</h6>
      <div className="rounded-[10px] border border-utilityGray200 shadow-sm p-4 bg-white flex gap-x-6 flex-wrap">
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Name
          </h6>
          <p
            className={`text-textSecondary text-sm font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {!isLoading ? contactData?.contactName || 'N/A' : 'Test Customer'}
          </p>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Phone Number
          </h6>
          <Link
            to={`tel:${contactData?.phoneNumber}`}
            className={`text-textSecondary text-sm font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {!isLoading
              ? getFormatPhoneWithPlus(contactData?.phoneNumber) || 'N/A'
              : '(123) 123-1231'}
          </Link>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Email
          </h6>
          <Link
            to={`mailto:${contactData?.email}`}
            className={`text-textSecondary text-sm font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {!isLoading ? contactData?.email || 'N/A' : 'Test@gmail.com'}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <li>
      <h6 className="text-textSecondary text-xs font-medium mb-1.5">
        Shipper & Consignee Details
      </h6>
      <AccordionCmp
        title={
          <div className="flex items-start flex-1">
            <div className="w-1/2 pr-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                From
              </h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                <span className="font-medium text-grayLight900">
                  {currentLegData?.shipper?.companyName || 'N/A'}
                </span>
                &nbsp;{' '}
                {currentLegData?.shipper?.shipperAddress?.address1 || 'N/A'}
              </p>
            </div>
            <div className="w-1/2 pl-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">To</h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                <span className="font-medium text-grayLight900">
                  {currentLegData?.consignee?.companyName || 'N/A'}
                </span>
                &nbsp;{' '}
                {currentLegData?.consignee?.consigneeAddress?.address1 || 'N/A'}
              </p>
            </div>
          </div>
        }
        className="!bg-gray25 !p-5"
        contentClassName="!bg-gray25 flex flex-col gap-y-4"
        contentParentClass="!border-utilityGray200"
      >
        {renderContactDetails(currentLegData?.shipper, 'Shipper')}
        {renderContactDetails(currentLegData?.consignee, 'Consignee')}
      </AccordionCmp>
    </li>
  );
};

export default AddressDetails;
