import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const NewOrderLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-44 w-44">
      <div className="flex items-center">
        <div className="table-profile-user-circle-xl custom-loading">J D</div>
        <div className="pl-3 text-xs font-medium max-w-32">
          <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1 custom-loading">
            <span className="inline-block max-w-32 truncate">
              XXXXX XXXXXXXX
            </span>
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
            # WAL-TEST-123
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[110px] max-w-[110px] w-[110px] ">
      <BadgeCmp style="modern" type="error" mainClassName="custom-loading">
        New
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-36 min-w-36 w-36">
      <TooltipCmp childrenClassName="!block truncate max-w-32 custom-loading">
        Unicon Concrete Specialties Ltd
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 max-w-36 min-w-36 w-36">
      <TooltipCmp childrenClassName="!block truncate max-w-32 custom-loading">
        Unicon Concrete Specialties Ltd
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 max-w-40 min-w-40 w-40 ">
      <TooltipCmp type="dark" parentClassName="">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            Etobicoke, ON, Canada
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp type="dark" parentClassName="max-w-90">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            {' '}
            Still water, OK 74078, USA
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-48 w-48 max-w-48">
      <span className="custom-loading">
        Jul 8<sup>th</sup> - 2024, 09:52 AM
      </span>
    </td>
    <td className={`px-5 py-4 font-normal min-w-28 max-w-28 w-28  `}>
      <div className="flex gap-1">
        <span className="custom-loading">$123.45</span>
      </div>
    </td>
    <td className={`px-5 py-4 font-normal min-w-28 max-w-28 w-28  `}>
      <div className="flex gap-1">
        <span className="custom-loading">$123.45</span>
      </div>
    </td>
    <td className={`px-5 py-4 font-normal min-w-28 max-w-28 w-28  `}>
      <div className="flex gap-1">
        <span className="custom-loading">$123.45</span>
      </div>
    </td>
    <td className={`px-5 py-4 font-normal min-w-28 max-w-28 w-28  `}>
      <div className="flex gap-1">
        <span className="custom-loading">$123.45</span>
      </div>
    </td>
    <td className="px-5 py-4 max-w-28 min-w-28 w-28 ">
      <span className="custom-loading">
        Jul 8<sup>th</sup> - 2024, 09:52 AM
      </span>
    </td>
  </>
);

export default NewOrderLoaderRow;
