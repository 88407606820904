import { AlertCircle, PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';

import {
  inbondContactMethodList,
  inbondTypeList,
  initServiceValidForm,
} from './additionalServices.const';

interface IProps {
  setServicesValidForm: any;
  setIsInbondMore: any;
  setIsInbondMoreModalOpen: any;
  isInBondFormValid: any;
  setIsSubmitForm: any;
  handleChangeRadio: any;
  inbondType: any;
  validServicesForm: any;
  inbondName: any;
  setInbondName: any;
  inbondAddress: any;
  setInbondAddress: any;
  getName: any;
  inbondContactMethod: any;
  inbondContactValue: any;
  setInbondContactValue: any;
  handleNumberChange: any;
}

const InBondMoreModal = ({
  setServicesValidForm,
  setIsInbondMore,
  setIsInbondMoreModalOpen,
  isInBondFormValid,
  setIsSubmitForm,
  handleChangeRadio,
  inbondType,
  validServicesForm,
  inbondName,
  setInbondName,
  inbondAddress,
  setInbondAddress,
  getName,
  inbondContactMethod,
  inbondContactValue,
  setInbondContactValue,
  handleNumberChange,
}: IProps) => (
  <CommonModal
    title={'In-Bond Details'}
    titleDesc={'Please fill In-Bond Details'}
    handleClose={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="in-bond"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsInbondMore(false);
      setIsInbondMoreModalOpen(false);
    }}
    headerIcon={<PackagePlus />}
    size={'max-w-[688px]'}
    secondaryBtnText="Cancel"
    primaryBtnText="Save Changes"
    primaryBtnOnClick={() => {
      if (isInBondFormValid()) {
        setIsInbondMoreModalOpen(false);
        setIsSubmitForm(true);
      }
    }}
    secondaryBtnOnClick={() => {
      let option = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][name="in-bond"]'
      );

      if (option?.length) {
        option[0].click();
      }
      setServicesValidForm(initServiceValidForm);
      setIsInbondMore(false);
      setIsInbondMoreModalOpen(false);
    }}
  >
    <div className="p-5 ">
      <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
        {inbondTypeList.map((val: any, i) => (
          <div className="flex gap-2" key={`inbond_${i}`}>
            <Radio
              id={`inbond_${i}`}
              onChangeFunc={handleChangeRadio}
              inputName={'inbondName'}
              value={val.value}
              checked={inbondType === val.value}
            />

            <label
              htmlFor={`inbond_${i}`}
              className="text-textSecondary text-xs cursor-pointer font-medium"
            >
              {val.name}
            </label>
          </div>
        ))}
      </div>
      <div className="w-full mb-4">
        <InputText
          inputName="inbondName"
          required={true}
          placeholder=""
          className={`form_control ${
            !validServicesForm.inbondName
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          label="Warehouse or Carrier who will cancel US Bond"
          labelClassName="block mb-1.5"
          value={inbondName}
          icon={
            !validServicesForm.inbondName ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
            ) : null
          }
          // errorText={!validServicesForm.inbondName ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setInbondName(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />
      </div>
      <div className="w-full mb-5">
        <InputText
          inputName="inbondAddress"
          placeholder="123 Address"
          required={true}
          className={`form_control ${
            !validServicesForm.inbondAddress
              ? 'border border-red-500 border-solid'
              : ''
          }`}
          label="Address"
          labelClassName="block mb-1.5"
          icon={
            !validServicesForm.inbondName ? (
              <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
            ) : null
          }
          value={inbondAddress}
          // errorText={!validServicesForm.inbondAddress ? 'This field is required' : null}
          onChangeFunc={(e) => {
            setInbondAddress(e.target.value);
            setServicesValidForm((old: any) => ({
              ...old,
              [e.target.name]: true,
            }));
          }}
        />
      </div>
      <div className="w-full mb-4">
        {getName(inbondContactMethod) !== 'Phone' ? (
          <InputText
            inputName="inbondContactValue"
            // inputType={getName(inbondContactMethod) === "Phone" ? "number" : "text"}
            placeholder=""
            required={true}
            className={`form_control ${
              !validServicesForm.inbondContactValue
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            icon={
              !validServicesForm.inbondContactValue ? (
                <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
              ) : null
            }
            label={getName(inbondContactMethod)}
            labelClassName="block mb-1.5"
            value={inbondContactValue}
            errorText={
              !validServicesForm.inbondContactValue
                ? 'This field is required'
                : !validServicesForm.isEmailValid &&
                  getName(inbondContactMethod) === 'Email'
                ? 'enter valid email'
                : ''
            }
            onChangeFunc={(e) => {
              setInbondContactValue(e.target.value);
              setServicesValidForm((old: any) => ({
                ...old,
                [e.target.name]: true,
              }));
            }}
          />
        ) : (
          <PhoneInput
            onNumberChange={handleNumberChange}
            name="phone"
            errors={
              !validServicesForm.inbondContactValue
                ? 'Phone number is required'
                : !validServicesForm.isPhoneValid &&
                  getName(inbondContactMethod) === 'Phone'
                ? 'Invalid Number'
                : null
            }
            value={inbondContactValue || ''}
          />
        )}
      </div>
      <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
        {inbondContactMethodList.map((val: any, i) => (
          <div className="flex gap-2" key={`inbond_contact_${i}`}>
            <Radio
              id={`inbond_contact_${i}`}
              onChangeFunc={handleChangeRadio}
              inputName={'contactMethod'}
              value={val.value}
              checked={inbondContactMethod === val.value}
            />
            <label
              htmlFor={`inbond_contact_${i}`}
              className="text-textSecondary text-xs cursor-pointer font-medium uppercase"
            >
              {val.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  </CommonModal>
);

export default InBondMoreModal;
