import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import { BasicContext } from 'src/context/BasicContext';

import { OrderContext } from '..';
import {
  IMapAddress,
  initAction,
  initShipperAddress,
  // initShipperAddress,
  scriptId,
} from '../../order.interface';

import ShipperAndConsigneeAddressModal from './ShipperAndConsigneeAddressModal';

interface IProps {
  action: any;
  setAction: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
}

const AddressDetails = ({
  action,
  setAction,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  const {
    isValidAddress,
    setIsValidAddress,
    shipperOptions,
    consigneeOptions,
    shippers,
    consignees,
    currentLegData,
  } = useContext<any>(OrderContext);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [addressData, setAddressData] = useState<IMapAddress>();

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    if (currentLegData) {
      setShipperAddress(currentLegData?.shipper?.shipperAddress?.fullAddress);
      setConsigneeAddress(
        currentLegData?.consignee?.consigneeAddress?.fullAddress
      );
    }
  }, [currentLegData]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value?.fullAddress);
      setValue('shipper.shipperAddress', value);
      setIsValidAddress((old: any) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      setConsigneeAddress(value?.fullAddress);
      setValue('consignee.consigneeAddress', value);
      setIsValidAddress((old: any) => ({ ...old, shipperAddress: true }));
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value?.type === 'blur') {
      // if (value.target.value) {
      //   setIsValidAddress((old) => ({ ...old, [name]: false }));
      // }

      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('shipper.shipperAddress.fullAddress', {
          type: 'manual',
          message: 'Shipper address is invalid.',
        });
        setValue('shipper.shipperAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('consignee.consigneeAddress.fullAddress', {
          type: 'manual',
          message: 'Consignee address is invalid.',
        });
        setValue('consignee.consigneeAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    }

    if (name === 'consigneeAddress') {
      clearErrors('consignee.consigneeAddress.fullAddress');
      setValue('consignee.consigneeAddress', value);
      setConsigneeAddress(value?.fullAddress);
    }

    if (name === 'shipperAddress') {
      clearErrors('shipper.shipperAddress.fullAddress');
      setValue('shipper.shipperAddress', value);
      setShipperAddress(value?.fullAddress);
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'shipperId') {
      let shipperAddr = initShipperAddress;

      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );

        setValue('shipper.companyName', shipper.companyName);
        shipperAddr = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setShipperAddress(shipper.fullAddress);
      } else {
        setValue('shipper.companyName', undefined);
        setShipperAddress('');
      }

      setValue('shipper.shipperAddress', shipperAddr);
    } else if (name === 'consigneeId') {
      let consigneeAddr = initShipperAddress;

      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        setValue('shipper.companyName', consignee.companyName);
        consigneeAddr = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setConsigneeAddress(consignee.fullAddress);
      } else {
        setValue('consignee.companyName', undefined);
        setConsigneeAddress('');
      }

      setValue('consignee.consigneeAddress', consigneeAddr);
    }
  };

  useEffect(() => {
    clearErrors('shipper.shipperAddress.fullAddress');

    if (!shipperAddress) {
      setValue('shipper.shipperAddress', initShipperAddress);
    }
  }, [shipperAddress]);

  useEffect(() => {
    clearErrors('consignee.consigneeAddress.fullAddress');

    if (!consigneeAddress) {
      setValue('consignee.consigneeAddress', initShipperAddress);
    }
  }, [consigneeAddress]);

  return (
    <>
      <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
        <h5 className="text-grayLight900 text-xl font-semibold">
          Address Details
        </h5>
        <Address
          isOpen={
            action.mode === 'addressCreate' || action.mode === 'addressUpdate'
          }
          mode={action.mode}
          handleClose={handleDrawerClose}
          setAddressData={handleAddressSelection}
          addressType={action}
          addressData={addressData}
          status={status}
        />
        <ShipperAndConsigneeAddressModal
          control={control}
          errors={errors}
          data="shipper"
          title="Shipper"
          onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
          onAddressInputChange={setShipperAddress}
          setAction={setAction}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={shipperAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={shipperOptions}
          handleSelectChange={handleSelectChange}
        />

        <ShipperAndConsigneeAddressModal
          control={control}
          errors={errors}
          data="consignee"
          title="Consignee"
          onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
          onAddressInputChange={setConsigneeAddress}
          setAction={setAction}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={consigneeAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={consigneeOptions}
          handleSelectChange={handleSelectChange}
        />
      </li>
    </>
  );
};

export default AddressDetails;
