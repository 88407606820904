import { yupResolver } from '@hookform/resolvers/yup';
import {
  Eye,
  File06,
  Trash01,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import ImageIcon from 'src/assets/img/image-icon.svg';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import { MAX_FILE_SIZE } from 'src/constants/common';
import { uploadDocuments } from 'src/services/OrderService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  convertToGBMBKB,
} from 'src/utils/CommonFunctions';
import * as Yup from 'yup';

import pdf from '../../../assets/img/pdf-icon.png';
import ViewOrderPodModal from '../OrderView/ViewOrderPodModal';

// const schema = Yup.object().shape({
//   title: Yup.string().required('Title is required'),
//   file: Yup.mixed().required('File is required'),
// });

const createSchema = (isShowTitle: boolean) =>
  Yup.object().shape({
    title: isShowTitle
      ? Yup.string().required('Title is required')
      : Yup.string().notRequired(),
    file: Yup.mixed().required('File is required'),
  });

interface IProps {
  orderLegId: any;
  handleClose: any;
  title: any;
  viewPDFTitle?: any;
  fileStorePath: any;
  documentType: any;
  documents?: any;
  handleOnSubmit: any;
  isShowTitle: any;
}

const UploadOrderPodModal = ({
  orderLegId,
  handleClose,
  title = 'Upload POD',
  viewPDFTitle,
  fileStorePath,
  documentType,
  documents,
  handleOnSubmit,
  isShowTitle = true,
}: IProps) => {
  const [isShowViewOrderPodModal, setIsShowViewOrderPodModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [displayDocument, setDisplayDocument] = useState<any>('');
  const [fileName, setFileName] = useState<any>('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSize, setFileSize] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);
  const schema = createSchema(isShowTitle);

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(schema),
  });

  // const titleWatch = watch('title');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    setErrorMessage('');
    clearErrors('file');

    if (file?.type?.match('image') || file?.type?.match('application/pdf')) {
      const { result } = checkFileTypeValidation(acceptedFiles, MAX_FILE_SIZE, [
        'image/svg+xml',
        'image/png',
        'image/jpeg',
        'application/pdf',
      ]);

      if (result) {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setDisplayDocument('');
          setErrorMessage('');
          setFileName('');
          setValue('file', '');
          setFileSize(0);
          setUploadProgress(0);
        };

        reader.onloadend = () => {
          setFileName(file.name);
          setFileSize(file.size);
          setDisplayDocument(reader.result as any);
          setUploadProgress(100);
          setValue('file', file);
        };

        reader.readAsDataURL(file);
      } else {
        setErrorMessage('File size is invalid');
      }
    } else {
      setErrorMessage('File type is invalid');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
      'application/pdf': ['.pdf'],
    },
  });

  const resetDocument = () => {
    setDisplayDocument('');
    setFileName('');
    setErrorMessage('');
    setValue('file', '');
  };

  const onSubmit = (formData: any) => {
    const { convertedFile }: any = convertBase64ToFile(displayDocument);

    const appendFormData: any = new FormData();

    if (fileStorePath) {
      appendFormData.append('fileStorePath', fileStorePath);
    }

    if (formData?.title) {
      appendFormData.append('title', formData?.title);
    }
    appendFormData.append('documentType', documentType);
    appendFormData.append('orderLegId', orderLegId);
    appendFormData.append('fileOriginalName', fileName);
    appendFormData.append('size', fileSize);
    appendFormData.append('file', convertedFile);

    setIsLoading(true);

    uploadDocuments(appendFormData)
      .then((response) => {
        if (response?.data) {
          handleOnSubmit(response?.data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <CommonModal
        title={title}
        titleDesc="Attachments for your reference and use."
        handleClose={handleClose}
        headerIcon={<File06 />}
        size={'max-w-[600px]'}
        primaryBtnText="Attach Files"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleClose}
        primaryBtnOnClick={handleSubmit(onSubmit)}
        primaryBtnDisabled={isLoading}
        primaryBtnLoading={isLoading}
      >
        <div className="p-5 flex flex-col gap-y-4">
          {isShowTitle && (
            <Controller
              name="title"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  label="Title"
                  labelClassName="block mb-1.5"
                  placeholder={'Enter Title'}
                  value={value}
                  onChangeFunc={onChange}
                  errorText={error ? error?.message : ''}
                />
              )}
            />
          )}
          <div>
            {!displayDocument && (
              <>
                <div className="flex justify-between">
                  <h6 className="form_label block mb-1.5">Upload attachment</h6>

                  {viewPDFTitle && (
                    <div
                      className={`flex gap-x-1 text-primary text-xs font-medium cursor-pointer  ${
                        documents && documents?.length > 0
                          ? ''
                          : 'opacity-50 pointer-events-none'
                      }`}
                      onClick={() => setIsShowViewOrderPodModal(true)}
                    >
                      <Eye className="w-4 h-4" />
                      <p>{viewPDFTitle}</p>
                    </div>
                  )}
                </div>
                <div className="flex gap-4" {...getRootProps()}>
                  <label
                    className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
                    htmlFor="fileSelect"
                  >
                    <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                      <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                    </div>
                    <div className="text-grayLight600 text-xs font-normal">
                      <p>
                        <span className="text-[#2422DD] font-semibold pr-1">
                          Click to upload
                        </span>
                        or drag and drop
                      </p>
                      <p className="text-[11px] leading-[18px]">
                        SVG, PNG or JPG (max. 800x400px)
                      </p>
                    </div>
                  </label>

                  <input
                    type="file"
                    name="image"
                    id="fileSelect11"
                    className="hidden"
                    accept=".png,.jpg,.jpeg,.svg,.pdf"
                    ref={fileInput}
                    {...getInputProps()}
                  />
                </div>
                <span className="text-xs font-normal text-red-600 dark:text-red-500">
                  {errorMessage && <ErrorMsg errorText={errorMessage} />}
                  {errors?.file && (
                    <ErrorMsg errorText={errors?.file?.message} />
                  )}
                </span>
              </>
            )}

            {displayDocument && (
              <div className="rounded-xl border border-utilityGray200 p-4 flex gap-x-3 ">
                <div>
                  {fileName.endsWith('.pdf') ? (
                    <img
                      src={pdf}
                      className="h-10 w-10 object-contain"
                      alt="PDF Icon"
                    />
                  ) : (
                    <img
                      src={ImageIcon}
                      className="h-10 w-10 object-contain"
                      alt="Image Icon"
                    />
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-textSecondary text-sm font-medium">
                    {fileName}
                  </p>
                  <span className="text-grayLight600 text-sm font-normal">
                    {convertToGBMBKB(fileSize)} – {uploadProgress}% uploaded
                  </span>
                </div>
                <div
                  className="text-[10px] cursor-pointer"
                  onClick={() => {
                    resetDocument();
                  }}
                >
                  <Trash01 />
                </div>
              </div>
            )}
          </div>
        </div>
      </CommonModal>
      {isShowViewOrderPodModal && (
        <ViewOrderPodModal
          handleClose={() => setIsShowViewOrderPodModal(false)}
          title={documentType === 'POD' ? 'View POD' : 'View Customs Documents'}
          titleDesc={
            documentType === 'POD'
              ? 'Verify and Approve Proof of Delivery'
              : 'Verify Customs Documents'
          }
          isShowButtons={false}
          contentTitle={
            documentType === 'POD' ? 'View PODs' : 'View Customs Documents'
          }
          documents={documents}
          orderLegId={orderLegId}
        />
      )}
    </>
  );
};
export default UploadOrderPodModal;
