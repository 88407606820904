import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateRangePicker from 'src/components/DateRangePicker';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';
// import TabButton from 'src/components/TabButton';
import {
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

const FilterModal = ({
  teamListOptions,
  userList,
  handleClose,
  params,
  setParams,
}: any) => {
  const { hasRoleV2 } = useRolePermission();
  const { allTimeStartDateActual } = useContext(BasicContext);
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [memberId, setMemberId] = useState(params?.member ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [startDate, setStartDate] = useState<any>(
    params.startDate ? moment(params.startDate).toDate() : null
  );
  const [endDate, setEndDate] = useState<any>(
    params.endDate ? moment(params.endDate).toDate() : null
  );

  const handleSave = () => {
    setParams((old: any) => ({
      ...old,
      segment: '',
      isPAgination: false,
      team: teamId,
      member: memberId,
    }));
    handleClose();
  };

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateActual);

    // for user if start of allTimeStartDateActual is less than last year's first date then not letting user go further than last year's first date
    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateActual);
    }
  }, [allTimeStartDateActual]);

  const handleDatePickerChange = (date: any, type: any) => {
    const [start, end] = date;
    console.log('start', start, ' ', end);
    setSelectedTimeRange(type);

    setStartDate(start ?? null);

    if (!end) {
      setEndDate(start ?? null);
    } else {
      setEndDate(end ?? null);
    }
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[490px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5 flex flex-col gap-3">
        {/* <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3"> */}
        <SelectBox
          label="Teams"
          labelClassName="form_label"
          name="teamListGroup"
          id="teamListGroup"
          className="form_control w-full"
          size="sm"
          placeholder="All Teams"
          noOptionMessage="No Teams Found"
          parentClassName="smd:flex-1"
          isClearable={true}
          isSearchable={true}
          options={teamListOptions}
          onChangeFunc={(event: any) => {
            setTeamId(event?.value ?? 'all');
            setMemberId('all');
          }}
          value={
            teamId
              ? teamListOptions.filter((val: any) => teamId === val.value)
              : null
          }
        />

        <SelectBox
          label="Users"
          labelClassName="form_label"
          name="userListGroup"
          id="userListGroup"
          size="sm"
          placeholder="All Users"
          className="form_control w-full"
          isClearable={true}
          isSearchable={true}
          options={teamMember}
          parentClassName="smd:flex-1"
          noOptionMessage="No Users Found"
          onChangeFunc={(event: any) => setMemberId(event?.value ?? 'all')}
          value={
            memberId
              ? teamMember.filter((user: any) => memberId === user.value)
              : null
          }
        />

        <div
          className="items-center cursor-pointer justify-between flex flex-1"
          key={`datepicker_${startDate}_${endDate}`}
        >
          <div className="smd:flex flex-wrap items-center flex-1 gap-1">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>
              Created Date
            </label>
            <DateRangePicker
              isDisplayAsBox={false}
              handleDatePickerChange={(dates: any, type?: string) =>
                handleDatePickerChange(dates, type)
              }
              selectedTimeRange={selectedTimeRange}
              startDate={startDate}
              endDate={endDate}
              allTimeStartDate={allTimeStartDate}
              showYearDropdown={false}
              isShowDropDownIcon={false}
              minDate={
                hasRoleV2('user')
                  ? moment().subtract(1, 'years').startOf('year').toDate()
                  : ''
              }
              containerClassName="!mt-0"
              containerWrapClassName="h-screen w-screen fixed left-1/2 top-1/2 flex items-center justify-center -translate-y-1/2 -translate-x-1/2 z-[1] after:content-[''] after:bg-black after:bg-opacity-20 after:backdrop-blur-[2px] after:w-full after:h-full"
              parentClassName="sales-datepicker"
              textWrapClass="!justify-start"
              calendarClass="!hidden"
              dateRangeWrapClass="!shadow-none"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </CommonModal>
  );
};

export default FilterModal;
