import React, { memo } from 'react';
import CommonModal from 'src/components/CommonModal';

interface IFormSubmitModalProps {
  title: string;
  description: string;
  handleClose(status: boolean): void;
  isSubmitting?: boolean;
  submitBtnText?: string;
  Icon: any;
  handleSubmit: any;
}

const ConfirmationModalCmp = ({
  title,
  description,
  handleClose,
  isSubmitting = false,
  submitBtnText = 'Save',
  Icon,
  handleSubmit,
}: IFormSubmitModalProps) => (
  <CommonModal
    title={title}
    size="max-w-[400px]"
    titleDesc={description}
    handleClose={() => (isSubmitting ? false : handleClose(false))}
    modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
    modalHeaderTextClass="!flex-col"
    modalFooterClass="border-t-0 pt-0"
    headerIcon={Icon}
    secondaryBtnOnClick={() => handleClose(false)}
    secondaryBtnDisabled={isSubmitting}
    secondaryBtnText="Cancel"
    primaryBtnOnClick={handleSubmit}
    primaryBtnDisabled={isSubmitting}
    primaryBtnLoading={isSubmitting}
    primaryBtnText={submitBtnText}
  >
    &nbsp;
  </CommonModal>
);

export default memo(ConfirmationModalCmp);
