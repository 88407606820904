import { MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import LineItemsRow from './LineItemsRow';

const radioOptions = [
  { id: 'dropoff', value: 'dropoff', label: 'Drop-offs' },
  { id: 'pickup', value: 'pickup', label: 'Pickups' },
  { id: 'both', value: 'both', label: 'Both' },
  { id: 'cross-dock', value: 'cross-dock', label: 'Cross-Dock' },
];

const LegOrderDetailsModal = ({
  handleClose,
  combinedOrderDimensions,
  handlingUnitOptions,
  serviceTypes,
}: any) => {
  const [selectedOption, setSelectedOption] = useState('dropoff');
  const [selectedLineItemId, setSelectedLineItemId] = useState<any>([]);
  const [additionalServiceOptions, setAdditionalServiceOptions] = useState<any>(
    []
  );
  const [additionalServiceLabel, setAdditionalServiceLabel] = useState<string>(
    'Delivery Additional Services'
  );

  useEffect(() => {
    if (serviceTypes) {
      setAdditionalServiceOptions(getSelectBoxOptions(serviceTypes.DELIVERY));
    }
  }, [serviceTypes]);

  const getOptionArr = (condition: string) => {
    let optionArr;
    const deliveryOptions = getSelectBoxOptions(serviceTypes.DELIVERY);
    const pickupOptions = getSelectBoxOptions(serviceTypes.PICKUP);
    const otherOptions = getSelectBoxOptions(serviceTypes.OTHER);

    switch (condition) {
      case 'both':
        optionArr = [...deliveryOptions, ...pickupOptions];
        setAdditionalServiceLabel('Delivery Additional Services');
        break;

      case 'pickup':
        optionArr = pickupOptions;
        setAdditionalServiceLabel('Pickup Additional Services');
        break;

      case 'dropoff':
        optionArr = deliveryOptions;
        setAdditionalServiceLabel('Pickup And Delivery Additional Services');
        break;

      case 'cross-dock':
        optionArr = otherOptions;
        setAdditionalServiceLabel('Cross-Dock Additional Services');
        break;

      default:
        break;
    }

    return optionArr;
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOption(value);

    const optionArr = getOptionArr(value);
    console.log('optionArr', optionArr);

    setAdditionalServiceOptions(optionArr);
  };

  const handleCheckBoxChange = (data: any) => {
    setSelectedLineItemId((prevSelectedIds: any) =>
      prevSelectedIds?.includes(data?.id)
        ? prevSelectedIds.filter((item: any) => item !== data?.id)
        : [...prevSelectedIds, data?.id]
    );
  };

  const lineItemsHeadCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'dimensions',
        name: 'Dimensions (Inches)',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
      {
        id: 'action',
        name: (
          <>
            <CheckBox classes="mr-2" onChangeFunc={() => {}} />
          </>
        ),
      },
    ],
    []
  );

  const closeAddStop = () => {
    handleClose();
  };

  return (
    <CommonModal
      title="Select a Midpoint"
      titleDesc="Select a midpoint to split your leg into 2."
      handleClose={handleClose}
      headerIcon={<MarkerPin04 />}
      size={'max-w-[800px] overflow-unset'}
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={closeAddStop}
      isOverflow={false}
      // primaryBtnOnClick={}
      // primaryBtnDisabled={!isValid}
    >
      <div className="p-5 flex flex-col gap-y-4">
        <div>
          <h6 className="text-textSecondary text-sm font-medium mb-1.5">
            Are there any pickups or drop-offs at this location?
          </h6>
          <div className="rounded-lg border border-borderPrimary bg-white px-3.5 py-2.5 flex gap-4">
            {radioOptions.map((option: any) => (
              <Radio
                key={option.id}
                id={option.id}
                inputName="transportType"
                value={option.value}
                checked={selectedOption === option.value}
                labelName={option.label}
                labelHtmlFor={option.id}
                onChangeFunc={handleRadioChange}
                radioLabelClassName="!normal-case"
              />
            ))}
          </div>
        </div>

        <SelectBox
          name="provider"
          id="provider"
          className="form_control"
          size="sm"
          placeholder="Search services"
          isClearable={true}
          isSearchable={false}
          options={additionalServiceOptions}
          onChangeFunc={() => {}}
          value={'Tailgate delivery'}
          label={additionalServiceLabel}
          labelClassName="form_label mb-1.5 block"
        />

        {selectedOption &&
          (selectedOption === 'dropoffs' || selectedOption === 'both') && (
            <div>
              <h6 className="text-textSecondary text-sm font-medium mb-1.5">
                Select the line items being dropped off at this stop
              </h6>
              <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={lineItemsHeadCells}
                    tableDataArr={combinedOrderDimensions}
                    TableRowCmp={LineItemsRow}
                    tableRowCmpProps={{
                      isShowCheckBox: true,
                      selectedLineItemId,
                      handlingUnitOptions,
                    }}
                    numberOfSkeletonRows={1}
                    isTableRowClickable
                    onRowClick={(data: any) => {
                      handleCheckBoxChange(data);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

        {selectedOption && selectedOption === 'cross-dock' && (
          <div className="flex gap-3">
            <SelectBox
              name="warehouse"
              id="warehouse"
              className="form_control"
              size="sm"
              placeholder="Select Warehouse"
              isClearable={true}
              isSearchable={false}
              options={additionalServiceOptions}
              onChangeFunc={() => {}}
              value={'Tailgate delivery'}
              label="Warehouse"
              labelClassName="form_label mb-1.5 block"
              parentClassName="flex-1"
            />
            <InputText
              label="Warehouse Charge"
              labelClassName=" mb-1.5 block"
              placeholder="Enter charge"
              parentClassName="flex-1"
            />
          </div>
        )}
      </div>
    </CommonModal>
  );
};
export default LegOrderDetailsModal;
