import React from 'react';

const CityListLoading = () => (
  <div>
    <ul>
      {[...Array(9)].map((_, index) => (
        <li
          key={index}
          className="relative flex items-start gap-x-2 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden [&:last-child>div:after]:hidden"
        >
          <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[26px] after:absolute flex items-center justify-center after:bg-utilityGray200">
            <div className="rounded-full shadow-xs px-1 py-0.5 border border-utilityGray200 text-grayLight900 text-xs min-w-[22px] text-center  custom-loading">
              {++index}
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-2">
            <h6 className="text-primary text-xs font-semibold custom-loading">
              test city
            </h6>
            <p className="text-grayLight600 text-xs font-normal custom-loading w-fit">
              population : 360
            </p>
          </div>

          <p className="text-right text-grayLight900 text-xs leading-[1.5] font-medium custom-loading">
            1000 km
          </p>
        </li>
      ))}
    </ul>
  </div>
);

export default CityListLoading;
