import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import TrimbleMap from 'src/components/TrimbleMap/Map';
import { BasicContext } from 'src/context/BasicContext';

import AutoCompleteGoogle from '../../components/AutoCompleteGoogle/AutoCompleteGoogle';
import CommonModal from '../../components/CommonModal';
import InputText from '../../components/InputText/InputText';
import Map from '../../components/Map/Map';
import { VALIDATION_MESSAGE } from '../../constants/common';

const initFormData = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

const initValidForm = {
  fullAddress: true,
  // address1: true,
  city: true,
  state: true,
  country: true,
  postal: true,
  latitude: true,
  longitude: true,
  stateCode: true,
  countryCode: true,
};

const requiredFields = ['fullAddress', 'city', 'state', 'country', 'postal'];

const UpdateLocation = ({
  isOpen,
  mode,
  handleClose,
  setAddressData,
  addressData,
  status,
}: any) => {
  const formRef = useRef(null);

  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [isFullAddressChanged, setIsFullAddressChanged] = useState(false);
  const [fullAddress, setFullAddress] = useState('');
  const { mapService } = useContext(BasicContext);

  useEffect(() => {
    if (isOpen) {
      if (mode === 'addressUpdate') {
        setFullAddress(addressData?.fullAddress);
        setFormData(addressData);
        setIsFullAddressChanged(true);
      }

      if (mode === 'addressCreate') {
        setIsFullAddressChanged(false);
      }
    } else {
      setFormData(initFormData);
      setValidForm(initValidForm);
      setIsFullAddressChanged(false);
    }
  }, [isOpen]);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      fullAddress: true,
      city: true,
      state: true,
      country: true,
      postal: true,
    };

    if (formData.fullAddress === '' || formData.fullAddress === null) {
      validFormData.fullAddress = false;
      valid = false;
    }

    if (formData.city === '' || formData.city === null) {
      validFormData.city = false;
      valid = false;
    }

    if (formData.state === '' || formData.state === null) {
      validFormData.state = false;
      valid = false;
    }

    if (formData.country === '' || formData.country === null) {
      validFormData.country = false;
      valid = false;
    }

    if (
      formData.postal === '' ||
      formData.postal === null ||
      formData.postal?.length < 4
    ) {
      validFormData.postal = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  useEffect(() => {
    let isFieldsValid = requiredFields.every(
      (requiredField) =>
        formData[requiredField] !== '' && formData[requiredField] !== null
    );

    if (formData?.postal?.length < 4) {
      isFieldsValid = false;
    }

    if (formData?.fullAddress) {
      isFormValid();
    }
    setIsValid(isFieldsValid);
  }, [formData]);

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!formData.fullAddress) {
        setFormData(initFormData);
        setValidForm(initValidForm);
        setValidForm({ ...validForm, fullAddress: false });
        setIsFullAddressChanged(false);
      }

      return;
    }

    setFormData(value);
    setIsFullAddressChanged(true);
    isFormValid();
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setAddressData(formData);
    handleClose();
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event?.preventDefault();
    }
  };

  const closeAddress = () => {
    handleClose();
  };

  const MapComponent = useMemo(() => {
    if (formData.fullAddress && formData.fullAddress !== '') {
      return (
        <div className="pb-5 border-b border-utilityGray200">
          <p className="form_label mb-1.5">Map location</p>
          {mapService === 'trimble_map' ? (
            <TrimbleMap
              address={formData}
              id={'addressMap'}
              height={261}
              onReverseAddressFunc={handleAutocompleteChange}
              className="rounded-[20px]"
            />
          ) : (
            <Map
              address={formData}
              id={'addressMap'}
              height={261}
              onReverseAddressFunc={handleAutocompleteChange}
              className="rounded-[20px]"
            />
          )}
        </div>
      );
    }

    return null;
  }, [isFullAddressChanged]);

  return (
    <>
      <CommonModal
        title="Address Selection"
        titleDesc="Drag the map so the pin matches the exact location of your business location."
        handleClose={handleClose}
        headerIcon={<PackagePlus />}
        size={'max-w-[688px]'}
        primaryBtnText="Save Changes"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={closeAddress}
        primaryBtnOnClick={handleSubmit}
        primaryBtnDisabled={!isValid}
      >
        <form
          ref={formRef}
          className="p-5 flex flex-col gap-5"
          onSubmit={handleSubmit}
          onKeyDown={handleKeyPress}
        >
          {status &&
            (mapService === 'trimble_map' ? (
              <AutoCompleteTrimble
                inputName="fullAddress"
                placeholder="Enter address"
                className="form_control truncate"
                label="Address"
                labelClassName="mb-1.5 block"
                dropdownClass="!max-h-[200px]"
                onAddressInputChange={setFullAddress}
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                errorText={
                  !validForm.fullAddress ? 'Address is required' : null
                }
                value={fullAddress}
              />
            ) : (
              <AutoCompleteGoogle
                inputName="fullAddress"
                placeholder="Enter address"
                className="form_control truncate"
                label="Address"
                labelClassName="mb-1.5 block"
                onAddressInputChange={handleAutocompleteChange}
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                errorText={
                  !validForm.fullAddress ? 'Address is required' : null
                }
                value={formData?.fullAddress}
              />
            ))}

          {MapComponent}

          <InputText
            inputName="address1"
            placeholder="Enter address 1"
            className="form_control"
            label="Street"
            labelClassName="block mb-1.5"
            value={formData.address1}
            // errorText={!validForm.address1 ? 'Address 1 name is required' : null}
            onChangeFunc={handleInputChange}
          />

          <div className="flex gap-5">
            <div className=" w-1/2 ">
              <InputText
                inputName="city"
                placeholder="Enter city"
                className="form_control"
                label="City"
                labelClassName="block mb-1.5"
                value={formData.city}
                errorText={
                  formData.fullAddress && !validForm.city
                    ? VALIDATION_MESSAGE.INVALID_ADDRESS
                    : null
                }
                onChangeFunc={handleInputChange}
              />
            </div>
            <div className="w-1/2 ">
              <InputText
                inputName="postal"
                placeholder="Enter postal code"
                className="form_control"
                label="Postal code"
                labelClassName="block mb-1.5"
                value={formData.postal}
                errorText={
                  formData.fullAddress && !validForm.postal
                    ? VALIDATION_MESSAGE.INVALID_ADDRESS
                    : null
                }
                onChangeFunc={handleInputChange}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <InputText
                inputName="state"
                placeholder="Enter state"
                className="form_control"
                label="State"
                labelClassName="block mb-1.5"
                value={formData.state}
                errorText={
                  formData.fullAddress && !validForm.state
                    ? VALIDATION_MESSAGE.INVALID_ADDRESS
                    : null
                }
                onChangeFunc={handleInputChange}
              />
            </div>
            <div className="w-1/2">
              <InputText
                inputName="country"
                placeholder="Enter country"
                className="form_control"
                label="Country"
                labelClassName="block mb-1.5"
                value={formData.country}
                errorText={
                  formData.fullAddress && !validForm.country
                    ? VALIDATION_MESSAGE.INVALID_ADDRESS
                    : null
                }
                onChangeFunc={handleInputChange}
              />
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};
export default memo(UpdateLocation);
