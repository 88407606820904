import { yupResolver } from '@hookform/resolvers/yup';
import {
  File06,
  CurrencyDollar,
  Edit05,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { updateClaimStatus } from 'src/services/ClaimService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const claimResolveSchema = yup.object().shape({
  claimResolveDescription: yup
    .string()
    .trim()
    .required('Description is required.')
    .min(50, 'Claim resolution description must be at least 50 characters.')
    .max(
      250,
      'Claim resolution description should be no longer than 250 characters.'
    ),
  claimID: yup.string().required('ClaimID is required.'),
  claimFinalAmount: yup.string().required('ClaimID is required.'),
});

interface IProps {
  handleClose: () => void;
  claim: any;
  getClaimList?: () => void;
  isClaimListPage: boolean;
  handleModifyClaimAmount?: () => void;
  handleOnSubmit?: any;
}

const ClaimResolve = ({
  handleClose,
  claim,
  getClaimList,
  isClaimListPage,
  handleModifyClaimAmount,
  handleOnSubmit,
}: IProps) => {
  const navigate = useNavigate();

  const [isClaimStatusUpdateLoading, setIsClaimStatusUpdateLoading] =
    useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(claimResolveSchema),
    defaultValues: {
      claimResolveDescription: '',
    },
  });

  const claimResolveDescription = watch('claimResolveDescription');

  useEffect(() => {
    if (claim && claim?.claimID) {
      setValue('claimID', `#${claim?.claimID}`);

      if (claim && claim?.claimAmount && !claim?.finalClaimAmount) {
        setValue('claimFinalAmount', claim?.claimAmount);
      }

      if (claim && claim?.finalClaimAmount) {
        setValue('claimFinalAmount', claim?.finalClaimAmount);
      }
    }
  }, []);

  useEffect(() => {
    if (claim && claim?.claimID) {
      if (claim && claim?.claimAmount && !claim?.finalClaimAmount) {
        setValue('claimFinalAmount', claim?.claimAmount);
      }

      if (claim && claim?.finalClaimAmount) {
        setValue('claimFinalAmount', claim?.finalClaimAmount);
      }
    }
  }, [claim]);

  const onSubmit = (formData: any) => {
    setIsClaimStatusUpdateLoading(true);

    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (key !== 'claimID') {
        appendFormData.append(key, value);
      }
    });

    if (claim && claim?.claimID) {
      appendFormData.append('claimID', claim?.claimID);
    }
    appendFormData.append('status', 'Resolved');

    updateClaimStatus(appendFormData)
      .then((response) => {
        if (isClaimListPage && getClaimList) {
          getClaimList();
        } else {
          if (response?.data && handleOnSubmit) {
            handleOnSubmit(response?.data);
          } else {
            navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
          }
        }
      })
      .catch(() =>
        WalToast.error('Something went wrong while resolving claim.')
      )
      .finally(() => {
        setIsClaimStatusUpdateLoading(false);
        handleClose();
      });
  };

  const headerIcon = <File06 className="w-7 h-7" />;

  const memorizedBadge = useMemo(
    () =>
      claimResolveDescription &&
      claimResolveDescription?.trim()?.length > 0 && (
        <div className="text-grayLight900 text-xs">
          {claimResolveDescription.trim().length}
          &nbsp;/&nbsp;250
        </div>
      ),
    [claimResolveDescription]
  );

  return (
    <CommonModal
      title={`Resolve Claim`}
      titleDesc={`Add description to resolve claim`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isClaimStatusUpdateLoading}
      primaryBtnDisabled={isClaimStatusUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isClaimStatusUpdateLoading}
    >
      <div className="p-5">
        <Controller
          name="claimID"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Claim ID"
              inputName="claimID"
              placeholder="Claim ID"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.claimID?.message ?? ''}
              labelClassName="block mb-1.5"
            />
          )}
        />

        <Controller
          name="claimFinalAmount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Resolution Amount"
              inputName="claimFinalAmount"
              placeholder="Resolution Amount"
              parentClassName="mb-4"
              className="pl-8"
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.claimFinalAmount?.message ?? ''}
              labelClassName="block mb-1.5"
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
              labelIconRight={
                handleModifyClaimAmount ? (
                  <Edit05
                    className="w-4 h-4 relative text-primary cursor-pointer"
                    onClick={() => {
                      if (handleModifyClaimAmount) handleModifyClaimAmount();
                    }}
                  />
                ) : (
                  false
                )
              }
            />
          )}
        />

        <Controller
          name="claimResolveDescription"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="claimResolveDescription"
              label={
                <>
                  Resolution description <span>(min. 50 characters)</span>
                </>
              }
              placeholder="Enter Resolve Claim Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.claimResolveDescription?.message ?? ''}
              asTextarea="textarea"
              labelIconRight={memorizedBadge}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ClaimResolve;
