import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  // useMemo,
  useState,
} from 'react';
import CarriersListLoading from 'src/app/Carriers/CarriersListLoading';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
// import TableCmp from 'src/components/TableCmp';
import { RECORDS_PER_PAGE } from 'src/constants/common';
import { getTeamPerformance } from 'src/services/QuoteService';

import Row from './Row';

// import Row from './Row';

export const filterArr: any = [
  {
    value: 'common',
    name: 'Common LTL',
  },
  // {
  //   value: 'private',
  //   name: 'Private LTL',
  // },
  // asked by client to hide private ltl temporary
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'common',
  provider: '',
};

interface IProps {
  startDate: any;
  endDate: any;
  userId?: any;
  teamId?: any;
}

const QuotingTeamTable = ({ startDate, endDate, userId, teamId }: IProps) => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [carriers, setCarriers] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setParams((old: any) => ({
      ...old,
      ...{ startDate: startDate, endDate: endDate },
    }));
  }, [startDate, endDate]);

  const headCells = useMemo(
    () => [
      {
        id: 'firstName',
        name: 'User',
        sortable: true,
        visible: true,
        rowClassName: 'min-w-60 w-60 max-w-60 cursor-pointer',
      },
      {
        id: 'totalHits',
        name: 'Total Hits',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'totalRequest',
        name: 'Total Quote Requests',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'totalQuote',
        name: 'Total Quotes',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'totalOrder',
        name: 'Total Orders',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'avgTimeToClassify',
        name: 'Avg. Time to Classify',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },

      {
        id: 'avgTimeToQuote',
        name: 'Avg. Time to Quote',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'winLoss',
        name: 'Win/Loss',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const getTeamPerformanceData = async () => {
    try {
      setCarriers([]);
      setTotals(0);
      setIsLoading(true);
      const payload = {
        ...params,
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
        userId: userId == 'all' ? null : userId,
        teamId: teamId == 'all' ? null : teamId,
      };
      const response: any = await getTeamPerformance(payload);

      if (response?.data) {
        setCarriers(response?.data);
        setTotals(response?.total);
      }
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTeamPerformanceData();
  }, [params, startDate, endDate, userId, teamId]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      setParams((old: any) => ({ ...old, page: 1 }));
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">
                {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                Team Performance
              </h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {totals} {totals > 1 ? 'Carriers' : 'Carrier'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Manage analytics of your customer quotes.
            </p>
          </div>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              inputName="searchQuote"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput"
              isClearable={true}
            />
          </div>
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={RECORDS_PER_PAGE}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={RECORDS_PER_PAGE.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div
          data-test-id="userTest"
          className="lg:min-h-[122px] w-full border-t border-gray100 "
        >
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            {/* <TableCmp
              headCells={headCells}
              tableDataArr={carriers}
              TableLoaderRowCmp={<ClaimLoaderRow />}
              TableRowCmp={<h1>hello</h1>}
              params={params}
              setParams={setParams}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={params.limit}
              isTableRowClickable={true}
              // tableRowCmpProps={{
              //   handleActionType: 'carrier',
              // }}
            /> */}
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={carriers}
              TableLoaderRowCmp={CarriersListLoading}
              TableRowCmp={Row}
              numberOfSkeletonRows={15}
              isTableDataLoading={isLoading}
              tableHeaderClass=""
              isTableRowClickable={false}
            />
          </div>
          {carriers.length <= 0 && !isLoading && (
            <NotFoundUI
              title="No Carriers found"
              desc="There are no data for carriers."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </div>

        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </div>
      </div>
    </>
  );
};

export default QuotingTeamTable;
