import React from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

const CostBreakDownFooterRow = ({ data }: IProps) => (
  <>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>Total Margin</div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50"></td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50"></td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>
        {data?.totalMarginInCAD < 0
          ? `-$${getFormattedNumber(
              Math.abs(data?.totalMarginInCAD).toFixed(2)
            )}`
          : `$${getFormattedNumber((data?.totalMarginInCAD ?? 0).toFixed(2))}`}
      </div>
    </td>

    <td className={`px-5 py-4 font-semibold text-sm bg-gray50`}>
      {data?.totalMarginInUSD && data?.totalMarginInUSD < 0
        ? `-$${getFormattedNumber(Math.abs(data?.totalMarginInUSD).toFixed(2))}`
        : `$${getFormattedNumber((data?.totalMarginInUSD ?? 0).toFixed(2))}`}
    </td>
  </>
);

export default CostBreakDownFooterRow;
