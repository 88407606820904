import React, { useContext, useEffect, useState } from 'react';
import { deleteAppointment } from 'src/services/OrderService';

import { OrderContext } from '..';
import CommonAppointmentDetails from '../../Common/Appointment/CommonAppointmentDetails';
import DraftMailModal from '../../Common/DraftMailModal';
import {
  appointmentDetails,
  getEmailMessageForAppointMent,
} from '../../order.constant';
import { initAction } from '../../order.interface';
import AppointmentModal from '../../OrderDetails/AppointmentDetails/AppointmentModal';
import AppointmentSuccessModal from '../../OrderDetails/AppointmentDetails/AppointmentSuccessModal';

const Appointment = () => {
  const { currentLegData, setCurrentLegData, isLoading } =
    useContext<any>(OrderContext);

  const [localLegData, setLocalLegData] = useState(currentLegData || {});
  const [action, setAction] = useState<any>(initAction);
  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });

  useEffect(() => {
    setLocalLegData(currentLegData);
  }, [currentLegData]);

  const handleDeleteAppointment = (type: any) => {
    deleteAppointment({ orderLegId: currentLegData?.id, type: type })
      .then((response) => {
        if (response?.data) {
          const updatedLegData = { ...localLegData };

          if (type === 'shipper-appointment') {
            updatedLegData.shipperAppointmentDatetime = null;
          } else if (type === 'carrier-pickup-appointment') {
            updatedLegData.carrierPickupAppointmentDatetime = null;
          } else if (type === 'carrier-delivery-appointment') {
            updatedLegData.carrierDeliveryAppointmentDatetime = null;
          } else if (type === 'delivery-appointment') {
            updatedLegData.deliveryAppointmentDatetime = null;
          }

          setLocalLegData(updatedLegData);
          setCurrentLegData((old: any) => ({ ...old, ...updatedLegData }));
        }
      })
      .catch(console.error);
  };

  return (
    <div>
      <div className="text-grayLight900 text-base font-semibold mb-1.5">
        Appointments{' '}
        <span className="text-sm font-medium text-textSecondary">
          {/* (#WAL-JCH-12-B) */}
        </span>
      </div>
      <CommonAppointmentDetails
        localLegData={localLegData}
        setAction={setAction}
        handleDeleteAppointment={handleDeleteAppointment}
        setEditMode={setEditMode}
        isShowDraftEmail={true}
        isLoading={isLoading}
      />

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={currentLegData?.id}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let date = '';
            let type = action.mode;

            if (type === 'carrier-pickup-appointment') {
              date = 'carrierPickupAppointmentDatetime';
            } else if (type === 'carrier-delivery-appointment') {
              date = 'carrierDeliveryAppointmentDatetime';
            } else if (type === 'shipper-appointment') {
              date = 'shipperAppointmentDatetime';
            } else if (type === 'delivery-appointment') {
              date = 'deliveryAppointmentDatetime';
            }

            const updatedLegData = {
              ...currentLegData,
              [`${date}`]: formattedData.combinedDatetime,
            };

            if (setCurrentLegData) {
              setCurrentLegData(updatedLegData);
            }

            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
              type: action.mode,
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={`Set Appointment`}
          DraftDetails={{
            subjectLine: appointmentDetails[action.type].draftTitle,
            toEmailAddress:
              appointmentDetails[action.type].sendMailTo === 'shipper'
                ? currentLegData?.shipper?.email
                : appointmentDetails[action.type].sendMailTo === 'consignee'
                ? currentLegData?.consignee?.email
                : '',
            emailDetails: getEmailMessageForAppointMent(
              action.type,
              currentLegData
            ),
          }}
        />
      )}
    </div>
  );
};

export default Appointment;
