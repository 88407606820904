import {
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  ChevronDown,
  DownloadCloud02,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import CustomPagination from 'src/components/CustomPagination';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { USER_ROLE } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { BasicContext } from 'src/context/BasicContext';
import {
  exportMonthlyReport,
  monthlyCommissionStatement,
  monthlyCommissionStatementCount,
} from 'src/services/CommissionReportService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import MonthlyCommissionReportLoadingRow from './MonthlyCommissionReportLoadingRow';
import MonthlyCommissionReportRow from './MonthlyCommissionReportRow';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const MonthlyCommissionReport = () => {
  const { id: reportId } = useParams<{ id: any }>();
  const {
    reportMonth: contextMonth,
    reportYear: contextYear,
    setReportMonth,
    setReportYear,
  } = useContext(BasicContext);
  const location = useLocation();
  const { reportMonth: stateMonth, reportYear: stateYear } =
    location.state || {};

  const reportMonth =
    contextMonth || stateMonth || localStorage.getItem('reportMonth');
  const reportYear =
    contextYear || stateYear || localStorage.getItem('reportYear');

  useEffect(() => {
    if (reportMonth) localStorage.setItem('reportMonth', reportMonth);
    if (reportYear) localStorage.setItem('reportYear', reportYear);
  }, [reportMonth, reportYear]);

  const initParams = {
    search: '',
    sortType: 'asc',
    sortField: 'name',
    page: 1,
    limit: 50,
    reportId,
    targetMonth: moment(`${reportYear}-${reportMonth}`, 'YYYY-MMMM').format(
      'YYYY-MM'
    ),
  };

  const navigate = useNavigate();
  const [params, setParams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUserData, setFilteredUserData] = useState<any[]>([]);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);
  const [isExportEnabled, setIsExportEnabled] = useState(false);

  const [month, setMonth] = useState<any>(new Date(2024, 8, 1));
  const [dateRange, setDateRange] = useState<any>(initParams.targetMonth);
  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [periodEndDate, setPeriodEndDate] = useState(null);
  const { hasRoleV2 } = useRolePermission();
  const isCurrentMonth = moment(dateRange, 'YYYY-MM').isSame(moment(), 'month');
  const [total, setTotal] = useState(0);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'User',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalMargin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'averageMargin',
        name: 'Average Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'loadsDelivered',
        name: 'Loads Delivered',
        sortable: true,
        visible: true,
      },
      {
        id: 'averageMarginPercentage',
        name: 'Margin Percentage',
        sortable: true,
        visible: true,
      },
      {
        id: 'activeClaims',
        name: 'Active Claims',
        sortable: true,
        visible: true,
      },
      {
        id: 'activeDisputes',
        name: 'Active Disputes',
        sortable: true,
        visible: true,
      },
      {
        id: 'conflicts',
        name: 'Conflicts',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  const updateUrlParameter = (newId: any) => {
    if (newId !== undefined && newId !== null) {
      navigate(`/sales/commission-report/${newId}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setUser([]);
    setFilteredUserData([]);

    Promise.all([
      monthlyCommissionStatement({ ...params, reportId: reportId }),
      monthlyCommissionStatementCount({ ...params, reportId: reportId }),
    ])
      .then(([responseData, responseCount]: [any, any]) => {
        if (responseData?.data) {
          setUser(responseData.data);
          setFilteredUserData(responseData.data);
          setIsExportEnabled(responseData.status ?? true);
          setPeriodStartDate(responseData?.periodStartDate);
          setPeriodEndDate(responseData?.periodEndDate);
          updateUrlParameter(responseData.reportId);
        }

        setTotal(responseCount.total ?? 0);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [params]);

  const updateDateRange = (newDate: moment.Moment) => {
    const formattedDate = newDate.format('YYYY-MM');
    setDateRange(formattedDate);
    setParams((prevParams) => ({
      ...prevParams,
      month: newDate.format('MMMM'),
      year: newDate.format('YYYY'),
      targetMonth: formattedDate,
    }));
  };

  const searchDebounce = useCallback(
    debounce(
      (search: string) =>
        setParams((old: any) => ({ ...old, ...{ search, page: 1 } })),
      700
    ),
    []
  );

  const getFilterRange = (type: string) => {
    let newDate;

    if (type === 'prev') {
      newDate = moment(dateRange, 'YYYY-MM').subtract(1, 'months');
    } else if (type === 'next') {
      newDate = moment(dateRange, 'YYYY-MM').add(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          !newDate.isSame(moment(), 'month') &&
          !newDate.isSame(moment().add(1, 'months'), 'month')
        ) {
          return;
        }
      }
    }

    if (newDate) {
      updateDateRange(newDate);
      setReportMonth(newDate.format('MMMM'));
      setReportYear(newDate.format('YYYY'));
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const downloadExcel = async () => {
    try {
      const response = (await exportMonthlyReport({
        reportId: reportId,
      })) as any;

      if (!response) {
        throw new Error('No data received');
      }

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `WAL_Commission_report_${reportMonth}_${reportYear}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      // setError('Failed to download the report. Please try again.');
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="View and manage your commission reports here."
            mainContainerClassName="md:flex-nowrap"
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  Commission Statement ({reportMonth ? reportMonth : stateMonth}
                  , {reportYear ? reportYear : stateYear})
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {total} {total > 1 ? 'Users' : 'User'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Here is commission report for the month of {reportMonth}{' '}
                {reportYear}.
              </p>
            </div>
            <ButtonCmp
              className={`btn-outline-primary ${
                !isExportEnabled
                  ? 'cursor-not-allowed disabled'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                if (isExportEnabled) {
                  downloadExcel();
                }
              }}
              disabled={false}
            >
              <DownloadCloud02 className="w-4 h-4 text-primary" />
              Export
            </ButtonCmp>
            <Link
              to={ROUTES.SALES_COMMISSION_REPORT}
              className=" max-sm:w-[calc(50%_-_6px)]"
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black !p-2 flex-none"
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </Link>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchValue}
                inputType="text"
                isClearable={true}
                parentClassName="table-searchInput"
                onChangeFunc={(e) => {
                  setSearchValue(e.target.value);
                  searchDebounce(e.target.value);
                }}
              />
            </div>
            <div
              className={`xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px] text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none bg-gray50 min-w-[260px] text-center ${
                isLoading ? 'custom-loading rounded-lg' : ''
              }`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: periodStartDate
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          periodStartDate,
                          `MMM Do YYYY`,
                          true,
                          false
                        )
                      )
                    : '-',
                }}
              ></span>
              {' - '}
              <span
                dangerouslySetInnerHTML={{
                  __html: periodEndDate
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          periodEndDate,
                          `MMM Do YYYY`,
                          true,
                          false
                        )
                      )
                    : '-',
                }}
              ></span>
            </div>
            <div className="table-datepicker-v2 cursor-pointer hidden">
              <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px]  justify-between items-center gap-1.5 flex bg-white  hover:border-grayText transition-all">
                <ArrowLeft
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('prev')}
                />

                <div
                  className="flex flex-1 justify-center items-center"
                  onClick={() => {
                    setIsOpenDropDown(true);
                  }}
                >
                  <CalendarDate className="w-4 h-4 mr-2" />
                  <div className="text-textSecondary text-xs font-medium leading-none">
                    {' '}
                    {moment(dateRange, 'YYYY-MM').format('MMMM YYYY')}
                  </div>
                </div>

                <ArrowRight
                  className={`xs:w-4 xs:h-4 w-3 h-3 flex-none ${
                    isCurrentMonth
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!isCurrentMonth) {
                      getFilterRange('next');
                    }
                  }}
                />
              </div>

              {isOpenMonthPicker && (
                <div
                  className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white datepicker-v2"
                  ref={datePickerRef}
                >
                  <DateSelect
                    inputName="pickupDate"
                    selected={month}
                    showMonthYearPicker
                    dateFormat="MMMM-yyyy"
                    onChangeFunc={(e: any) => {
                      const selectedDate = moment(e.value, 'MMM YYYY');
                      setMonth(selectedDate.toDate());
                      setDateRange(
                        selectedDate.add(1, 'month').format('YYYY-MM')
                      );
                      setReportMonth(selectedDate.format('MMMM'));
                      setReportYear(selectedDate.format('YYYY'));
                      setParams((prevParams) => ({
                        ...prevParams,
                        month: selectedDate.format('MMMM'),
                        year: selectedDate.format('YYYY'),
                        targetMonth: selectedDate.format('YYYY-MM'),
                      }));
                      setIsOpenDropDown(false);
                    }}
                    inline={isOpenMonthPicker}
                    icon={<ChevronDown className="w-5 h-5" />}
                    maxDate={new Date('2024-09-30')}
                  />
                </div>
              )}
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
              <TableCmp
                headCells={headCells}
                tableDataArr={filteredUserData}
                TableLoaderRowCmp={MonthlyCommissionReportLoadingRow}
                TableRowCmp={MonthlyCommissionReportRow}
                params={params}
                setParams={setParams}
                // tableRowCmpProps={{
                //   handleActionType: handleActionType,
                // }}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={params.limit}
                isTableRowClickable={true}
                onRowClick={(rowData: any) => {
                  // if (rowData?.salesRepId) {
                  navigate(
                    `${PATH.COMMISSION_REPORT}/${reportId}/sales-rep/${rowData.salesRepId}`,
                    {
                      state: { reportMonth, reportYear },
                    }
                  );
                  // }
                }}
              />
            </div>
            {!user.length && !isLoading && (
              <NotFoundUI
                title="No User Found"
                desc="There are no data for user."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={total}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default MonthlyCommissionReport;
