import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const OrderDetailsRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 w-[10%] min-w-[10%] max-w-[10%]">
      <span>{data?.handlingUnitNo}</span>
    </td>
    <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-px">
      <span>{data?.handlingUnit}</span>
    </td>

    <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
      <span>#{data?.legId}</span>
    </td>
    <td className="px-5 py-4 w-[18%] min-w-[18%] max-w-px">
      <TooltipCmp
        message={
          data?.shipper?.address1?.length > 20 ? data?.shipper?.address1 : ''
        }
      >
        <p className="truncate">{data?.shipper?.address1}</p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 w-[18%] min-w-[18%] max-w-px">
      <TooltipCmp
        message={
          data?.consignee?.address1?.length > 20
            ? data?.consignee?.address1
            : ''
        }
      >
        <p className="truncate">{data?.consignee?.address1}</p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
      <span className="lowercase">
        {data?.totalWeight} ({data?.weightMeasurement})
      </span>
    </td>
    <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
      <span>{data?.refNumber ?? '-'}</span>
    </td>
  </>
);

export default OrderDetailsRow;
