import {
  LayersThree01,
  ChevronDown,
  HomeLine,
  SearchLg,
  Settings01,
  Users01,
  PieChart03,
  Menu02,
  XClose,
  UserSquare,
  ChevronUp,
  LayersTwo01,
  File06,
  CreditCard01,
  HelpCircle,
  ChevronSelectorVertical,
  PackagePlus,
  Truck01,
  Package,
  CheckCircleBroken,
  Target05,
} from '@untitled-ui/icons-react/build/cjs';
import React, {
  Fragment,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import Radio from 'src/components/Radio/Radio';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { setUserStatus, switchUserRole } from 'src/services/UserService';

import LogoDark from '../../assets/img/logo-dark.png';
import LogoSmall from '../../assets/img/logo-small.png';
import InputText from '../../components/InputText/InputText';
import { PERMISSION, USER_ROLE } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import { logout } from '../../services/AuthService';
import {
  getShortName,
  onError,
  useRolePermission,
} from '../../utils/CommonFunctions';

const Sidebar = () => {
  const useIdNum = useId();
  const { pathname } = useLocation();
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const { appearance } = useContext(BasicContext);
  const { reloadCounts, entityCounts } = useContext(EntityCountsContext);
  const [hiddenModule, setHiddenModule] = useState<any>([]);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [counts, setCounts] = useState<any>(entityCounts);

  const [currentUserRole, setCurrentUserRole] = useState([]);
  const [isOpenSettingMenu, setIsOpenSettingMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const checkMode =
    window.MODE === 'production' || window.MODE === 'staging' ? false : true;

  const { control, watch, setValue } = useForm({
    defaultValues: {
      role: currentUser.role.name,
    },
  });

  const selectedRole = watch('role');
  const divRef = useRef<any>(null);

  useEffect(() => {
    if (currentUser?.roles && currentUser?.roles.length > 0) {
      setCurrentUserRole(currentUser?.roles);
    } else if (
      (!currentUser?.roles || currentUser?.roles.length === 0) &&
      currentUser?.role
    ) {
      const roles: any = [];
      roles.push(currentUser?.role);
      setCurrentUserRole(roles);
    }
  }, [currentUser]);

  useEffect(() => {
    setCounts(() => entityCounts);
  }, [entityCounts]);

  const mainOptions = useMemo(
    () => [
      {
        path: ROUTES.HOME,
        displayName: 'Home',
        Icon: HomeLine,
        permission:
          (hasRoleV2('admin') ||
            hasRoleV2('manager') ||
            hasRoleV2('sales') ||
            hasRoleV2('operations')) &&
          checkMode,
      },
      {
        hasSubMenu: true,
        displayName: 'Quoting Hub',
        Icon: PackagePlus,
        permission:
          // hasPermissionV2(PERMISSION.QUOTING_REQUEST) ||
          // hasPermissionV2(PERMISSION.VIEW_SMART_QUOTE) ||
          // hasPermissionV2(PERMISSION.LANE_HISTORY) ||
          // hasPermissionV2(PERMISSION.RATE_SHOPPING),
          hasPermissionV2([
            PERMISSION.QUOTING_REQUEST,
            PERMISSION.VIEW_SMART_QUOTE,
            PERMISSION.LANE_HISTORY,
            PERMISSION.RATE_SHOPPING,
          ]),
        subMenu: [
          {
            subPath: ROUTES.QUOTING_REQUEST,
            subDisplayName: 'Requests',
            subPermission: hasPermissionV2(PERMISSION.QUOTING_REQUEST),
          },
          {
            subPath: ROUTES.QUOTING_DASHBOARD,
            subPathAlt: 'quotes/dashboard',
            subDisplayName: 'Dashboard',
            subPermission: hasPermissionV2(PERMISSION.VIEW_SMART_QUOTE),
            childPathNames: [
              ROUTES.MY_QUOTE,
              ROUTES.ADDRESS_DETAILS,
              ROUTES.DIMENSIONS,
            ],
          },
          {
            subPath: ROUTES.RATE_SHOPPING,
            subDisplayName: 'Rate Shopping',
            subPermission: hasPermissionV2(PERMISSION.RATE_SHOPPING),
          },
          // {
          //   subPath: ROUTES.QUOTING_ANALYTICS,
          //   subPathAlt: 'quotes/analytics',
          //   subDisplayName: 'Analytics',
          //   subPermission: hasPermissionV2(PERMISSION.QUOTING_REQUEST),
          // },
        ],
      },
      {
        hasSubMenu: true,
        displayName: 'Operations',
        Icon: LayersThree01,
        permission: hasPermissionV2(PERMISSION.TRACK_AND_TRACE),
        subMenu: [
          {
            subPath: ROUTES.TRACK_AND_TRACE,
            subDisplayName: 'Track and Trace',
            subPermission: hasPermissionV2(PERMISSION.TRACK_AND_TRACE),
          },
        ],
      },
      {
        hasSubMenu: true,
        displayName: 'Sales Dashboard',
        Icon: PieChart03,
        permission:
          hasRoleV2('admin') || hasRoleV2('manager') || hasRoleV2('sales')
            ? hasPermissionV2(PERMISSION.OVERVIEW) ||
              // hasPermissionV2(PERMISSION.ADVANCE_VIEW) ||
              hasPermissionV2(PERMISSION.TARGET_VIEW)
            : false,
        subMenu: [
          {
            subPath: ROUTES.SALES_OVERVIEW,
            subDisplayName: 'Overview',
            subPermission:
              hasRoleV2('admin') || hasRoleV2('manager') || hasRoleV2('sales')
                ? hasPermissionV2(PERMISSION.OVERVIEW)
                : false,
          },
          {
            subPath: ROUTES.SALES_HISTORY,
            subDisplayName: 'Backup',
            subPermission:
              (hasRoleV2('admin') || hasRoleV2('manager')
                ? hasPermissionV2(PERMISSION.ADVANCE_VIEW)
                : false) && checkMode,
          },
          {
            subPath: ROUTES.SALES_TARGET,
            subDisplayName: 'Targets',
            subPermission:
              hasRoleV2('admin') || hasRoleV2('manager') || hasRoleV2('sales')
                ? hasPermissionV2(PERMISSION.TARGET_VIEW)
                : false,
            childPathNames: [ROUTES.SALES_TARGET_EDIT],
          },
          {
            subPath: ROUTES.SALES_COMMISSION_REPORT,
            subDisplayName: 'Commission Reports',
            // subPermission:
            //   hasRoleV2('manager') ||
            //   (hasRoleV2('sales') && window.MODE === 'production')
            //     ? false
            //     : true,
            subPermission:
              hasRoleV2('admin') && window.MODE != 'production' ? true : false,
            childPathNames: [
              ROUTES.SALES_MONTHLY_COMMISSION_REPORT,
              ROUTES.SALES_REP_COMMISSION_REPORT,
            ],
          },
        ],
      },
      {
        hasSubMenu: true,
        displayName: 'Team',
        Icon: Users01,
        permission:
          hasPermissionV2(PERMISSION.USER_LIST) ||
          hasPermissionV2(PERMISSION.TEAM_LIST) ||
          hasPermissionV2(PERMISSION.ORGANIZATIONAL_CHART),
        subMenu: [
          {
            subPath: ROUTES.TEAMS,
            subDisplayName: 'Team Members',
            subPermission: hasPermissionV2(PERMISSION.USER_LIST),
            isShowCount: true,
            countKey: 'teamMemberCount',
          },
          {
            subPath: ROUTES.TEAM_MANAGEMENT,
            subDisplayName: 'Team Management',
            subPermission: hasPermissionV2(PERMISSION.TEAM_LIST),
            childPathNames: [ROUTES.TEAM_DETAIL, ROUTES.TEAM_MANAGEMENT_DETAIL],
            isShowCount: true,
            countKey: 'teamCount',
          },
          // {
          //     subPath: ROUTES.ORGANIZATIONAL_CHART,
          //     subDisplayName: "Organizational Chart",
          //     subPermission: hasPermissionV2(PERMISSION.ORGANIZATIONAL_CHART),
          // },
        ],
      },
      {
        path: ROUTES.CARRIERS,
        displayName: 'Carriers',
        Icon: Truck01,
        permission: hasPermissionV2(PERMISSION.CARRIER_VIEW),
        isShowCount: true,
        countKey: 'carrierCount',
      },
      {
        path: ROUTES.CUSTOMERS,
        displayName: 'Customers',
        Icon: UserSquare,
        permission: hasPermissionV2(PERMISSION.CUSTOMER_LIST),
        isShowCount: true,
        countKey: 'customerCount',
      },

      {
        path: ROUTES.ORDERS,
        displayName: 'Orders',
        Icon: Package,
        permission: hasPermissionV2(PERMISSION.ORDERS),
        isShowCount: true,
        countKey: 'orderCount',
      },
      {
        path: ROUTES.RADAR,
        displayName: 'Radar',
        Icon: Target05,
        permission: true,
        isShowCount: true,
        countKey: 'radarCount',
      },
      {
        path: ROUTES.CLAIMS_AND_DISPUTES_DETAILS,
        displayName: 'Claims & Disputes',
        Icon: File06,
        permission: true,
        isShowCount: true,
        countKey: 'claimsAndDisputesCount',
      },
      {
        path: ROUTES.CREDIT_DASHBOARD,
        displayName: 'Credit Dashboard',
        Icon: CreditCard01,
        isShowCount: true,
        countKey: 'creditDashboardCount',
        permission:
          hasRoleV2('admin') ||
          hasRoleV2('manager') ||
          hasRoleV2('operation') ||
          hasRoleV2('claims') ||
          hasRoleV2('sales'),
      },
      {
        path: ROUTES.LOAD_INSURANCE,
        displayName: 'Load Insurance',
        Icon: CheckCircleBroken,
        isShowCount: true,
        countKey: 'loadInsuranceCount',
        permission:
          hasRoleV2('admin') ||
          hasRoleV2('manager') ||
          hasRoleV2('operation') ||
          hasRoleV2('claims') ||
          hasRoleV2('sales'),
      },
    ],
    []
  );

  const bottomOptions = useMemo(
    () => [
      // {
      //   path: ROUTES.FEEDBACK_LIST,
      //   displayName: 'Support',
      //   Icon: LifeBuoy01,
      //   permission: window.MODE === 'production' ? false : true,
      // },
      // {
      //   path: ROUTES.SETTING,
      //   displayName: 'Settings',
      //   Icon: Settings01,
      //   permission: true,
      // },
      {
        path: ROUTES.INTEGRATION,
        displayName: 'Integrations',
        Icon: LayersTwo01,
        permission: hasRoleV2('admin') && window.MODE !== 'production',
      },
    ],
    []
  );

  const dropdownOptions = useMemo(
    () => [
      {
        path: ROUTES.FEEDBACK_LIST,
        displayName: 'Support',
        Icon: HelpCircle,
        permission: window.MODE === 'production' ? false : true,
        isShowCount: true,
        countKey: 'feedbackCount',
      },
      {
        path: ROUTES.SETTING,
        displayName: 'Account Settings',
        Icon: Settings01,
        permission: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (window.MODE === 'staging') {
      setHiddenModule(['Lane History', 'Rate Shopping', 'Operations']);
    } else if (window.MODE === 'production') {
      let listModule = [
        // 'Credit Dashboard',
        'Requests',
        'Lane History',
        'Rate Shopping',
        'Operations',
        'Targets',
        'Integrations',
        'Geography',
        'Radar',
        // 'Load Insurance',
      ];

      if (
        hasRoleV2(USER_ROLE.ADMIN) ||
        hasRoleV2(USER_ROLE.MANAGER) ||
        hasRoleV2(USER_ROLE.SALES)
      ) {
        listModule = [
          // 'Credit Dashboard',
          'Requests',
          'Lane History',
          'Rate Shopping',
          'Operations',
          'Integrations',
          'Geography',
          'Radar',
          // 'Load Insurance',
        ];
      }
      setHiddenModule(listModule);
    } else {
      setHiddenModule([]);
    }

    // setInterval(getRate, 30 * 60 * 1000);
  }, []);

  const [containerHeights, setContainerHeights] = useState<any>({});

  const toggleHeight = (index: string, isKeepOpen: boolean = false) => {
    const updatedContainerHeights = {} as any; // keep single submenu open
    updatedContainerHeights[index] =
      !!containerHeights[index] && !isKeepOpen
        ? 0
        : document.getElementById(`container${index}`)?.scrollHeight;
    setContainerHeights(updatedContainerHeights);
  };

  const handleLogout = () => {
    setUserStatus({
      status: 'offline',
      userId: currentUser?.id,
    })
      .then(() => {})
      .catch(console.log);

    logout()
      .then((response) => (window.location.href = response.data.url))
      .catch(console.log);
  };

  const matchPattern = (pattern: any, subPath: any) => {
    const patternSegments = pattern.split('/');
    const pathnameSegments = subPath.split('/');

    const patternLength = patternSegments.length;
    const pathnameLength = pathnameSegments.length;

    if (pathnameLength < patternLength || pathnameLength > patternLength)
      return false;

    for (let i = 0; i < patternLength; i++) {
      const patternSegment = patternSegments[i];
      const pathnameSegment = pathnameSegments[i];

      if (patternSegment.startsWith(':')) continue;

      if (patternSegment !== pathnameSegment) return false;
    }

    return true;
  };

  const handleMenuAndSubMenuActivation = () => {
    mainOptions.forEach((mainOption: any) => {
      const pathNameMain = pathname.split('/').filter((ptn) => ptn);
      const isSubActive = mainOption.subMenu
        ? mainOption.subMenu.find(
            (item: any) =>
              (pathname === '/' &&
                item.subPath === '/quotes/dashboard' &&
                !checkMode) ||
              item.subPath === pathname ||
              (item.subPath === '/' && item.subPathAlt === pathNameMain?.[0]) ||
              (item.subPath === '/' && pathNameMain?.[0] === 'login') ||
              item.childPathNames?.includes(pathname) ||
              item.childPathNames?.some((child: any) =>
                matchPattern(child, pathname)
              )
          )
        : undefined;

      if (isSubActive) {
        toggleHeight(mainOption.displayName, true);
      }
    });
  };

  useEffect(() => {
    setIsSideMenuOpen(false);
    handleMenuAndSubMenuActivation();
  }, [pathname]);

  useEffect(() => {
    if (sessionStorage.getItem('roleSwitched')) {
      reloadCounts();
      // WalToast.success('Your account has been successfully switched!');
      sessionStorage.removeItem('roleSwitched');
    }
  }, []);

  const handleClickOutside = (event: any) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsOpenSettingMenu(false);
    }
  };

  useEffect(() => {
    if (isOpenSettingMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenSettingMenu]);

  const handleSwitchRole = (role: string) => {
    setTimeout(() => {
      setIsLoading(true);
    }, 400);

    switchUserRole({ role })
      .then((response) => {
        if (response) {
          sessionStorage.setItem('roleSwitched', 'true');
          location.reload();
        }
      })
      .catch((error) => console.error('Error switching role:', error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <aside
      className="absolute top-0 left-0 flex flex-col md:w-[var(--layout-sidebar-width)] w-full bg-utilityGray300 md:h-full md:pt-[var(--layout-top-padding)]"
      aria-label="SideNav"
    >
      <div className="md:pb-1 md:pt-6 md:px-[11px] px-4 py-3 flex items-center gap-3 justify-between  md:z-auto">
        <div className="flex items-end md:justify-between w-full">
          <Link to={ROUTES.HOME}>
            {/* <img
              src={LogoDark}
              alt="logo-light"
              className="lg:w-[180px] w-[160px] h-[36px] object-cover md:pl-2 md:pt-[2px] md:inline-block hidden"
            /> */}

            {appearance?.logo ? (
              <>
                <img
                  src={appearance?.logoUrl + appearance?.logo}
                  alt="logo-light"
                  className="lg:w-[180px] w-[160px] h-[36px] object-contain md:pl-2 md:pt-[2px] md:inline-block hidden"
                />
                <img
                  src={appearance?.logoUrl + appearance?.logo}
                  alt="logo-light"
                  className="w-11 md:pl-2 md:pt-[2px] md:hidden z-[7] relative"
                />
              </>
            ) : (
              <>
                <img
                  src={LogoDark}
                  alt="logo-light"
                  className="lg:w-[180px] w-[160px] h-[36px] object-contain md:pl-2 md:pt-[2px] md:inline-block hidden"
                />
                <img
                  src={LogoSmall}
                  alt="logo-light"
                  className="w-11 md:pl-2 md:pt-[2px] md:hidden z-[7] relative"
                />
              </>
            )}
          </Link>
          <span className="text-[8px] items-end font-medium text-[#667085]">
            v2.0
          </span>
        </div>
        <ButtonCmp
          className="md:hidden rounded-lg border border-grayLight600 p-2 flex justify-center items-center"
          onClick={() => setIsSideMenuOpen((prev) => !prev)}
        >
          <Menu02 className="text-grayLight600" />
        </ButtonCmp>
      </div>
      <div
        key={`sidebar_${useIdNum}`}
        className={`flex-grow overflow-hidden flex flex-col md:relative fixed top-0  md:h-auto h-full z-[14] md:z-auto md:w-full md:[&>div]:w-full [&>div]:w-[280px] [&>div]:bg-utilityGray300  md:[&:not(.menu-open)]:right-[unset] [&:not(.menu-open)]:right-[-280px] [&.menu-open]:right-0 transition-all duration-[0.5s] [&.menu-open]:after:content-[''] [&.menu-open]:after:bg-black [&.menu-open]:after:opacity-50 [&.menu-open]:after:fixed [&.menu-open]:after:left-0 [&.menu-open]:after:h-full [&.menu-open]:after:w-full [&.menu-open]:after:-z-[1] [&.menu-open]:after:top-[var(--layout-top-padding)] after:opacity-0 after:transition-all after:duration-[0.5s] md:after:hidden ${
          isSideMenuOpen ? 'menu-open' : ''
        }`}
      >
        <div className="pb-1 pt-[9px] px-[11px] md:hidden flex justify-end">
          <ButtonCmp
            className="md:hidden rounded-lg border border-grayLight600 p-2 flex justify-center items-center"
            onClick={() => setIsSideMenuOpen((prev) => !prev)}
          >
            <XClose className="text-grayLight600" />
          </ButtonCmp>
        </div>
        <div className="py-6 px-3">
          <InputText
            inputName="search"
            placeholder="Search"
            className="pl-[26px] placeholder:text-secondaryTxtColor border-gray400"
            icon={
              <SearchLg className="absolute -translate-y-2/4 top-2/4 left-[7px] text-grayText h-4 w-4" />
            }
          />
        </div>
        <div className="flex-grow overflow-y-auto px-3 scrollbar-hide">
          <ul className="space-y-2">
            {mainOptions.map((option: any, index: number) => {
              const {
                hasSubMenu,
                path,
                Icon,
                displayName,
                permission,
                subMenu,
                isShowCount,
                countKey,
              } = option;
              const pathNameMain = pathname.split('/').filter((ptn) => ptn);
              const isSubActive = subMenu
                ? subMenu.find(
                    (item: any) =>
                      (pathname === '/' &&
                        item.subPath === '/quotes/dashboard' &&
                        !checkMode) ||
                      item.subPath === pathname ||
                      item.subPath === pathNameMain ||
                      (item.subPath === '/' &&
                        item.subPathAlt === pathNameMain?.[0]) ||
                      item.childPathNames?.includes(pathname) ||
                      item.childPathNames?.some((child: any) =>
                        matchPattern(child, pathname)
                      )
                  )
                : undefined;

              if (!permission || hiddenModule.includes(displayName)) {
                return (
                  <Fragment key={`nav_${displayName}_${index}`}></Fragment>
                );
              }

              return (
                <li key={`nav_${displayName}_${index}`} className="relative">
                  {hasSubMenu ? (
                    <div
                      onClick={() => toggleHeight(displayName)}
                      className={`wal__sidebar_link ${
                        isSubActive && 'active-nav bg-utilityGray400'
                      }`}
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="flex items-center">
                          <Icon
                            className={`link_icon ${
                              isSubActive && 'active_icon'
                            }`}
                          />
                          <span className="ml-2.5">{displayName}</span>
                        </div>
                        {containerHeights[displayName] ? (
                          <ChevronUp className="h-3.5 w-3.5" />
                        ) : (
                          <ChevronDown className="h-3.5 w-3.5" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={path}
                      onClick={() => toggleHeight(displayName)}
                      className={({ isActive }) =>
                        `wal__sidebar_link ${
                          (isActive || isSubActive?.subPath === pathname) &&
                          'active-nav'
                        }`
                      }
                      // eslint-disable-next-line react/no-children-prop
                      children={({ isActive }) => (
                        <div className="w-full flex items-center">
                          <Icon
                            className={`link_icon ${
                              (isActive || isSubActive?.subPath === pathname) &&
                              'active_icon'
                            }`}
                          />
                          <span className="ml-3 flex-1">{displayName}</span>
                          {isShowCount &&
                          counts[countKey] &&
                          counts[countKey] > 0 ? (
                            <BadgeCmp
                              style="pill"
                              type="primary"
                              mainClassName="!border-borderPrimary ml-3"
                            >
                              {counts[countKey]}
                            </BadgeCmp>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    />
                  )}

                  {subMenu && hasSubMenu && (
                    <div
                      className={`relative overflow-hidden transition-all duration-100 pl-1 ml-5`}
                      style={{ maxHeight: containerHeights[displayName] || 0 }}
                      id={`container${displayName}`}
                    >
                      {subMenu
                        .filter(
                          (item: any) =>
                            item.subPermission &&
                            !hiddenModule.includes(item.subDisplayName)
                        )
                        .map((item: any, idx: number) => {
                          const {
                            subPath,
                            subDisplayName,
                            isShowCount: isShowCountSubMenu,
                            countKey: countKeySubMenu,
                          } = item;
                          const subMenuLength = subMenu.filter(
                            (itemVal: any) =>
                              itemVal.subPermission &&
                              !hiddenModule.includes(itemVal.subDisplayName)
                          )?.length;

                          return (
                            <div
                              key={`sub_nav_${subPath}`}
                              className={`first:pt-2 flex items-center sticky`}
                            >
                              <div
                                className={`h-2 w-2 flex-none ${
                                  isSubActive?.subPath === subPath
                                    ? 'bg-grayLight900'
                                    : 'bg-gray500'
                                }  rounded wal__sidebar_sub_line_container ${
                                  idx === 0 ? 'is_first_child' : ''
                                } ${
                                  idx + 1 === subMenuLength
                                    ? 'is_last_child'
                                    : ''
                                }`}
                              ></div>
                              <NavLink
                                to={subPath}
                                className={`wal__sidebar_sub_link ${
                                  isSubActive?.subPath === subPath &&
                                  'text-grayLight900'
                                }`}
                              >
                                <span className="ml-[5px]">
                                  {subDisplayName}
                                </span>
                                {isShowCountSubMenu &&
                                counts[countKeySubMenu] &&
                                counts[countKeySubMenu] > 0 ? (
                                  <BadgeCmp
                                    style="pill"
                                    type="primary"
                                    mainClassName="!border-borderPrimary ml-3"
                                  >
                                    {counts[countKeySubMenu]}
                                  </BadgeCmp>
                                ) : (
                                  ''
                                )}
                              </NavLink>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="px-3.5 pb-4">
          <div className="content-end">
            <ul className="flex flex-col gap-1 pb-4 pt-2">
              {bottomOptions.map((option: any) => {
                const { path, Icon, displayName, permission } = option;

                return (
                  <li
                    key={`bottom_nav_${path}`}
                    className={`${permission ? 'visible' : 'hidden'}`}
                  >
                    <NavLink
                      to={path}
                      className={({ isActive }) =>
                        `wal__sidebar_link ${isActive && 'active-nav'}`
                      }
                      // eslint-disable-next-line react/no-children-prop
                      children={({ isActive }) => (
                        <>
                          <Icon
                            className={`link_icon ${isActive && 'active_icon'}`}
                          />
                          <span className="ml-2.5">{displayName}</span>
                        </>
                      )}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="border-t border-utilityGray200">
              <div
                className="flex mt-3 px-2 py-3 items-center w-full gap-2  cursor-pointer bg-white hover:bg-utilityGray100 border border-utilityGray200 rounded-xl"
                onClick={() => {
                  setIsOpenSettingMenu(!isOpenSettingMenu);
                }}
                ref={divRef}
              >
                {currentUser.image ? (
                  <img
                    src={`${currentUser.imageUrl}${currentUser.image}`}
                    className="w-10 h-10 object-cover rounded-full cursor-pointer border-[0.75px] border-black/[0.08] flex-none "
                    alt={
                      isAuthenticated &&
                      `${currentUser.firstName} ${currentUser.lastName}`
                    }
                    title={
                      isAuthenticated &&
                      `${currentUser.firstName} ${currentUser.lastName}`
                    }
                    onError={onError}
                  />
                ) : (
                  <div className="w-9 h-9 text-sm rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                    {getShortName(
                      `${currentUser.firstName} ${currentUser.lastName}`
                    )}
                  </div>
                )}

                <div className=" flex-grow overflow-hidden">
                  <h4 className="text-textSecondary text-xs font-semibold mb-1">
                    {isAuthenticated &&
                      `${currentUser.firstName} ${currentUser.lastName}`}
                    {currentUser?.role?.displayName ? (
                      <span className="text-grayLight600 text-xs font-normal truncate">
                        {'   '}({currentUser.role.displayName})
                      </span>
                    ) : (
                      ''
                    )}
                  </h4>

                  <div className="text-grayLight600 text-xs font-normal truncate">
                    {currentUser.email}
                  </div>
                </div>
                <div className="flex items-center cursor-pointer flex-none self-start">
                  {/* <LogOut02 className="text-gray400 w-5 h-5" /> */}
                  <ChevronSelectorVertical className="text-gray400 w-5 h-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`rounded-lg border border-utilityGray200 bg-white shadow-lg -right-[259px] w-[270px] bottom-4 z-[22] absolute overflow-hidden px-1.5 ${
          isOpenSettingMenu ? '' : 'hidden'
        }`}
        ref={divRef}
      >
        {currentUserRole && currentUserRole.length > 0 && (
          <div className="py-3 px-1.5 border-b border-utilityGray200 ">
            {currentUserRole.map((item: any, index: number) => (
              <div
                className={`rounded-lg border border-utilityGray200 px-3 py-2 flex items-center gap-x-3 last:mb-0 mb-1 cursor-pointer  hover:bg-gray50 current_user_role_${item.id}_${index}`}
                key={`current_user_role_${item.id}_${index}`}
                onClick={() => {
                  if (!isLoading && currentUserRole.length > 1) {
                    setValue('role', item.name);
                    handleSwitchRole(item.name);
                  }
                }}
              >
                {currentUser.image ? (
                  <img
                    src={`${currentUser.imageUrl}${currentUser.image}`}
                    alt={
                      isAuthenticated &&
                      `${currentUser.firstName} ${currentUser.lastName}`
                    }
                    title={
                      isAuthenticated &&
                      `${currentUser.firstName} ${currentUser.lastName}`
                    }
                    className="w-8 h-8 object-contain rounded-full border border-utilityGray200"
                  />
                ) : (
                  <div className="w-10 h-10 text-sm rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                    {getShortName(
                      `${currentUser.firstName} ${currentUser.lastName}`
                    )}
                  </div>
                )}

                <div className="flex-1">
                  <h4 className="text-textSecondary text-xs  font-semibold">
                    {currentUser.firstName} {currentUser.lastName}
                  </h4>
                  <p className="text-grayLight600 text-xs font-normal">
                    {item.displayName}
                  </p>
                </div>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Radio
                      id={`role`}
                      onChangeFunc={(e) => field.onChange(e.target.value)}
                      inputName={'role'}
                      value={item.name}
                      checked={selectedRole === item.name}
                      disabled={isLoading || currentUserRole.length === 1}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        )}
        <ul className="py-1.5">
          {dropdownOptions &&
            dropdownOptions.map((option: any) => {
              const { path, displayName, permission, isShowCount, countKey } =
                option;

              return (
                <>
                  <NavLink
                    key={`dropdown_nav_${path}`}
                    to={path}
                    className={`flex items-center gap-x-2 mx-1.5 px-3 py-1.5 cursor-pointer hover:bg-gray50 [&.active]:bg-gray50 rounded-md first:mt-0 mt-1   ${
                      permission ? 'visible' : 'hidden'
                    }`}
                    onClick={() => {
                      setIsOpenSettingMenu(false);
                    }}
                  >
                    {/* <Icon className="w-4 text-gray500" /> */}
                    <p className="text-utilityGray800 text-sm font-medium">
                      {displayName}
                    </p>
                    {isShowCount && counts[countKey] && counts[countKey] > 0 ? (
                      <BadgeCmp
                        style="pill"
                        type="primary"
                        mainClassName="!border-borderPrimary ml-3"
                      >
                        {counts[countKey]}
                      </BadgeCmp>
                    ) : (
                      ''
                    )}
                  </NavLink>
                </>
              );
            })}
          <div
            className="flex items-center gap-x-2 mx-1.5 px-3 py-1.5 rounded-md cursor-pointer hover:bg-gray50 mt-1"
            onClick={handleLogout}
          >
            <p className="text-utilityGray800 text-sm font-medium">Sign out</p>
          </div>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
