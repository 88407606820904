import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

import { IOrderInfo } from './Order.interface';

interface IState {
  data: {
    orderInfo: IOrderInfo | null;
    shippers: any | null;
    consignees: any | null;
    isLoading: boolean;
    customerList: any[];
    orderLegs: any[];
    isValidAddress: {
      shipperAddress: boolean;
      consigneeAddress: boolean;
    };
    currentLegData: any | null;
  };
}
export const initialState: IState = {
  data: {
    orderInfo: null,
    orderLegs: [],
    currentLegData: null,
    isLoading: true,
    shippers: [],
    customerList: [],
    consignees: [],
    isValidAddress: {
      shipperAddress: true,
      consigneeAddress: true,
    },
  },
};

export const OrderSlice = createSlice({
  name: '/order',
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
});

export const { reducer: accountReducer } = OrderSlice;
export const { setData } = OrderSlice.actions;

export const selectOrderData = (state: RootState) => state.Order.data;
