import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import DeleteModal from 'src/components/DeleteModal';
import NotFoundUI from 'src/components/NotFoundUI';
import PdfPreviewModal from 'src/components/PdfPreviewModal/PdfModal';
import TableCmp from 'src/components/TableCmp';
import { MAX_FILE_SIZE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import {
  deleteFile,
  getLoadInsuranceFiles,
  uploadLoadInsurance,
} from 'src/services/LoadInsurance';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import LoadInsuranceFiles from '../LoadInsuranceFiles';
import LoadInsuranceFilesLoading from '../LoadInsuranceFilesLoading';

interface IProps {
  loadInsuranceId?: number;
  setAction: any;
  action: any;
}

const initAction = {
  isEdit: false,
  mode: '',
  id: 0,
  data: {
    name: '',
    value: '',
  },
};

const FileUpload = ({ loadInsuranceId, setAction, action }: IProps) => {
  // const [fileAttachmentFilter, setFileAttachmentFilter] = useState('all');
  const [upload, setUpload] = useState(false);
  const [displayImage, setDisplayImage] = useState<any>('');
  const [displayPDF, setDisplayPDF] = useState<any>('');
  const [displayPDFUrl, setDisplayPDFUrl] = useState<any>('');
  const fileNameRef = useRef<any>('');
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [isShowSweetAlert, setIsShowSweetAlert] = useState<boolean>(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  const [isFileListLoading, setIsFileListLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [total, setTotal] = useState(0);

  const getFiles = () => {
    setIsFileListLoading(true);
    console.log('loadInsuranceId', loadInsuranceId);
    getLoadInsuranceFiles(loadInsuranceId)
      .then((response: any) => {
        if (response) {
          setFileList(response?.data);
          setTotal(response?.total);
          setIsFileListLoading(false);
        }
      })
      .finally(() => {
        setIsFileListLoading(false);
      });
  };

  useEffect(() => {
    getFiles();
  }, []);

  const uploadFile = (file: any) => {
    const appendFormData: any = new FormData();
    appendFormData.append('loadInsuranceId', loadInsuranceId);
    appendFormData.append('uploadedBy', currentUser?.id);
    appendFormData.append('fileOriginalName', file?.convertedFile?.name);
    appendFormData.append('size', file?.convertedFile?.size);
    appendFormData.append('file', file?.convertedFile);

    uploadLoadInsurance(appendFormData)
      .then((response: any) => {
        if (response) {
          getFiles();
          setTotal(response.total);
          setIsFileUploadLoading(false);
          setUpload(false);
          setDisplayImage('');
          setDisplayPDF('');
          setErrorMessage('');
        }
      })
      .catch((e: any) => {
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        );
        setDisplayImage('');
        setDisplayPDF('');
        setErrorMessage('');
      })
      .finally(() => {
        setIsFileUploadLoading(false);
      });
  };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo: any = convertBase64ToFile(appLogoImage);
    console.log('fileInfo', fileInfo);

    if (fileInfo?.convertedFile && fileInfo?.convertedFile != '') {
      setIsFileUploadLoading(true);
      uploadFile(fileInfo);
    }
  };

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   const file = acceptedFiles[0];

  //   if (file?.type?.match('image') || file?.type?.match('application/pdf')) {
  //     const { result } = checkFileTypeValidation(acceptedFiles, MAX_FILE_SIZE, [
  //       'image/svg+xml',
  //       'image/png',
  //       'image/jpeg',
  //       'application/pdf',
  //     ]);

  //     if (result) {
  //       const reader = new FileReader();

  //       reader.onloadstart = () => {
  //         setDisplayImage('');
  //         setDisplayPDF('');
  //         setErrorMessage('');
  //         setFileName('');
  //       };

  //       reader.onloadend = () => {
  //         setFileName(file.name);

  //         if (file?.type?.match('application/pdf')) {
  //           setDisplayPDFUrl(URL.createObjectURL(file));
  //           setDisplayPDF(reader.result as any);
  //         } else {
  //           setDisplayImage(reader.result as any);
  //         }

  //         setUpload(true);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       setErrorMessage('File size is invalid');
  //     }
  //   } else {
  //     setErrorMessage('File type is invalid');
  //   }
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  //   maxFiles: 1,
  //   multiple: false,
  //   accept: {
  //     'image/png': ['.png'],
  //     'image/jpeg': ['.jpg', '.jpeg'],
  //     'image/svg+xml': ['.svg'],
  //     'application/pdf': ['.pdf'],
  //   },
  // });

  const FilesheadCells = useMemo(
    () => [
      {
        id: 'filename',
        name: 'File Name',
      },
      {
        id: 'dateuploaded',
        name: 'Date Uploaded',
      },

      {
        id: 'uploadedby',
        name: 'Uploaded By',
      },
      {
        id: '',
        name: '',
      },
    ],
    []
  );

  const onAttachmentRowClick = (url: any) => () => {
    console.log('url', url);

    window.open(url, '_blank');
  };

  // const handleModalClose = useCallback(
  //   (status: boolean) => () => {
  //     if (status && isDeleteID) {
  //       deleteClaimDisputeAttachmentFile(isDeleteID)
  //         .then(() => {
  //           // WalToast.success(`File deleted successfully`);
  //           setIsDeleteFile(false);
  //           setIsDeleteID(null);
  //           getFileAttachmentListData();
  //         })
  //         .catch((e) => {
  //           WalToast.error(
  //             e?.response?.data?.message ??
  //               'Something went wrong while deleting file.'
  //           );
  //         });
  //     } else {
  //       setIsDeleteFile(false);
  //       setIsDeleteID(null);
  //     }
  //   },
  //   [isDeleteID]
  // );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (
      file &&
      (file.type.match('image') || file.type.match('application/pdf'))
    ) {
      const { result } = checkFileTypeValidation([file], MAX_FILE_SIZE, [
        'image/svg+xml',
        'image/png',
        'image/jpeg',
        'application/pdf',
      ]);

      if (result) {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setDisplayImage('');
          setDisplayPDF('');
          setErrorMessage('');
          fileNameRef.current = '';
          setDisplayPDFUrl('');
        };

        reader.onloadend = () => {
          fileNameRef.current = file.name;

          if (file.type.match('application/pdf')) {
            setDisplayPDFUrl(URL.createObjectURL(file));
            setDisplayPDF(reader.result as any);
          } else {
            setDisplayImage(reader.result as any);
            imageUpload(reader.result);
          }

          setUpload(true);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage('File size is invalid');
      }
    } else {
      setErrorMessage('File type is invalid');
    }
  };

  useEffect(() => {
    if (!upload) {
      if (fileInput?.current) {
        fileInput.current.value = '';
      }
    }
  }, [upload]);

  useEffect(() => {
    if (errorMessage) {
      setIsShowSweetAlert(true);
    }
  }, [errorMessage]);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteFile(action?.id)
          .then(() => {
            getFiles();
            setAction(initAction);
            WalToast.success('Statement Deleted successfully');
          })
          .catch((e: any) => {
            setAction(initAction);
            WalToast.error(
              e?.response?.data?.message ??
                'something went wrong please try again'
            );
          });
      } else {
        setAction(initAction);
      }
    },
    [action]
  );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mb-5 flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">All Files & Attachments</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${isFileListLoading ? 'custom-loading' : ''}`}
              >
                {total} {total > 1 ? 'Files' : 'File'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {`All Files and assets that have been attached to this load insurance.`}
            </p>
          </div>

          <input
            type="file"
            id="fileInput"
            accept=".png,.jpg,.jpeg,.svg,.pdf"
            ref={fileInput}
            onChange={(e) => {
              handleFileChange(e);
            }}
            className="hidden"
          />
          <ButtonCmp
            className=" btn-outline-primary"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => {
              setErrorMessage(null);
              document.getElementById('fileInput')?.click();
            }}
            loading={isFileUploadLoading}
            disabled={isFileUploadLoading}
          >
            Upload Files
          </ButtonCmp>
        </div>
        <div className="h-full w-full border-t border-gray100 flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
            <TableCmp
              headCells={FilesheadCells}
              tableDataArr={fileList}
              isTableDataLoading={isFileListLoading}
              tableRowCmpProps={{
                deleteFile: (row: any) =>
                  setAction((old: any) => ({
                    ...old,
                    ...{
                      mode: 'deleteFile',
                      id: row?.id,
                    },
                  })),
                onRowClick: onAttachmentRowClick,
              }}
              TableLoaderRowCmp={LoadInsuranceFilesLoading}
              TableRowCmp={LoadInsuranceFiles}
            />
          </div>
          {!isFileListLoading && fileList?.length <= 0 && (
            <NotFoundUI
              title={'No file attachment found'}
              desc={`There are no file attachment found`}
              containerClassName="!h-auto !my-6 flex-1"
              testId="wal-Claims-dataNotFoundText"
              iconClassName="!hidden"
            />
          )}
        </div>
      </div>

      {upload &&
        (displayPDF && displayPDF != '' ? (
          <PdfPreviewModal
            modalTitle={`Load Insurance Attachment preview`}
            setUpload={setUpload}
            displayPDF={displayPDF}
            imageUpload={imageUpload}
            setIsFileUploadLoading={setIsFileUploadLoading}
            isFileUploadLoading={isFileUploadLoading}
            displayPDFUrl={displayPDFUrl}
          />
        ) : (
          <CropperModal
            modalTitle={`Edit Load Insurance Image`}
            modalDesc={`Edit Load Insurance Image as per your requirement`}
            imageUpload={imageUpload}
            imageUrl={displayImage}
            setUpload={setUpload}
            defaultImage={''}
            setCropData={setDisplayImage}
            isCropType={false}
            defaultCropType="Square"
            isSetToModalClose={false}
            primaryBtnLoading={isFileUploadLoading}
          />
        ))}

      {isShowSweetAlert && (
        <CommonModal
          title={errorMessage}
          primaryBtnText="Close"
          primaryBtnOnClick={() => {
            setIsShowSweetAlert(false);

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }}
          handleClose={() => {
            setIsShowSweetAlert(false);

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }}
        >
          <div className="p-5 text-sm">
            {errorMessage === 'File type is invalid' ? (
              <p>
                The file type you selected is not supported. Please upload a
                file in one of the following formats:{' '}
                <strong>SVG, PNG, JPG, JPEG, or PDF</strong>.
              </p>
            ) : errorMessage === 'File size is invalid' ? (
              <p>
                The file you selected is too large. The maximum allowed file
                size is <strong>{MAX_FILE_SIZE / (1024 * 1024)} MB</strong>.
                Please choose a smaller file to upload.
              </p>
            ) : (
              <p>{errorMessage}</p>
            )}
          </div>
        </CommonModal>
      )}
      {action.mode === 'deleteFile' && (
        <DeleteModal
          moduleName={`file from load Insurance`}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default FileUpload;
