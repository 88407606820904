import {} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';

import ButtonCmp from '../ButtonCmp';
import CheckBox from '../CheckBox';
import InputText from '../InputText/InputText';

interface IProps {
  handleClose: any;
  routeFilters: any;
  setRouteFilters: any;
}

const MapFilterModal = ({
  handleClose,
  routeFilters,
  setRouteFilters,
}: IProps) => {
  const [formData, setFormData] = useState(routeFilters);
  const [exclude, setExclude] = useState<any[]>([]);
  const [errMsg, setErrMsg] = useState<any>({
    max_height: '',
    max_width: '',
    max_weight: '',
  });
  useEffect(() => {
    console.log('RouteFilters Filter', routeFilters);
  }, [routeFilters]);

  const isValid = () => {
    let valid = true;
    setErrMsg({
      max_height: '',
      max_width: '',
      max_weight: '',
    });

    if (formData.max_height > 10) {
      setErrMsg({
        ...errMsg,
        max_height: 'value must be between 1 to 10 meters',
      });
      valid = false;
    }

    if (formData.max_width > 10) {
      setErrMsg({
        ...errMsg,
        max_width: 'value must be between 1 to 10 meters',
      });
      valid = false;
    }

    if (formData.max_weight > 100) {
      setErrMsg({
        ...errMsg,
        max_weight: 'value must be between 1 to 100 meters',
      });
      valid = false;
    }

    return valid;
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleCheckbox = (event: any) => {
    const { value, checked } = event.target;
    let updatedServiceArea = [...exclude];

    if (checked) {
      updatedServiceArea.push(value);
    } else {
      updatedServiceArea = updatedServiceArea.filter((item) => item !== value);
    }
    setExclude(updatedServiceArea);
    setFormData({ ...formData, exclude: updatedServiceArea });
  };

  const handleSave = () => {
    console.log('handleSave', formData);

    if (isValid()) {
      setRouteFilters({ ...formData });
      handleClose();
    }
  };

  return (
    <>
      {/* <CommonModal
        size={'smd:max-w-[535px] max-w-[336px] overflow-unset'}
        handleClose={handleClose}
        title="Filters"
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleClose}
        titleDesc="Please change as per your requirement."
        isOverflow={false}
      > */}
      <div className="max-w-[1140px] mx-auto mt-2 rounded-[20px] bg-white shadow-xl">
        <div className="p-5 flex gap-5 flex-wrap">
          <InputText
            parentClassName="flex-1"
            inputName="max_height"
            inputType="number"
            label="Max. Vehicle Height (in)"
            labelClassName="block mb-1.5"
            placeholder="250"
            max={10}
            onChangeFunc={handleInputChange}
            value={formData?.max_height}
            errorText={errMsg.max_height}
          />
          <InputText
            parentClassName="flex-1"
            inputName="max_width"
            label="Max. Vehicle Width (in)"
            labelClassName="block mb-1.5"
            placeholder="450"
            onChangeFunc={handleInputChange}
            value={formData?.max_width}
            errorText={errMsg.max_width}
          />
          <InputText
            parentClassName="flex-1"
            inputName="max_weight"
            label="Max. Vehicle Weight (lbs)"
            labelClassName="block mb-1.5"
            placeholder="500"
            onChangeFunc={handleInputChange}
            value={formData?.max_weight}
            errorText={errMsg.max_weight}
          />
          <ul className="rounded-xl border border-utilityGray200 bg-gray25 w-full overflow-x-hidden flex flex-wrap">
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="motorway"
                checked={formData?.exclude?.includes('motorway')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Use motorways to avoid highways.
              </label>
            </li>
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="toll"
                checked={formData?.exclude?.includes('toll')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Add tolls to avoid routes with additional costs.
              </label>
            </li>
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="ferry"
                checked={formData?.exclude?.includes('ferry')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Add ferry to avoid ferry routes and save time/cost.
              </label>
            </li>
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="unpaved"
                checked={formData?.exclude?.includes('unpaved')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Use unpaved to avoid dirt or poor-quality roads.
              </label>
            </li>
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="cash_only_tolls"
                checked={formData?.exclude?.includes('cash_only_tolls')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Enable cash only tool to avoid cash-only toll.
              </label>
            </li>
            <li className="relative w-1/2 flex px-5 py-4 gap-x-1.5 before:content-[' '] before:bg-utilityGray200 before:w-[90vw] before:h-px before:-top-px   before:left-0 before:absolute">
              <CheckBox
                onChangeFunc={handleCheckbox}
                classes=""
                value="country_border"
                checked={formData?.exclude?.includes('country_border')}
              />
              <label className="block tetx-textSecondary text-xs font-normal">
                Use country border for international, state border for
                interstate limit.
              </label>
            </li>
          </ul>
        </div>
        <div className="px-5 py-4 gap-x-3 flex border-t border-utilityGray200">
          <ButtonCmp
            type="button"
            onClick={handleClose}
            className="btn_secondary_black flex-1"
          >
            Cancel
          </ButtonCmp>
          <ButtonCmp
            type="button"
            onClick={handleSave}
            className="btn-primary-light flex-1"
          >
            Save
          </ButtonCmp>
        </div>
      </div>
      {/* </CommonModal> */}
    </>
  );
};
export default MapFilterModal;
