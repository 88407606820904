import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChevronDown,
  Plus,
  ChevronUp,
  Target05,
  XClose,
  Save01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InternalChatAndNotes from 'src/app/Claims&Disputes/InternalChatAndNotes';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import ConfirmModal from 'src/components/ConfirmModal';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import DeleteModal from 'src/components/DeleteModal';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { FOOT, KGS, LBS, MEASUREMENT, TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { CustomersAllList } from 'src/services/CustomerService';
import {
  getOrder,
  saveOrderAsDraft,
  updateOrder,
} from 'src/services/OrderService';
import { listShippers } from 'src/services/ShipperService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getSelectBoxOptions,
  getShortName,
} from 'src/utils/CommonFunctions';

import defaultimage from '../../../assets/img/default-image.jpg';
import CreateOrder from '../createOrder';
import { constructOrderParam } from '../order.constant';
import {
  defualtOrderValue,
  // dimensionObj,
  initAction,
  orderSchema,
  processOrderData,
} from '../order.interface';
import RecurringOrderModal from '../OrderView/RecurringOrder/RecurringOrderModal';

import AddOrderDetails from './AddOrderDetails';
import AddressDetails from './AddressDetails';
import AppointmentDetails from './AppointmentDetails';
import CarrierAndSalesPrice from './CarrierAndSalesPrice';

export const OrderContext = createContext({});

const orderDimensionInitAction = {
  tailgate: false,
  unusual: false,
};

const OrderDetails = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const [action, setAction] = useState(initAction);
  const [orderDimensionAction, setOrderDimensionAction] = useState(
    orderDimensionInitAction
  );
  const [order, setOrder] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isAllowOverWeigth, setIsAllowOverWeigth] = useState(false);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  //   const [isShowOrderActionsDropDown, setIsShowOrderActionsDropDown] =
  //     useState(false);
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
  });

  const [orderLegs, setOrderLegs] = useState([
    { value: 'leg1', name: 'Master (#Leg 1)' },
    { value: 'leg1', name: 'Master (#Leg 1)' },
    { value: 'leg1', name: 'Master (#Leg 1)' },
  ]);
  const [activeLeg, setActiveLeg] = useState<any>(null);
  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [tabValues, setTabValues] = useState<{ [key: string]: any }>({
    leg1: {},
  });
  const [isDisplayOrderDetail, setIsDisplayOrderDetail] = useState(false);
  const [shipperAddress, setShipperAddress] = useState<any>(null);
  const [consigneeAddress, setConsigneeAddress] = useState<any>(null);
  const [isShowSaveAsDraftModal, setIsShowSaveAsDraftModal] = useState(false);

  const methods = useForm({
    resolver: yupResolver(orderSchema) as Resolver<any>,
    defaultValues: defualtOrderValue,
  });

  const { handleSubmit, reset, getValues, trigger, clearErrors } = methods;

  const getOrderData = () => {
    setIsLoading(true);
    const param = { id };

    getOrder(param)
      .then((response) => {
        if (response?.data) {
          const masterOrder = response?.data;
          // const childOrders = response?.data?.childOrders || [];

          let orderLegsData: any = [];

          if (masterOrder?.orderLegs) {
            orderLegsData = masterOrder?.orderLegs;
          }

          setOrder({ ...masterOrder, orderLegs: orderLegsData });

          let orderLegItems = [];

          orderLegItems = [
            ...orderLegsData.map((subOrder: any, index: number) => ({
              value: subOrder?.legId,
              name:
                index === 0
                  ? `Master (#${subOrder?.legId})`
                  : `Leg${index + 1} (#${subOrder?.legId})`,
            })),
          ];

          // const orderLegItems = [
          //   {
          //     value: masterOrder?.orderId,
          //     name: `Master (#${masterOrder?.orderId})`,
          //   },
          //   ...childOrders.map((subOrder: any, index: number) => ({
          //     value: subOrder?.orderId,
          //     name: `Leg${index + 1} (#${subOrder?.orderId})`,
          //   })),
          // ];

          setActiveLeg(masterOrder?.orderId);
          setOrderLegs(orderLegItems);
          // const resetOrderData = processOrderData(masterOrder);
          const childOrderData = orderLegsData.reduce(
            (acc: any, subOrder: any, index: number) => {
              if (index === 0) {
                const resetOrderData = processOrderData(subOrder);
                setCurrentLegData(resetOrderData);
                reset({
                  ...resetOrderData,
                  declaredValue: masterOrder?.declaredValue,
                  declaredValueUnit: masterOrder?.declaredValueUnit,
                });

                setWeightMeasurement(resetOrderData?.weightMeasurement);
                setLengthMeasurement(resetOrderData?.lengthMeasurement);
              }

              acc[subOrder.legId] = processOrderData(subOrder);

              return acc;
            },
            {}
          );

          const initialTabValues = {
            ...childOrderData,
          };

          setTabValues(initialTabValues);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => {
        // setIsShipperLoading(false)
      })
      .catch(console.error);
  };

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
    getShipper();
    getCustomerList();
  }, []);

  // const handleServiceActionType = (actionKey: string) => {
  //   setOrderDimensionAction((old) => ({ ...old, [actionKey]: true }));
  // };

  const onSubmit = (data: any) => {
    const currentData = { ...currentLegData, ...data };

    // const updatedTabValues: any = {
    //   ...tabValues,
    //   [activeLeg]: {
    //     ...tabValues[activeLeg],
    //     ...data,
    //     weightMeasurement: MEASUREMENT.WEIGHT1.value,
    //     lengthMeasurement: MEASUREMENT.LENGTH1.value,
    //   },
    // };

    // console.log('updatedTabValues', updatedTabValues);

    // const masterOrderKey = Object.keys(tabValues)[0];
    // const masterOrder = updatedTabValues[masterOrderKey];

    const param: any = {
      shipperCompanyName: currentData?.shipper?.companyName,
      shipperContactName: currentData?.shipper?.contactName,
      shipperContactNumber: currentData?.shipper?.phoneNumber,
      shipperEmail: currentData?.shipper?.email,
      id: currentData?.id,
      shipperFullAddress: currentData?.shipper?.shipperAddress?.fullAddress,
      shipperAddress1: currentData?.shipper?.shipperAddress?.address1,
      shipperCity: currentData?.shipper?.shipperAddress?.city,
      shipperState: currentData?.shipper?.shipperAddress?.state,
      shipperCountry: currentData?.shipper?.shipperAddress?.country,
      shipperPostal: currentData?.shipper?.shipperAddress?.postal,
      shipperLatitude: currentData?.shipper?.shipperAddress?.latitude,
      shipperLongitude: currentData?.shipper?.shipperAddress?.longitude,
      shipperStateCode: currentData?.shipper?.shipperAddress?.stateCode,
      shipperCountryCode: currentData?.shipper?.shipperAddress?.countryCode,
      consigneeCompanyName: currentData?.consignee?.companyName,
      consigneeContactName: currentData?.consignee?.contactName,
      consigneeContactNumber: currentData?.consignee?.phoneNumber,
      consigneeEmail: currentData?.consignee?.email,
      consigneeFullAddress:
        currentData?.consignee?.consigneeAddress?.fullAddress,
      consigneeAddress1: currentData?.consignee?.consigneeAddress?.address1,
      consigneeCity: currentData?.consignee?.consigneeAddress?.city,
      consigneeState: currentData?.consignee?.consigneeAddress?.state,
      consigneeCountry: currentData?.consignee?.consigneeAddress?.country,
      consigneePostal: currentData?.consignee?.consigneeAddress?.postal,
      consigneeLatitude: currentData?.consignee?.consigneeAddress?.latitude,
      consigneeLongitude: currentData?.consignee?.consigneeAddress?.longitude,
      consigneeStateCode: currentData?.consignee?.consigneeAddress?.stateCode,
      consigneeCountryCode:
        currentData?.consignee?.consigneeAddress?.countryCode,
      order_dimensions: currentData?.order_dimensions,
      declaredValue: currentData?.declaredValue,
      declaredValueUnit: currentData?.declaredValueUnit || 'CAD',
      equipmentType: currentData?.equipmentType,
      linearFootage: currentData?.linearFootage,
      orderType: currentData?.orderType,
      poNumber: currentData?.poNumber,
      refNumber: currentData?.refNumber,
      specialNotes: currentData?.specialNotes,
      weightMeasurement: weightMeasurement,
      lengthMeasurement: lengthMeasurement,
      additionalServices: currentData?.additionalServices,
      carrierPrice: currentData?.carrierPrice,
      salesPrice: currentData?.salesPrice,
      masterOrderId: +id,
    };

    // const legData: any = [];
    // let isAnyLegOverweight = false;
    // let isAnyLegOversized = false;

    // Object.keys(updatedTabValues).forEach((key) => {
    //   const leg = tabValues[key];
    //   let totalWeight: any = 0;
    //   let weightMeasurementValue = '';
    //   let isItemLengthOver = false;

    //   leg?.order_dimensions?.forEach((form: any) => {
    //     totalWeight += form.totalWeight;
    //     weightMeasurementValue = form.weightMeasurement;

    //     const maxLengthOver =
    //       lengthMeasurement === MEASUREMENT.LENGTH1.value
    //         ? LBS.ITEM_LENGTH_OVER
    //         : KGS.ITEM_LENGTH_OVER;

    //     if (form.length >= maxLengthOver) {
    //       isItemLengthOver = true;
    //     }
    //   });

    //   const isTotalWeightOverBool =
    //     weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //       ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //       : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    //   if (isTotalWeightOverBool && !isAnyLegOverweight) {
    //     isAnyLegOverweight = true;
    //   }

    //   if (isItemLengthOver && !isAnyLegOversized) {
    //     isAnyLegOversized = true;
    //   }

    //   // if (key !== masterOrderKey) {
    //   legData.push({
    //     orderId: key,
    //     ...leg,
    //   });
    //   // }
    // });

    // const finalData = {
    //   legs: updatedTabValues,
    // };

    // let totalWeight: number | string = 0;
    // let weightMeasurementValue = '';
    // let isItemLengthOver = false;

    // data?.order_dimensions.forEach((form: any) => {
    //   totalWeight += form.totalWeight;
    //   weightMeasurementValue = form.weightMeasurement;
    // });

    // data?.order_dimensions.forEach((form: any) => {
    //   const maxLengthOver =
    //     lengthMeasurement === MEASUREMENT.LENGTH1.value
    //       ? LBS.ITEM_LENGTH_OVER
    //       : KGS.ITEM_LENGTH_OVER;

    //   if (form.length >= maxLengthOver) {
    //     isItemLengthOver = true;
    //   }
    // });

    // totalWeight = totalWeight.toFixed();

    // const isTotalWeightOverBool =
    //   weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //     ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //     : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    // if (
    //   !isAllowOverWeigth &&
    //   isTailgate.length &&
    //   (isAnyLegOverweight || isAnyLegOversized)
    // ) {
    //   handleServiceActionType('tailgate');

    //   return;
    // }

    setIsSubmitLoading(true);

    updateOrder(param)
      .then((response) => {
        if (response) {
          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setOrderDimensionAction(orderDimensionInitAction);

      if (status) {
        setOrderDimensionAction(orderDimensionInitAction);
        setIsAllowOverWeigth(true);
      }
    },
    [orderDimensionAction]
  );

  useEffect(() => {
    if (isAllowOverWeigth) {
      handleSubmit(onSubmit)();
    }
  }, [isAllowOverWeigth]);

  const [isOrderRecurringModal, setIsOrderRecurringModal] = useState(false);
  const [isOrderDiscardModal, setIsOrderDiscardModal] = useState(false);

  const initTabArray: any = [
    {
      value: 'overview',
      name: 'Overview',
      count: 0,
    },
    {
      value: 'pricingTools',
      name: 'Pricing Tools',
      count: 0,
    },
    {
      value: 'emailThreads',
      name: 'Email Threads',
      count: 0,
    },
  ];

  // Add Stop

  // useEffect(() => {
  //   if (order) {
  //     let salesPrice: any = [];
  //     let carrierPrice = [];

  //     if (order?.salesPrice && Object.keys(order.salesPrice).length > 0) {
  //       salesPrice = [
  //         {
  //           ...order.salesPrice,
  //           additional_charge: order.salesPrice.additional_charge
  //             ? JSON.parse(order.salesPrice.additional_charge)
  //             : [],
  //         },
  //       ];
  //     }

  //     if (order?.carrierPrice && order?.carrierPrice?.length > 0) {
  //       carrierPrice = order.carrierPrice.map((carrier: any) => {
  //         const additionalCharge = carrier.additional_charge
  //           ? JSON.parse(carrier.additional_charge)
  //           : [];

  //         return {
  //           ...carrier,
  //           additional_charge: additionalCharge,
  //         };
  //       });
  //     }

  //     const resetOrderData = {
  //       shipper: {
  //         companyName: order?.shipperCompanyName || '',
  //         contactName: order?.shipperContactName || '',
  //         phoneNumber: order?.shipperContactNumber || '',
  //         email: order?.shipperEmail || '',
  //         shipperAddress: {
  //           fullAddress: order?.shipperFullAddress || '',
  //           address1: order?.shipperAddress1 || '',
  //           city: order?.shipperCity || '',
  //           state: order?.shipperState || '',
  //           country: order?.shipperCountry || '',
  //           postal: order?.shipperPostal || '',
  //           latitude: order?.shipperLatitude || '',
  //           longitude: order?.shipperLongitude || '',
  //           stateCode: order?.shipperStateCode || '',
  //           countryCode: order?.shipperCountryCode || '',
  //         },
  //       },
  //       consignee: {
  //         companyName: order?.consigneeCompanyName || '',
  //         contactName: order?.consigneeContactName || '',
  //         phoneNumber: order?.consigneeContactNumber || '',
  //         email: order?.consigneeEmail || '',
  //         consigneeAddress: {
  //           fullAddress: order?.consigneeFullAddress || '',
  //           address1: order?.consigneeAddress1 || '',
  //           city: order?.consigneeCity || '',
  //           state: order?.consigneeState || '',
  //           country: order?.consigneeCountry || '',
  //           postal: order?.consigneePostal || '',
  //           latitude: order?.consigneeLatitude || '',
  //           longitude: order?.consigneeLongitude || '',
  //           stateCode: order?.consigneeStateCode || '',
  //           countryCode: order?.consigneeCountryCode || '',
  //         },
  //       },
  //       orderType: order?.orderType || 'ltl',
  //       equipmentType: order?.equipmentType || 'dryvan',
  //       poNumber: order?.poNumber || '',
  //       refNumber: order?.refNumber || '',
  //       linearFootage: order?.linearFootage || undefined,
  //       declaredValue: order?.declaredValue || undefined,
  //       specialNotes: order?.specialNotes || '',
  //       declaredValueUnit: order?.declaredValueUnit || 'CAD',
  //       order_dimensions:
  //         order?.order_dimensions?.length > 0
  //           ? order?.order_dimensions
  //           : [dimensionObj],
  //       additionalServices: order?.additionalServices || null,
  //       salesPrice: salesPrice,
  //       carrierPrice: carrierPrice,
  //       carrierPickupAppointmentDate: order?.carrierPickupAppointmentDate,
  //       carrierPickupAppointmentTime: order?.carrierPickupAppointmentTime,
  //       carrierDeliveryAppointmentDate: order?.carrierDeliveryAppointmentDate,
  //       carrierDeliveryAppointmentTime: order?.carrierDeliveryAppointmentTime,
  //       shipperAppointmentDate: order?.shipperAppointmentDate,
  //       shipperAppointmentTime: order?.shipperAppointmentTime,
  //       deliveryAppointmentDate: order?.deliveryAppointmentDate,
  //       deliveryAppointmentTime: order?.deliveryAppointmentTime,
  //       weightMeasurement:
  //         order?.order_dimensions?.length > 0
  //           ? order?.order_dimensions?.[0]?.weightMeasurement
  //           : weightMeasurement,
  //       lengthMeasurement:
  //         order?.order_dimensions?.length > 0
  //           ? order?.order_dimensions?.[0]?.lengthMeasurement
  //           : lengthMeasurement,
  //     };

  //     reset(resetOrderData);
  //     setCurrentLegData(resetOrderData);

  //     setWeightMeasurement(resetOrderData?.weightMeasurement);
  //     setLengthMeasurement(resetOrderData?.lengthMeasurement);

  //     setTabValues((prev) => ({
  //       ...prev,
  //       [order?.orderId]: resetOrderData,
  //     }));
  //   }
  // }, [order]);

  const handleAddStop = () => {
    const suffix = String.fromCharCode(64 + orderLegs.length);

    setOrderLegs((prev) => [
      ...prev,
      {
        value: `${order?.orderId}-${suffix}`,
        name: `Leg ${prev.length} (#${order?.orderId}-${suffix})`,
      },
    ]);

    setActiveLeg(`${order?.orderId}-${suffix}`);
    setCurrentLegData(defualtOrderValue);
    reset(defualtOrderValue);
  };

  const saveCurrentLegData = () => {
    const currentData = getValues();

    setTabValues((prev) => ({
      ...prev,
      [activeLeg]: currentData,
    }));
  };

  const handleTabSwitch = async (tabValue: string) => {
    if (tabValue !== activeLeg) {
      // temp condition
      const isValid = await trigger();

      if (isValid) {
        const currentTabValue = tabValues[tabValue] ?? defualtOrderValue;
        saveCurrentLegData();

        setActiveLeg(tabValue);
        reset(currentTabValue);
        setCurrentLegData(currentTabValue);
      }
    }
  };

  const handleDiscardModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        localStorage.setItem(TABLE_IDS.ORDER_LIST, JSON.stringify('new-order'));

        navigate(`${ROUTES.ORDERS}`);
      } else {
        setIsOrderDiscardModal(false);
      }
    },
    []
  );

  const handleSaveAsDraft = async () => {
    const formData = getValues();
    const fieldsToValidate: string[] = [
      'shipper.companyName',
      'consignee.companyName',
    ];

    clearErrors();

    const fieldsToCheck = [
      { path: 'shipper.email', value: formData.shipper?.email },
      { path: 'shipper.phoneNumber', value: formData.shipper?.phoneNumber },
      { path: 'consignee.email', value: formData.consignee?.email },
      { path: 'consignee.phoneNumber', value: formData.consignee?.phoneNumber },
    ];

    fieldsToCheck.forEach((field) => {
      if (field.value?.trim()) {
        fieldsToValidate.push(field.path);
      }
    });

    if (shipperAddress) {
      fieldsToValidate.push('shipper.shipperAddress.fullAddress');
    }

    if (consigneeAddress) {
      fieldsToValidate.push('consignee.consigneeAddress.fullAddress');
    }

    const validationResults = await trigger(fieldsToValidate);

    if (!validationResults) {
      return;
    }

    setIsShowSaveAsDraftModal(true);
  };

  const handleSubmitSaveAsDraft = () => {
    const formData = getValues();

    const draftParam = constructOrderParam(
      formData,
      currentLegData?.id,
      weightMeasurement,
      lengthMeasurement
    );

    setIsLoading(true);

    saveOrderAsDraft({ ...draftParam, masterOrderId: id })
      .then((response) => {
        if (response) {
          navigate(`${ROUTES.ORDERS}`);
          setIsShowSaveAsDraftModal(false);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseCreateOrder = () => {
    setIsCreateOrder(false);
  };

  const handleOnCreateOrder = () => {
    reset();
    setActiveLeg(null);
    setCurrentLegData(null);
    setTabValues({
      leg1: {},
    });
    getOrderData();
    handleCloseCreateOrder();
  };

  return (
    <OrderContext.Provider
      value={{
        isTailgate,
        setIsTailgate,
        orderDimensionAction,
        setOrderDimensionAction,
        order,
        setWeightMeasurement,
        setLengthMeasurement,
        lengthMeasurement,
        weightMeasurement,
        customerList,
        isCustomerLoading,
        isValidAddress,
        setIsValidAddress,
        shippers,
        consignees,
        shipperOptions,
        consigneeOptions,
        currentLegData,
        setCurrentLegData,
        setTabValues,
        tabValues,
        activeLeg,
      }}
    >
      <PageSectionLayout
        header={
          <Header
            leftClassName="!w-auto"
            title={
              <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
                Order:
                <span className={`${isLoading ? 'custom-loading' : ''}`}>
                  #{order ? order.orderId : 'CL-AOI1-141'}
                </span>
                <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                  {order?.customerName && (
                    <TooltipCmp message={order?.customerName}>
                      {order?.customerImage ? (
                        <div className="rounded-full bg-white">
                          <img
                            src={order?.customerImageUrl + order?.customerImage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultimage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                  {order?.salesRepFirstName && (
                    <TooltipCmp
                      message={`${order?.salesRepFirstName} ${order?.salesRepLastName}`}
                    >
                      {order?.salesImage ? (
                        <div className="rounded-full bg-white">
                          <img
                            src={order?.salesImageUrl + order?.salesImage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultimage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                </div>
                <BadgeCmp
                  style="modern"
                  mainClassName={`rounded-md ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  type="success"
                >
                  {order ? order.orderStatus : 'Opened'}
                </BadgeCmp>
              </div>
            }
            desc={`Detailed review of items, prices, and order status`}
            rightSideContent={
              <>
                <ButtonCmp
                  className="btn-outline-primary hidden"
                  onClick={() => {
                    setIsCreateOrder(true);
                  }}
                >
                  Create Order
                </ButtonCmp>
                <TooltipCmp message={'Discard Changes'}>
                  <ButtonCmp
                    className="btn_secondary_black lg:!px-[9px] !px-2"
                    onClick={() => {
                      setIsOrderDiscardModal(true);
                    }}
                  >
                    <XClose className="w-4 h-4" />
                  </ButtonCmp>
                </TooltipCmp>
              </>
            }
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <FormProvider {...methods}>
          <div className="flex h-full w-full">
            <div className="flex-1 xl:pr-8 pb-5 overflow-auto custom-scrollbar-v2 pt-6 flex flex-col gap-y-4">
              <TabButton
                parentClassName="!bg-gray50 !w-full "
                className="rounded-md text-gray500 !border-0 min-w-[182px] "
                tabParentClassName={`!w-full ${
                  window.MODE === 'production' ? '!hidden' : ''
                }`}
                activeClassName="!bg-primary100 !text-primary700"
                tabArray={initTabArray}
                isActive={'overview'}
                isTab={true}
                handleOnClick={() => {}}
              />
              {/* NEED TO DO FUNCTIONALITY FOR ACCORDION - REMOVE "H-[84PX]" CLASS WHEN CLICK ON ARROW ChevronUp */}
              <div className="flex ml-1 overflow-hidden h-[84px] flex-none">
                <div className="flex-1 flex flex-wrap -mx-1">
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200 ">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Customer
                    </h6>

                    <Link
                      to={`${PATH.CUSTOMER_DETAILS}/${order?.customerId}`}
                      className="text-primary font-medium"
                    >
                      {order?.customerName &&
                      order?.customerName.length > 24 ? (
                        <TooltipCmp
                          message={'customer name'}
                          parentClassName="md:!p-3 !p-1.5 max-w-90"
                        >
                          <p
                            className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                              isLoading ? 'custom-loading' : ''
                            }`}
                          >
                            {!isLoading && order?.customerName
                              ? order?.customerName
                              : 'DSS Marine Incorporated (USD)'}
                          </p>
                        </TooltipCmp>
                      ) : (
                        <p
                          className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                        >
                          {!isLoading && order?.customerName
                            ? order?.customerName
                            : 'DSS Marine Incorporated (USD)'}
                        </p>
                      )}
                    </Link>
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Sales Rep.
                    </h6>
                    <div className="flex items-center gap-x-1">
                      {order?.salesImage &&
                        (order?.salesImageUrl ? (
                          <img
                            src={order?.salesImageUrl + order?.salesImage}
                            className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                          />
                        ) : (
                          <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                            {getShortName(
                              `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                            )}
                          </div>
                        ))}

                      <p
                        className={`text-sm ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {order?.salesRepId
                          ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Created At
                    </h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: order
                          ? getDateWithSuffixFormat(
                              getFormattedDate(
                                order.createdAt,
                                `Do MMMM YYYY`,
                                true,
                                true
                              )
                            )
                          : '-',
                      }}
                      className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    ></p>
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200 relative">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      From
                    </h6>

                    {order?.shipperAddress?.address1 &&
                    order?.shipperAddress?.address1.length > 24 ? (
                      <TooltipCmp
                        message={`${order?.shipperAddress?.address1}`}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p
                          className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                        >
                          {order?.shipperAddress?.address1
                            ? order?.shipperAddress?.address1
                            : '-'}
                        </p>
                      </TooltipCmp>
                    ) : (
                      <p
                        className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {order?.shipperAddress?.address1
                          ? order?.shipperAddress?.address1
                          : '-'}
                      </p>
                    )}
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      To
                    </h6>
                    {order?.consigneeAddress?.address1 &&
                    order?.consigneeAddress?.address1.length > 24 ? (
                      <TooltipCmp
                        message={`${order?.consigneeAddress?.address1}`}
                        parentClassName="md:!p-3 !p-1.5 max-w-90"
                      >
                        <p
                          className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                        >
                          {order?.consigneeAddress?.address1
                            ? order?.consigneeAddress?.address1
                            : '-'}
                        </p>
                      </TooltipCmp>
                    ) : (
                      <p
                        className={`xlm:max-w-56 max-w-40 text-sm font-medium truncate ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {order?.consigneeAddress?.address1
                          ? order?.consigneeAddress?.address1
                          : '-'}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className="relative flex-none pt-5 px-1 min-h-[84px] self-start"
                  onClick={() => {
                    setIsDisplayOrderDetail(!isDisplayOrderDetail);
                  }}
                >
                  {isDisplayOrderDetail ? (
                    <ChevronUp className="w-4 h-4 cursor-pointer " />
                  ) : (
                    <ChevronDown className="w-4 h-4 cursor-pointer" />
                  )}
                </div>
              </div>
              {isDisplayOrderDetail && (
                <div className="flex">
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Claim ID
                    </h6>
                    <p>-</p>
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Dispute ID
                    </h6>
                    <p>-</p>
                  </div>
                  <div className="relative sm:w-[20%] py-5 px-1 before:content-[' '] before:h-px before:w-[90vw] before:absolute before:left-0 before:bottom-0 before:bg-utilityGray200">
                    <h6 className="text-xs font-normal text-grayLight600 mb-1">
                      Load Insurance ID
                    </h6>
                    <p>-</p>
                  </div>
                </div>
              )}
              <div className="flex items-center flex-wrap gap-x-2">
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-base font-semibold">
                    Overview
                  </h6>
                  <p className="text-grayLight600 text-sm font-medium">
                    Track and Review Every Detail of Your Order
                  </p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <ButtonCmp
                    className="btn-outline-primary"
                    icon={<Plus className="w-4 h-4" />}
                    onClick={() => handleAddStop()}
                    disabled={true}
                  >
                    Add Stop
                  </ButtonCmp>
                  {order?.orderStatus === 'draft' && (
                    <ButtonCmp
                      className="btn-outline-primary min-w-[80px]"
                      onClick={handleSaveAsDraft}
                      loading={isSubmitLoading}
                      disabled={isSubmitLoading}
                    >
                      Save As Draft
                    </ButtonCmp>
                  )}
                  <ButtonCmp
                    className="btn_secondary_black min-w-[80px]"
                    onClick={handleSubmit(onSubmit)}
                    loading={isSubmitLoading}
                    disabled={isSubmitLoading}
                  >
                    Save
                  </ButtonCmp>
                </div>
              </div>
              <TabButton
                parentClassName="!bg-gray50 !w-full "
                className={`!rounded-md !border-0 [&>span]:text-gray400 min-w-[200px] ${
                  isLoading ? 'custom-loading' : ''
                }`}
                activeClassName={`!bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md  ${
                  isLoading ? 'custom-loading' : ''
                }`}
                tabArray={orderLegs}
                tabParentClassName=""
                handleOnClick={(e: any) => {
                  const tabValue = e.target.dataset.value;
                  handleTabSwitch(tabValue);
                }}
                isActive={activeLeg}
                isTab={true}
              />
              <ul className="flex flex-col gap-y-5">
                <AddressDetails
                  action={action}
                  setAction={setAction}
                  shipperAddress={shipperAddress}
                  setShipperAddress={setShipperAddress}
                  consigneeAddress={consigneeAddress}
                  setConsigneeAddress={setConsigneeAddress}
                />

                <li className="rounded-[20px] border border-utilityGray200 bg-gray25 p-5 flex flex-col gap-y-4">
                  <AppointmentDetails
                    action={action}
                    setAction={setAction}
                    orderLegId={currentLegData?.id}
                  />
                </li>

                <AddOrderDetails />

                <CarrierAndSalesPrice />
              </ul>
            </div>
            <div className="flex flex-col bg-gray25">
              <div className="pl-5 py-5 border-b border-l border-utilityGray200">
                <div className="rounded-lg relative h-[190px] overflow-hidden">
                  <div className="h-full bg-gray200">Map</div>
                  <div className="flex gap-1.5 items-center mt-2 px-1.5 py-1 rounded shadow-sm bg-white absolute bottom-2.5 left-2.5">
                    <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                    <span className="text-xs text-grayLight900 font-medium">
                      Last update: Today, 11:03 AM
                    </span>
                  </div>
                  <Link
                    to={ROUTES.HOME}
                    className="flex gap-1.5 items-center px-1.5 py-1 rounded-md border border-borderPrimary bg-white absolute top-2.5 right-2.5"
                  >
                    <Target05 className="w-4 h-4 text-grayLight600" />
                    <p className="text-textSecondary text-xs font-medium">
                      Open in Radar
                    </p>
                  </Link>
                </div>
              </div>
              <InternalChatAndNotes
                // claim={claim}
                isClaim={true}
                notesCount={<></>}
                setNotesCount={<></>}
                isShowChatAndNotesSideBar={<></>}
                setIsShowChatAndNotesSideBar={<></>}
                chatWrapClassName="overflow-x-auto"
              />
            </div>
          </div>
        </FormProvider>

        {isCreateOrder && (
          <CreateOrder
            handleClose={handleCloseCreateOrder}
            onCreate={handleOnCreateOrder}
          />
        )}
        {orderDimensionAction.tailgate && (
          <ConfirmModal
            title="Tailgate Over Dimensions"
            description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
              weightMeasurement === MEASUREMENT.WEIGHT1.value
                ? `${LBS.MAX_WEIGHT}lbs`
                : `${KGS.MAX_WEIGHT}kgs`
            }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
            button1="Yes, I am sure"
            button2="Modify Selection"
            handleClose={handleModalClose}
          />
        )}
        {isOrderRecurringModal && (
          <RecurringOrderModal
            handleClose={() => setIsOrderRecurringModal(false)}
          />
        )}

        {isOrderDiscardModal && (
          <DeleteModal
            moduleTitle={'Discard Changes'}
            moduleTitleDesc={`Are you sure you want to discard the changes of order details?`}
            handleClose={handleDiscardModalClose}
            dangerBtnText={'Discard'}
          />
        )}

        {isShowSaveAsDraftModal && (
          <ConfirmationModalCmp
            title="Save As Draft"
            description="Are you sure you want to save this order as draft?"
            Icon={<Save01 className="w-7 h-7" />}
            handleClose={() => setIsShowSaveAsDraftModal(false)}
            isSubmitting={isLoading}
            handleSubmit={handleSubmitSaveAsDraft}
          />
        )}
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderDetails;
