import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadBoardRatesLoader = () => (
  <>
    <td className="px-5 py-4 max-w-[1px] w-44 min-w-44">
      <div className="flex items-center truncate">
        {/* <img
            src="https://storage.googleapis.com/western-alliance-gcp/474454c2-1e01-418b-b50d-65c6dc2c3248.svg"
            className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
          /> */}
        <div className="table-profile-customer-circle-xl custom-loading">
          TT
        </div>
        <div className="truncate ml-3">
          <TooltipCmp message={''} parentClassName="">
            <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5 ml-3 custom-loading">
              Canadian Logistic Express
            </span>
          </TooltipCmp>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 max-w-24 w-24 min-w-24">
      <div className="rounded-md border border-utilityGray200 px-1 py-0.5 inline-flex items-center gap-x-0.5 text-gray500 font-normal custom-loading">
        4.5
        <ArrowUp className="w-4 h-4" />
      </div>
    </td>
    <td className="px-5 py-4 max-w-32 w-32 min-w-32">
      <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
        in progress
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-32 w-32 min-w-32">
      <span className="custom-loading">inbound</span>
    </td>
    <td className="px-5 py-4 max-w-[1px] w-32 min-w-32">
      <span className="custom-loading">Madison</span>
    </td>
    <td className="px-5 py-4  max-w-32 w-32 min-w-32">
      <span className="custom-loading">125</span>
    </td>
    <td className="px-5 py-4 max-w-40 w-40 min-w-40">
      <span className="custom-loading">Dry Van</span>
    </td>
    <td className="px-5 py-4 max-w-44 w-44 min-w-44">
      <div className="truncate custom-loading">Aug. 11th</div>
    </td>
    <td className="px-5 py-4 min-w-36 w-36 max-w-36">
      <div className="truncate custom-loading">Aug. 11th</div>
    </td>
    <td className="px-5 py-4 max-w-44 w-44 min-w-44">
      <p className="truncate custom-loading">
        dispatch@beebalogistics.ca 2045135312
      </p>
    </td>
    <td className="px-5 py-4 max-w-16 w-16 min-w-16">
      <div className="w-4 h-4 custom-loading">
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer " />
      </div>
    </td>
  </>
);

export default LoadBoardRatesLoader;
