import React, { useContext, useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';

import CostBreakDownFooterRow from './CostBreakDownFooterRow';
import CostBreakdownlRow from './CostBreakdownRow';
import CostBreakdownlSubRow from './CostBreakdownSubRow';

const OrderCostBreakDown = () => {
  const [subDataVisibility, setSubDataVisibility] = useState<any>({});
  const [totalMargin, setTotalMargin] = useState<any>({});

  const { currentLegData, order } = useContext<any>(OrderContext);

  console.log('order', order);

  const combinedData = [
    ...(Array.isArray(order?.salesPrice)
      ? order.salesPrice.map((item: any) => ({
          ...item,
          name: item?.customer ? item?.customer?.name : '',
          image: item?.customer ? item?.customer?.image : '',
          imageUrl: item?.customer ? item?.customer?.imageUrl : '',
          isSalesPrice: true,
        }))
      : []),
    ...(Array.isArray(currentLegData?.carrierPrice)
      ? currentLegData.carrierPrice.map((item: any) => ({
          ...item,
          name: item?.carrier ? item?.carrier?.name : '',
          image: item?.carrier ? item?.carrier?.image : '',
          imageUrl: item?.carrier ? item?.carrier?.imageUrl : '',
          isSalesPrice: false,
        }))
      : []),
  ];

  const uniqueCombinedData: any = [];
  let totalSalePriceCAD = 0;
  let totalSalePriceUSD = 0;
  let totalMarginInCAD = 0;
  let totalMarginInUSD = 0;

  combinedData.forEach((item, index) => {
    item.id = index;

    if (item.isSalesPrice) {
      totalSalePriceCAD = item.totalSalePrice || 0;
      totalSalePriceUSD = item.totalSalePriceUSD || 0;

      uniqueCombinedData.push({
        ...item,
        totalSalePriceCAD,
      });
    } else {
      const { totalPrice = 0, totalPriceUSD = 0 } = item;

      const carrierRatePercentageInCAD =
        totalSalePriceCAD > 0
          ? ((totalSalePriceCAD - totalPrice) / totalSalePriceCAD) * 100
          : 0;
      const carrierRatePercentageInUSD =
        totalSalePriceUSD > 0
          ? ((totalSalePriceUSD - totalPriceUSD) / totalSalePriceUSD) * 100
          : 0;
      const carrierDiffCAD = Math.abs(totalSalePriceCAD - totalPrice);
      const carrierDiffUSD = Math.abs(totalSalePriceUSD - totalPriceUSD);

      totalMarginInCAD += totalSalePriceCAD - totalPrice;
      totalMarginInUSD += totalSalePriceUSD - totalPriceUSD;

      uniqueCombinedData.push({
        ...item,
        carrierRatePercentageInCAD: carrierRatePercentageInCAD.toFixed(2),
        carrierRatePercentageInUSD: carrierRatePercentageInUSD.toFixed(2),
        carrierDiffCAD: carrierDiffCAD.toFixed(2),
        carrierDiffUSD: carrierDiffUSD.toFixed(2),
      });
    }
  });

  useEffect(() => {
    setTotalMargin({ totalMarginInCAD, totalMarginInUSD });
  }, [totalMarginInCAD, totalMarginInUSD]);

  const CostBreakdownHeadCells = useMemo(
    () => [
      {
        id: 'accordionAction',
        name: '',
        rowClassName: '',
      },
      {
        id: 'billingParty',
        name: 'Billing Party',
        rowClassName: '',
      },
      {
        id: 'providedRate',
        name: 'Provided Rate',
        rowClassName: '',
      },
      {
        id: 'cad',
        name: 'CAD',
        rowClassName: '',
      },
      {
        id: 'usd',
        name: 'USD',
        rowClassName: '',
      },
    ],
    []
  );

  const handleOnRowClick = (currentCustomer: any) => {
    setSubDataVisibility((prevState: any) => ({
      ...prevState,
      [currentCustomer.id]: !prevState[currentCustomer.id],
    }));
  };

  return (
    <>
      {combinedData && combinedData?.length > 0 && (
        <li>
          <div className="flex gap-x-1.5 mb-1.5">
            <h6 className="text-grayLight600 text-xs font-medium flex-1">
              Order Cost Breakdown
            </h6>
            <div className="flex gap-x-4">
              <div className="text-grayLight900 text-sm font-normal">
                USD:&nbsp;
                <span className="font-medium">
                  ${getFormattedNumber(currentLegData?.usdRate)}
                </span>
              </div>
              <div className="text-grayLight900 text-sm font-normal">
                CAD:&nbsp;
                <span className="font-medium">
                  ${getFormattedNumber(currentLegData?.cadRate)}
                </span>
              </div>
              {/* <div className="text-grayLight900 text-sm font-normal">
            Conversion Rate:&nbsp;
            <span className="font-medium">1</span>
          </div> */}
            </div>
          </div>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={CostBreakdownHeadCells}
                tableDataArr={uniqueCombinedData}
                TableRowCmp={CostBreakdownlRow}
                tableRowCmpProps={{ subDataVisibility }}
                isTableRowClickable={true}
                onRowClick={(currentCustomer: any) => {
                  handleOnRowClick(currentCustomer);
                }}
                SubDataCmp={CostBreakdownlSubRow}
                isDisplaySubData={true}
                selectedRows={subDataVisibility}
                TableFooterRowCmp={CostBreakDownFooterRow}
                footerDataArr={[totalMargin]}
              />
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default OrderCostBreakDown;
